import { useEffect } from 'react';

import { useRouter } from 'next/router';

import GET_ENVIRONMENT from 'src/graphql/queries/getEnvironment.gql';

import useDeliveryCountry from 'src/hooks/useDeliveryCountry';
import usePriceSwitch from 'src/hooks/usePriceSwitch';
import useLazyShopQuery from 'src/hooks/useLazyShopQuery';
import useLocalStorage from 'src/hooks/useLocalStorage';
import useCurrency from 'src/hooks/useCurrency';

import { EnvironmentContext } from './EnvironmentContext';

const defaultProps = {
  children: null,
};

export const EnvironmentProvider = ({ children } = defaultProps) => {
  const router = useRouter();
  const [getLocalStorageItem, setLocalStorageItem] = useLocalStorage();
  const [, setCurrency] = useCurrency();
  const [, setPriceType] = usePriceSwitch();
  const [, setDeliveryCountry] = useDeliveryCountry();

  const [
    getEnvironment,
    { data: environmentData, loading: isEnvironmentLoading, refetch: refetchEnvironment },
  ] = useLazyShopQuery(GET_ENVIRONMENT, {
    onCompleted: ({ environment }) => {
      const selectedPriceType = environment?.availablePriceTypes?.find((item) => !!item.selected);

      if (getLocalStorageItem('currentDelivery')?.name !== environment.currentDelivery?.name) {
        setLocalStorageItem('currentDelivery', environment.currentDelivery);
      }
      if (getLocalStorageItem('currentCurrency')?.id !== environment.currentCurrency?.id) {
        setLocalStorageItem('currentCurrency', environment.currentCurrency);
      }
      if (getLocalStorageItem('currentPriceType')?.name !== selectedPriceType.name) {
        setLocalStorageItem('currentPriceType', selectedPriceType);
      }
    },
  });

  const localStorageCurrentDeliveryCountry = getLocalStorageItem('currentDelivery');
  const localStorageCurrentCurrency = getLocalStorageItem('currentCurrency');
  const localStorageCurrentPriceType = getLocalStorageItem('currentPriceType');

  useEffect(() => {
    getEnvironment();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEnvironmentLoading) {
      return;
    }

    router.events.on('routeChangeComplete', getEnvironment);

    return () => {
      router.events.off('routeChangeComplete', getEnvironment);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EnvironmentContext.Provider
      value={{
        getEnvironment,
        environmentData,
        setCurrency,
        setDeliveryCountry,
        localStorageCurrentDeliveryCountry,
        localStorageCurrentCurrency,
        refetchEnvironment,
        isEnvironmentLoading,
        localStorageCurrentPriceType,
        setPriceType,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};
