import { Fragment, memo, forwardRef } from 'react';
import NextLink from 'next/link';
import Icon from '../Icon';
import * as S from './styles';

const nextLinkBaseProps = {
  prefetch: false,
};

const defaultProps = {
  href: null,
  variant: 'primary',
  children: null,
  isNextLink: false,
  nextLinkProps: {},
  icon: null,
  iconBefore: false,
  blank: false,
  block: false,
  onClick: () => {},
  hasMicrodataName: false,
};

// eslint-disable-next-line react/display-name
export const Link = memo(
  forwardRef(
    (
      {
        href,
        variant,
        children,
        isNextLink,
        nextLinkProps,
        icon,
        iconBefore,
        blank,
        block,
        onClick,
        hasMicrodataName,
        ...restProps
      } = defaultProps,
      ref,
    ) => {
      const Parent = isNextLink ? NextLink : Fragment;
      const parentProps = isNextLink
        ? {
            ...nextLinkBaseProps,
            ...nextLinkProps,
          }
        : {};
      const hasIconBefore = icon && iconBefore;
      const hasIconAfter = icon && !iconBefore;

      if (isNextLink) {
        parentProps.href = href;
        parentProps.passHref = true;
      }

      const contentProps = {
        className: 'content',
        role: 'presentation',
      };

      if (hasMicrodataName) {
        contentProps.itemProp = 'name';
      }

      return (
        <Parent {...parentProps}>
          <S.Anchor
            href={!isNextLink ? href : null}
            variant={variant}
            iconBefore={hasIconBefore}
            iconAfter={hasIconAfter}
            hasContent={!!children}
            blank={blank}
            block={block}
            onClick={onClick}
            ref={ref}
            {...restProps}
          >
            {hasIconBefore && <Icon icon={icon} className="icon" />}

            {children ? (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <span {...contentProps}>{children}</span>
            ) : (
              <span className="spacer" aria-hidden="true">
                &nbsp;
              </span>
            )}

            {hasIconAfter && <Icon icon={icon} className="icon" />}
          </S.Anchor>
        </Parent>
      );
    },
  ),
);

Link.defaultProps = defaultProps;
