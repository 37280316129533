export const ACTIONS = {
  SET_DELIVERY_COUNTRY: 'setDeliveryCountry',
  SET_LOCALE: 'setLocale',
  SET_CURRENCY: 'setCurrency',
  OPEN_DROPDOWN: 'openDropdown',
  CLOSE_DROPDOWN: 'closeDropdown',
  SET_INITIAL_STATE_LAZILY: 'setInitialStateLazily',
  SET_PRICE_TYPE: 'setPriceType',
};

export const initialState = {
  locale: '',
  deliveryCountry: '',
  currency: {},
  isDropdownOpen: false,
  priceType: 1,
};

const handlers = {
  [ACTIONS.SET_CURRENCY]: (state, action) => {
    const { currency } = action.payload;

    return { ...state, currency };
  },
  [ACTIONS.OPEN_DROPDOWN]: (state) => {
    return { ...state, isDropdownOpen: true };
  },
  [ACTIONS.CLOSE_DROPDOWN]: (state) => {
    return { ...state, isDropdownOpen: false };
  },
  [ACTIONS.SET_INITIAL_STATE_LAZILY]: (state, action) => {
    const { locale, deliveryCountry, currency, priceType } = action.payload;

    return { ...state, locale, deliveryCountry, currency, priceType };
  },
  [ACTIONS.SET_CURRENCY]: (state, action) => {
    const { currency } = action.payload;

    return { ...state, currency };
  },
  [ACTIONS.SET_LOCALE]: (state, action) => {
    const { locale } = action.payload;

    return { ...state, locale };
  },
  [ACTIONS.SET_DELIVERY_COUNTRY]: (state, action) => {
    const { deliveryCountryObj } = action.payload;

    return { ...state, currency: deliveryCountryObj.defcurr, deliveryCountry: deliveryCountryObj.id };
  },
  [ACTIONS.SET_PRICE_TYPE]: (state, action) => {
    const { priceType } = action.payload;

    return { ...state, priceType };
  },
};

export const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);
