export const sendMessageTargetsKeys = {
  SALESMAN_INQUIRY: 'salesmanInquiry',
  PRODUCT_AVAILABILITY_INQUIRY: 'productAvailabilityInquiry',
  CONTACT_CMS: 'contactFormViaLink',
};

export const sendMessageTargetsMap = new Map([
  [0, sendMessageTargetsKeys.SALESMAN_INQUIRY],
  [1, sendMessageTargetsKeys.PRODUCT_AVAILABILITY_INQUIRY],
  [2, sendMessageTargetsKeys.CONTACT_CMS],
]);
