import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client/react';
import GET_LOCAL_STATE from 'src/graphql/queries/getLocalState.gql';
import GET_AVAILABLE_PRICE_TYPE from 'src/graphql/queries/getEnvironment.gql';
import SET_PRICE_TYPE from 'src/graphql/mutations/setPriceType.gql';
import { defaultPriceType } from 'config/basket';
import useRESTMutation from '../useRESTMutation';
import useShopQuery from '../useShopQuery';

const defaultOnCompletedCallback = () => null;

export const usePriceSwitch = (onCompletedCallback = defaultOnCompletedCallback) => {
  const [initialPriceType, setInitialPriceType] = useState(defaultPriceType);
  const { data: localState } = useQuery(GET_LOCAL_STATE);
  const cachedPriceType = localState?.basketSummary?.priceType?.type || null;

  const { data: priceTypeData, refetch: refetchPriceTypeData } = useShopQuery(GET_AVAILABLE_PRICE_TYPE, {
    fetchPolicy: 'cache-first',
  });

  const resPriceTypesAvailable = useMemo(
    () => (priceTypeData ? priceTypeData?.environment?.availablePriceTypes || [] : []),
    [priceTypeData],
  );

  const [priceTypesAvailable, setPriceTypesAvailable] = useState(resPriceTypesAvailable);

  const [setPriceTypeRequest] = useRESTMutation(SET_PRICE_TYPE, {
    onCompleted: onCompletedCallback,
  });

  const setPriceType = async (type) => {
    await setPriceTypeRequest({
      variables: { input: { type } },
    });

    refetchPriceTypeData();
  };

  useEffect(() => {
    if (cachedPriceType) {
      setInitialPriceType(cachedPriceType);
    }
  }, [cachedPriceType]);

  useEffect(() => {
    setPriceTypesAvailable(resPriceTypesAvailable);
  }, [resPriceTypesAvailable]);

  return [priceTypesAvailable, setPriceType, initialPriceType];
};
