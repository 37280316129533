import { useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import useDebounce from 'src/hooks/useDebounce';

import * as S from './styles';

export const Overlay = ({ displayOverlay = false, className, onClick = () => {}, ...rest }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [docHeight, setDocHeight] = useState(0);
  const router = useRouter();
  const handleHideOverlay = () => {
    setIsVisible(false);
  };
  const handleShowOverlay = () => {
    setIsVisible(true);
  };

  const [handleHideVisibilityDebounced, cancelDebounce] = useDebounce(() => {
    handleHideOverlay();
  }, 500);

  useEffect(() => {
    if (displayOverlay) {
      cancelDebounce();
      handleShowOverlay();
      return;
    }
    handleHideVisibilityDebounced();
    /* eslint-disable-next-line */
  }, [displayOverlay]);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleHideOverlay);
    return () => {
      router.events.off('routeChangeComplete', handleHideOverlay);
    };
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    const updateDocHeight = () => {
      const height = document.body.offsetHeight;
      setDocHeight(height);
    };

    updateDocHeight();

    window.addEventListener('resize', updateDocHeight);

    return () => {
      window.removeEventListener('resize', updateDocHeight);
    };
  }, []);

  return (
    <S.Overlay
      displayOverlay={displayOverlay}
      isVisible={isVisible}
      bgHeight={docHeight}
      className={className}
      onClick={onClick}
      {...rest}
    />
  );
};
