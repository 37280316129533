import SimpleBarComponent from 'simplebar-react';
import styled from 'styled-components';
import { rem, em } from 'polished';

import Text from 'src/components/Text';
import ImportedButton from 'src/components/Button';
import ImportedInput from 'src/components/Input';

export const Select = styled.div`
  position: relative;

  .select__counter {
    padding-left: 0.25em;

    color: ${({ theme }) => theme.defaultPalette.monochrome[3]};
  }

  width: 100%;

  .selectBox {
    width: 100%;
  }

  & button {
    box-shadow: none;

    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[2]};
    width: 100%;
    position: relative;
    padding-top: ${em(12)};
    padding-bottom: ${em(12)};

    & > .inner {
      justify-content: space-between;
    }

    & .content {
      color: #000;
    }

    &::after {
      content: ${({ placeholder, hasSelectedItemId }) =>
        !!placeholder && hasSelectedItemId ? `"${placeholder}"` : ''};
      position: absolute;
      top: ${em(-10)};
      left: ${rem(8)};
      background-color: white;
      color: gray;
      padding: 0 ${em(8)};
      font-size: 14px;
      border-radius: 7px;
    }

    &:not(:disabled):hover {
      box-shadow: none;
    }
  }
`;

export const SelectLabel = styled(Text)`
  position: absolute;
  top: -10px;
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  color: ${({ theme }) => theme.defaultPalette.monochrome[3]};
  z-index: 100;
  left: 10px;
  &:after {
    content: ' ';
  }
`;

export const CustomSelect = styled.div`
  position: relative;
  display: inline-block;
`;

export const NativeSelect = styled.select`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
`;

export const SelectListWrapper = styled.div`
  top: ${rem(60)};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  width: 100%;
  position: absolute;
  border-radius: ${rem(20)};
  padding: ${rem(10)} ${rem(5)};
  border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  z-index: 200;
  margin-top: ${rem(-1)};
`;

export const SelectScrollBar = styled(SimpleBarComponent)`
  width: 100%;
  padding: 0.25em;
  max-height: ${rem(275)};
`;

export const SelectList = styled.ul`
  padding: 0;
  position: relative;
  list-style: none;
`;

export const ScrollBarWrapper = styled.div`
  ${({ addRightMarginToItem }) => addRightMarginToItem && `padding-right: ${rem(5)}`}
`;

export const SelectListItem = styled.li`
  background-color: ${({ isHighlighted, noBackgroundHighlight, theme }) =>
    isHighlighted && !noBackgroundHighlight ? theme.defaultPalette.monochrome[2] : 'unset'};
  cursor: pointer;
  min-height: 33px;
  display: flex;
  padding: 0 16px;
  display: flex;
  align-items: center;
  text-align: left;
  ${({ addRightMarginToItem }) => addRightMarginToItem && 'margin-right: 13px'};
  &.active {
    font-weight: 700;
  }
`;

export const TooltipBox = styled.div`
  margin-top: 2px;
`;

export const Tooltip = styled.span`
  background: inherit;
  color: ${({ theme }) => theme.input.tooltipColor};
  font-family: inherit;
  font-size: ${({ theme }) => theme.input.tooltipFontSize};
`;

export const OptionWithIconWrapper = styled.div`
  display: flex;

  text-align: start;
  align-items: center;
  gap: 8px;
`;

export const SelectButton = styled(ImportedButton)`
  border-radius: ${rem(20)};
  text-align: left;
`;

export const Search = styled.div`
  margin-bottom: ${rem(10)};
  padding: 0 ${rem(5)};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  z-index: 1000;
  ${({ addRightMarginToItem }) => addRightMarginToItem && 'margin-right: 13px'};
  position: relative;
`;

export const SearchInput = styled(ImportedInput)`
  margin-bottom: 0;
  width: 100%;
  height: 40px;

  .inputBox {
    height: 100%;

    background-color: ${({ theme }) => theme.defaultPalette.monochrome[2]};
  }
  .labelBox {
    display: none;
  }
  .icon {
    background-color: transparent;
    height: 18px;

    width: 20px;
  }
`;
