import { clientTypesKeys, clientTypesMap } from 'src/enums/clientsTypes';

import { createNextLinkProps } from 'src/utils/createNextLinkProps';

import useCoreRoutesInfo from 'src/hooks/useCoreRoutesInfo';
import useLanguage from 'src/hooks/useLanguage';

import LogoImg from './LogoImg';
import Link from '../Link';

import * as S from './styles';

const defaultProps = {
  user: null,
  homePageSlug: '',
  homePageLayout: '',
  inverse: false,
};

export const Logo = ({ user, inverse, shouldDisplayLogoAsH1, className } = defaultProps) => {
  const coreRoutesInfo = useCoreRoutesInfo();
  const [language] = useLanguage();

  const isB2BClientType = clientTypesMap.get(user?.clientType) === clientTypesKeys.CLIENT_B2B;

  const homePageLayout = coreRoutesInfo?.homePage?.layout;

  let linkProps;

  switch (clientTypesMap.get(user?.clientType)) {
    case clientTypesKeys.CLIENT_B2B: {
      const userB2bHomePageLayout = coreRoutesInfo?.userB2bHomePage?.layout;
      const userB2bHomePageSlug = coreRoutesInfo?.userB2bHomePage?.name?.[language];
      linkProps = createNextLinkProps(userB2bHomePageSlug, userB2bHomePageLayout);
      break;
    }
    case clientTypesKeys.CLIENT_B2C: {
      const userB2CHomePageLayout = coreRoutesInfo?.userB2cHomePage?.layout;
      const userB2CHomePageSlug = coreRoutesInfo?.userB2cHomePage?.name?.[language];
      linkProps = createNextLinkProps(userB2CHomePageSlug, userB2CHomePageLayout);
      break;
    }
    default: {
      linkProps = createNextLinkProps(coreRoutesInfo?.homePage?.name?.[language], homePageLayout);
      break;
    }
  }

  return (
    <S.HeaderLogoWrapper isB2BClientType={isB2BClientType} className={className}>
      <Link blank {...linkProps}>
        <LogoImg inverse={inverse} isB2BClientType={isB2BClientType} shouldDisplayLogoAsH1={shouldDisplayLogoAsH1} />
      </Link>
    </S.HeaderLogoWrapper>
  );
};

Logo.defaultProps = defaultProps;
