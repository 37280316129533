import styled, { css } from 'styled-components';
import { em, rem } from 'polished';
import { up } from 'styled-breakpoints';
import Link from '../Link';
import IconComponent from '../Icon';
import Heading from '../Heading';

export const SearchSuggest = styled.div`
  background: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  position: absolute;
  top: calc(100% - 1.7em);
  width: 100%;
  z-index: ${({ isOpen }) => (isOpen ? 299 : 28)};
  left: 0;
  border-radius: 0 ${rem(30)} ${rem(30)} ${rem(30)};

  border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  transform-origin: top left;
  padding: 3em 1.5em 1em;
  max-height: calc(100vh - ${em(160)});
  overflow-y: scroll;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.1s ease;

  box-shadow: 0px -4px 0px 4px ${({ theme }) => theme.defaultPalette.monochrome[0]},
    inset 0px -4px 0px 4px ${({ theme }) => theme.defaultPalette.monochrome[0]};

  a:focus,
  [role='button']:focus {
    text-decoration: underline;

    color: ${({ theme }) => theme.defaultPalette.teal[0]};
  }

  ${up('tablet')} {
    box-shadow: none;
  }

  ${up('desktop')} {
    width: ${em(880)};
    max-width: 70vw;
    overflow: hidden;
    display: flex;
    height: auto;
    left: -1px;
    padding: 3em 1.5em ${({ totalmore }) => (totalmore ? '2.5em' : '0')};
    max-height: max-content;

    border: 1px solid ${({ theme }) => theme.defaultPalette.prussianBlue[3]};
  }

  &:after {
    display: none;

    ${up('desktop')} {
      content: '';
      position: absolute;
      width: 1px;
      top: 3em;
      bottom: 4em;
      right: 53%;
      display: block;

      background-color: ${({ theme }) => theme.defaultPalette.monochrome[2]};
    }
  }

  ${({ isOpen, theme }) => {
    if (isOpen) {
      return css`
        animation: ${theme.animations.scaleUpWithFade} ${theme.transitions.durations[1]} 1;
        transform: scale(1);
        z-index: 299 !important;
      `;
    }
    return css`
      transform: scale(0);
      opacity: 0;
      pointer-events: none;
      z-index: 0 !important;
      border: none !important;
    `;
  }};
`;

export const AutoSuggestLink = styled(Link)`
  display: block !important;
  font-size: ${em(16)} !important;
  line-height: 130% !important;
  padding: 0.25em 0;

  ${up('desktop')} {
    font-size: ${em(14)} !important;
  }

  .content {
    display: block !important;
  }
`;

export const SearchSuggestFooter = styled.div`
  background: ${({ theme }) => theme.defaultPalette.monochrome[1]};
  padding: 0.5em 1em;
  position: relative;
  bottom: -1em;
  width: calc(100% + 3em);
  left: -1.5em;

  ${up('desktop')} {
    position: absolute;
    bottom: 0em;
    width: 100%;
    left: 0;
  }
`;

export const FooterAutoSuggestLink = styled(AutoSuggestLink)`
  font-size: ${em(16)};
`;

export const LinksContainer = styled.div`
  padding-left: 1em;
`;

export const PhrasesContainer = styled.div`
  padding-bottom: 0.5em;
`;

export const CategoriesContainer = styled.div`
  padding-bottom: 0.5em;
`;

export const BrandsContainer = styled.div`
  padding-bottom: 1em;
`;

export const ProductsContainer = styled.div`
  ${up('desktop')} {
    padding-bottom: 1em;
    width: 53%;
    padding-left: 1em;
  }
`;

export const LinksLeftContainer = styled.div`
  ${up('desktop')} {
    width: 47%;
    padding-right: 2em;
  }
`;

export const Icon = styled(IconComponent)`
  path {
    fill: ${({ theme }) => theme.defaultPalette.blazeOrange[0]};
  }
`;

export const ProductHeading = styled(Heading)`
  padding-bottom: 0.5em;

  ${up('desktop')} {
    display: none;
  }
`;

export const SearchSuggestBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 111;
  opacity: 0;
`;

export const SearchFilter = styled.div`
  display: block;
  font-size: ${em(16)};
  line-height: 130%;
  padding: 0.25em 0;
  cursor: pointer;

  ${up('desktop')} {
    font-size: ${em(14)};
  }

  &:hover {
    color: ${({ theme }) => theme.defaultPalette.teal[0]};
    text-decoration: underline;
  }
`;
