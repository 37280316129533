import { useRef, useState, useEffect } from 'react';

export function useTooltipOpenByClick(anchorElement, popperElement) {
  const timer = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!anchorElement) {
      return undefined;
    }
    const handleAnchorClick = () => {
      setIsOpen((open) => !open);
    };

    const handleOutsideClick = (e) => {
      if (!popperElement) {
        return;
      }

      if (!(popperElement.contains(e.target) || anchorElement.contains(e.target))) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    anchorElement.addEventListener('click', handleAnchorClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
      anchorElement.removeEventListener('click', handleAnchorClick);
    };
  }, [popperElement, anchorElement, timer]);

  return isOpen;
}
