import { useMemo } from 'react';
import GET_CURRENCY from 'src/graphql/queries/getEnvironment.gql';
import SET_CURRENCY from 'src/graphql/mutations/setCurrency.gql';
import useShopQuery from '../useShopQuery';
import useRESTMutation from '../useRESTMutation';

const defaultOnCompletedCallback = () => null;

const defaultArgs = {
  onCompletedCallback: defaultOnCompletedCallback,
  fetchPolicy: 'network-only',
};

export const useCurrency = ({ onCompletedCallback, fetchPolicy } = defaultArgs) => {
  const { data: currencyData, refetch: refetchCurrencyData } = useShopQuery(GET_CURRENCY, {
    fetchPolicy,
  });

  const [setCurrencyRequest] = useRESTMutation(SET_CURRENCY, {
    onCompleted: (...args) => {
      refetchCurrencyData();
      onCompletedCallback(...args);
    },
  });

  const resAvailableCurrencies = useMemo(() => (currencyData ? [...currencyData.environment.availableCurrences] : []), [
    currencyData,
  ]);

  const resCurrentCurrency = useMemo(() => (currencyData ? { ...currencyData.environment.currentCurrency } : {}), [
    currencyData,
  ]);

  const setCurrency = async (currencyId) => {
    await setCurrencyRequest({
      variables: {
        input: {
          currencyId,
        },
      },
    });
  };

  return [{ availableCurrencies: resAvailableCurrencies, currentCurrency: resCurrentCurrency }, setCurrency];
};
