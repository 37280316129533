import dynamic from 'next/dynamic';

import useTranslation from 'src/hooks/useTranslation';

import Notifications from 'src/components/Notifications';

import useLogic from './useNotificationsIndicatorLogic';

import * as S from './styles';
import NumberForIcon from 'src/components/NumberForIcon';

const NavbarMenuDropdown = dynamic(() => import('src/components/NavbarMenuDropdown'), { ssr: false });

export const NotificationsIndicator = ({ ...rest }) => {
  const { t } = useTranslation();
  const {
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    isNotificationsListPopped,
    handleCloseModal,
    isModalOpen,
    shouldImportModalRef,
    isNotificationsPending,
    markNotificationAsClosed,
    closedNotificationId,
    changeNotificationStatus,
    goToNotificationDetails,
    newNotificationsCount,
    fetchMore,
    skeletonsQuantity,
    showSkeleton,
    isMobileDevice,
  } = useLogic();

  const commonProps = {
    markNotificationAsClosed,
    fetchMore,
    changeNotificationStatus,
    closedNotificationId,
    goToNotificationDetails,
    isNotificationsPending,
    skeletonsQuantity,
    showSkeleton,
    isMobileDevice,
  };

  return (
    <S.NotificationsIndicatorMainWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...rest}>
      <S.Button blank onClick={handleClick}>
        <NumberForIcon
          number={newNotificationsCount < 10 ? newNotificationsCount : '9+'}
          hideNumber={newNotificationsCount === 0}
        >
          <S.Bell />
        </NumberForIcon>

        <S.IconLabel className="clipboardLabel">{t('notifications')}</S.IconLabel>
      </S.Button>
      {shouldImportModalRef.current === true && (
        <S.Modal
          isOpen={isModalOpen}
          onCloseClick={handleCloseModal}
          title={t('notifications')}
          icon="notificationsBell"
        >
          <Notifications {...commonProps} />
        </S.Modal>
      )}

      {isNotificationsListPopped && (
        <>
          <div style={{ width: '50px', height: '20px', position: 'absolute' }} />
          <NavbarMenuDropdown id="navbar-menu-dropdown">
            <Notifications {...commonProps} />
          </NavbarMenuDropdown>
        </>
      )}
    </S.NotificationsIndicatorMainWrapper>
  );
};
