import { isStorageAvailable } from 'src/utils/checkIsStorageAvailable';

export const useLocalStorage = () => {
  const setLocalStorageItem = (itemName, value) => {
    if (typeof window !== 'undefined' && isStorageAvailable('localStorage')) {
      localStorage.setItem(itemName, JSON.stringify(value));
    }
  };

  const getLocalStorageItem = (itemName) => {
    if (typeof window !== 'undefined' && isStorageAvailable('localStorage')) {
      const localStorageValue = localStorage.getItem(itemName);
      return JSON.parse(localStorageValue);
    }
  };

  return [getLocalStorageItem, setLocalStorageItem];
};
