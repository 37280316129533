import { defaultPalette as p1 } from 'src/styles/palettes';

const getBadgeTheme = (variant) =>
  ({
    success: { color: p1.chateauGreen[0] },
    error: { color: p1.alizarinCrimson[1] },
    new: { color: p1.chateauGreen[0] },
  }[variant] ?? { color: p1.prussianBlue[0] });

export { getBadgeTheme };
