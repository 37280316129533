import { createContext } from "react";

export const AuthenticationContext = createContext({
  isAuthModalOpen: null,
  setIsAuthModalOpen: () => {
    // eslint-disable-next-line no-console
    console.warn('AuthenticationContext has no provider over him');
  },
  shouldRefetch: null,
  setShouldRefetch: () => {
      // eslint-disable-next-line no-console
      console.warn('AuthenticationContext has no provider over him');
  }
})

AuthenticationContext.displayName = 'AuthenticationContext';