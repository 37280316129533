import styled, { css } from 'styled-components';
import { em } from 'polished';

const animatedUnderline = (focusColor, activeColor, bottomPos = -4) => css`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: ${em(bottomPos)};
    left: 0;
    height: 1px;
    width: 100%;
    transform: scaleX(0%);
    transform-origin: left center;
    background: transparent;
    ${(p) => p.theme.helpers.transition(['background', 'transform'])};
  }

  &:hover,
  &:focus,
  &.focus {
    &:before {
      background: ${focusColor};
      transform: scaleX(100%);
    }
  }

  &:active,
  &.active {
    &:before {
      background: ${activeColor};
      transform: scaleX(0%);
      transform-origin: right center;
    }
  }
`;

const primaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('primary', props)};
  ${animatedUnderline(props.theme.link.primary.underLineColor, props.theme.link.primary.color.active)};
`;

const secondaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('secondary', props)};
`;

const tertiaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('tertiary', props)};
`;

const quaternaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('quaternary', props)};

  .icon {
    color: ${props.theme.link.quaternary.iconColor};
  }
`;

const quinaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('quinary', props)};
  ${animatedUnderline(props.theme.link.quinary.underLineColor, props.theme.link.quinary.color.active)};
`;

const senaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('senary', props)};
  ${animatedUnderline(props.theme.link.senary.underLineColor, props.theme.link.senary.color.active)};
  text-transform: uppercase;
`;

const septenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('septenary', props)};

  .icon {
    path {
      fill: ${props.theme.link.septenary.iconColor};
    }
  }
`;
const novendenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('novendenary', props)};
  &:hover,
  &:focus-visible {
    font-family: ${props.theme.global.fontFamily.secondary};
  }
  .icon {
    path {
      fill: ${props.theme.link.novendenary.iconColor};
    }
  }
`;

const octonaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('octonary', props)};
  ${animatedUnderline(props.theme.link.octonary.underLineColor, props.theme.link.octonary.color.active, 0)};
`;

const nonaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('nonary', props)};

  > .content {
    flex-direction: column;
    align-items: flex-start;

    > *:not(:first-child) {
      color: ${({ theme }) => theme.link.nonary.basedColor};

      &:hover,
      &:focus,
      &.focus {
        color: ${({ theme }) => theme.link.nonary.basedColor};
      }

      &:active,
      &.active {
        color: ${({ theme }) => theme.link.nonary.basedColor};
      }
    }
  }

  .icon {
    color: ${props.theme.link.septenary.iconColor};
  }
`;

const denaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('denary', props)};
`;

const duodenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('duodenary', props)};

  .content {
    text-decoration: underline;
  }
`;

const tridenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('tridenary', props)};

  &:hover,
  &:active {
    .content {
      text-decoration: underline;
    }
  }
`;

const quattuordenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('quattuordenary', props)};
`;

const quindenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('quindenary', props)};

  > .content {
    flex-direction: column;
    align-items: flex-start;

    > *:not(:first-child) {
      color: ${({ theme }) => theme.link.quindenary.basedColor};

      &:hover,
      &:focus,
      &.focus {
        color: ${({ theme }) => theme.link.quindenary.basedColor};
      }

      &:active,
      &.active {
        color: ${({ theme }) => theme.link.quindenary.basedColor};
      }
    }
  }

  .icon {
    path {
      fill: ${props.theme.link.septenary.iconColor};
    }
  }
`;

const sedenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('sedenary', props)};

  > .content {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5em 1em;

    color: ${({ theme }) => theme.link.sedenary.color.default};
    box-shadow: 0 -0.125rem 0 -0.0625rem ${({ theme }) => theme.link.sedenary.underLineColor} inset;
  }

  &:hover,
  &:focus,
  &.focus {
    > .content {
      color: ${({ theme }) => theme.link.sedenary.color.active};
      box-shadow: 0 -0.125rem 0 -0.0625rem ${({ theme }) => theme.link.sedenary.color.active} inset;
    }
  }
`;

const septendenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('septendenary', props)};

  .content {
    text-decoration: underline;
  }
`;

const octodenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultLinkVariant('octodenary', props)};

  .icon {
    path {
      fill: ${props.theme.link.septenary.iconColor};
    }
  }
`;

export const Anchor = styled.a`
  display: ${(p) => (p.block ? 'flex' : 'inline-flex')};
  align-items: center;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  color: inherit;

  ${(p) =>
    !p.blank &&
    css`
      ${p.theme.helpers.transition('color')};

      & > .content {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ${p.variant === 'primary' && primaryVariantStyles(p)};
      ${p.variant === 'secondary' && secondaryVariantStyles(p)};
      ${p.variant === 'tertiary' && tertiaryVariantStyles(p)};
      ${p.variant === 'quaternary' && quaternaryVariantStyles(p)};
      ${p.variant === 'quinary' && quinaryVariantStyles(p)};
      ${p.variant === 'senary' && senaryVariantStyles(p)};
      ${p.variant === 'septenary' && septenaryVariantStyles(p)};
      ${p.variant === 'octonary' && octonaryVariantStyles(p)};
      ${p.variant === 'nonary' && nonaryVariantStyles(p)};
      ${p.variant === 'denary' && denaryVariantStyles(p)};
      ${p.variant === 'duodenary' && duodenaryVariantStyles(p)};
      ${p.variant === 'tridenary' && tridenaryVariantStyles(p)};
      ${p.variant === 'quattuordenary' && quattuordenaryVariantStyles(p)};
      ${p.variant === 'quindenary' && quindenaryVariantStyles(p)};
      ${p.variant === 'sedenary' && sedenaryVariantStyles(p)};
      ${p.variant === 'septendenary' && septendenaryVariantStyles(p)};
      ${p.variant === 'octodenary' && octodenaryVariantStyles(p)};
      ${p.variant === 'novendenary' && novendenaryVariantStyles(p)};
    `};

  &:hover,
  &:focus {
    cursor: pointer;
  }

  & > .spacer {
    width: 0;
  }

  & > .icon {
    font-size: inherit;
    line-height: inherit;
    margin-right: ${(p) => p.hasContent && p.iconBefore && '0.5em'};
    margin-left: ${(p) => p.hasContent && p.iconAfter && '0.5em'};
  }
`;
