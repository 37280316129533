import { SSR_BASE_URL } from 'config/ssr';
import pullOutCorePath from './pullOutCorePath';

const transformUrlToNextLink = ({ href, routesMap, slugsMap }) => {
  const origin = typeof window !== 'undefined' ? window?.location?.origin : SSR_BASE_URL;
  const url = new URL(href, origin);

  const { mainSlug } = pullOutCorePath(url.pathname);

  const currentPageId = slugsMap.get(mainSlug);
  const routeMap = routesMap.get(currentPageId);

  if (!routeMap) {
    return null;
  }

  const queryParams = {};
  for (const [key, value] of url.searchParams.entries()) {
    queryParams[key] = value;
  }

  // NOTE: We use hrefWithoutOrigin because it allows us to stay on a specific environment
  return {
    slug: `${url.pathname}${url.search}${url.hash}`,
    layout: routeMap.layout,
    queryParams,
  };
};

export default transformUrlToNextLink;
