import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { breakpointsKeys } from 'src/enums/breakpoints';

export const StyledModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 99999990;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;

  ${up(breakpointsKeys.TABLET)} {
    height: 100vh;
  }
`;
