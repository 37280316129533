const DOCUMENT_READY_KEY = 'complete';

export const checkDocumentStateEqualComplete = (callback) => {
  const isCompleteReadyDocument = document.readyState === DOCUMENT_READY_KEY;

  if (isCompleteReadyDocument) {
    callback();
  } else {
    const onReadyStateChangeHandler = () => {
      if (document.readyState === DOCUMENT_READY_KEY) {
        callback();
        document.removeEventListener('readystatechange', onReadyStateChangeHandler);
      }
    };

    document.addEventListener('readystatechange', onReadyStateChangeHandler);
  }
};
