import { useEffect, useRef, useState } from 'react';

const defaultConfigProps = {
  anchorElement: true,
  popperElement: true,
  delayOpen: 0,
};

export function useTooltipOpenByHover(anchorElement, popperElement, delayClose, config = defaultConfigProps) {
  const closeTimer = useRef();
  const openTimer = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!anchorElement) {
      return undefined;
    }

    const handleMouseEnter = () => {
      if (closeTimer.current) {
        clearTimeout(closeTimer.current);
        closeTimer.current = undefined;
      }

      openTimer.current = setTimeout(() => {
        setIsOpen(true);
      }, config.delayOpen);
    };

    const handleMouseLeave = () => {
      closeTimer.current = setTimeout(() => {
        setIsOpen(false);
      }, delayClose);

      if (openTimer.current) {
        clearTimeout(openTimer.current);
        openTimer.current = undefined;
      }
    };

    const handleAddEventListeners = (element) => {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('focusin', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
      element.addEventListener('focusout', handleMouseLeave);
      element.addEventListener('touchstart', handleMouseEnter); // ONNINB2B-1138 should work like a toggle on mobile
    };

    const handleClearEventListeners = (element) => {
      element.removeEventListener('mouseenter', handleMouseEnter);
      element.removeEventListener('focusin', handleMouseEnter);
      element.removeEventListener('mouseleave', handleMouseLeave);
      element.removeEventListener('focusout', handleMouseLeave);
      element.removeEventListener('touchstart', handleMouseEnter);
    };

    if (config.anchorElement) handleAddEventListeners(anchorElement);
    if (popperElement && config.popperElement) handleAddEventListeners(popperElement);

    return () => {
      if (config.anchorElement) handleClearEventListeners(anchorElement);
      if (popperElement && config.popperElement) handleClearEventListeners(popperElement);

      if (closeTimer.current) {
        clearTimeout(closeTimer.current);
        closeTimer.current = undefined;
      }

      if (openTimer.current) {
        clearTimeout(openTimer.current);
        openTimer.current = undefined;
      }
    };
  }, [
    popperElement,
    anchorElement,
    delayClose,
    closeTimer,
    config.anchorElement,
    config.popperElement,
    config.delayOpen,
  ]);

  return isOpen;
}
