import styled from 'styled-components';
import { rem } from 'polished';
import Link from '../Link';

export const ProducersList = styled.div`
  /* background: ${(p) => (p.hasInverseBg ? 'transparent' : p.theme.defaultPalette.monochrome[0])}; */

  height: ${(p) => rem(p.logoSize)};
  
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 30px;
  ${({ CMSSpacing }) => CMSSpacing};
  & > * {
    width: inherit;
  }
`;

export const ProducersListCarouselItem = styled.div`
  min-height: ${(p) => rem(p.size)};

  background: none;
`;

export const ProducerLink = styled(Link)`
  ${(p) => p.theme.helpers.transition(['filter', 'transform'])};
  animation: ${(p) => p.theme.animations.fadeIn} ${(p) => p.theme.transitions.durations[0]} linear 1;
  filter: ${(p) => p.greyscaleEffect && 'grayscale(100%)'};
  min-width: ${(p) => rem(p.size)};
  min-height: ${(p) => rem(p.size)};

  transform: scale(0.8);

  &:hover {
    cursor: pointer;
    transform: scale(0.9);
    filter: ${(p) => p.greyscaleEffect && 'grayscale(0%)'};
  }
`;

export const ProducerImg = styled.img`
  object-fit: contain;
  vertical-align: middle;
`;
