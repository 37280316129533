import Icon from 'src/components/Icon';

import * as S from './styles.js';

const defaultProps = {
  icon: '',
  title: '',
  onCloseClick: () => null,
};

export const HeadingWithIcon = ({ icon, title, onCloseClick, ...rest } = defaultProps) => {
  return (
    <S.HeadingWrapper {...rest}>
      {icon && (
        <S.IconWrapper>
          <Icon svgSupportedViewBox="0 0 28 35" fill="#00857E" icon={icon} />
        </S.IconWrapper>
      )}
      {title && <S.Text variant="primary">{title}</S.Text>}
      <S.CloseIcon icon="close" onClick={onCloseClick} />
    </S.HeadingWrapper>
  );
};
