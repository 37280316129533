import * as S from './styles';

const defaultProps = {
  message: '',
};

const messageTextVariant = 'quaternary';

export const NotificationsListErrorItem = ({ message } = defaultProps) => {
  return (
    <S.ListItemErrorWrapper>
      <S.IconWrapper>
        <S.Icon icon="notificationsInfo" fill="#ff6900" svgSupportedViewBox="0 0 28 26" />
      </S.IconWrapper>
      <S.ErrorTextWrapper>
        <S.Text variant={messageTextVariant} dangerouslySetInnerHTML={{ __html: message }} />
      </S.ErrorTextWrapper>
    </S.ListItemErrorWrapper>
  );
};
