import vest, { test, enforce } from 'vest';
import isEmail from 'validator/lib/isEmail';
import { phone } from '../utils/regExps';

enforce.extend({ isEmail });
const requiredField = enforce.template(enforce.isNotEmpty());

export const sendProductAvailabilityQuestionSchemaVariables = {
  availabilityQuestion: {
    max: 1024,
  },
};

export const sendProductAvailabilityQuestionGuestSchema = vest.create((data = {}) => {
  test('availabilityQuestion', 'questionRequired', () => {
    requiredField(data.availabilityQuestion.trim());
  });
  test('availabilityQuestion', 'questionTooLong', () => {
    enforce(data.availabilityQuestion.trim())
      .isString()
      .shorterThanOrEquals(sendProductAvailabilityQuestionSchemaVariables.availabilityQuestion.max);
  });
  test('fullName', 'fullNameRequired', () => {
    requiredField(data.fullName.trim()).isString();
  });
  test('email', 'emailRequired', () => {
    requiredField(data.email.trim());
  });
  test('email', 'emailIncorrect', () => {
    enforce(data.email.trim()).isEmail();
  });

  if (!data?.phone || data?.phone?.trim() === '') {
    vest.skip('phone');
  }
  test('phone', 'phoneIncorrect', () => {
    enforce(data.phone.trim()).matches(phone);
  });

  if (data?.isCompany === '0' || data?.isCompany === 0) {
    vest.skip('taxcode');
  }
  test('taxcode', 'nipInputRequired', () => {
    requiredField(data.taxcode.trim());
  });
  test('taxcode', 'nipInputLength', () => {
    enforce(data.taxcode.trim()).lengthEquals(10);
  });
});

export const sendProductAvailabilityQuestionUserSchema = vest.create((data = {}) => {
  test('availabilityQuestion', 'questionRequired', () => {
    requiredField(data.availabilityQuestion.trim());
  });
  test('availabilityQuestion', 'questionTooLong', () => {
    enforce(data.availabilityQuestion.trim())
      .isString()
      .shorterThanOrEquals(sendProductAvailabilityQuestionSchemaVariables.availabilityQuestion.max);
  });
});
