import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useRouter } from 'next/router';
import SEND_MFA_TOKEN from 'src/graphql/mutations/sendMfaToken.gql';
import SEND_MFA_UID from 'src/graphql/mutations/sendMfaUid.gql';
import GET_MFA from 'src/graphql/queries/getMfa.gql';
import useLazyShopQuery from 'src/hooks/useLazyShopQuery';
import useRESTMutation from 'src/hooks/useRESTMutation';
import { authenticationSteps } from 'src/enums/authenticationSteps';
import { actions, initialState, reducer, setInitialValues } from './reducer';
import Spinner from '../Spinner';
import Button from '../Button';
import * as S from './styles';

export const AuthenticationModal = ({ isOpen, setIsOpen }) => {
  const [state, dispatch] = useReducer(reducer, initialState, setInitialValues);
  const [tokenValue, setTokenValue] = useState('');
  const [isSuccessAuth, setIsSuccessAuth] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const sendTokenBtnRef = useRef(null);
  const inputSendTokenRef = useRef(null);
  const router = useRouter();

  const [getAuthentication, { loading }] = useLazyShopQuery(GET_MFA, {
    onCompleted: ({ mfaResponse }) => {
      dispatch({
        type: actions.GET_TOKEN,
        payload: { mfaResponse },
      });
    },
    onError: (error) => {
      setErrorMessage(error.networkError.response.message);
    },
  });

  const [getSmsToken, { loading: loadingGetSmsToken }] = useRESTMutation(SEND_MFA_UID, {
    onCompleted: ({ sendMfaUid }) => {
      dispatch({
        type: actions.SEND_TOKEN,
        payload: { sendMfaUid },
      });
    },
    onError: ({ networkError }) => {
      if (networkError) {
        setErrorMessage(networkError.result.message);
      }
    },
  });

  const [sendToken, { loading: loadingSendToken }] = useRESTMutation(SEND_MFA_TOKEN, {
    onCompleted: ({ sendMfaToken }) => {
      dispatch({
        type: actions.SUCCESSFUL_AUTHORIZATION,
        payload: { sendMfaToken },
      });
      setIsSuccessAuth(true);
    },
    onError: ({ networkError }) => {
      if (networkError) {
        setErrorMessage(networkError.result.message);
        if (!!inputSendTokenRef) {
          inputSendTokenRef.current.focus();
        }
      }
    },
  });

  const handlegetSmsToken = () => {
    setErrorMessage('');
    getSmsToken({
      variables: {
        input: {
          uid: state.authUid,
        },
      },
    });
  };

  const handleSendToken = (e) => {
    e.preventDefault();
    setErrorMessage('');
    if (!!sendTokenBtnRef) {
      sendTokenBtnRef.current.focus();
    }
    sendToken({
      variables: {
        input: {
          uid: state.authUid,
          step: state.actualStep,
          token: tokenValue,
        },
      },
    });
  };

  const handleCloseModal = () => {
    if (isSuccessAuth) {
      router.reload();
    } else {
      setIsOpen(false);
    }
  };

  const stepOne = () => (
    <div>
      <S.Text>{state.message}</S.Text>
      <S.ButtonGetToken onClick={handlegetSmsToken}>{loadingGetSmsToken ? <Spinner /> : state.btnTxt}</S.ButtonGetToken>
      {!!errorMessage ? <S.ErrorMessage>{errorMessage}</S.ErrorMessage> : null}
    </div>
  );

  const stepTwo = () => (
    <div>
      <S.Text>{state.authMessage}</S.Text>
      <S.Text>{state.message}</S.Text>
      <S.SendTokenForm onSubmit={(e) => handleSendToken(e)} id="authenticationForm">
        <S.Input
          onChange={(e) => setTokenValue(e.target.value)}
          placeholder={state.inputPlaceholder}
          label={state.inputPlaceholder}
          value={tokenValue}
          type="number"
          ref={inputSendTokenRef}
          autoComplete="one-time-code"
        />
        <S.Button form="authenticationForm" type="submit" ref={sendTokenBtnRef}>
          {loadingSendToken ? <Spinner /> : state.btnTxt}
        </S.Button>
      </S.SendTokenForm>
      {!!errorMessage ? <S.ErrorMessage>{errorMessage}</S.ErrorMessage> : null}
    </div>
  );

  const stepThree = () => (
    <div>
      <S.Text>{state.message}</S.Text>
      <Button onClick={handleCloseModal}>{state.btnTxt}</Button>
    </div>
  );

  const renderContent = () => {
    switch (state.actualStep) {
      case authenticationSteps.VERIFY_STEP:
        return stepTwo();
      case authenticationSteps.FINAL_STEP:
        return stepThree();
      default:
        return stepOne();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setInitialValues();
      getAuthentication();
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <S.AuthenticationModal isOpen={isOpen} onBackgroundClick={handleCloseModal} onCloseClick={handleCloseModal}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <S.Title variant="octonary" fontSizeIndex={1}>
            {state.modalTitle}
          </S.Title>
          {renderContent()}
        </>
      )}
    </S.AuthenticationModal>
  );
};
