const product = {
  paramName: 'productId',
  paramPath: '/:productId',
};

const article = {
  paramName: 'articleSlug',
  paramPath: '/:articleSlug',
};

const category = {
  paramName: 'categorySlug',
  paramPath: '/:categorySlug*',
};

const brand = {
  paramName: 'brandName',
  paramPath: '/:brandName',
};

const order = {
  paramName: 'orderId',
  paramPath: '/:orderId',
};

const series = {
  paramName: 'seriesName',
  paramPath: '/:seriesName',
};

const invoice = {
  paramName: 'invoiceId',
  paramPath: '/:invoiceId',
};

const offer = {
  paramName: 'offerId',
  paramPath: '/:offerId',
};

const inquiry = {
  paramName: 'inquiryId',
  paramPath: '/:inquiryId',
};

module.exports = {
  product,
  article,
  category,
  brand,
  order,
  series,
  invoice,
  offer,
  inquiry,
};
