import Skeleton from 'src/components/Skeleton';

import * as S from './styles';

const skeletonsQuantity = 10;

export const CategoryList = ({
  categoryArray = [],
  productsPageSlug = '',
  onCloseMenu = () => {},
  onMoveToCategory = () => {},
  productsPageLayout = '',
  isLoading = false,
}) => {
  if (isLoading) {
    return Array.from({ length: skeletonsQuantity }, (_, index) => {
      return <Skeleton height={43} key={index} variant="rounded" />;
    });
  }

  return categoryArray.map((category) => {
    const categoryQuantity = category?.size;

    const hasChildrenCategories = category?.children?.length;

    return (
      <S.MenuItem key={category.slug}>
        <S.MenuLinkWithQuantity
          href={productsPageLayout}
          blank
          block
          isNextLink
          nextLinkProps={{
            as: `/${productsPageSlug}${category.slug}`,
          }}
          onClick={(e) => (hasChildrenCategories ? onMoveToCategory(category, e) : onCloseMenu())}
        >
          {category.name}
          <S.MenuLinkItemWithQuantityNameAndQuantityValue>
            <S.CategoryQuantity>{categoryQuantity}</S.CategoryQuantity>
            {hasChildrenCategories && <S.MoreItemsIcon icon="arrowRight" />}
          </S.MenuLinkItemWithQuantityNameAndQuantityValue>
        </S.MenuLinkWithQuantity>
      </S.MenuItem>
    );
  });
};
