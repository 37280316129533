import styled from 'styled-components';
import { em, rem } from 'polished';
import { up } from 'styled-breakpoints';

import { breakpointsKeys } from 'src/enums/breakpoints';

import ImportedSpinner from 'src/components/Spinner';
import ImportedLink from 'src/components/Link';
import ImportedIcon from 'src/components/Icon';
import ImportedSkeleton from 'src/components/Skeleton';
import ImportedText from 'src/components/Text';

import SimpleBarComponent from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export const ListItemWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'icon message close'
    'icon message close'
    'icon bottom bottom';
  grid-template-rows: ${em(25)} auto ${em(20)};
  grid-template-columns: ${em(40)} auto ${em(30)};
  grid-gap: ${em(5)};
  ${({ disableClick }) => disableClick && 'pointer-events: none'};
  cursor: pointer;

  ${up(breakpointsKeys.TABLET)} {
    max-width: ${rem(320)};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    ${({ isMobileDevice }) => !isMobileDevice && 'grid-template-rows: auto'}
  }
`;
export const ListItemErrorWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'icon message'
    'icon message'
    'icon message';
  grid-template-rows: auto;
  align-items: center;
  grid-template-columns: ${em(40)} auto;
  grid-gap: ${em(5)};
  min-height: ${em(50)};
`;

export const ErrorTextWrapper = styled.div`
  grid-area: message;
  display: flex;
  align-items: center;
`;

export const BottomPart = styled.div`
  display: flex;
  grid-area: bottom;
  width: 100%;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-area: icon;
`;

export const TextWrapper = styled.div`
  grid-area: message;
`;

export const CloseWrapper = styled.div`
  grid-area: close;
  display: flex;
  justify-content: flex-end;
  padding-top: ${em(5)};
  padding-right: ${em(1)};
  pointer-events: all;
  ${up(breakpointsKeys.DESKTOP)} {
    padding-right: ${em(5)};
  }
`;

export const Icon = styled(ImportedIcon)`
  width: ${em(30)};
  height: ${em(32)};
`;

export const DeleteIcon = styled(ImportedIcon)`
  ${({ theme }) => theme.helpers.transition(['transform'])};

  color: ${({ theme }) => theme.basketProductsList.colors.deleteIcon};
  height: ${em(12)};

  cursor: pointer;
  transform: scale(1);
  &:hover {
    transform: scale(1.25);
  }
`;

export const Link = styled(ImportedLink)`
  font-size: ${em(14)};
`;

export const SimpleBar = styled(SimpleBarComponent)`
  padding: 0 ${em(16)} 0 0;

  ${up(breakpointsKeys.TABLET)} {
    padding: 0 ${em(8)};
    max-height: ${em(432)};
  }
  .simplebar-scrollbar {
    background: ${({ theme }) => theme.defaultPalette.teal[0]};
    &:before {
      background: ${({ theme }) => theme.defaultPalette.teal[0]};
    }
  }

  background: ${({ theme }) => theme.userDropDownMenu.bg};
  z-index: 30;
  color: ${({ theme }) => theme.userDropDownMenu.dropdownContentColor};
`;

export const Divider = styled.hr`
  border-top: 1px solid #eaeaea;
  width: 100%;
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  flex-direction: column;

  ${up(breakpointsKeys.TABLET)} {
    max-height: ${em(432)};
    padding: 0 ${em(16)};
  }
`;
export const SpinnerPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  height: ${em(50)};
  align-items: center;
  margin-bottom: ${em(1)};
`;

export const Spinner = styled(ImportedSpinner)`
  height: ${em(32)};
  width: ${em(32)};
`;

export const SkeletonItemWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'icon message close'
    'icon message close'
    'icon bottom bottom';
  grid-template-rows: ${em(25)} auto ${em(20)};
  grid-template-columns: ${em(40)} auto ${em(30)};
  grid-gap: ${em(5)};
  width: 100%;
  padding: 0 ${em(10)};

  ${up(breakpointsKeys.TABLET)} {
    padding: unset;
    grid-template-columns: ${em(40)} auto ${em(30)};
    grid-template-rows: auto;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-height: ${em(30)};

  ${up(breakpointsKeys.DESKTOP)} {
    max-height: ${({ isMobileDevice }) => (isMobileDevice ? em(30) : em(12))};
  }
`;

export const IconSkeleton = styled(ImportedSkeleton)`
  grid-area: icon;
  margin-left: ${em(5)};
`;

export const MessageSkeleton = styled(ImportedSkeleton)`
  width: 100%;
  ${up(breakpointsKeys.TABLET)} {
    width: unset;
  }
`;

export const Text = styled(ImportedText)`
  font-size: ${({ theme }) => theme.typography.fontSize.s2};
  text-align: left;
`;
