import styled, { css } from 'styled-components';
import { em, rem } from 'polished';
import { up } from 'styled-breakpoints';

import TextareaComponent from '../Textarea';
import DefaultButton from '../Button';
import FormComponent from '../Form';
import FormStatusMessageComponent from '../FormStatusMessage';

export const Button = styled(DefaultButton)`
  ${(p) => p.theme.helpers.fontFamilyWithFallback(p.theme.header.search.fontFamily)};
  border-radius: 2em;
  padding-left: 2em;
  padding-right: 2em;
  z-index: 4;
  font-size: ${em(12)}!important;
`;

export const Form = styled(FormComponent)``;

export const FormStatusMessage = styled(FormStatusMessageComponent)`
  padding: 0;
  text-align: left;
  height: 3em;
  margin-bottom: 0.75em;
`;

export const SearchMany = styled.div`
  background: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  position: absolute;
  top: calc(100% - 1.7em);
  width: 100%;
  z-index: ${({ isOpen }) => (isOpen ? 299 : 28)};
  left: auto;

  border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  transform-origin: top left;
  max-height: calc(100vh - ${em(160)});
  overflow-y: scroll;
  opacity: 1;
  transition: transform 0.2s ease-in-out, opacity 0.1s ease;
  box-shadow: 0px -4px 0px 4px ${({ theme }) => theme.defaultPalette.monochrome[0]},
    inset 0px -4px 0px 4px ${({ theme }) => theme.defaultPalette.monochrome[0]};
  border-radius: 0 0 ${rem(30)} ${rem(30)};
  a:focus,
  [role='button']:focus {
    text-decoration: underline;

    color: ${({ theme }) => theme.defaultPalette.teal[0]};
  }

  ${up('tablet')} {
    box-shadow: none;
  }

  ${up('desktop')} {
    width: ${em(270)};
    max-width: 70vw;
    overflow: hidden;
    height: auto;
    right: 0.5em;
    max-height: max-content;

    border: 1px solid ${({ theme }) => theme.defaultPalette.prussianBlue[3]};
  }

  ${({ isOpen, theme }) => {
    if (isOpen) {
      return css`
        animation: ${theme.animations.scaleUpWithFade} ${theme.transitions.durations[1]} 1;
        transform: scale(1);
        z-index: 299 !important;
      `;
    }
    return css`
      transform: scale(0);
      opacity: 0;
      pointer-events: none;
      z-index: 0 !important;
      border: none !important;
    `;
  }};
`;

export const SearchManyContainer = styled.div`
  padding: 3em 1.5em 1em;

  ${up('tablet')} {
    padding: 3em 1.5em 0;

    height: ${rem(435)};
  }
`;

export const Textarea = styled(TextareaComponent)`
  border: 0 !important;
  margin-top: 0.5em;
  margin-bottom: 0.75em !important;

  textarea {
    cursor: auto;

    scrollbar-color: ${(p) => p.theme.defaultPalette.teal[0]} ${(p) => p.theme.defaultPalette.monochrome[2]};
    scrollbar-width: thin;
    font-size: ${em(14)} !important;
    line-height: 1.7em;
    padding: 0.75em !important;
  }

  textarea::-webkit-scrollbar {
    width: 0.42rem !important;
  }

  textarea::-webkit-scrollbar-track {
    box-shadow: inset 0 0 16px ${({ theme }) => theme.defaultPalette.monochrome[2]};
    -webkit-box-shadow: inset 0 0 16px ${({ theme }) => theme.defaultPalette.monochrome[2]};
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.defaultPalette.teal[0]};

    outline: none;
  }
`;

export const SearchManyFooter = styled.div`
  background: ${({ theme }) => theme.defaultPalette.monochrome[1]};
  padding: 0.5em 1em;
  position: relative;
  text-align: center;
`;

export const SearchManyBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 111;
  opacity: 0;
`;

export const TextareaContainer = styled.div`
  position: relative;
`;

export const Placeholder = styled.span`
  position: absolute;
  z-index: 5;
  top: ${em(20)};
  left: ${em(14)};
  font-size: ${em(14)} !important;
  opacity: 0.7;
  line-height: 1.7em;
`;
