// import { availableLanguages, availableLocales } from '../../config/locales';
const { SSR_BASE_URL } = require('../../config/ssr');
const { availableLanguages, availableLocales, defaultLanguage } = require('../../config/locales');

const decodeSlugToLanguageCodeAndSlug = (slugPath) => {
  const url = new URL(slugPath, SSR_BASE_URL);

  const splittedSlug = url.pathname.split('/');

  if (!splittedSlug[0]) {
    splittedSlug.shift();
  }

  const languageIdx = availableLanguages.findIndex((lang) => lang === splittedSlug[0]);

  if (languageIdx < 0) {
    return {
      languageCode: defaultLanguage,
      decodeSlug: slugPath,
      decodeSlugWithParams: slugPath
    };
  }

  // Note: remove language code
  splittedSlug.shift();

  return {
    languageCode: availableLanguages[languageIdx],
    decodeSlug: `/${splittedSlug.join('/')}`,
    decodeSlugWithParams: `/${splittedSlug.join('/')}${url.search}`
  };
};

module.exports = {
  decodeSlugToLanguageCodeAndSlug,
};
