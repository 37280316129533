import { useRef } from 'react';

import dynamic from 'next/dynamic';
import { EnvironmentProvider } from 'src/providers/environment';
import { NotificationsProvider } from 'src/providers/notifications';

import ScrolledHeader from '../ScrolledHeader';
import useLogic from './useNavbarLogic';
import StaticHeader from '../Header';

const MobileMenu = dynamic(() => import('src/components/MobileMenu'));

export const Navbar = ({
  innerClassName,
  user,
  basket,
  locale,
  balancePanelDetails,
  searchValue,
  coreRoutesInfo,
  categories,
  topMenuNavigation,
  bottomLeftNavigation,
  rightBottomNavigation,
  searchMenuPagesData,
  markerRef,
  icontooltip,
  icon,
  isBasketPage,
}) => {
  const headerRef = useRef();
  const smallHeaderRef = useRef();
  const dropDownMenuRef = useRef();

  const {
    isScrolledHeaderVisible,
    headerHeight,
    handleSearch,
    language,
    redirectToNewSearchPath,
    isCurrentRouteProductsList,
    isPathContainingProductFilter,
    shouldDisplayBalancePanelDetails,
    balancePanelDetailsRedirectCfg,
    paymentStatus,
    localState,
    headerBasketLinkProps,
    headerClipboardLinkProps,
    headerGuestLinkProps,
    handleCloseMobileMenu,
    handleOpenMobileMenu,
    isMobileMenuOpen,
    initialMenuType,
    basketIndicatorData,
    isClientB2BType,
    shouldRenderDropDownMenu,
    searchMenuPages,
    handleChangeCustomer,
    isDropDownMenuOpen,
    handleCloseDropdownMenu,
    headerUserLinkProps,
    filtersTooltipValue,
    setFiltersTooltipValue,
    setIsFiltersIcon,
    isFiltersIcon,
    shouldDisplayLogoAsH1,
    shouldRenderMobileMenu,
  } = useLogic({
    markerRef,
    headerRef,
    coreRoutesInfo,
    user,
    balancePanelDetails,
    basket,
    dropDownMenuRef,
    searchMenuPagesData,
    icontooltip,
    icon,
    smallHeaderRef,
    isBasketPage,
  });

  const commonProps = {
    user,
    coreRoutesInfo,
    handleSearch,
    language,
    redirectToNewSearchPath,
    searchValue,
    isCurrentRouteProductsList,
    isPathContainingProductFilter,
    shouldDisplayBalancePanelDetails,
    balancePanelDetailsRedirectCfg,
    balancePanelDetails,
    paymentStatus,
    localState,
    headerBasketLinkProps,
    headerClipboardLinkProps,
    headerGuestLinkProps,
    handleOpenMobileMenu,
    basketIndicatorData,
    isClientB2BType,
    isDropDownMenuOpen,
    filtersTooltipValue,
    setFiltersTooltipValue,
    setIsFiltersIcon,
    isFiltersIcon,
    shouldDisplayLogoAsH1,
  };

  return (
    <NotificationsProvider>
      {isScrolledHeaderVisible ? <ScrolledHeader headerHeight={headerHeight} {...commonProps} /> : null}
      {isBasketPage ? (
        <ScrolledHeader headerHeight={headerHeight} isStatic smallHeaderRef={smallHeaderRef} {...commonProps} />
      ) : null}
      <EnvironmentProvider>
        {!isScrolledHeaderVisible && !isBasketPage ? (
          <StaticHeader
            innerClassName={innerClassName}
            locale={locale}
            shouldRenderDropDownMenu={shouldRenderDropDownMenu}
            categories={categories}
            ref={[headerRef, dropDownMenuRef]}
            topMenuNavigation={topMenuNavigation}
            bottomLeftNavigation={bottomLeftNavigation}
            rightBottomNavigation={rightBottomNavigation}
            searchMenuPagesData={searchMenuPagesData}
            searchMenuPages={searchMenuPages}
            handleCloseDropdownMenu={handleCloseDropdownMenu}
            isDropDownMenuOpen={isDropDownMenuOpen}
            headerUserLinkProps={headerUserLinkProps}
            {...commonProps}
          />
        ) : null}
        {shouldRenderMobileMenu ? (
          <MobileMenu
            className="no-print"
            isMenuOpen={isMobileMenuOpen}
            initialMenuType={initialMenuType}
            toggleMenu={handleCloseMobileMenu}
            user={user}
            topMenuEntries={topMenuNavigation}
            leftBottomMenuEntries={bottomLeftNavigation}
            rightBottomMenuEntries={rightBottomNavigation}
            searchMenuPages={searchMenuPages || []}
            locale={language}
            coreRoutesInfo={coreRoutesInfo}
            handleChangeCustomer={handleChangeCustomer}
            selectedPriceTypeApiId={user?.pricetype?.type}
          />
        ) : null}
      </EnvironmentProvider>
    </NotificationsProvider>
  );
};
