import { defaultLanguage } from 'config/locales';
import { datoHtmlFieldsTypenames } from 'src/enums/datoHtmlFieldsTypenames';

const regex = /<a\s+[^>]*\bhref\s*=\s*["']?\/[^"'\s>]*["']?[^>]*>/g;
const regexForOption = /<option\s+([^>]*\svalue="\/[^"]*")/g;

export const addLangSignToHtmlLinksFromDato = (content, language) => {
  for (let i = 0; i < content.length; i++) {
    switch (content[i].__typename) {
      case datoHtmlFieldsTypenames.htmlSet:
        let modifiedHtmlSet = '';
        let modifiedHtmlNotTranslatedSet = '';

        modifiedHtmlSet = content[i].htmlSet.html.replace(regexForOption, (match) => {
          return match.replace('value="/', `value="/${language}/`);
        });

        modifiedHtmlSet = modifiedHtmlSet.replace(regex, (match) => {
          return match.replace('href="/', `href="/${language}/`);
        });

        modifiedHtmlNotTranslatedSet = content[i].htmlSet.htmlNotTranslated.replace(regex, (match) => {
          return match.replace('href="/', `href="/${language}/`);
        });

        const updatedHtmlSet = {
          ...content[i],
          htmlSet: {
            html: modifiedHtmlSet,
            htmlNotTranslated: modifiedHtmlNotTranslatedSet,
            __typename: content[i].__typename,
          },
        };
        content[i] = updatedHtmlSet;
        break;

      case datoHtmlFieldsTypenames.htmlRecord:
        let modifiedHtmlRecord = '';

        modifiedHtmlRecord = content[i].html.replace(regexForOption, (match) => {
          return match.replace('value="/', `value="/${language}/`);
        });

        modifiedHtmlRecord = modifiedHtmlRecord.replace(regex, (match) => {
          return match.replace('href="/', `href="/${language}/`);
        });

        const updatedHtmlRecord = {
          ...content[i],
          html: modifiedHtmlRecord,
        };
        content[i] = updatedHtmlRecord;
        break;

      case datoHtmlFieldsTypenames.bannersCarouselSet:
        const updatedBannerCarouselItems = [];

        for (let j = 0; j < content[i].bannersCarousel.length; j++) {
          if (!!content[i]?.bannersCarousel[j].customData?.link) {
            if (content[i]?.bannersCarousel[j].customData?.link[0] === '/') {
              const updatedBannerCarouselLinks = {
                ...content[i].bannersCarousel[j],
                customData: { link: `/${language}${content[i].bannersCarousel[j].customData.link}` },
              };
              updatedBannerCarouselItems.push(updatedBannerCarouselLinks);
            } else {
              updatedBannerCarouselItems.push(content[i].bannersCarousel[j]);
            }
          } else {
            updatedBannerCarouselItems.push(content[i].bannersCarousel[j]);
          }
        }

        const updatedBannerCarouselSet = {
          ...content[i],
          bannersCarousel: updatedBannerCarouselItems,
        };
        content[i] = updatedBannerCarouselSet;
        break;

      case datoHtmlFieldsTypenames.bannersCarouselPersonalizedSet:
        const updatedBannerCarouselPersonalizedItems = [];

        for (let j = 0; j < content[i].personalizedImages.length; j++) {
          if (!!content[i]?.personalizedImages[j].image?.customData?.link) {
            if (content[i]?.personalizedImages[j].image?.customData?.link[0] === '/') {
              const updatedBannerCarouselPersonalizedLinks = {
                ...content[i].personalizedImages[j],
                image: {
                  ...content[i].personalizedImages[j].image,
                  customData: { link: `/${language}${content[i].personalizedImages[j].image.customData.link}` },
                },
              };
              updatedBannerCarouselPersonalizedItems.push(updatedBannerCarouselPersonalizedLinks);
            } else {
              updatedBannerCarouselPersonalizedItems.push(content[i].personalizedImages[j]);
            }
          } else {
            updatedBannerCarouselPersonalizedItems.push(content[i].personalizedImages[j]);
          }
        }

        const updatedBannerCarouselPersonalizedSet = {
          ...content[i],
          personalizedImages: updatedBannerCarouselPersonalizedItems,
        };
        content[i] = updatedBannerCarouselPersonalizedSet;
        break;

      case datoHtmlFieldsTypenames.branchInfo:
        if (!!content[i]?.branch?.description) {
          const modifiedHtmlBranchInfo = content[i].branch.description.replace(regex, (match) => {
            return match.replace('href="/', `href="/${language}/`);
          });

          const updatedBranchInfo = {
            ...content[i],
            branch: {
              ...content[i].branch,
              description: modifiedHtmlBranchInfo,
            },
          };
          content[i] = updatedBranchInfo;
        }

        break;
    }
  }

  return content;
};

export const addLangSignToFooterHtmlLinks = (content, language) => {
  const updatedFooterHtmlContent = [];
  for (let i = 0; i < content.length; i++) {
    if (content[i].__typename === datoHtmlFieldsTypenames.footerHtml) {
      const modifiedHtmlFooter = content[i].htmlContent.replace(regex, (match) => {
        return match.replace('href="/', `href="/${language}/`);
      });

      const updatedFooterHtml = {
        ...content[i],
        htmlContent: modifiedHtmlFooter,
      };
      updatedFooterHtmlContent.push(updatedFooterHtml);
    } else {
      updatedFooterHtmlContent.push(content[i]);
    }
  }
  content = updatedFooterHtmlContent;
  return content;
};

export const addLangToLinksFromHtmlSetDangerously = (content, language) => {
  if (language !== defaultLanguage) {
    const modifiedHtmlFromHtmlSetDangerously = content.replace(regex, (match) => {
      return match.replace('href="/', `href="/${language}/`);
    });

    content = modifiedHtmlFromHtmlSetDangerously;
  }

  return content;
};
