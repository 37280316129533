export const notificationsListPopperState = {
  MOUSE_ENTER: 'mouseEnter',
  CLICKED: 'clicked',
  HIDDEN: 'hidden',
  MODAL_OPEN: 'modalOpen',
};

export const notificationsStatus = {
  NEW: 0,
  READED: 1,
  CLOSED: 2,
};

export const notNetworkResponseNotificationsActions = {
  ERROR: 'error',
  NO_NEW_NEW_NOTIFICATIONS: 'noNewNotifications',
};

export const notificationsIcons = new Map([
  [0, { icon: 'notificationsTrumpet', fill: '#ff6900' }],
  [1, { icon: 'notificationsEbon', fill: '#00857E' }],
  [2, { icon: 'notificationsInfo', fill: '#ff6900' }],
  [3, { icon: 'notificationsHands', fill: '#00857E' }],
]);

export const actionTypes = {
  EXTERNAL: 0,
  INTERNAL: 1,
  NO_ACTION: 2,
};
