import styled from 'styled-components';
import { rem } from 'polished';
import { up } from 'styled-breakpoints';

import { breakpointsKeys } from 'src/enums/breakpoints';

const BurgerItem = styled.div`
  background: ${({ theme }) => theme.header.burgerColor};
  width: 26px;
  height: 3px;
  position: relative;
`;

const BurgerShape = () => (
  <>
    <BurgerItem />
    <BurgerItem />
    <BurgerItem />
  </>
);

const Burger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.5em;
  width: ${rem(26)};
  height: ${rem(19)};
  cursor: pointer;

  ${up(breakpointsKeys.TABLET)} {
    height: ${rem(19)};
  }
  ${up(breakpointsKeys.DESKTOP_LARGE)} {
    display: none;
  }
`;

export { BurgerShape, Burger };
