import { useReducer } from 'react';

import useLanguage from 'src/hooks/useLanguage';
import useLocalStorage from 'src/hooks/useLocalStorage';

import { LocaleOptionsDropdown } from './LocaleOptionsDropdown';

import { ACTIONS, initialState, reducer } from './localeOptionsReducer';

import * as S from './styles';

export const LocaleOptions = ({ className }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [language] = useLanguage();

  const [getLocalStorageItem] = useLocalStorage();
  const handleOpenModal = () => {
    dispatch({ type: ACTIONS.OPEN_DROPDOWN });
  };

  const currentDelivery = getLocalStorageItem('currentDelivery');
  const currentCurrency = getLocalStorageItem('currentCurrency');

  const currentDeliveryCountryName = currentDelivery?.name;
  const currentDeliveryCountryShort = currentDelivery?.id;

  const shouldDisplayDeliveryCountryAsShorthand = currentDeliveryCountryName?.length > 10;
  const deliveryCountryNameInfoToDisplay = shouldDisplayDeliveryCountryAsShorthand
    ? currentDeliveryCountryShort
    : currentDeliveryCountryName;

  return (
    <S.LocaleOptionsMainWrapper className={className}>
      <S.LocaleOptionsWrapper onClick={handleOpenModal}>
        <S.Flag code={language} />
        <S.TypographyWrapper>
          <S.DeliveryCountryText>{deliveryCountryNameInfoToDisplay}</S.DeliveryCountryText>
          <S.CurrencyAndIconWrapper>
            <S.DeliveryCountryText>{currentCurrency?.name}</S.DeliveryCountryText>
            <S.Icon icon="arrowDown" isUp={state.isDropdownOpen} />
          </S.CurrencyAndIconWrapper>
        </S.TypographyWrapper>
      </S.LocaleOptionsWrapper>
      <LocaleOptionsDropdown dispatch={dispatch} state={state} />
    </S.LocaleOptionsMainWrapper>
  );
};
