import styled from 'styled-components';

const SVG = styled.svg``;

export const Search = ({ className }) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      className={className}
    >
      <path
        d="M19.3357 20.5898C19.1643 20.5898 18.9929 20.5256 18.8643 20.3899L14.4143 15.9486C14.1571 15.6845 14.1571 15.2632 14.4143 15.0061C14.6786 14.7419 15.1 14.7419 15.3571 15.0061L19.8071 19.4545C20.0643 19.7116 20.0643 20.1329 19.8071 20.3899C19.6714 20.5256 19.5071 20.5898 19.3357 20.5898ZM7.77857 16.1486C3.48571 16.1486 0 12.657 0 8.36564C0 4.07431 3.48571 0.589844 7.77857 0.589844C12.0643 0.589844 15.5571 4.07431 15.5571 8.36564C15.5571 12.657 12.0643 16.1486 7.77857 16.1486ZM7.77857 1.92508C4.22143 1.92508 1.33571 4.80977 1.33571 8.36564C1.33571 11.9215 4.22143 14.8133 7.77857 14.8133C11.3286 14.8133 14.2214 11.9215 14.2214 8.36564C14.2214 4.80977 11.3286 1.92508 7.77857 1.92508Z" //eslint-disable-line
        fill="#00857E"
      />
    </SVG>
  );
};
