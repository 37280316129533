import { localesEnum } from 'config/locales';
import { SSR_BASE_URL } from 'config/ssr';

const mapLanguageToHrefLangKey = {
  [localesEnum.polish]: ['pl'],
  [localesEnum.ukrainian]: ['uk', 'uk_PL'],
  [localesEnum.english]: ['en_GB', 'en_IE', 'x-default'],
  [localesEnum.czech]: ['cs_CZ'],
  [localesEnum.slovak]: ['sk_SK'],
  [localesEnum.romanian]: ['ro_RO'],
  [localesEnum.serbian]: ['sr_XS', 'sr_BA', 'sr_ME'],
  [localesEnum.german]: ["de_DE", "de_AT", "de_LI", "de_CH", "de_LU"],
  [localesEnum.hungarian]: ['hu_HU'],
};

const createHrefLangConfigItem = (slug, hrefLang) => {
  const origin = typeof window !== 'undefined' ? `${window.location.origin}` : SSR_BASE_URL;

  const url = new URL(slug, origin);

  return {
    rel: 'alternative',
    lang: hrefLang,
    href: `${url.origin}${url.pathname}`,
  };
};

export const createHrefLangItems = (routingInfo) => {
  const hrefLangItems = [];

  Object.entries(routingInfo).forEach(([localeKey, slug]) => {
    Object.values(localesEnum).forEach((locale) => {
      if (locale === localeKey) {
        mapLanguageToHrefLangKey[localeKey].forEach((hrefLangToken) =>
          hrefLangItems.push(createHrefLangConfigItem(slug, hrefLangToken)),
        );
      }
    });
  });

  return hrefLangItems;
};
