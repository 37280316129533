import { availableLanguages, defaultLanguage } from 'config/locales';

const clearEmptyString = (value) => !!value;

const pullOutCorePath = (partOfUrl) => {
  const path = partOfUrl.split(/[#?]/)[0];
  let mainSlug = '';
  let partsOfPath = path.split('/').filter(clearEmptyString);
  let pathAfterMainSlug = [];

  if (partsOfPath.length === 0) {
    return { mainSlug: '', pathAfterMainSlug: '' };
  }

  if (defaultLanguage !== partsOfPath[0] && availableLanguages.includes(partsOfPath[0])) {
    mainSlug = [partsOfPath[0], partsOfPath[1]].filter(clearEmptyString).join('/');
    pathAfterMainSlug = [...partsOfPath].splice(2);
  } else {
    mainSlug = partsOfPath[0];
    pathAfterMainSlug = [...partsOfPath].splice(1);
  }

  pathAfterMainSlug = pathAfterMainSlug.join('/');

  return { mainSlug, pathAfterMainSlug };
};

export default pullOutCorePath;
