import useTranslation from 'src/hooks/useTranslation';

import { notificationsStatus, notificationsIcons } from 'src/enums/notifications';
import { uiComponentVariant } from 'src/enums/uiComponentVariant';

import SpinnerComponent from 'src/components/Spinner';
import Text from 'src/components/Text';

import * as S from './styles';

const defaultProps = {
  message: '',
  category: 0,
  datedesc: '',
  status: 0,
  onClickAction: () => null,
  onClickDelete: () => null,
  isPending: false,
  action: '',
  isMobileDevice: false,
};

const notificationsGoToActionArrow = '»';

export const NotificationsListItem = ({
  message,
  category,
  datedesc,
  status,
  onClickDelete,
  onClickAction,
  isPending,
  action,
  isMobileDevice,
} = defaultProps) => {
  const { t } = useTranslation();

  const { icon, fill } = notificationsIcons.get(category);
  const isMessageRead = status === notificationsStatus.READED;
  const messageTextVariant = isMessageRead ? uiComponentVariant.QUINARY : uiComponentVariant.QUATERNARY;

  const goToActionMessage = `${t('notificationsGoToAction')} ${notificationsGoToActionArrow}`;
  const isRedirectAvailable = !!action;

  const isReadedNotificationWithNoActionAvailable = !action && isMessageRead;
  const disableClick = isReadedNotificationWithNoActionAvailable || isPending;
  const preventClickOnItemWhileActionIsPending = isPending;

  const handleClick = (e) => {
    if (preventClickOnItemWhileActionIsPending) return;
    const target = e.target;
    const isCloseIcon = target.closest('#closeIcon');
    if (isCloseIcon) {
      onClickDelete();
      return;
    }

    onClickAction();
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClickAction();
  };

  return (
    <S.ListItemWrapper onClick={handleClick} disableClick={disableClick} isMobileDevice={isMobileDevice}>
      <S.IconWrapper>
        <S.Icon icon={icon} fill={fill} svgSupportedViewBox="0 0 28 32" />
      </S.IconWrapper>
      <S.TextWrapper>
        <S.Text variant={messageTextVariant} dangerouslySetInnerHTML={{ __html: message }} />
      </S.TextWrapper>

      <S.CloseWrapper>
        <S.CloseIconWrapper id="closeIcon" isMobileDevice={isMobileDevice}>
          {isPending ? <SpinnerComponent /> : <S.DeleteIcon icon="close" />}
        </S.CloseIconWrapper>
      </S.CloseWrapper>

      <S.BottomPart>
        <Text variant="secondary">{datedesc}</Text>
        {isRedirectAvailable && (
          <S.Link variant="duodenary" href={action} onClick={handleLinkClick}>
            {goToActionMessage}
          </S.Link>
        )}
      </S.BottomPart>
    </S.ListItemWrapper>
  );
};
