import styled from 'styled-components';
import { rem } from 'polished';
import { up } from 'styled-breakpoints';

import _Link from 'src/components/Link';

import { breakpointsKeys } from 'src/enums/breakpoints';
const itemsSpacing = '1.75em';
export const HorizontalMenu = styled.ul`
  display: none;

  ${up('tablet')} {
    display: flex;
    gap: ${rem(10)};
    /* padding: ${rem(8)} 0; */

    padding-top: ${rem(8)};
    flex-direction: row;
    background: inherit;
    margin-bottom: 0;
  }
`;
export const HorizontalMenuItemChildren = styled.div`
  margin-top: 1em;
  padding-left: ${itemsSpacing};
  background: inherit;

  ${up('tablet')} {
    display: none;
    white-space: nowrap;
    position: absolute;
    padding: 1em;
    left: -1em;
    margin: 0;
    top: 1.25em;
    z-index: 9990;
  }
`;

export const HorizontalMenuItem = styled.li`
  position: relative;
  background: inherit;
  height: 100%;

  display: inline-flex;

  flex-direction: column;

  ${up('tablet')} {
    padding-bottom: ${rem(8)};
    ${HorizontalMenuItemChildren} {
      display: flex;
    }
  }
  ${up(breakpointsKeys.DESKTOP)} {
    z-index: 20;
  }
`;

export const Link = styled(_Link)`
  z-index: 100;
  color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  font-size: ${({ theme }) => theme.typography.fontSize.s3};
  padding: ${rem(6)} ${rem(12)};
  border-radius: 30px;
  border: 1px solid transparent;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  }
  ${up('tablet')} {
    font-family: ${({ theme }) => theme.global.fontFamily.primary};
    ${({ theme }) => theme.helpers.transition(['border'])};
    &:hover,
    &:focus {
      border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[0]};
    }
  }
  > .content {
    margin-top: ${rem(2)};
  }
`;
