import { gql } from '@apollo/client/core';

export const StatusResponse = gql`
  type StatusResponse {
    status: String!
    message: String!
    name: String
    boolv: Boolean
    strv: String
    intv: Number
  }
`;

export const OnnTopBalance = gql`
  type OnnTopBalance {
    topay: Number
    payout: Number
    currency: String
  }
`;

export const SetMemoListValueResponse = gql`
  type StatusResponse {
    key: String!
    value: String!
    values: [String]
  }
`;

export const SetValueResponse = gql`
  type SetValueResponse {
    status: String!
    message: String!
  }
`;

export const Currency = gql`
  type Currency {
    id: Number!
    name: String!
    description: String!
    sign: String!
  }
`;

export const User = gql`
  type User {
    name: String!
    userName: String!
    email: String!
    phone: String!
    phoneCountry: String!
  }
`;

export const Customer = gql`
  type Customer {
    id: Number
    idEx: String
    name: String
    nameShort: String
    taxCode: String
    description: String
    selected: Boolean
    city: String
    postcode: String
    address: String
    bankAccount: String
    bankName: String
    segmentName: String
  }
`;

export const Salesman = gql`
  type Salesman {
    name: String!
    phone: String!
    email: String!
    localization: String!
    status: Number!
    statusDescription: String!
    replacementPerson: Salesman
  }
`;

export const Access = gql`
  type Access {
    name: String!
  }
`;

export const Store = gql`
  type Store {
    id: Number!
    name: String!
    type: Number!
  }
`;

export const Einvoice = gql`
  type EInvoice {
    type: Number
    email: String
    description: String
    lastchange: String
  }
`;

export const UserInfoRegulation = gql`
  type UserInfoRegulation {
    mustaccept: Boolean
    accepteddate: String
    whoaccept: String
  }
`;

export const UserPopup = gql`
  type UserPopup {
    id: Number
    title: String
    component: String
  }
`;

export const UserInfoResponse = gql`
  type UserInfoResponse {
    tags: [String]
    user: User!
    availableCustomers: [Customer!]!
    currentCustomer: Customer!
    salesman: Salesman!
    accessList: [Access!]!
    stores: [Store!]!
    clientType: Number!
    einvoice: EInvoice
    storelc: Store
    pricetype: PriceType
    regulation: UserInfoRegulation
    popups: [UserPopup]
  }
`;

export const GetStoresResponse = gql`
  type GetStoresResponse {
    items: [Store!]!
    status: String!
    message: String!
  }
`;

export const SetStoreResponse = gql`
  type SetStoreResponse {
    id: Number
    name: String
    type: Number
    title: String
    status: String!
    message: String!
  }
`;

export const SetPriceTypeResponse = gql`
  type SetPriceTypeResponse {
    type: Number!
    name: String!
    status: String!
    message: String!
  }
`;

export const CatalogCategoryItemState = gql`
  type CatalogCategoryItemState {
    opened: Boolean!
  }
`;

export const CatalogCategory = gql`
  type CatalogCategory {
    slug: String
    name: String
    size: Number!
    description: String
    imageurl: String
    children: [CatalogCategory!]
    brands: [CatalogItemBrand!]
    state: CatalogCategoryItemState!
    recommendation: String
  }
`;

export const CatalogItemSeries = gql`
  type CatalogItemSeries {
    slug: String
    name: String
  }
`;

export const CatalogItemBrand = gql`
  type CatalogItemBrand {
    slug: String
    searchfilter: String
    name: String
    description: String
    logoUrl: String
    size: Number!
    top: Boolean!
  }
`;

export const CatalogItem = gql`
  type CatalogItem {
    id: Number!
    slug: String
    index: String
    catalogindex: String
    name: String
    imageth: String
    imagemd: String
    quantitydc: Number!
    quantitylc: Number!
    pricebase: Number!
    currency: String
    prices: String
    leadtime: Number!
    avail: ProductAvail!
    groupcount: Number!
    group: String
    packages: [Number!]
    series: [CatalogItemSeries!]
    brand: [CatalogItemBrand!]
    categories: [CatalogCategory!]
    unit: String
    score: Number!
    leadtimeovs: Number!
  }
`;

export const CatalogFacet = gql`
  type CatalogFacet {
    groupname: String
    slug: String
    value: String
    size: Number!
    selected: Boolean!
    items: [CatalogFacet!]
  }
`;

export const CatalogProductsGroup = gql`
  type CatalogProductsGroup {
    title: String
    description: String
    items: [CatalogItem!]
    total: Number
  }
`;

export const CatalogProductAd = gql`
  type CatalogProductAd {
    title: String
    body: String
    slug: String
    target: String
  }
`;

export const CatalogProductDimensions = gql`
  type CatalogProductDimensions {
    measurements: String
    volume: String
    weight: String
  }
`;

export const CatalogProductAttribute = gql`
  type CatalogProductAttribute {
    name: String
    value: String
    slug: String
  }
`;

export const CatalogProductImage = gql`
  type CatalogProductImage {
    imageurl: String
    imageurl_t: String
    imageurl_m: String
    title: String
    type: String
  }
`;

export const CatalogProductDocument = gql`
  type CatalogProductDocument {
    name: String
    url: String
    ext: String
  }
`;

export const CatalogProductCert = gql`
  type CatalogProductCert {
    name: String
    url: String
    ext: String
  }
`;

export const CatalogProductLink = gql`
  type CatalogProductLink {
    name: String
    url: String
  }
`;

export const CatalogProductBestDelivery = gql`
  type CatalogProductBestDelivery {
    name: String
    nametype: String
    cost: Number
    costfreefrom: String
  }
`;

export const CatalogProductDetails = gql`
  type CatalogProductDetails {
    dimensions: CatalogProductDimensions!
    description: String
    descriptionex: String
    descriptionsales: String
    descriptionsalesex: String
    model3d: String
    oldsindexs: String
    barcodes: [String!]!
    attributes: [CatalogProductAttribute!]!
    images: [CatalogProductImage!]!
    documents: [CatalogProductDocument!]!
    links: [CatalogProductLink!]!
    certificates: [CatalogProductCert!]!
    bestdelivery: CatalogProductBestDelivery
  }
`;

export const CatalogProductCard = gql`
  type CatalogProductCard {
    id: Number!
    slug: String
    index: String
    catalogindex: String
    name: String
    imageth: String
    imagemd: String
    avail: ProductAvail!
    quantityinbasket: Number!
    pricebase: Number!
    currency: String
    purchase: Boolean!
    ispartial: Boolean!
    onntop: Number!
    onntopcb: Number!
    leadtime: Number!
    groupcount: Number!
    group: String
    packages: [Number!]!
    series: [CatalogItemSeries!]!
    brand: [CatalogItemBrand!]!
    categories: [CatalogCategory!]!
    unit: String
    ads: [CatalogProductAd!]!
    prices: CatalogProductPrice
    score: Number!
    leadtimeovs: Number!
    ext: CatalogProductDetails!
    promotions: [PromotionsData!]!
    isnew: Boolean!
  }
`;

export const ProductAvail = gql`
  type ProductAvail {
    quantitydc: Number!
    quantitylc: Number!
    quantityinbasket: Number!
    availstatus: Number!
    showask: Boolean!
    description: String!
  }
`;

export const CatalogProductPrice = gql`
  type CatalogProductPrice {
    items: [PriceItem]!
    promo: Number!
    fullopk: Boolean!
    currsig: String!
    fotit: String
    title: String
    pricetypename: String!
    vat: Number!
  }
`;

export const CatalogResponse = gql`
  type CatalogResponse {
    stats: [String!]
    items: [CatalogItem!]
    categories: [CatalogCategory!]
    facets: [CatalogFacet!]
    total: Number!
    status: String
    message: String
  }
`;

export const CatalogGroupsResponse = gql`
  type CatalogGroupsResponse {
    categories: [CatalogCategory!]
    brands: [CatalogItemBrand!]
    searchnamecategory: String
    searchnamebrand: String
    status: String
    message: String
  }
`;

export const LocalStorage = gql`
  type LocalStorage {
    id: number!
    name: String!
    slug: string!
    type: number!
  }
`;

export const RecommendationItem = gql`
  type RecommendationItem {
    title: String
    description: String
    value: String
    rid: String
  }
`;

export const ProductCardOg = gql`
  type ProductCardOg {
    title: String
    description: String
  }
`;

export const CatalogProductCardResponse = gql`
  type CatalogProductCardResponse {
    card: CatalogProductCard!
    additionalproducts: CatalogProductsGroup!
    categorypath: CatalogCategory
    recommendation: [RecommendationItem]
    status: String
    message: String
    storelc: LocalStorage
    og: ProductCardOg!
    pricetype: PriceType
  }
`;

export const LocalAvailabilitiesResponse = gql`
  type LocalAvailabilitiesResponse {
    productId: Number!
    status: String!
    message: String!
    items: [LocalAvailabilitiesItem!]!
  }
`;

export const LocalAvailabilitiesItemStore = gql`
  type LocalAvailabilitiesItemStore {
    id: Number!
    name: String!
    slug: String!
    type: Number!
  }
`;

export const LocalAvailabilitiesItem = gql`
  type LocalAvailabilitiesItem {
    store: LocalAvailabilitiesItemStore!
    quantity: Number!
    unit: String!
  }
`;

export const LocaleInput = gql`
  type LocaleInput {
    locale: String!
  }
`;

export const LocaleResponse = gql`
  type LocaleResponse {
    locale: String
    status: String!
    message: String!
  }
`;

export const LoginInput = gql`
  type LoginInput {
    userName: String!
    password: String!
    rememberMe: Boolean
  }
`;

export const LoginResponse = gql`
  type LoginResponse {
    token: String
    status: String!
    message: String!
  }
`;

export const ResetPasswordInput = gql`
  type ResetPasswordInput {
    email: String
    phone: String
    token: String
    newPassword: String
  }
`;

export const ChangePasswordInput = gql`
  type ChangePasswordInput {
    oldPassword: String
    newPassword: String
  }
`;

export const ChangeCustomerInput = gql`
  type ChangeCustomerInput {
    customerId: Number
  }
`;

export const GetStoresInput = gql`
  type GetStoresInput {
    lat: Number
    lon: Number
  }
`;

export const SetStoreInput = gql`
  type SetStoreInput {
    storeId: Number!
  }
`;

export const CatalogProductCardInput = gql`
  type CatalogProductCardInput {
    slug: String
  }
`;

export const SendMessageInput = gql`
  type SendMessageInput {
    message: String!
    referrer: String!
    source: String!
    sourceid: Number
    subject: String
    target: String!
  }
`;

export const CancelOrderInput = gql`
  type CancelOrderInput {
    id: String!
    comment: String
  }
`;

export const AcceptOfferInput = gql`
  type AcceptOfferInput {
    id: String!
    acceptstatus: Number!
    message: String
  }
`;

export const TransferOfferInput = gql`
  type TransferOfferInput {
    id: String!
  }
`;

export const SendMessagePublicInput = gql`
  type SendMessagePublicInput {
    message: String!
    referrer: String!
    source: String!
    subject: String
    fromname: String!
    fromemail: String!
    fromphone: String
    recaptchatoken: String
    iscompany: Number
  }
`;

export const GetLocalAvailabilitiesInput = gql`
  type GetLocalAvailabilitiesInput {
    productId: Number!
    lat: Number
    lon: Number
  }
`;

export const MemoListInput = gql`
  type GetMemoListInput {
    key: String!
    value: String
  }
`;

export const SetPriceInput = gql`
  type SetPriceInput {
    type: Number!
  }
`;

export const ProductPartInput = gql`
  type ProductPartInput {
    id: String
    quantity: Number!
  }
`;

export const AddToBasketInput = gql`
  type AddToBasketInput {
    productId: Number!
    quantity: Number!
    parts: [ProductPartInput!]
    source: Number
    onlylocal: Boolean
  }
`;

export const UpdateBasketLinesInput = gql`
  type UpdateBasketLinesInput {
    items: [BasketLineUpdatePayload!]!
  }
`;

export const DeliveryMatrixInput = gql`
  type DeliveryMatrixInput {
    doctype: Number!
    hids: [String!]!
    apple: Boolean
  }
`;

export const SetDeliveryInput = gql`
  type SetDeliveryInput {
    propname: String!
    hid: String!
    value: String!
  }
`;

export const SetPaymentInput = gql`
  type SetPaymentInput {
    paymentId: Number!
  }
`;

export const SearchFacets = gql`
  type Facets {
    filtername: String
    slug: String
    value: String
    size: Number
    selected: Boolean
  }
`;

export const PriceItem = gql`
  type PriceItem {
    pricecatalog: Number
    pricecross: Number
    priceend: Number
    discount: Number
    quantity: Number
    priceseo: Number
  }
`;

export const Prices = gql`
  type Price {
    items: [PriceItem!]!
    promo: Number
    fullopk: Boolean
    currsig: String
    fotit: String
    title: String
    pricetype: Number
    pricetypename: String
    vatdescription: String
    vat: Number
  }
`;

export const SearchSorts = gql`
  type SearchSorts {
    name: String
    value: String
    selected: Boolean
    slug: String
  }
`;

export const SearchResult = gql`
  type SearchResult {
    id: Number
    slug: String
    index: String
    catalogindex: String
    name: String
    imageth: String
    imagemd: String
    quantitydc: Number
    quantitylc: Number
    quantityinbasket: Number
    pricebase: Number
    currency: String
    price: Price
    purchase: Boolean
    leadtime: Number
    avail: ProductAvail!
    groupcount: Number
    group: String
    packages: [Number]
    series: [CatalogItemSeries]
    unit: String
    ads: any
    score: Number
    leadtimeovs: Number
    categories: [any]
    brand: [any]
    panels: [RecommendationItem]
    onntop: Number!
    onntopcb: Number!
  }
`;

export const SearchItems = gql`
  type SearchItems {
    title: String
    description: String
    items: [SearchResult]
    rid: String
  }
`;

export const SearchResponseLogo = gql`
  type SearchResponseLogo {
    imageurl: String
    title: String
  }
`;

export const NaviSubcategoryState = gql`
  type SearchNaviSubcategory {
    selected: Boolean
    opened: Boolean
  }
`;

export const SearchNaviSubcategory = gql`
  type SearchNaviSubcategory {
    name: String
    size: Number
    slug: String
    state: NaviSubcategoryState
  }
`;

// TODO: Create navicategory type and delete categories
export const SearchResponse = gql`
  type SearchResponse {
    status: String
    explanation: String
    page: Number
    lastpage: Number
    total: Number
    facets: [Facets]
    sorts: [SearchSorts]
    filters: any
    items: [SearchItems]
    categories: [any]
    navicategory: any
    navisubcategory: [SearchNaviSubcategory]
    categorypath: CatalogCategory
    logo: SearchResponseLogo
    pricetype: PriceType
  }
`;

export const SearchSuggestFrazes = gql`
  type SearchSuggestFrazes {
    name: String!
    value: String!
  }
`;

export const SearchSuggestProducts = gql`
  type SearchSuggestProducts {
    name: String!
    slug: String!
    filtername: String!
    avail: Number!
    image: String
  }
`;

export const SearchSuggestBrands = gql`
  type SearchSuggestBrands {
    name: String!
    slug: String!
    filtername: String!
    size: Number!
  }
`;

export const PromotionsData = gql`
  type PromotionsData {
    html: String!
  }
`;

export const SearchSuggestCategories = gql`
  type SearchSuggestCategories {
    name: String!
    slug: String!
    size: Number!
  }
`;

export const SearchSuggestTotalMoreFilter = gql`
  type SearchSuggestTotalMoreFilter {
    name: String!
    slug: String!
    value: String!
  }
`;

export const SearchSuggestResponse = gql`
  type SearchSuggestResponse {
    callback: String
    total: Number!
    totalmore: Number!
    totalmorefilter: [SearchSuggestTotalMoreFilter]
    message: String
    frazes: [SearchSuggestFrazes]
    products: [SearchSuggestProducts]
    brands: [SearchSuggestBrands]
    cats: [SearchSuggestCategories]
  }
`;

export const BasketError = gql`
  type BasketError {
    lp: Number!
    type: Number!
    name: String
  }
`;

export const BasketLineUpdatePayload = gql`
  type BasketLineUpdatePayload {
    lineId: Number!
    quantity: Number!
  }
`;

export const BasketAddedLine = gql`
  type BasketAddedLine {
    lineId: Number!
    productId: Number!
    quantity: Number!
    price: Number!
    pricevat: Number!
    allocation: Number!
    value: Number!
    productName: String
    unit: String
    partid: String
  }
`;

export const BasketRecommendation = gql`
  type BasketRecommendation {
    name: String
    recommendation: String
  }
`;

export const BasketAdded = gql`
  type BasketAdded {
    quantity: Number!
    lines: [BasketAddedLine!]
    recommendations: [BasketRecommendation!]
  }
`;

export const PriceType = gql`
  type PriceType {
    name: String!
    selected: Boolean!
    title: String!
    type: Number!
  }
`;

export const BasketSummaryResponse = gql`
  type BasketSummaryResponse {
    value: Number!
    currencySign: String!
    priceType: String!
    quantity: Number!
    quantityModulo: Number!
    unit: String
    price: PriceType!
    pricevat: Number!
    value: Number!
    editable: Boolean!
    delete: Boolean!
    allocation: Number!
    quantityMin: Number!
    productInfo: BasketProductInfo!
    errors: [BasketError!]
    partName: String
    partCut: Number!
  }
`;

export const BasketHeader = gql`
  type BasketHeader {
    summary: BasketSummaryResponse!
    items: [BasketLine!]
    hid: String
    name: String
  }
`;

export const RegisterBasketHeader = gql`
  type RegisterBasketHeader {
    hid: String!
    selecteddelivery: Number!
    selectedaddress: Number!
    deliverydate: String
    authorizedperson: String
    selforder: String
    description: String
    tooltip: String
  }
`;

export const RegisterBasketInput = gql`
  type RegisterBasketInput {
    doctype: Number!
    headers: [RegisterBasketHeader!]
    selectedpayment: Number!
    selectedagreements: [Number!]
    selectedoptions: [Number!]
  }
`;

export const BoilerInstallationInput = gql`
  type BoilerInstallationInput {
    productname: String
    producttype: String
    serialnumber: String
    dateinstall: String
    postcode: String
    city: String
    street: String
    invoicenumber: String
    invoicecompany: String
    realizetypeid: Number
    clientinvoice: String
    clientaccount: String
    agree: Boolean
  }
`;

export const BasketResponse = gql`
  type BasketResponse {
    items: [BasketHeader!]
    summary: BasketSummaryResponse!
    status: String
    message: String
  }
`;

export const PayJump = gql`
  type PayJump {
    payTitle: String!
    payValue: Number!
    payJump: Boolean!
    payJumpUrl: String!
    payCurrency: String!
  }
`;

export const PurchaseData = gql`
  type PurchaseData {
    docnumber: String
    deliverytypename: String
    value: Number
    valuenetto: Number
    valuegross: Number
    linescount: Number
  }
`;

export const PurchaseLineItem = gql`
  type PurchaseLineItem {
    number: Number
    index: Number
    productname: String
    catalogindex: String
    quantity: String
    quantityconfirmed: Number
  }
`;

export const RegisterBasketResponse = gql`
  type RegisterBasketResponse {
    transactionId: String!
    checkurl: String!
    title: String!
    recheck: Boolean!
    progress: Number!
    status: String!
    payjump: PayJump
    purchaseeventdata: [PurchaseData]
    price: Number
    pricevat: Number
    value: Number
    valuenetto: Number
    valuegross: Number
    status: Number
  }
`;

export const Doc = gql`
  type Doc {
    number: String!
    description: String!
  }
`;

export const BankInfo = gql`
  type BankInfo {
    name: String
    address: String
    bankname: String
    bankaccount: String
    transfertitle: String
    value: Number!
  }
`;

export const TransactionStatusResponse = gql`
  type TransactionStatusResponse {
    transactionId: String
    checkurl: String
    title: String
    description: String
    recheck: Boolean!
    progress: Number!
    endmessage: String
    thankyou: String
    payjump: PayJump
    paytitle: String
    paystatus: String
    paystate: Number!
    bankinfo: BankInfo
    recommendations: [RecommendationItem!]
    createddoc: [Doc!]
    status: String!
    message: String!
  }
`;

export const PartItem = gql`
  type PartItem {
    qtyinbasket: Number!
    id: String
    partname: String
    partdesc: String
    muststay: Number
    manufacturer: String
    quantity: Number!
    quantitymod: Number!
    allocation: Number!
    type: Number!
    cutting: Number!
    unit: String
    partdiscount: Number!
    price: Number!
    currencysign: String!
  }
`;

export const BasketPartsResponse = gql`
  type BasketPartsResponse {
    productId: Number!
    unit: String
    items: [PartItem!]
    status: String
    message: String
  }
`;

export const Delivery = gql`
  type Delivery {
    id: Number!
    addresstype: Number!
    name: String!
    description: String!
    cost: Number!
    costgross: Number!
    costfreefrom: String
    selected: Boolean!
    showdeliveryharm: Boolean!
  }
`;

export const BasketHeaders = gql`
  type BasketHeaders {
    id: Number!
    hid: String!
    name: String!
    deliveries: [Delivery!]!
    address: [AvailableAddress!]!
    deliverydate: String!
    authorizedperson: String!
    selforder: String!
    tooltip: String
    description: String!
    summary: BasketSummaryResponse!
  }
`;

export const BasketPayments = gql`
  type BasketPayments {
    id: Number!
    name: String!
    description: String!
    icon: String!
    selected: Boolean!
  }
`;

export const BasketStepDescription = gql`
  type BasketStepDescription {
    stepTitle: String!
    stepButton: String!
  }
`;

export const BasketOption = gql`
  type BasketOption {
    id: Number!
    name: String!
    selected: Boolean!
  }
`;

export const BasketAgreementsList = gql`
  type BasketAgreementsList {
    title: String!
    name: String!
    required: Boolean!
    items: [BasketOption!]!
  }
`;

export const BasketOptionsList = gql`
  type BasketOptionsList {
    title: String!
    items: [BasketOption!]!
  }
`;

export const PaymentInWallet = gql`
  type PaymentInWallet {
    valueactive: Number
    currencysig: String
  }
`;

export const PaymentInItem = gql`
  type PaymentInItem {
    id: Number
    name: String
    typename: String
    type: Number
    value: Number
    error: String
  }
`;

export const PaymentIn = gql`
  type PaymentIn {
    wallet: PaymentInWallet
    paymentitems: [PaymentInItem]
  }
`;

export const DeliveryMatrixResponse = gql`
  type DeliveryMatrixResponse {
    headers: [BasketHeaders!]!
    payments: [BasketPayments!]!
    agreements: BasketAgreementsList!
    options: BasketOptionsList!
    stepdescription: BasketStepDescription!
    summary: BasketSummaryResponse!
    status: String!
    message: String!
    paymentin: PaymentIn
  }
`;

export const EnvironmentResponse = gql`
  type EnvironmentResponse {
    currentLocale: String!
    currentCurrency: CurrentCurrency!
    availableLocales: [AvailableLocale]!
    availableCurrences: [AvailableCurrency]!
    availablePriceTypes: [AvailablePriceType]!
  }
`;

export const CurrentCurrency = gql`
  type currentCurrency {
    id: Number!
    name: String!
    description: String!
    sign: String!
  }
`;

export const AvailableLocale = gql`
  type availableLocale {
    locale: String!
    name: String!
  }
`;

export const AvailableCurrency = gql`
  type availableCurrency {
    id: Number!
    name: String!
    description: String!
    sign: String!
  }
`;

export const AvailablePriceType = gql`
  type availablePriceType {
    type: Number!
    name: String!
    selected: Boolean!
  }
`;

export const Serie = gql`
  type Serie {
    slug: String
    name: String
    filtername: String
  }
`;

export const Brand = gql`
  type Brand {
    slug: String
    filtername: String
    name: String
    description: String
    logourl: String
    size: Number
    top: boolean
  }
`;

export const RecommendationResponse = gql`
  type RecommendationResponse {
    rid: String
    id: Number
    slug: String
    index: String
    catalogindex: String
    name: String
    imageth: String
    imagemd: String
    rating: Number
    currency: String
    purchase: Boolean
    ispartial: Boolean
    avail: ProductAvail
    series: [Serie]
    brand: [Brand]
    unit: String
    price: [Price]
    onntop: Number
    onntopcb: Number
  }
`;

export const RecommendationPagedResponse = gql`
  type RecommendationPagedResponse {
    title: String
    description: String
    total: Number
    page: Number
    lastpage: Number
    items: [RecommendationResponse!]
  }
`;

export const AvailableAddress = gql`
  type AvailableAddress {
    id: Number
    name: String
    personname: String
    address: String
    postcode: String
    city: String
    country: String
    phone: String
    description: String
    email: String
    iscompany: Boolean
    edit: Boolean
    selected: Boolean
    group: Number
    type: Number
  }
`;

export const GetBasketAddressesInput = gql`
  type GetBasketAddressesInput {
    hid: Number
    type: Number!
  }
`;

export const BoilerFormRealizeTypes = gql`
  type BoilerFormRealizeTypes {
    id: Number
    name: String
    selected: Selected
  }
`;

export const GetBoilerInstallationFormResponse = gql`
  type GetBoilerInstallationFormResponse {
    agree: Boolean
    agreetxt: String
    maskserial: String
    maskdate: String
    maskaccount: String
    rtyp2txt: String
    realizetypes: [BoilerFormRealizeTypes]
  }
`;

export const BasketAddressesResponse = gql`
  type BasketAddressesResponse {
    items: [AvailableAddress]
    status: String
  }
`;

export const SetBasketAddressIdInput = gql`
  type SetBasketAddressIdInput {
    hid: String!
    addressId: Number!
    addressType: Number!
  }
`;

export const SetBasketAddressesInput = gql`
  type SetBasketAddressesInput {
    id: Number
    name: String
    personname: String
    address: String
    postcode: String
    city: String
    phone: String
    email: String
    description: String
    iscompany: Boolean
    country: String
  }
`;

export const ErrorItem = gql`
  type ErrorItem {
    id: String
    name: String
  }
`;

export const SetBoilerInstallationFormResponse = gql`
  type SetBoilerInstallationFormResponse {
    errors: [ErrorItem]
    status: String!
    message: String!
  }
`;

export const SetBasketAddressIdResponse = gql`
  type SetBasketAddressIdResponse {
    items: [AvailableAddress]!
    address: AvailableAddress!
    errors: [ErrorItem]
    status: String!
    message: String!
  }
`;

export const StatusSetBasketAddresses = gql`
  type StatusSetBasketAddresses {
    status: String
    message: String
    errors: [ErrorItem]
    address: AvailableAddress!
    items: [AvailableAddress]!
  }
`;

export const DeleteBasketAddressesInput = gql`
  type DeleteBasketAddressesInput {
    id: Number!
  }
`;

export const RegisterAccountAgreeInput = gql`
  type RegisterAccountAgreeInput {
    id: Number
  }
`;

export const RegisterAccountInput = gql`
  type RegisterAccountInput {
    name: String
    agree: [RegisterAccountAgreeInput]
    city: String
    email: String
    password: String
    phone: String
    phonecountry: String
    postcode: String
    street: String
  }
`;

export const RegisterClientLegalCode = gql`
  type RegisterClientLegalCode {
    name: String
    code: String
  }
`;

export const RegisterClient = gql`
  type RegisterClient {
    name: String
    nameShort: String
    taxcode: String
    postcode: String
    city: String
    street: String
    legalCode: RegisterClientLegalCode
  }
`;

export const RegisterInput = gql`
  type RegisterInput {
    step: Number
    registerid: String
    account: RegisterAccountInput
    verificationCodeSMS: String
    verificationCodeMAIL: String
    client: RegisterClient
  }
`;

export const RegisterAgree = gql`
  type RegisterAgree {
    id: Number
    text: String
    required: Boolean
    selected: Boolean
  }
`;

export const Register = gql`
  type Register {
    registerid: String
    step: Number
    stepTitle: String
    errors: [any]
    status: String
    message: String
    stepDesc: String
    agree: [RegisterAgree]
    errors: [any]
    client: [RegisterClient]
    segments: [any]
  }
`;

export const OrdersItem = gql`
  type OrdersItem {
    id: Number
    docdate: String
    docnumber: String
    docdatereal: String
    docselforder: String
    docdescription: String
    docauthorizedperson: String
    deliveryname: String
    deliverycity: String
    deliverypostcode: String
    deliverystreet: String
    paymentname: String
    autorname: String
    autorsrc: String
    paymentrequired: Boolean
    value: Number
    valuenetto: Number
    valuegross: Number
    valuepaymentin: Number
    currency: CurrentCurrency
    linescount: Number
  }
`;

export const OrdersAndInvoicesDatain = gql`
  type OrdersAndInvoicesDatain {
    datefrom: String
    dateto: String
    ordernumber: String
    onlymy: Boolean
    onlyinprogress: Boolean
    onlyweb: Boolean
    page: Number
  }
`;

export const QuotationDataIn = gql`
  type QuotationDataIn {
    datefrom: String
    dateto: String
    onlyvalid: Boolean
    find: String
    page: Number
    sorttype: Number
  }
`;

export const SortData = gql`
  type SortData {
    page: Number
    sorttype: Number
  }
`;

export const WalletPaymentItem = gql`
  type WalletPaymentItem {
    id: Number
    name: String
    date: String
    value: Number
    currencysig: String
    description: String
    active: Boolean
    inout: Number
  }
`;

export const InstallationForm = gql`
  type InstallationForm {
    id: Number
    date: String
    name: String
    serial: String
    dateinstall: String
    street: String
    formpay: String
    status: String
    account: String
  }
`;

export const BonusAgreementLevel = gql`
  type BonusAgreementLevel {
    eturnover: Number
    evalue: Number
  }
`;

export const BonusAgreement = gql`
  type BonusAgreement {
    year: String
    number: String
    status: String
    state: String
    datefrom: String
    dateto: String
    quarter: Number
    levels: [BonusAgreementLevel]
    turnt: Number
    turnb: Number
    turne: Number
    bonus: Number
    currency: String
  }
`;

export const BonusAgreementsList = gql`
  type BonusAgreementsList {
    items: [BonusAgreement]
    title: String
    status: String
  }
`;

export const InstallationFormsList = gql`
  type InstallationFormsList {
    items: [InstallationForm]
    valueactive: Number
    valuenotactive: Number
    count: Number
    currencysig: String
    lastpage: Number
    recordfrom: Number
    recordto: Number
    datain: SortData
  }
`;

export const WalletPaymentsList = gql`
  type WalletPaymentsList {
    items: [WalletPaymentItem]
    valueactive: Number
    valuenotactive: Number
    valuein: Number
    valueout: Number
    count: Number
    currencysig: String
    lastpage: Number
    recordfrom: Number
    recordto: Number
    datain: SortData
    currencysig: String
    status: String
    message: String
  }
`;

export const Orders = gql`
  type Orders {
    orders: [OrdersItem]
    count: Number
    lastpage: Number
    datain: OrdersAndInvoicesDatain
    value: Number
    valuenetto: Number
    valuegross: Number
    currency: Currency
    pricetype: PriceType!
  }
`;

export const Order = gql`
  type Order {
    order: OrdersItem
    count: Number
    lastpage: Number
    datain: OrdersAndInvoicesDatain
  }
`;

export const PayOrder = gql`
  type PayOrder {
    payTitle: String
    payJumpUrl: String
    payCurrency: String
    status: String
    message: String
    payValue: Number
    payJump: Boolean
  }
`;
export const CustBalanceCustomer = gql`
  type CustBalanceCustomer {
    nameShort: String
    bankName: String
    bankAccount: String
  }
`;

export const CustBalanceWallet = gql`
  type CustBalanceWallet {
    valueactive: Number
  }
`;

export const CustBalance = gql`
  type CustBalance {
    balancemessage: String
    creditLimit: Number
    sumopens: Number
    limit: Number
    freebalance: Number
    currentbasket: Number
    exceedingvalue: Number
    currency: String
    indelivery: Number
    ininvoice: Number
    initems: Number
    inorders: Number
    inspecial: Number
    paymenttype: String
    balancedescription: CustBalanceCustomer
    wallet: CustBalanceWallet
  }
`;

export const LocalState = gql`
  type LocalState {
    language: String!
    basketSummary: BasketSummary!
  }
`;

export const ShortInfoSectionItemCoulumn = gql`
  type ShortInfoSectionItemCoulumn {
    value: string
    action: string
    cssclass: string
  }
`;

export const ShortInfoSectionItem = gql`
  type ShortInfoSectionItem {
    columns: [ShortInfoSectionItemCoulumn]
  }
`;

export const ShortInfoSection = gql`
  type ShortInfoSection {
    id: String
    title: String
    moredesc: String
    moreaction: String
    cssclass: String
    items: [ShortInfoSectionItem]
  }
`;

export const ShortInfo = gql`
  type ShortInfo {
    customer: Customer
    user: User
    status: String
    message: String
    sections: [ShortInfoSection]
  }
`;

export const InvoiceItemLine = gql`
  type InvoiceItemLine {
    linenumber: String
    productname: String
    quantity: Number
    price: Number
    priceVat: Number
    value: Number
    valuegross: Number
    valuenetto: Number
    valuetax: Number
  }
`;

export const Invoice = gql`
  type Invoice {
    doctype: String
    currency: Currency
    dateinvoice: String
    datepayment: String
    value: Number
    valuegross: Number
    valuenetto: Number
    valueopen: Number
    lines: [InvoiceItemLine]
  }
`;

export const InvoicesItem = gql`
  type InvoicesItem {
    id: Number
    lines: [InvoiceItemLine]
  }
`;

export const Invoices = gql`
  type Invoices {
    invoices: [InvoicesItem]
    count: Number
    datain: OrdersAndInvoicesDatain
    errors: [ErrorItem]
    value: Number
    valuenetto: Number
    valuegross: Number
    records: Number
    pricetype: PriceType
    currency: Currency
    lastpage: Number
    recordfrom: Number
    recordto: Number
    status: String
    message: String
  }
`;

export const OfferItemLine = gql`
  type OfferItemLine {
    number: Number
    index: String
    productname: String
    catalogindex: String
    quantity: Number
    unit: Unit
    price: Number
    priceVat: Number
    value: Number
    valuegross: Number
    valuenetto: Number
  }
`;

export const OfferItem = gql`
  type OfferItem {
    docdate: String
    docnumber: String
    docdatevalidto: String
    docdatevalidfrom: String
    autorname: String
    valuenetto: String
    valuegross: Number
    currency: Currency
    linescount: Number
  }
`;

export const Offers = gql`
  type Offers {
    quotations: [OfferItem]
    errors: [ErrorItem]
    value: Number
    valuenetto: Number
    valuegross: Number
    pricetype: PriceType
    currency: Currency
    datain: QuotationDataIn
    records: Number
    count: Number
    lastpage: Number
    recordfrom: Number
    recordto: Number
    status: String
    message: String
    pricetype: PriceType
  }
`;

export const InquiryItem = gql`
  type InquiryItem {
    id: Number
    docdate: String
    docnumber: String
    docselforder: String
    docdescription: String
    docinvestmentname: String
    deliveryname: String
    autorname: String
    value: Number
    valuenetto: Number
    valuegross: Number
    linescount: Number
    weight: Number
    status: Number
    statusdesc: String
  }
`;

export const Inquiries = gql`
  type Inquiries {
    inqueries: [InquiryItem]
    errors: [ErrorItem]
    value: Number
    valuenetto: Number
    valuegross: Number
    pricetype: PriceType
    currency: Currency
    datain: QuotationDataIn
    records: Number
    count: Number
    lastpage: Number
    recordfrom: Number
    recordto: Number
    status: String
    message: String
  }
`;

export const SingleOffer = gql`
  type SingleOffer {
    docnumber: String
    currency: Currency
    docdate: String
    docdatevalidto: String
    docdescription: String
    autorname: String
    getpdfpossible: Boolean
    status: Number
    statusdesc: String
    deliverycity: String
    deliverypostcode: String
    deliverystreet: String
    lines: [OfferItemLine]
  }
`;

export const Offer = gql`
  type Offer {
    quotation: SingleOffer
    currency: Currency
    errors: [ErrorItem]
    pricetype: PriceType
    message: String
    status: String
  }
`;

export const InquiryItemLine = gql`
  type InquiryItemLine {
    number: Number
    index: String
    productname: String
    catalogindex: String
    quantity: Number
    unit: Unit
    price: Number
    priceVat: Number
    value: Number
    valuegross: Number
    valuenetto: Number
  }
`;

export const SingleInquiry = gql`
  type SingleInquiry {
    id: Number
    docnumber: String
    currency: Currency
    docdate: String
    docdatevalidto: String
    docdescription: String
    autorname: String
    getpdfpossible: Boolean
    status: Number
    statusdesc: String
    deliverycity: String
    deliverypostcode: String
    deliverystreet: String
    lines: [OfferItemLine]
    quotationnumber: String
  }
`;

export const Inquiry = gql`
  type Inquiry {
    inquery: SingleInquiry
    currency: Currency
    errors: [ErrorItem]
    pricetype: PriceType
  }
`;

export const InvoicePayment = gql`
  type InvoicePayment {
    docnumber: String
    value: Number
    date: String
    docclearing: String
  }
`;

export const InvoicePayments = gql`
  type InvoicePayments {
    payments: [InvoicePayment]
    status: String
    message: String
  }
`;

export const PaymentsPayment = gql`
  type PaymentsPayment {
    number: String
    id: String
    date: String
    days: Number
    status: Number
    statusdesc: String
    value: Number
    paypossible: Boolean
  }
`;

export const PaymentItem = gql`
  type PaymentItem {
    docnumber: String
    doctype: String
    docdate: String
    datepayment: String
    docvaluegross: Number
    docvaluenetto: Number
    docvalueopen: Number
    paycount: Number
    invoice: String
    currency: String
    payments: [PaymentsPayment]
  }
`;

export const PaymentInfoItem = gql`
  type PaymentInfoItem {
    name: String
    value: String
    css: String
  }
`;

export const PaymentWallet = gql`
  type PaymentWallet {
    valueactive: Number
    valuenotactive: Number
    valuein: Number
    valueout: Number
    count: Number
    currencysig: String
  }
`;

export const Payments = gql`
  type Payments {
    payments: [PaymentItem]
    errors: [ErrorItem]
    openbalance: Number
    value: Number
    valuegross: Number
    records: Number
    currency: Currency
    status: String
    message: String
    infoitems: [PaymentInfoItem]
    wallet: PaymentWallet
  }
`;

export const PayUPayment = gql`
  type PayUPayment {
    payTitle: String
    payValue: Number
    payJump: Boolean
    payJumpUrl: String
    payCurrency: String
    status: String
    message: String
  }
`;

export const CheckStatus = gql`
  type CheckStatus {
    transactionId: String
    title: String
    endmessage: String
    recheck: Boolean
    status: String
    message: String
  }
`;

export const Segment = gql`
  type Segment {
    id: Number
    name: String
    groupname: String
    subaslist: Boolean!
    prc: Number
    description: String
    selected: Boolean!
    subsegment: [any]!
  }
`;

export const Segments = gql`
  type Segments {
    customer: Customer
    segments: [Segment]
    declarationvalue: Number
    iseditable: Boolean
    errors: [ErrorItem]
    status: String
    message: String
  }
`;

export const GetDeliveryRouteInput = gql`
  type GetDeliveryRouteInput {
    postcode: String!
    deliveryid: Number!
  }
`;

export const DeliveryRouteItem = gql`
  type DeliveryRouteItem {
    daynumber: Number
    name: String
    delivery: Boolean
    date: String
    near: Boolean
  }
`;

export const DeliveryRouteResponse = gql`
  type DeliveryRouteResponse {
    postcode: String
    endtime: String
    status: String!
    items: [DeliveryRouteItem!]
  }
`;

export const BalancePanel = gql`
  type BalancePanel {
    paymenttype: String
    freebalance: Number
    currency: String
    balancestatus: Number
    balancemessage: String
  }
`;

export const UpdateEInvoice = gql`
  type UpdateEInvoice {
    email: String
    status: String
    message: String
  }
`;

export const Unit = gql`
  type Unit {
    unit: String
    unitL: String
  }
`;

export const PackageSettlementItem = gql`
  type PackageSettlementItem {
    index: String
    name: String
    number: String
    invoice: String
    invoiceline: Number
    quantity: Number
    unit: Unit
    days: Number
    day: String
    description: String
    percent: Number
  }
`;

export const PackageSettlement = gql`
  type PackageSettlement {
    items: [PackageSettlementItem]
    status: String
    message: String
  }
`;

export const AddExtraPaymentInput = gql`
  type AddExtraPaymentInput {
    id: Number
    type: Number
    code: String
    value: Number
    doctype: Number
    hids: [String]
  }
`;

export const RemoveExtraPaymentInput = gql`
  type RemoveExtraPaymentInput {
    id: Number
  }
`;

export const AddExtraPaymentResult = gql`
  type AddExtraPaymentResult {
    message: String
    paymentvalue: Number
    paymentitems: [PaymentInItem]
    wallet: PaymentInWallet
  }
`;

export const ProductAddedToClipboard = gql`
  type ProductAddedToClipboard {
    quantity: Number
    unit: String
  }
`;

export const AllProductsAddedToClipboard = gql`
  type AllProductsAddedToClipboard {
    hids: [String]
    status: String
    message: String
  }
`;

export const AddedToClipboardInfoResponse = gql`
  type AddedToClipboardInfoResponse {
    id: Number
    number: Number
    linescount: Number
    name: String
    selected: Boolean
    status: String
    message: String
    added: ProductAddedToClipboard
  }
`;

export const GetCustomerAccessList = gql`
  type GetCustomerAccessList {
    customerid: Number
    userid: Number
  }
`;

export const ClipboardInfoResponse = gql`
  type ClipboardInfoResponse {
    id: Number
    number: Number
    linescount: Number
    name: String
    selected: Boolean
    status: String
    message: String
  }
`;

export const CreateClipboardInput = gql`
  type CreateClipboardInput {
    id: Number
    number: Number
    linescount: Number
    name: String
    selected: Boolean
    status: String
    message: String
  }
`;

export const GetGpayScriptInput = gql`
  type GetGpayScriptInput {
    transactionid: String
  }
`;
export const GetApplePayScriptInput = gql`
  type GetApplePayScriptInput {
    transactionid: String
  }
`;

export const SetClipboardNoteInput = gql`
  type SetClipboardNoteInput {
    lineid: Number
    linenote: String
  }
`;

export const AddProductToClipboardInput = gql`
  type AddProductToClipboardInput {
    id: Number
    productId: Number
    quantity: Number
    type: Number
  }
`;

export const CreateInquiryFromClipboardInput = gql`
  type CreateInquiryFromClipboardInput {
    id: Number
    selecteddelivery: Number
    description: String
    selforder: String
    investmentname: String
    doccreated: String
    doccreatedid: Number
    lineids: [Number]
  }
`;

export const ClipboardBaseInfoResponse = gql`
  type ClipboardBaseInfoResponse {
    clipboards: [ClipboardInfoResponse]
    linescount: Number
    name: String
    selected: Boolean
  }
`;

export const SetClipboardNoteResponse = gql`
  type SetClipboardNoteResponse {
    lineid: Number
    linenote: String
    name: String
    desc: String
    status: String
    message: String
  }
`;

export const CreateInquiryFromClipboardResponse = gql`
  type CreateInquiryFromClipboardResponse {
    id: Number
    status: String
    message: String
  }
`;

export const ClipboardLine = gql`
  type ClipboardLine {
    productInfo: BasketProductInfo
  }
`;

export const InquiryDeliveryItem = gql`
  type InquiryDeliveryItem {
    id: Number
    name: String
    selected: Boolean
  }
`;

export const ClipboardResponse = gql`
  type ClipboardResponse {
    clipboards: [ClipboardInfoResponse]
    linescount: Number
    name: String
    selected: Boolean
    lines: [ClipboardLine]
    id: Number
    inquerydelivs: [InquiryDeliveryItem]
    inqueryinvestmentrequired: Boolean
    inquerymessage: String
    inquery: String
    valuenetto: Number
    valuegross: Number
    description: String
    pricetype: PriceType
  }
`;

export const ClipboardDeleteResponse = gql`
  type ClipboardDeleteResponse {
    status: String
    message: String
  }
`;

export const ClipboardSetNameAndDescResponse = gql`
  type ClipboardSetNameAndDescResponse {
    status: String
    message: String
  }
`;

export const ClipboardSetNameAndDescInput = gql`
  type ClipboardSetNameAndDescInput {
    name: String
    desc: String
  }
`;

export const ClipboardCopyToClipboardInput = gql`
  type ClipboardCopy {
    id: Number
    lineids: [Number]
    tid: Number
    tname: String
    status: String
    message: String
  }
`;

export const ClipboardCopyToBasketInput = gql`
  type ClipboardCopy {
    id: Number
    lineids: [Number]
    tid: Number
    tname: String
    status: String
    message: String
  }
`;

export const CopyBasketToClipboardInput = gql`
  type AllProductsAddedToClipboard {
    hids: [String]
  }
`;

export const CloseProductAdvertiseResponse = gql`
  type CloseProductAdvertiseResponse {
    status: String
    message: String
  }
`;

export const RemoveMemoListValueResponse = gql`
  type StatusResponse {
    key: String!
    value: String!
    values: [String]
  }
`;

export const Answer = gql`
  type Answer {
    id: Number
    selected: Boolean
    value: String
  }
`;

export const SetPromotionInput = gql`
  type SetPromotionInput {
    id: Number
    answers: [Answer]
  }
`;

export const ClipboardActivate = gql`
  type ClipboardActivate {
    status: String
    message: String
  }
`;

export const AlertActiveInput = gql`
  type AlertActiveInput {
    isactive: Boolean
    lineId: Number
  }
`;

export const SetAlertActiveResponse = gql`
  type SetAlertActiveResponse {
    status: String
  }
`;

export const CustomRoles = gql`
  type CustomRoles {
    id: Number
    level: Number
    name: String
    description: String
    selected: Boolean
    iscustom: Boolean
    category: String
  }
`;

export const Customers = gql`
  type Customers {
    customerid: Number
    userid: Number
    customername: String
    customeridex: String
    joined: Boolean
    issubcart: Boolean
    levelselected: Number
    customroles: [CustomRoles]
  }
`;

export const UserStatus = gql`
  type UserStatus {
    lastlogindata: String
    create: String
    locked: Boolean
    deleted: Boolean
    lockstatus: Number
  }
`;

export const AccessCustomerUser = gql`
  type AccessCustomerUser {
    id: Number
    name: String
    userName: String
    email: String
    phone: Number
    phoneCountry: Number
    status: [UserStatus]
    loggedin: String
    editable: Boolean
    customers: [Customers]
    agreements: String
  }
`;

export const SetCustomerAccessUserInput = gql`
  type SetCustomerAccessUserInput {
    user: [AccessCustomerUser]
  }
`;

export const SendMfaUidInput = gql`
  type SendMfaUidInput {
    uid: String
  }
`;

export const SendMfaTokenInput = gql`
  type SendMfaUidInput {
    uid: String
    token: String
    step: Number
  }
`;

export const SendMfaAcceptAgreementUidInput = gql`
  type SendMfaAcceptAgreementTokenInput {
    refid: Number
    reftype: Number
  }
`;

export const SendMfaAcceptAgreementTokenInput = gql`
  type SendMfaAcceptAgreementTokenInput {
    token: String
    uid: String
    agree: Boolean
  }
`;

export const DeleteFileFromComplaintInput = gql`
  type DeleteFileFromComplaintInput {
    itemid: Number
    getname: String
    transactionid: Number
  }
`;

export const RegisterConsumerInput = gql`
  type RegisterInput {
    step: Number
    registerid: String
    account: RegisterAccountInput
  }
`;

export const RegisterConfirmationInput = gql`
  type RegisterConfirmation {
    code: String
    id: String
  }
`;

export const GetAddressInfoInput = gql`
  type GetAddressInfo {
    iserp: Boolean
  }
`;

export const AuthorizedPersonInput = gql`
  type AuthorizedPerson {
    id: String
    customerid: Number
    name: String
    phone: String
    email: String
    doc: String
    doctype: String
  }
`;

export const JoinAuthorizedPersonInput = gql`
  type JoinAuthorizedPerson {
    id: String
    customerid: Number
  }
`;

export const GetDeliveryCostInfoInput = gql`
  type GetDeliveryCostInfo {
    productid: Number
    quantity: Number
  }
`;

export const GetProductOpinionsInput = gql`
  type GetProductOpinions {
    productid: Number
    nexttoend: Boolean
  }
`;

export const PayByBlikInput = gql`
  type PayByBlik {
    blikcode: Number
    transactionid: Number
  }
`;

export const OneTimeFormAgreesInput = gql`
  type OneTimeFormAgrees {
    id: Number
    text: String
    required: Boolean
    selected: Boolean
  }
`;

export const OneTimeFormInput = gql`
  type OneTimeForm {
    formtype: Number
    persongivenname: String
    personlastname: String
    personemail: String
    personphonecountry: String
    personphone: String
    personcountry: String
    personaddress: String
    personcity: String
    personpostcode: String
    isotheraddress: Boolean
    othername: String
    otheremail: String
    othergivenname: String
    otherlastname: String
    otherphonecountry: String
    otherphone: String
    otheraddress: String
    othercity: String
    otherpostcode: String
    othercountry: String
    companygivenname: String
    companylastname: String
    companyemail: String
    companyphonecountry: String
    companyphone: String
    companyname: String
    companytaxcode: String
    companyaddress: String
    companycity: String
    companycountry: String
    companypostcode: String
    agrees: [OneTimeFormAgreesInput]
  }
`;
export const SetProductReviewInput = gql`
  type SetProductReview {
    id: Number
    rating: Number
    review: String
  }
`;

export const GetReturnDocInput = gql`
  type GetReturnDoc {
    agree: Boolean
    number: String
    optionid: Number
    type: Number
  }
`

export const GetReturnProcessInputLines = gql`
  type GetReturnProcess {
    linenumber: String
    quantity_return: Number
  }
`

export const GetReturnProcessInput = gql`
  type GetReturnProcess {
    agree: Boolean
    number: String
    optionid: Number
    type: Number
    lines: [GetReturnProcessInputLines]
    goodoption: Number
    refundoption: Number
  }
`

export const GetReturnsAndComplaintsInput = gql`
  type GetReturnsAndComplaintsList {
    datefrom: String
    dateto: String
    returns: Boolean
    claims: Boolean
    open: Boolean
    find: String
    page: Number
  }
`