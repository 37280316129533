import { vestResolver } from '@hookform/resolvers/vest/dist/vest.umd';
import { useForm } from 'react-hook-form';

import { sendMessageTargetsKeys } from 'src/enums/sendMessageTargets';

import SEND_MESSAGE from 'src/graphql/mutations/sendMessage.gql';

import useGlobalContactFormViaLink from 'src/hooks/useGlobalContactFormViaLink';
import useRESTMutation from 'src/hooks/useRESTMutation';
import useTranslation from 'src/hooks/useTranslation';

import { sendMessageSchema, sendMessageSchemaVariables } from 'src/schemas/sendMessage';

import { getSalesmanBadgeTheme } from './formSalesmanHelpers';
import Button from '../Button';
import * as S from './styles';
import PhoneFull from '../Icons/PhoneFull';
import MessageFull from '../Icons/MessageFull';
import PersonBold from '../Icons/PersonBold';

const validationResolver = vestResolver(sendMessageSchema);

const defaultProps = {
  phone: null,
  email: null,
};

export const FormContactSalesman = ({ name, phone, email, status, statusDescription } = defaultProps) => {
  const { t } = useTranslation();
  const { t: tErr } = useTranslation('errors');
  const { register, handleSubmit, reset, errors, formState } = useForm({
    mode: 'onSubmit',
    resolver: validationResolver,
  });
  const [sendMessage, { data, loading, error }] = useRESTMutation(SEND_MESSAGE);
  const addEventListenerByRef = useGlobalContactFormViaLink();
  const isBadgeVisible = !!status;
  const onFormSubmit = (input) => {
    if (loading) return;

    sendMessage({
      variables: {
        input: {
          ...input,
          target: email,
          referrer: document.location.href,
          source: sendMessageTargetsKeys.SALESMAN_INQUIRY,
        },
      },
    });

    reset();
  };

  return (
    <S.FormContactSalesman>
      <S.FormContactSalesmanLinks>
        <S.FormContactSalesmanName>
          <PersonBold />
          {name}
          {isBadgeVisible ? <S.Badge variant={getSalesmanBadgeTheme(status)} text={statusDescription} /> : null}
        </S.FormContactSalesmanName>
        {!!phone && (
          <S.FormSalesmanTopLink href={`tel:${phone}`} variant="quaternary">
            <span className="link-text" role="presentation">
              <PhoneFull />
              {phone}
            </span>
          </S.FormSalesmanTopLink>
        )}

        {!!email && (
          <S.FormSalesmanTopLink href={`mailto:${email}`} variant="quaternary">
            <S.ContactElement className="link-text" role="presentation">
              <MessageFull />
              {email}
            </S.ContactElement>
          </S.FormSalesmanTopLink>
        )}
      </S.FormContactSalesmanLinks>
      <S.FormContactSalesmanForm>
        <S.FormContactSalesmanTextarea
          className="textarea"
          ref={register}
          rows={2}
          id="salesman_contact_form"
          name="message"
          label={t('salesmanMessage')}
          placeholder={t('writeSalesmanMessage')}
          error={tErr(errors.message?.message, sendMessageSchemaVariables)}
          hideLabel
          block
          autoComplete="nope"
        />
      </S.FormContactSalesmanForm>
      {!formState.isDirty && (
        <S.FormSalesmanStatusMessage
          className="statusMessage"
          successMessage={errors?.message ? undefined : data?.sendMessage?.message}
          errorMessage={error?.networkError?.result?.message}
        />
      )}

      <S.FormContactSalesmanSubmitWrapper>
        <Button
          onClick={handleSubmit(onFormSubmit)}
          variant="primary"
          fontSizeIndex={1}
          busy={loading}
          busyChildren={t('loading')}
          isRounded
        >
          {t('send')}
        </Button>
      </S.FormContactSalesmanSubmitWrapper>
    </S.FormContactSalesman>
  );
};

FormContactSalesman.defaultProps = defaultProps;
