import { useState } from 'react';
import { AuthenticationContext } from './AuthenticationContext';
import AuthenticationModal from 'src/components/AuthenticationModal';

const defaultProps = {
  children: null,
}

export const AuthenticationProvider = ({ children } = defaultProps) => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [shouldRefetch, setSouldRefetch] = useState(false)


  return (
    <AuthenticationContext.Provider value={{ isAuthModalOpen, setIsAuthModalOpen, shouldRefetch, setSouldRefetch }}>
      <AuthenticationModal isOpen={isAuthModalOpen} setIsOpen={setIsAuthModalOpen}/>
      {children}
    </AuthenticationContext.Provider>
  );
};
