import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { rem } from 'polished';

import { breakpointsKeys } from 'src/enums/breakpoints';

import FullscreenModal from 'src/components/FullscreenModal';
import _Bell from 'src/components/Icons/Bell';
import _Button from 'src/components/Button';

export const NotificationsIndicatorMainWrapper = styled.div`
  position: relative;
  height: ${rem(27)};
  ${up(breakpointsKeys.TABLET)} {
    height: unset;
  }
`;

export const Modal = styled(FullscreenModal)`
  padding: ${rem(16)} ${rem(16)} 0;
`;

export const Divider = styled.hr`
  opacity: 0.3;
  width: 100%;
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  flex-direction: column;

  ${up(breakpointsKeys.TABLET)} {
    max-height: ${rem(432)};
    padding: 0 ${rem(16)};
  }
`;

export const Bell = styled(_Bell)`
  height: ${rem(28)};

  ${up(breakpointsKeys.TABLET)} {
    > path {
      stroke: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    }
  }
`;

export const IconLabel = styled.p`
  display: none;
  ${up(breakpointsKeys.TABLET)} {
    color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    margin-top: ${rem(4)};
    display: block;
    font-size: ${({ theme }) => theme.typography.fontSize.s1};
  }
`;

export const Button = styled(_Button)`
  ${up(breakpointsKeys.TABLET)} {
    height: 100%;
    .content {
      display: flex;
      flex-direction: column;
    }
    &:focus-visible {
      .content {
        ${up(breakpointsKeys.TABLET)} {
          ${Bell} > path {
            ${({ theme }) => theme.helpers.transition(['stroke'])};
            stroke: ${({ theme }) => theme.helpers.hexToRGBA(theme.defaultPalette.teal[0])};
          }

          ${IconLabel} {
            ${({ theme }) => theme.helpers.transition(['color'])};
            color: ${({ theme }) => theme.defaultPalette.teal[0]};
          }
        }
      }
    }
  }

  ${up(breakpointsKeys.DESKTOP)} {
    &:hover {
      .content {
        ${Bell} > path {
          ${({ theme }) => theme.helpers.transition(['stroke'])};
          stroke: ${({ theme }) => theme.helpers.hexToRGBA(theme.defaultPalette.teal[0])};
        }

        ${IconLabel} {
          ${({ theme }) => theme.helpers.transition(['color'])};
          color: ${({ theme }) => theme.defaultPalette.teal[0]};
        }
      }
    }
  }
`;
