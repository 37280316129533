import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import useLocalState from '../useLocalState';
import useLanguage from '../useLanguage';
import { checkDocumentStateEqualComplete } from 'src/utils/checkDocumentStateEqualComplete';
import { decodeSlugToLanguageCodeAndSlug } from 'src/utils/decodeSlugToLanguageCodeAndSlug';
import transformUrlToNextLink from 'src/utils/transformUrlToNextLink';

const doOnEveryFundedElement = (queryString, callback) => {
  const $elementsList = document.querySelectorAll(queryString);
  $elementsList.forEach(callback);
};

export const useEffectWysiwygLinkTransformToSpa = () => {
  const [localState] = useLocalState();
  const [currentLanguage] = useLanguage();
  const { events: routeEvents, push } = useRouter();

  const onClickHandler = useCallback(
    (e) => {
      const href = e.target.href || e.target.parentElement?.href;

      if (!href) {
        return;
      }

      const origin = typeof window !== 'undefined' ? window?.location?.origin : SSR_BASE_URL;
      const url = new URL(href, origin);
      const { languageCode } = decodeSlugToLanguageCodeAndSlug(url.pathname);

      if (languageCode !== currentLanguage) {
        return;
      }

      const routesMap = new Map(localState?.routingMap?.routesMap);
      const slugsMap = new Map(localState?.routingMap?.slugsMap);
      const nextLinkData = transformUrlToNextLink({ href, routesMap, slugsMap });

      if (nextLinkData) {
        e.preventDefault();
        push(nextLinkData.layout, nextLinkData.slug);
      }
    },
    [localState, push, currentLanguage],
  );

  useEffect(() => {
    // Note: in some case event will add twice event, even if return function have remove event function
    if (!localState?.routingMap?.routesMap) {
      return;
    }

    const addEventOnClinkLinks = () => {
      doOnEveryFundedElement('.wysiwyg a:not([target])', ($linkTag) => {
        $linkTag.addEventListener('click', onClickHandler);
      });
    };

    const removeEventOnClinkLinks = () => {
      doOnEveryFundedElement('.wysiwyg a:not([target])', ($linkTag) => {
        $linkTag.removeEventListener('click', onClickHandler);
      });
    };

    // Note: this run on first mounted, after SSR
    checkDocumentStateEqualComplete(addEventOnClinkLinks);

    routeEvents.on('routeChangeComplete', addEventOnClinkLinks);
    routeEvents.on('routeChangeStart', removeEventOnClinkLinks);
    return () => {
      routeEvents.off('routeChangeComplete', addEventOnClinkLinks);
      routeEvents.off('routeChangeStart', removeEventOnClinkLinks);
      removeEventOnClinkLinks();
    };
  }, [localState, onClickHandler, routeEvents]);
};
