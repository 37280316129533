import { useState } from 'react';

import { useRouter } from 'next/router';
import * as S from './styles';

import { breakpointsKeys } from 'src/enums/breakpoints';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import UserCustomersList from '../UserCustomersList';
import Person from '../Icons/Person';

import LOGOUT from 'src/graphql/queries/logout.gql';
import GET_USER_INFO from 'src/graphql/queries/getUserInfo.gql';
import useTranslation from 'src/hooks/useTranslation';
import useLazyShopQuery from 'src/hooks/useLazyShopQuery';

import SmallDialog from 'src/components/SmallDialog';

export const HeaderUserControls = ({ user = {}, homePageSlug, homePageLayout }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const currentBreakpoint = useBreakpointDetector();
  const isDesktop =
    currentBreakpoint === breakpointsKeys.DESKTOP ||
    currentBreakpoint === breakpointsKeys.DESKTOP_LARGE ||
    currentBreakpoint === breakpointsKeys.HD;

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const [getUserInfoLazily] = useLazyShopQuery(GET_USER_INFO, {
    variables: { query: 'user' },
  });

  const [logout] = useLazyShopQuery(LOGOUT, {
    onCompleted: () => {
      getUserInfoLazily();
      router.push(homePageLayout, `/${homePageSlug}`);
    },
  });

  const handleLogout = () => {
    logout();
  };
  if (!user) return null;
  return (
    <S.HeaderUserControlWrapper>
      <S.UserControlsButton blank onClick={handleOpenDialog}>
        {isDesktop ? (
          <>
            <S.UserHeaderPersonIcon />
            <S.UserControlsLabel>{user?.user?.name}</S.UserControlsLabel>
          </>
        ) : (
          <S.LogIn />
        )}
      </S.UserControlsButton>

      <SmallDialog isOpen={isDialogOpen} onOverlayClick={handleCloseDialog}>
        <S.HeaderUserControlsDialogContentWrapper>
          <S.HeaderUserControlsNameAndIconWrapper>
            <Person />
            <S.HeaderUserControlsMainUserName>{user?.user?.name}</S.HeaderUserControlsMainUserName>
          </S.HeaderUserControlsNameAndIconWrapper>
          <UserCustomersList user={user} afterItemClick={handleCloseDialog} />
          <S.LogoutButton isRounded onClick={handleLogout}>
            {t('logout')}
          </S.LogoutButton>
        </S.HeaderUserControlsDialogContentWrapper>
      </SmallDialog>
    </S.HeaderUserControlWrapper>
  );
};
