import { query } from 'config/queries';

export const encodeSearchQueryParam = (optionsObject) =>
  Object.keys(optionsObject)
    // eslint-disable-next-line consistent-return
    .map((key) => {
      const option = optionsObject[key];
      // eslint-disable-next-line default-case
      if (typeof option === 'object') {
        if (Array.isArray(option)) {
          return `/${key}:${option.map(encodeURIComponent).join(',')}`;
        }
        if (option instanceof Set) {
          return `/${key}:${[...option].map(encodeURIComponent).join(',')}`;
        }

        // eslint-disable-next-line no-console
        console.error('I got an unsupported type');
        return '';
      }

      return `/${key}:${option}`;
    })
    .join('');

export const generateQueryUri = (slug, optionsObject, addonSlug = '') => {
  const queryPrefix = `${query}=`;
  const queryOptions = optionsObject ? `?${queryPrefix}${encodeSearchQueryParam(optionsObject)}` : '';
  return `/${slug}${addonSlug}${queryOptions}`;
};

export const mapFacetToFiltersNavigationCheckboxList = (facet) => {
  const navigationCheckboxList = [];
  const oneLevelObj = {
    items: [],
  };

  facet.items.forEach((firstLevel) => {
    if ((firstLevel.value === null || firstLevel.value === undefined) && firstLevel.items?.length > 0) {
      navigationCheckboxList.push({
        heading: firstLevel.filtername,
        items: firstLevel.items.map((secondLevel) => ({
          label: secondLevel.value,
          value: {
            key: facet.slug,
            value: secondLevel.slug,
          },
          productsAmount: secondLevel.size,
          checked: secondLevel.selected || false,
        })),
      });
    } else {
      oneLevelObj.items.push({
        label: firstLevel.value,
        value: {
          key: facet.slug,
          value: firstLevel.slug,
        },
        productsAmount: firstLevel?.size,
        checked: firstLevel.selected || false,
      });
    }
  });

  if (oneLevelObj.items.length > 0) {
    navigationCheckboxList.push(oneLevelObj);
  }

  return navigationCheckboxList;
};

export const returnQuery = (path) => path.split(`${query}=`)[1];

export const getQueryParamValue = (queryString) => {
  const str = `${queryString}&`;
  const regExp = new RegExp(`${query}=(.*?)[&?]`);
  const regExpCatchResults = str.match(regExp);

  return regExpCatchResults != null ? regExpCatchResults[1] : '';
};
