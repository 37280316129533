import * as S from './styles';

import Skeleton from 'src/components/Skeleton';

const NotificationsListSkeleton = () => (
  <S.SkeletonItemWrapper>
    <S.IconSkeleton width={25} height={25} variant="circular" />
    <S.MessageSkeleton width={250} height={30} variant="rounded" style={{ gridArea: 'message' }} />
    <Skeleton width={25} height={25} variant="circular" style={{ gridArea: 'close' }} />
    <S.BottomPart>
      <Skeleton width={70} height={15} variant="rounded" />
      <Skeleton width={50} height={15} variant="rounded" />
    </S.BottomPart>
  </S.SkeletonItemWrapper>
);

export default NotificationsListSkeleton;
