import { defaultLanguage } from 'config/locales';
import { createNextLinkProps } from './createNextLinkProps';

const defaultProps = {
  navEntries: [],
  currentPageName: '',
  language: defaultLanguage,
  user: null,
};

export const createCustomLinkProps = ({ 
  navEntries: initNavEntries, 
  currentPageName, 
  language, 
  user,
 } = defaultProps) => {
  const navEntries = !Array.isArray(initNavEntries) ? [initNavEntries] : initNavEntries;

  return navEntries
    .filter(({ _modelApiKey, ...linkProps }) => {
      if (_modelApiKey === 'custom_link') {
        return true;
      }

      try {
        const { page } = linkProps;
        const { slug, layout } = page;

        if (!layout || !slug) {
          return false;
        } else {
          return true;
        }
      } catch {
        return false;
      }
    })
    .filter(({ _modelApiKey, ...linkProps }) => {
      if (_modelApiKey === 'custom_link') {
        return true;
      }

      try {
        const { page } = linkProps;
        let isPagePersonalized = true;

        if(!!page?.pagePersonalizationTags.length) {
          const found = page?.pagePersonalizationTags?.some(({id}) => user?.tags?.includes(id));

          if (found) {
            isPagePersonalized = true;
          } else {
            isPagePersonalized = false;
          }
        } else {
          isPagePersonalized = true;
        }

        return isPagePersonalized;
      } catch {
        return false;
      }
    })
    .map(({ _modelApiKey, ...linkProps }) => {
      if (_modelApiKey === 'custom_link') {
        const { link, rel, target, title } = linkProps;

        return {
          href: link,
          alt: title,
          label: title,
          isNextLink: false,
          target,
          rel,
          isActive: false,
        };
      }

      const { slugParams, page, customTitle } = linkProps;
      const { title, slug, layout } = page;

      const baseNextLinkProps = createNextLinkProps(slug, layout, language);

      const enrichedLinkProps = {
        ...baseNextLinkProps,
        alt: customTitle || title,
        label: customTitle || title,
        isActive: currentPageName === title,
      };

      if (slugParams) {
        enrichedLinkProps.nextLinkProps.as = `${enrichedLinkProps.nextLinkProps.as}?${slugParams}`;
      }

      return enrichedLinkProps;
    });
};
