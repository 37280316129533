import styled from 'styled-components';
import { em, rem } from 'polished';

import SimpleBarComponent from 'simplebar-react';

import _Person from '../Icons/Person';

export const UserCustomersList = styled.div`
  display: block;

  width: 100%;
`;

export const SelectScrollBar = styled(SimpleBarComponent)`
  width: 100%;

  max-height: ${rem(160)};
`;

export const UserCustomersListItem = styled.li`
  font-family: ${({ selected, theme }) => selected && theme.global.fontFamily.tertiary};
  display: flex;

  align-items: center;
  text-align: left;
  position: relative;
  font-size: ${({ theme }) => theme.typography.fontSize.s2};
  line-height: ${({ theme }) => theme.typography.fontSize.s2};
  padding: ${rem(3)} ${rem(10)};
  cursor: pointer;
  &:hover {
    font-family: ${({ theme }) => theme.global.fontFamily.tertiary}!important;
  }
`;
export const IconWrapper = styled.div`
  height: ${rem(25)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${rem(10)};
`;

export const Heading = styled.p`
  margin-bottom: ${em(8)};
  text-align: center;
`;

export const Person = styled(_Person)`
  width: ${rem(16)};

  height: ${rem(16)};
  > path {
    stroke: ${({ theme }) => theme.defaultPalette.monochrome[4]};
    stroke-width: 3px;
  }
`;
