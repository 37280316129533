export const selectElementById = (hash) => {
  let elementToScroll;

  const itemId = hash.substring(1);
  const elementToScrollById = document.getElementById(decodeURIComponent(itemId));
  const elementToScrollByQuerySelector = document.querySelector(`a[name="${itemId}"]`);
  if (!!elementToScrollById) elementToScroll = elementToScrollById;
  if (!!elementToScrollByQuerySelector) elementToScroll = elementToScrollByQuerySelector;

  history.replaceState(null, null, `#${itemId}`);
  return elementToScroll;
};

export const getAnchorPositionAndScroll = (element, offset) => {
  if (typeof element === 'undefined' || typeof element === 'null') return;
  const targetPosition = element.getBoundingClientRect().top + window.pageYOffset;
  window.scrollTo({ behavior: 'smooth', top: targetPosition - offset });
};

export const scrollToAnchorOnLoad = (offset) => {
  const element =
    document.querySelector(decodeURIComponent(window.location.hash)) ??
    document.getElementById(decodeURIComponent(window.location.hash.substring(1)));

  if (!!element) {
    getAnchorPositionAndScroll(element, offset);
  }
};
