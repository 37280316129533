import { breakpointsKeys } from 'src/enums/breakpoints';

import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import useTranslation from 'src/hooks/useTranslation';

import * as S from './styles';

const LogoImg = ({ inverse, isB2BClientType, shouldDisplayLogoAsH1, ...rest }) => {
  const { t } = useTranslation();
  const currentBreakpoint = useBreakpointDetector();

  const isMobileView = currentBreakpoint === breakpointsKeys.MOBILE;

  const logoLinkBeforeLoginAndB2CUserDesktop = t('logoBeforeLoginAndB2CUserDesktop');
  const logoLinkBeforeLoginAndB2CUserMobile = t('logoBeforeLoginAndB2CUserMobile');
  const logoAfterLoginB2BUserDesktop = t('logoAfterLoginB2BUserDesktop');
  const logoAfterLoginB2BUserMobile = t('logoAfterLoginB2BUserMobile');
  const logoAltImg = t('logoImageAlt');

  const renderB2BClientLogo = () =>
    isMobileView ? (
      <img src={logoAfterLoginB2BUserMobile} alt={logoAltImg} />
    ) : (
      <img src={inverse ? logoAfterLoginB2BUserMobile : logoAfterLoginB2BUserDesktop} alt={logoAltImg} />
    );

  const renderB2CClientLogo = () =>
    isMobileView ? (
      <img src={logoLinkBeforeLoginAndB2CUserMobile} alt={logoAltImg} />
    ) : (
      <img
        src={inverse ? logoLinkBeforeLoginAndB2CUserMobile : logoLinkBeforeLoginAndB2CUserDesktop}
        alt={logoAltImg}
      />
    );

  return (
    <S.HeaderLogoOnninen isB2BClientType={isB2BClientType} as={shouldDisplayLogoAsH1 && 'h1'} {...rest}>
      {isB2BClientType ? renderB2BClientLogo() : renderB2CClientLogo()}
    </S.HeaderLogoOnninen>
  );
};

export default LogoImg;
