import { useRef, useState, useEffect } from 'react';

export const SliderPlaceholder = ({ children }) => {
  const [isShow, setShow] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const element = ref.current;
    if (element && !isShow) {
      var callback = function(entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShow(true);
          }
        });
      };
      var observer = new IntersectionObserver(callback, {
        rootMargin: '50px',
        threshold: 1.0,
      });

      observer.observe(element);
    }
  }, [isShow]);

  return <div ref={ref}>{isShow && children}</div>;
};

SliderPlaceholder.displayName = 'SliderPlaceholder';
