import { useState } from 'react';

import * as S from './styles';
import { breakpointsKeys } from 'src/enums/breakpoints';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import useTranslation from 'src/hooks/useTranslation';
import { createNextLinkProps } from 'src/utils/createNextLinkProps';

import SmallDialog from 'src/components/SmallDialog';

import { defaultLanguage } from 'config/locales';

export const HeaderGuestControl = ({ className, coreRoutesInfo, language = defaultLanguage, ...rest }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { t } = useTranslation();
  const currentBreakpoint = useBreakpointDetector();

  const isDesktop =
    currentBreakpoint === breakpointsKeys.DESKTOP ||
    currentBreakpoint === breakpointsKeys.DESKTOP_LARGE ||
    currentBreakpoint === breakpointsKeys.HD;

  const loginLinkProps = createNextLinkProps(
    coreRoutesInfo.businessUserLoginPage.name[language],
    coreRoutesInfo.businessUserLoginPage.layout,
  );

  const registerB2BLinkProps = createNextLinkProps(
    coreRoutesInfo.registerPageForB2b.name[language],
    coreRoutesInfo.registerPageForB2b.layout,
  );

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <S.HeaderGuestControlWrapper className={className} {...rest}>
      <S.HeaderGuestControlMainContentWrapper>
        <S.Button blank onClick={handleOpenDialog}>
          {isDesktop ? (
            <>
              <S.GuestHeaderPersonIcon />
              <S.GuestHeaderPersonIconLabel>{t('loginOrRegister')}</S.GuestHeaderPersonIconLabel>
            </>
          ) : (
            <S.LogIn />
          )}
        </S.Button>
        <SmallDialog isOpen={isDialogOpen} onOverlayClick={handleCloseDialog}>
          <S.HeaderGuestControlDialogContentWrapper>
            <S.Person />
            <S.HeaderGuestControlsLinksWrapper>
              <S.NavigateToLogInLink {...loginLinkProps} variant="tertiary" onClick={handleCloseDialog}>
                {t('headerGuestControlLoginLabel')}
              </S.NavigateToLogInLink>
              <S.HeaderGuestControlsSubHeading variant="quaternary">
                {t('headerGuestControlSubLabel')}
              </S.HeaderGuestControlsSubHeading>
              <S.NavigateToRegisterLink variant="tertiary" {...registerB2BLinkProps} onClick={handleCloseDialog}>
                {t('headerGuestControlRegisterLabel')}
              </S.NavigateToRegisterLink>
            </S.HeaderGuestControlsLinksWrapper>
          </S.HeaderGuestControlDialogContentWrapper>
        </SmallDialog>
      </S.HeaderGuestControlMainContentWrapper>
    </S.HeaderGuestControlWrapper>
  );
};
