import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { rem } from 'polished';

import { breakpointsKeys } from 'src/enums/breakpoints';

import _Basket from 'src/components/Icons/Basket';

export const Basket = styled(_Basket)`
  height: ${rem(25)};
  ${up(breakpointsKeys.TABLET)} {
    height: ${rem(28)};
    > path {
      fill: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    }
  }
`;

export const BasketLabel = styled.p`
  display: none;
  ${up(breakpointsKeys.TABLET)} {
    margin-top: ${rem(4)};
    color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    display: block;
    font-size: ${({ theme }) => theme.typography.fontSize.s1};
  }
`;

export const Wrapper = styled.button`
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${up(breakpointsKeys.DESKTOP)} {
    &:hover {
      ${Basket} {
        > path {
          ${({ theme }) => theme.helpers.transition(['fill'])};
          /* NOTE: it has to be a rgb, cause when we use # transition doesnt animate */
          fill: ${({ theme }) => theme.helpers.hexToRGBA(theme.defaultPalette.teal[0])};
        }
      }
      ${BasketLabel} {
        ${({ theme }) => theme.helpers.transition(['color'])};
        color: ${({ theme }) => theme.defaultPalette.teal[0]};
      }
    }
  }
`;
