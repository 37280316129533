import styled from 'styled-components';

const SVG = styled.svg``;

export const PhoneFull = ({ className, ...rest }) => {
  return (
    <SVG
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      {/* eslint-disable-next-line */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        //eslint-disable-next-line
        d="M3.25019 1.3576C4.59814 0.0495182 6.81776 0.281946 7.9464 1.75435L9.34419 3.57486C10.2635 4.77267 10.1815 6.44616 9.09055 7.50452L8.82694 7.76181C8.79718 7.86983 8.79414 7.98322 8.81808 8.09261C8.88786 8.53368 9.26555 9.46772 10.8472 11.0028C12.4288 12.5379 13.3925 12.9055 13.851 12.9747C13.9664 12.9986 14.0861 12.9953 14.1999 12.965L14.6518 12.526C15.622 11.5855 17.1107 11.4093 18.3113 12.0461L20.4268 13.1704C22.2399 14.1303 22.6974 16.5346 21.2132 17.9757L19.6393 19.5021C19.1431 19.9832 18.4763 20.3843 17.6633 20.4589C15.6586 20.6416 10.9879 20.4081 6.07789 15.6438C1.49576 11.1963 0.616328 7.31749 0.50446 5.40618C0.44908 4.43971 0.916486 3.62242 1.51237 3.04514L3.25019 1.3576ZM6.61729 2.72623C6.05573 1.99435 5.01016 1.93597 4.42092 2.50785L2.682 4.19431C2.31649 4.54889 2.14149 4.94024 2.16364 5.31429C2.25225 6.83318 2.96111 10.3326 7.24972 14.4947C11.7488 18.86 15.9034 18.9908 17.5094 18.8438C17.8372 18.8146 18.1629 18.6481 18.4675 18.353L20.0403 16.8254C20.6804 16.2049 20.5398 15.0741 19.6338 14.5941L17.5183 13.4709C16.9334 13.1617 16.2489 13.2633 15.8236 13.6763L15.3197 14.166L14.7341 13.5923C15.3197 14.166 15.3175 14.1671 15.3175 14.1671L15.3164 14.1693L15.313 14.1725L15.3053 14.179L15.2887 14.1941C15.2416 14.2361 15.1912 14.2744 15.138 14.3087C15.0494 14.366 14.932 14.4298 14.7847 14.4828C14.4857 14.592 14.0891 14.6503 13.5996 14.5768C12.6393 14.433 11.3667 13.7941 9.67536 12.1531C7.98517 10.512 7.32504 9.27746 7.17662 8.34126C7.1002 7.86343 7.16112 7.47641 7.27409 7.18452C7.33674 7.02049 7.42572 6.86722 7.5377 6.73047L7.57314 6.69264L7.58865 6.67642L7.59529 6.66994L7.59862 6.66669L7.60083 6.66453L7.91982 6.35535C8.39387 5.89373 8.46033 5.12942 8.01397 4.54673L6.61729 2.72623Z"
        fill="#00857E"
      />
      <path
        //eslint-disable-next-line
        d="M6.61729 2.72623C6.05573 1.99435 5.01016 1.93597 4.42092 2.50785L2.682 4.19431C2.31649 4.54889 2.14149 4.94024 2.16364 5.31429C2.25225 6.83318 2.96111 10.3326 7.24972 14.4947C11.7488 18.86 15.9034 18.9908 17.5094 18.8438C17.8372 18.8146 18.1629 18.6481 18.4675 18.353L20.0403 16.8254C20.6804 16.2049 20.5398 15.0741 19.6338 14.5941L17.5183 13.4709C16.9334 13.1617 16.2489 13.2633 15.8236 13.6763L15.3197 14.166L14.7341 13.5923C15.3197 14.166 15.3175 14.1671 15.3175 14.1671L15.3164 14.1693L15.313 14.1725L15.3053 14.179L15.2887 14.1941C15.2416 14.2361 15.1912 14.2744 15.138 14.3087C15.0494 14.366 14.932 14.4298 14.7847 14.4828C14.4857 14.592 14.0891 14.6503 13.5996 14.5768C12.6393 14.433 11.3667 13.7941 9.67536 12.1531C7.98517 10.512 7.32504 9.27746 7.17662 8.34126C7.1002 7.86343 7.16112 7.47641 7.27409 7.18452C7.33674 7.02049 7.42572 6.86722 7.5377 6.73047L7.57314 6.69264L7.58865 6.67642L7.59529 6.66994L7.59862 6.66669L7.60083 6.66453L7.91982 6.35535C8.39387 5.89373 8.46033 5.12942 8.01397 4.54673L6.61729 2.72623Z"
        fill="#00857E"
      />
    </SVG>
  );
};
