import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { em, rem, hideVisually } from 'polished';
import Link from '../Link';

export const BottomBar = styled.div`
  background: ${(p) => p.theme.global.bgAlt};
  color: ${(p) => p.theme.global.bg};
  font-size: inherit;
  padding: 1em 0;

  ${up('tablet')} {
    padding: ${em(13)} 0;
  }
`;

export const BottomBarInner = styled.div`
  ${up('tablet')} {
    display: flex;
    flex-basis: auto;
    justify-content: space-between;
  }
`;

export const BottomBarLeft = styled.div`
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.typography.sizesSet.r7)}
  margin-bottom: 1em;

  ${up('tablet')} {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
`;

export const BottomBarRight = styled.div`
  font-size: 2.25em;
  display: flex;

  & > * {
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: auto;
    align-items: center;
    justify-content: center;

    & + * {
      margin-left: 0.75em;
    }
  }

  .social-name {
    ${hideVisually()};
  }
`;

export const BottomBarLogoLink = styled(Link)`
  margin-bottom: 1em;

  img {
    width: ${rem(105)};
    height: ${rem(30)};
    vertical-align: middle;
  }

  ${up('tablet')} {
    margin-right: 2em;
    margin-bottom: 0;
  }
`;
