// TODO: Create one place where keep configuration for GraphQL.
// The second configuration is in /setup/datoConfiguration.js
let cmsUrl = process.env.cmsURL;

if (process.env.cmsEnvironmentName) {
  cmsUrl = `${cmsUrl}environments/${process.env.cmsEnvironmentName}`;
}

export const contentDatasourceApolloClientConfig = {
  uri: cmsUrl,
  headers: {
    authorization: `Bearer ${process.env.cmsReadOnlyToken}` || '',
  },
};

export const shopDatasourceApolloClientConfig = {
  uri: `${process.env.backendUrl}/`,
};
