const keyOfNavigationsSet = {
  SINGLE_PAGE: 'single_page',
  PAGE_WITH_PARAM: 'page_with_param',
  // This don't have access tag
  // CUSTOM_LINK: 'custom_link',
};

export const filterNavigationSet = (listOfNavigationsSet, accessNameList) =>
  listOfNavigationsSet.filter((navSet) => {
    switch (navSet?._modelApiKey) {
      case keyOfNavigationsSet.SINGLE_PAGE:
        return !accessNameList.includes(navSet?.page?.requiredAccessToApiModules || '');

      case keyOfNavigationsSet.PAGE_WITH_PARAM:
        return !accessNameList.includes(navSet?.page?.requiredAccessToApiModules || '');

      default:
        return true;
    }
  });
