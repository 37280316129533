import { useState, useEffect, useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints as bp } from '../../styles/breakpoints';

const bpMobile = 'mobile';
const bpTablet = 'tablet';
const bpDesktop = 'desktop';
const bpHd = 'hd';
const bpDesktopLarge = 'desktopLarge';

const isBrowser = typeof window !== 'undefined';
const useEnhancedEffect = isBrowser ? useLayoutEffect : useEffect;

export const useBreakpointDetector = () => {
  const isTablet = useMediaQuery({ minWidth: bp[bpTablet] });
  const isDesktop = useMediaQuery({ minWidth: bp[bpDesktop] });
  const isHd = useMediaQuery({ minWidth: bp[bpHd] });
  const isDesktopLarge = useMediaQuery({ minWidth: bp[bpDesktopLarge] });

  const [currentBreakpoint, setCurrentBreakpoint] = useState(bpHd);

  useEnhancedEffect(() => {
    const tabletBp = !isDesktop && isTablet;
    const desktopBp = !isDesktopLarge && isDesktop;
    const desktopLarge = !isHd && isDesktopLarge;
    const hdBp = isHd;
    let newBpName = bpMobile;

    if (hdBp) newBpName = bpHd;
    if (desktopLarge) newBpName = bpDesktopLarge;
    if (desktopBp) newBpName = bpDesktop;
    if (tabletBp) newBpName = bpTablet;

    if (currentBreakpoint !== newBpName) {
      setCurrentBreakpoint(newBpName);
    }
  }, [isTablet, isDesktop, isHd, currentBreakpoint, isDesktopLarge]);

  return currentBreakpoint;
};
