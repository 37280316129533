import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { transparentize, rem } from 'polished';
import { breakpointsKeys } from 'src/enums/breakpoints';
import _Link from 'src/components/Link';
export const ExtendedMenu = styled.ul`
  display: none;

  ${up(breakpointsKeys.TABLET)} {
    display: flex;
    gap: ${rem(10)};

    padding-top: ${rem(8)};
    flex-direction: row;
    background: inherit;
    margin-bottom: 0;
  }
`;

export const SubNavList = styled.ul`
  box-shadow: ${(p) => `2px 2px 6px 1px ${transparentize(0.74, p.theme.defaultPalette.monochrome[5])}`};

  background: ${(p) => p.theme.defaultPalette.monochrome[0]};
  transform: translate3d(0, -1.1em, 0);
  padding: 1em 1em 1em 0;
  border-radius: ${rem(20)};
  list-style: none;
  min-width: 12em;

  & > li {
    font-size: 1.1em;
    text-align: left;
    position: relative;
    margin-left: 2.2em;

    &:not(:last-child) {
      padding-bottom: 0.5em;
    }

    &:before {
      color: ${(p) => p.theme.defaultPalette.teal[0]};
      content: '•';
      position: absolute;
      left: -0.75em;
      top: -0.425em;
      font-size: 2em;
    }

    & + li {
      margin-top: 0.5em;
    }
  }
  margin-top: ${rem(15)};
`;
export const Link = styled(_Link)`
  z-index: 100;
  color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  font-size: ${({ theme }) => theme.typography.fontSize.s3};
  padding: ${rem(6)} ${rem(12)};
  border-radius: 30px;
  border: 1px solid transparent;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  }
  ${up('tablet')} {
    font-family: ${({ theme }) => theme.global.fontFamily.primary};
    ${({ theme }) => theme.helpers.transition(['border', 'background-color'])};
    &:hover,
    &:focus {
      border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[0]};
      background-color: ${({ theme }) => theme.defaultPalette.teal[0]};
    }
  }
  > .content {
    margin-top: ${rem(2)};
  }
`;

export const ExtendedMenuItem = styled.div`
  position: relative;
  background: inherit;
  height: 100%;

  display: inline-flex;

  flex-direction: column;

  ${up('tablet')} {
    padding-bottom: ${rem(8)};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    z-index: 20;
  }
`;
