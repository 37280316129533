import styled from 'styled-components';
import { em, rem } from 'polished';

export const Badge = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSize.xs3};
  letter-spacing: 0.4px;
  color: ${({ variant }) => variant.color};
  border: 1px solid ${({ variant }) => variant.color};
  padding: 0 ${em(8)};
  margin: 0 ${em(8)};

  display: inline-block;
  border-radius: ${rem(6)};
  font-family: ${({ theme }) => theme.global.fontFamily.tertiary};
  line-height: normal !important;
`;
