import styled from 'styled-components';
import { transparentize, rem } from 'polished';
import Image from '../Image';
import Text from '../Text';

export const LargeImgWrapper = styled.div`
  background: ${(p) => p.theme.defaultPalette.monochrome[0]};
  z-index: 9;
  padding: 1rem;
  max-width: ${rem(350)};
  min-width: ${rem(200)};
  border-radius: ${rem(20)};

  box-shadow: ${(p) => `2px 2px 6px 1px ${transparentize(0.74, p.theme.defaultPalette.monochrome[5])}`};
`;

export const PreviewTitle = styled(Text)`
  color: ${(p) => p.theme.defaultPalette.monochrome[4]};

  text-align: left;
`;

export const LargeImg = styled(Image)`
  margin-top: 1em;
  max-width: ${rem(300)};
  min-width: ${rem(200)};

  > img {
    max-width: 100%;
  }
`;
