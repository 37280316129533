import { useState } from 'react';
import * as S from './styles';
import useTranslation from 'src/hooks/useTranslation';

const defaultProps = {
  dimensions: null,
  customDimensions: true,
};

export const Image = ({ url, alt, dimensions, customDimensions, ...restProps } = defaultProps) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const hasUrl = !!url;

  return (
    <S.ImageWrapper dimensions={dimensions} customDimensions={customDimensions} {...restProps}>
      <S.Image
        src={!hasError && hasUrl ? url : t('imagePlaceholder')}
        alt={alt}
        loading="lazy"
        onError={() => setHasError(true)}
      />
    </S.ImageWrapper>
  );
};

Image.defaultProps = defaultProps;
