export const getCoreRoutesInfoByLocale = (routingMapFromReq, language) => {
  return Object.keys(routingMapFromReq).reduce((simplifiedObjects, key) => {
    const obj = routingMapFromReq[key];

    const isLanguageUndefined =
      obj.name[language] === undefined && obj.pattern[language] === undefined && obj.pageTitle[language] === undefined;

    if (!isLanguageUndefined) {
      // NOTE: change this util if req.routingMap will change
      simplifiedObjects[key] = {
        parentId: obj.parentId,
        layout: obj.layout,
        name: { [language]: obj.name[language] },
        pattern: { [language]: obj.pattern[language] },
        pageTitle: { [language]: obj.pageTitle[language] },
        id: obj.id,
      };
    }

    return simplifiedObjects;
  }, {});
};
