import TooltipPopper from '../TooltipPopper';
import * as S from './styles';
import useTranslation from 'src/hooks/useTranslation';

export const BalancePanelDetail = ({
  children,
  title = '',
  redirectPageSlug,
  redirectPageLayout,
  tooltipTitle,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <S.BalancePanelDetail
      variant="duodenary"
      href={{
        pathname: `/${redirectPageLayout}`,
      }}
      isNextLink
      nextLinkProps={{ as: `/${redirectPageSlug}` }}
      blank
      block
      {...rest}
    >
      {title && <div>{t(title)}:</div>}
      <TooltipPopper title={tooltipTitle} placement="top" trigger="mouseenter">
        {children}
      </TooltipPopper>
    </S.BalancePanelDetail>
  );
};
