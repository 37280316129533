import { filterNavigationSet } from 'src/utils/filterNavigationSet';

const keyOfNavigationElements = {
  FOOTER_CONTACT: 'footer_contact',
  FOOTER_CUSTOM_NAV: 'footer_custom_nav',
  // This two don't have link (currency ;))
  // FOOTER_FORM : 'footer_form',
  // FOOTER_HTML : 'footer_html',
};

const removeLinksToPagesWithoutAccess = (listOfColumns, accessNameList) => {
  const clearedListOfColumns = listOfColumns.map((column) => {
    switch (column._modelApiKey) {
      case keyOfNavigationElements.FOOTER_CUSTOM_NAV:
        if (column?.navigation?.set) {
          const newColumn = {
            ...column,
            navigation: {
              ...column.navigation,
              set: filterNavigationSet(column.navigation.set, accessNameList),
            },
          };
          return newColumn;
        }
        return column;

      case keyOfNavigationElements.FOOTER_CONTACT:
        const newItem = { ...column };

        if (
          newItem?.allContactsPage &&
          accessNameList.includes(newItem.allContactsPage?.requiredAccessToApiModules || '')
        ) {
          newItem.allContactsPage = {};
        }

        if (
          newItem?.officeLocatorPage &&
          accessNameList.includes(newItem.officeLocatorPage?.requiredAccessToApiModules || '')
        ) {
          newItem.officeLocatorPage = {};
        }

        return newItem;

      default: {
        return column;
      }
    }
  });

  return clearedListOfColumns;
};

export default removeLinksToPagesWithoutAccess;
