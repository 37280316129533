import dynamic from 'next/dynamic';
import { useRef, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import DOMPurify from 'dompurify';
import GET_SEARCH_SUGGESTIONS_RESULT from 'src/graphql/queries/getSearchSuggestResult.gql';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import useLazyShopQuery from 'src/hooks/useLazyShopQuery';
import useTranslation from 'src/hooks/useTranslation';
import { keyboardEventCode } from 'src/enums/keyboardEventProperties';
import SearchSuggest from '../SearchSuggest';
import Icon from '../Icon';
import SearchMany from '../SearchMany';
import { breakpointsKeys } from 'src/enums/breakpoints';
import { isEnterKey } from 'src/utils/isEnterKey';
import * as S from './styles';
import TooltipPopper from '../TooltipPopper';

import Form from '../Form';

const CodeScanner = dynamic(() => import('../CodeScanner'));

const defaultProps = {
  coreRoutesInfo: {},
  id: 'search',
  isCurrentRouteProductsList: false,
  redirectToNewSearchPath: () => {},
  isPathContainingProductFilter: false,
};

export const Search = ({
  onSearch,
  coreRoutesInfo,
  locale,
  id,
  redirectToNewSearchPath,
  isCurrentRouteProductsList,
  isPathContainingProductFilter,
  isFiltersIcon,
  setIsFiltersIcon,
  tooltipValue,
  setTooltipValue,
  user,
  ...restProps
} = defaultProps) => {
  const inputRef = useRef(null);
  const timeStamp = useRef(null);
  const isUserLoggedIn = !!user;

  const router = useRouter();

  const { t } = useTranslation();
  const { t: tSearch } = useTranslation('searchfilter');

  const searchKey = tSearch('find');

  const [suggestListData, setSuggestListData] = useState(null);

  const [haveDateFromApi, setHaveDataFromApi] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isOpenSearchMany, setIsOpenSearchMany] = useState(false);
  const [isRemoveClearFiltersByBackspace, setIsRemoveClearFiltersByBackspace] = useState(false);

  const currentBreakpoint = useBreakpointDetector();

  const isDesktopView =
    currentBreakpoint === breakpointsKeys.HD ||
    currentBreakpoint === breakpointsKeys.DESKTOP ||
    currentBreakpoint === breakpointsKeys.DESKTOP_LARGE;

  const [getSearchSuggestions] = useLazyShopQuery(GET_SEARCH_SUGGESTIONS_RESULT, {
    onCompleted: async (data) => {
      const {
        search: { callback, message, total, totalmore, frazes },
      } = data;

      if (data && (frazes || (!frazes && message)) && +callback === +timeStamp.current) {
        if (!total && !totalmore && !message) {
          setHaveDataFromApi(false);
        } else {
          setHaveDataFromApi(true);
        }
        return setSuggestListData(data);
      }

      return setSuggestListData(null);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(haveDateFromApi && isInputFocused);
    }, 3);
    return () => clearTimeout(timer);
  }, [haveDateFromApi, isInputFocused]);

  const shouldCloseIconBeDisplayed = isOpen && !isDesktopView;

  useEffect(() => {
    const handleRouterChange = (incomingPath) => {
      const regExp = new RegExp(`\/${searchKey}:\\S[^\/]*`);
      const decodedPath = decodeURIComponent(incomingPath);
      if (!decodedPath.match(regExp) && inputRef.current?.value) {
        inputRef.current.value = '';
      }
    };

    router.events.on('routeChangeComplete', handleRouterChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouterChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, tSearch]);

  const handleRemoveValueButtonOnDesktop = () => {
    setIsInputFocused(true);

    inputRef.current.value = '';
    inputRef.current.focus();

    getSearchSuggestions({
      variables: { query: '', callback: timeStamp.current },
    });
  };

  const handleRemoveValueButtonOnMobile = () => {
    if (!inputRef.current.value) {
      setIsInputFocused(false);
      setHaveDataFromApi(false);
    } else {
      inputRef.current.value = '';
      inputRef.current.focus();
      getSearchSuggestions({
        variables: { query: '', callback: timeStamp.current },
      });
    }
  };

  const handleRemoveFilters = () => {
    inputRef.current.value = '';
    inputRef.current.focus();
    getSearchSuggestions({
      variables: { query: '', callback: timeStamp.current },
    });
    setIsFiltersIcon(false);
    setTooltipValue('');
  };

  const handleRemoveFiltersIconByBackspace = () => {
    setIsFiltersIcon(false);
    setTooltipValue('');
    getSearchSuggestions({
      variables: { query: '', callback: timeStamp.current },
    });
  };

  useEffect(() => {
    if (isRemoveClearFiltersByBackspace) {
      const handleRouteChange = () => {
        setIsRemoveClearFiltersByBackspace(false);
      };

      router.events.on('routeChangeStart', handleRouteChange);

      return () => {
        router.events.off('routeChangeStart', handleRouteChange);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setIsInputFocused(false);
          onSearch(inputRef.current.target.value);
          setIsRemoveClearFiltersByBackspace(false);
        }}
      >
        <S.Search isOpen={isOpen || isOpenSearchMany}>
          <S.SearchIconContainer>
            <TooltipPopper title={tooltipValue} trigger="mouseenter" disabled={!isFiltersIcon}>
              <S.LeftIcon
                icon={isFiltersIcon ? 'clearFilters' : shouldCloseIconBeDisplayed ? 'close' : ''}
                onClick={() =>
                  isFiltersIcon
                    ? handleRemoveFilters()
                    : shouldCloseIconBeDisplayed
                    ? handleRemoveValueButtonOnMobile()
                    : true
                }
                shouldCloseIconBeDisplayed={shouldCloseIconBeDisplayed}
              />
            </TooltipPopper>
          </S.SearchIconContainer>

          <S.Input
            removeLeftPadding={!isFiltersIcon && !shouldCloseIconBeDisplayed}
            variant="tertiary"
            id={id}
            hideLabel
            placeholder={t('searchPlacecholder')}
            label={t('searchLabel')}
            icon={<Icon />}
            iconBefore
            autoComplete="off"
            shouldCloseIconBeDisplayed={shouldCloseIconBeDisplayed}
            ref={inputRef}
            onClick={(e) => {
              timeStamp.current = new Date().getMilliseconds();

              const sanitizedInputValue = DOMPurify.sanitize(e.currentTarget.value);

              getSearchSuggestions({
                variables: { query: sanitizedInputValue, callback: timeStamp.current },
              });
              setIsInputFocused(true);
              if (isOpenSearchMany) {
                setIsOpenSearchMany(!isOpenSearchMany);
              }
              setIsOpen(true);
            }}
            onChange={(e) => {
              timeStamp.current = new Date().getMilliseconds();

              const sanitizedInputValue = DOMPurify.sanitize(e.currentTarget.value);

              getSearchSuggestions({
                variables: { query: sanitizedInputValue, callback: timeStamp.current },
              });
            }}
            onKeyDown={(e) => {
              const isEnter = isEnterKey(e.code) || e.key === keyboardEventCode.ENTER;

              if (isEnter) {
                e.preventDefault();
                setIsInputFocused(false);
                onSearch(e.currentTarget.value);
                setIsRemoveClearFiltersByBackspace(false);
              } else if (!isEnter && !isInputFocused) {
                setIsInputFocused(true);
              }
            }}
            onKeyUp={(e) => {
              if ((e.code === keyboardEventCode.DELETE || e.code === keyboardEventCode.BACKSPACE) && !isInputFocused) {
                setIsInputFocused(true);
              }

              if (e.code === keyboardEventCode.BACKSPACE && isRemoveClearFiltersByBackspace && e.target.value === '') {
                handleRemoveFiltersIconByBackspace();
              }

              if (
                e.code === keyboardEventCode.BACKSPACE &&
                isFiltersIcon &&
                e.target.value === '' &&
                !isRemoveClearFiltersByBackspace
              ) {
                setIsRemoveClearFiltersByBackspace(true);
              }

              if (isRemoveClearFiltersByBackspace && !!e.target.value) {
                setIsRemoveClearFiltersByBackspace(false);
              }
            }}
            inputMode="text"
            {...restProps}
          />
          {isDesktopView ? (
            <S.SearchManyContainer>
              {!!inputRef?.current?.value ? <Icon onClick={handleRemoveValueButtonOnDesktop} icon="close" /> : null}
              {isUserLoggedIn && (
                <>
                  <S.SearchManyBtnVerticalLine />
                  <S.SearchManyBtn
                    onClick={() => {
                      setIsOpenSearchMany((prev) => !prev);
                      setIsOpen(false);
                    }}
                  >
                    {t('searchManyBtn')}
                  </S.SearchManyBtn>
                </>
              )}
            </S.SearchManyContainer>
          ) : null}

          {!isDesktopView && (
            <S.CodeScannerContainer>
              <CodeScanner />
            </S.CodeScannerContainer>
          )}
          <S.Button
            blank
            onClick={() => {
              onSearch(DOMPurify.sanitize(inputRef.current.value));
              setIsInputFocused(false);
            }}
          >
            <S.SearchIcon />
          </S.Button>
        </S.Search>
        {suggestListData && (
          <SearchSuggest
            inputRef={inputRef}
            coreRoutesInfo={coreRoutesInfo}
            locale={locale}
            search={suggestListData?.search}
            isOpen={isOpen}
            setIsInputFocused={setIsInputFocused}
            isCurrentRouteProductsList={isCurrentRouteProductsList}
            isPathContainingProductFilter={isPathContainingProductFilter}
            onSearch={onSearch}
          />
        )}
      </Form>
      {isOpenSearchMany && (
        <SearchMany
          inputRef={inputRef}
          coreRoutesInfo={coreRoutesInfo}
          locale={locale}
          isOpen={isOpenSearchMany}
          setIsInputFocused={() => setIsOpenSearchMany(false)}
          redirectToNewSearchPath={redirectToNewSearchPath}
          isCurrentRouteProductsList={isCurrentRouteProductsList}
          isPathContainingProductFilter={isPathContainingProductFilter}
        />
      )}
    </div>
  );
};

Search.defaultProps = defaultProps;
