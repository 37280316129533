import * as S from './styles';

export const NumberForIcon = ({ children, number, className, hideNumber = false, ...rest }) => {
  return (
    <S.NumberForIcon className={className} {...rest}>
      {children}
      {!hideNumber && (
        <S.DotWithNumber hideNumber={hideNumber}>
          <S.Number>{number}</S.Number>
        </S.DotWithNumber>
      )}
    </S.NumberForIcon>
  );
};
