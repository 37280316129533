import styled, { css } from 'styled-components';
import { em, rem } from 'polished';
import { up } from 'styled-breakpoints';

const calculatePaddingBottomPercentage = ({ x, y }) => {
  if (x === 0 || y === 0) {
    return 0;
  }

  return (y * 100) / x;
};

export const LoadingCover = styled.div`
  position: relative;

  ${({ isReady }) => {
    if (isReady) {
      return css`
        overflow: visible;
        height: auto;
        padding-bottom: 0;
      `;
    }

    return css`
      overflow: hidden;
      height: 0;
      padding-bottom: calc(
        ${({ mobile }) => calculatePaddingBottomPercentage(mobile)}% +
          ${({ mobile }) => rem(mobile.verticalPixelOffset)}
      );

      ${up('tablet')} {
        padding-bottom: calc(
          ${({ tablet }) => calculatePaddingBottomPercentage(tablet)}% +
            ${({ tablet }) => rem(tablet.verticalPixelOffset)}
        );
      }

      ${up('desktop')} {
        padding-bottom: calc(
          ${({ desktop }) => calculatePaddingBottomPercentage(desktop)}% +
            ${({ desktop }) => rem(desktop.verticalPixelOffset)}
        );
      }
    `;
  }};
`;

export const LoadingCoverFront = styled.div`
  ${(p) => p.theme.helpers.transition(['opacity', 'visibility'], 1)};

  font-size: 3em;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #eee;
  background-size: 200% 100%;
  background-position-x: 180%;

  animation: 1.5s shine linear infinite;
  @keyframes shine {
    to {
      background-position-x: -20%;
    }
  }
  &.ready {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  & > * {
    margin-top: ${em(2)};
  }
`;

export const LoadingCoverContent = styled.div`
  position: relative;
  z-index: 2;
`;
