import { useState, useRef, useEffect } from 'react';

import { useMutation } from '@apollo/client/react';
import { useRouter } from 'next/router';

import {
  notificationsListPopperState,
  notificationsStatus,
  notNetworkResponseNotificationsActions,
  actionTypes,
} from 'src/enums/notifications';
import { breakpointsKeys } from 'src/enums/breakpoints';

import SET_CACHED_NEW_NOTIFICATIONS_COUNT from 'src/graphql/mutations/setCachedNewNotificationsCount.gql';
import CHANGE_NOTIFICATION_STATUS from 'src/graphql/mutations/changeNotificationsStatus.gql';
import GET_NOTIFICATIONS from 'src/graphql/queries/getNotifications.gql';

import useCheckIsMobileDevice from 'src/hooks/useCheckIsMobileDevice';
import useNotificationsContext from 'src/hooks/useNotificationsContext';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import useLazyShopQuery from 'src/hooks/useLazyShopQuery';
import useRESTMutation from 'src/hooks/useRESTMutation';
import useTranslation from 'src/hooks/useTranslation';
import useLocalState from 'src/hooks/useLocalState';

import { markNotificationAsReaded } from './notificationsIndicatorHelpers';

const useNotificationsIndicatorLogic = () => {
  const [popperState, setPopperState] = useState(notificationsListPopperState.HIDDEN);
  const [closedNotificationId, setClosedNotificationId] = useState();

  const {
    setNotificationsArray,
    notificationsArray,
    setCanFetchMore,
    setShowSkeleton,
    showSkeleton,
  } = useNotificationsContext();
  const router = useRouter();
  const initialFetched = useRef(false);

  const isMobileDevice = useCheckIsMobileDevice();

  const isMouseOverContainer = useRef(false);
  const shouldFetchNotificationsRef = useRef(false);
  const shouldImportModalRef = useRef(false);
  const resetNotificationStateOnRouteChange = () => {
    setNotificationsArray([]);
    initialFetched.current = false;
    setShowSkeleton(true);
  };
  useEffect(() => {
    router.events.on('routeChangeComplete', resetNotificationStateOnRouteChange);
    return () => {
      router.events.off('routeChangeComplete', resetNotificationStateOnRouteChange);
    };

    // eslint-disable-next-line
  }, []);

  const { t } = useTranslation();
  const currentBreakpoint = useBreakpointDetector();
  const [localState] = useLocalState();
  const newNotificationsCount = localState?.newNotificationsCount;

  const [decrementCachedNewNotificationsCount] = useMutation(SET_CACHED_NEW_NOTIFICATIONS_COUNT);
  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;
  const handleCloseModal = () => setPopperState(notificationsListPopperState.HIDDEN);
  const isModalOpen = popperState === notificationsListPopperState.MODAL_OPEN;
  const shouldFetchNotificationsOnComponentListImport =
    notificationsArray.length === 0 &&
    (popperState === notificationsListPopperState.MODAL_OPEN || popperState === notificationsListPopperState.CLICKED);
  const isPopperLocked = popperState === notificationsListPopperState.CLICKED;
  const noNewNotificationsMessageObject = {
    message: t('noNewNotifications'),
    action: notNetworkResponseNotificationsActions.NO_NEW_NEW_NOTIFICATIONS,
  };

  const [getNotifications, { loading: isNotificationsPending }] = useLazyShopQuery(GET_NOTIFICATIONS, {
    onCompleted: ({ data }) => {
      const noNewNotifications = data.notificationsCount === 0;
      if (noNewNotifications) {
        setNotificationsArray([noNewNotificationsMessageObject]);
        setShowSkeleton(false);
        return;
      }
      const hasMoreToFetch = data.notifications.length < data.notificationsCount;
      setCanFetchMore(hasMoreToFetch);
      setShowSkeleton(false);
      setNotificationsArray(data.notifications);
    },
    onError: (res) => {
      const networkError = res?.networkError;
      setCanFetchMore(false);

      if (notificationsArray.length === 0) {
        initialFetched.current = false;
      }

      setNotificationsArray((prevNotifications) => [
        ...prevNotifications,
        { message: networkError?.message, action: notNetworkResponseNotificationsActions.ERROR },
      ]);
      setShowSkeleton(false);
    },
  });

  const calculateSkeletonsQuantity = () => {
    switch (true) {
      case newNotificationsCount === 0:
        return 1;
      case currentBreakpoint === breakpointsKeys.MOBILE && newNotificationsCount > 17:
        return 17;
      case currentBreakpoint === breakpointsKeys.MOBILE:
        return newNotificationsCount;
      case newNotificationsCount > 7:
        return 7;
      default:
        return newNotificationsCount;
    }
  };

  const skeletonsQuantity = calculateSkeletonsQuantity();
  const removeClosedNotificationById = (removedNotificationId) => (prev) => {
    const updatedArray = prev.filter(({ id }) => id !== removedNotificationId);
    const noNotificationsRemain = updatedArray.length === 0;
    if (noNotificationsRemain) {
      updatedArray.push(noNewNotificationsMessageObject);
    }
    return updatedArray;
  };

  const fetchMore = getNotifications;
  const [changeNotificationStatus] = useRESTMutation(CHANGE_NOTIFICATION_STATUS, {
    onCompleted: ({ data }) => {
      if (data.notificationstatus === notificationsStatus.CLOSED) {
        setNotificationsArray(removeClosedNotificationById(data.id));
        setClosedNotificationId(null);
        return;
      }
      if (data.notificationstatus === notificationsStatus.READED) {
        setNotificationsArray(markNotificationAsReaded(data));
      }
    },
    onError: () => {
      setClosedNotificationId(null);
    },
  });

  useEffect(() => {
    if (shouldFetchNotificationsOnComponentListImport && initialFetched.current === false) {
      getNotifications({
        variables: {
          pageNumber: 1,
        },
      });
      initialFetched.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchNotificationsOnComponentListImport]);

  const handleMouseEnter = () => {
    shouldFetchNotificationsRef.current = true;
    isMouseOverContainer.current = true;
    // eslint-disable-next-line
    // TODO - usunąć returna poniżej jak będziemy chcieli aby dropdown wyskakiwał po najechaniu, na razie pokazuje się tylko po kliku
    return;
    if (!isPopperLocked) {
      setPopperState(notificationsListPopperState.MOUSE_ENTER);
    }
  };

  const handleMouseLeave = () => {
    isMouseOverContainer.current = false;
    // eslint-disable-next-line
    // TODO - usunąć returna poniżej jak będziemy chcieli aby dropdown wyskakiwał po najechaniu, na razie pokazuje się tylko po kliku
    return;
    if (!isMouseOverContainer.current && !isPopperLocked) {
      setPopperState(notificationsListPopperState.HIDDEN);
    }
  };

  const markNotificationAsClosed = async (notificationId) => {
    setClosedNotificationId(notificationId);
    const closedNotificationStatus = notificationsArray.find(({ id }) => id === notificationId).status;
    const shouldDecreaseNewNotificationsCounter = closedNotificationStatus === notificationsStatus.NEW;

    await changeNotificationStatus({
      variables: {
        input: {
          id: notificationId,
          status: notificationsStatus.CLOSED,
        },
      },
    });
    if (shouldDecreaseNewNotificationsCounter) {
      decrementCachedNewNotificationsCount({
        variables: {
          newNotificationsCount: newNotificationsCount - 1,
        },
      });
    }
    setClosedNotificationId(null);
  };

  const findLayoutForInternalNavigation = (slug) => {
    const routingMapForSlugs = new Map(localState.routingMap.slugsMap);
    const layoutId = routingMapForSlugs.get(slug);

    const routingMapForRoutes = new Map(localState.routingMap.routesMap);
    return routingMapForRoutes.get(layoutId).layout;
  };

  const handleNavigateToInternalUrl = (actionSlug) => {
    const currentSlug = router.asPath.substring(1);
    const shouldNavigate = currentSlug !== actionSlug;
    if (shouldNavigate) {
      const layout = findLayoutForInternalNavigation(actionSlug);
      router.push(layout, `/${actionSlug}`);
      return;
    }
    setPopperState(notificationsListPopperState.HIDDEN);
  };

  const handleNavigate = (clickedNotification) => {
    switch (clickedNotification.actiontype) {
      case actionTypes.EXTERNAL:
        router.push(clickedNotification.action);
        break;
      case actionTypes.INTERNAL:
        handleNavigateToInternalUrl(clickedNotification.action);
        break;
      default:
        break;
    }
  };
  const goToNotificationDetails = async (notificationId) => {
    const clickedNotification = notificationsArray.find(({ id }) => id === notificationId);
    const shouldChangeNotificationStatus = clickedNotification.status === notificationsStatus.NEW;
    if (isMobile) {
      handleCloseModal();
    }
    if (shouldChangeNotificationStatus) {
      await changeNotificationStatus({
        variables: {
          input: {
            id: notificationId,
            status: notificationsStatus.READED,
          },
        },
      });

      decrementCachedNewNotificationsCount({
        variables: {
          newNotificationsCount: newNotificationsCount - 1,
        },
      });
    }
    if (!!clickedNotification.action) {
      handleNavigate(clickedNotification);
    }
  };

  const isNotificationsListPopped =
    !isMobile &&
    (popperState === notificationsListPopperState.CLICKED || popperState === notificationsListPopperState.MOUSE_ENTER);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMouseOverContainer.current === false && !event.target.closest('.navbar-menu-dropdown')) {
        setPopperState(notificationsListPopperState.HIDDEN);
      }
    };

    if (!isMobile && popperState === notificationsListPopperState.CLICKED) {
      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
        setPopperState(notificationsListPopperState.HIDDEN);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBreakpoint, popperState]);

  const handleClick = () => {
    if (isMobile) {
      shouldImportModalRef.current = true;
      setPopperState(notificationsListPopperState.MODAL_OPEN);
      return;
    }
    setPopperState((prevState) =>
      prevState === notificationsListPopperState.CLICKED
        ? notificationsListPopperState.HIDDEN
        : notificationsListPopperState.CLICKED,
    );
  };

  return {
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    isNotificationsListPopped,
    handleCloseModal,
    isModalOpen,
    shouldImportModalRef,
    isNotificationsPending,
    markNotificationAsClosed,
    fetchMore,
    closedNotificationId,
    goToNotificationDetails,
    newNotificationsCount,
    skeletonsQuantity,
    showSkeleton,
    isMobileDevice,
  };
};

export default useNotificationsIndicatorLogic;
