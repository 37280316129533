import { useLazyQuery } from '@apollo/client/react';
import { SHOP_CLIENT_NAME } from 'src/graphql/constants';
import useAuthenticationModal from 'src/hooks/useAuthenticationModal';

export const useLazyShopQuery = (query = '', queryOptions = {}) => {
  const { setIsAuthModalOpen } = useAuthenticationModal()

  if(typeof queryOptions.onError === 'function') {
    const oldErrorFunction = queryOptions.onError
    const updatedError = (error) => {
      oldErrorFunction(error);
      if(error?.networkError?.response?.status === 406) {
        setIsAuthModalOpen(true)
      }
    }

    queryOptions.onError = updatedError
  } else {
    const updatedError = (error) => {
      if(error?.networkError?.response?.status === 406) {
        setIsAuthModalOpen(true)
      }
    }

    queryOptions.onError = updatedError
  }

  const options = {
    context: { clientName: SHOP_CLIENT_NAME },
    fetchPolicy: 'no-cache',
    ...queryOptions,
  };

  return useLazyQuery(query, options);
};
