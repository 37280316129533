import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import TooltipPopper from '../TooltipPopper';
import useCheckIsMobileDevice from 'src/hooks/useCheckIsMobileDevice';
import { breakpointsKeys } from 'src/enums/breakpoints';
import * as S from './styles';

const defaultProps = {
  title: '',
  altTxt: '',
  imgSrc: null,
  openDelay: 0,
};

export const PreviewOfLargeImage = ({ title, imgSrc, altTxt, children, openDelay, className } = defaultProps) => {
  const currentBreakpoint = useBreakpointDetector();
  const isMobileDevice = useCheckIsMobileDevice();

  const isOnServerSite = typeof document === 'undefined';
  const shouldNotUseTooltip =
    isOnServerSite ||
    currentBreakpoint === breakpointsKeys.MOBILE ||
    currentBreakpoint === breakpointsKeys.TABLET ||
    isMobileDevice;

  if (shouldNotUseTooltip || !imgSrc) {
    return <div>{children}</div>;
  }

  return (
    <TooltipPopper
      ariaLabel={title}
      hideOnTooltipHover={false}
      isVirtualElement
      theme="photoTooltip"
      trigger="mouseenter"
      hasMaxWidth={false}
      delay={0}
      distance={30}
      openDelay={openDelay}
      hasShadow
      popperConfig={{
        anchorElement: true,
        popperElement: false,
      }}
      className={className}
      tooltipContent={
        <S.LargeImgWrapper>
          <S.PreviewTitle>{title}</S.PreviewTitle>
          <S.LargeImg alt={altTxt} url={imgSrc} />
        </S.LargeImgWrapper>
      }
    >
      {children}
    </TooltipPopper>
  );
};

PreviewOfLargeImage.defaultProps = defaultProps;
