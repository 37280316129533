import { Fragment, useRef, useEffect, useLayoutEffect } from 'react';

import { rem } from 'polished';

import { notNetworkResponseNotificationsActions } from 'src/enums/notifications';
import { breakpointsKeys } from 'src/enums/breakpoints';

import useNotificationsContext from 'src/hooks/useNotificationsContext';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';

import { NotificationsListErrorItem } from './NotificationsListErrorItem';
import { NotificationsListItem } from './NotificationsListItem';

import * as S from './styles';

const defaultProps = {
  fetchMore: () => null,
  isNotificationsPending: false,
  markNotificationAsClosed: () => null,
  closedNotificationId: null,
  goToNotificationDetails: () => null,
};

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
};
const headerTitleHeightIxPx = 85;

export const NotificationsList = ({
  fetchMore,
  isNotificationsPending,
  markNotificationAsClosed,
  closedNotificationId,
  goToNotificationDetails,
  isMobileDevice,
} = defaultProps) => {
  const simpleBarRef = useRef();
  const spinnerRef = useRef();
  const currentBreakpoint = useBreakpointDetector();
  const { notificationsArray, canFetchMore } = useNotificationsContext();
  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;

  useLayoutEffect(() => {
    if (simpleBarRef.current) {
      if (isMobile) {
        const windowInnerHeight = window.innerHeight;
        simpleBarRef.current.el.style.maxHeight = rem(windowInnerHeight - headerTitleHeightIxPx);
      }
    }
  }, [isMobile]);

  useEffect(() => {
    const callback = ([entry], observer) => {
      if (entry.isIntersecting && !isNotificationsPending) {
        fetchMore({
          variables: {
            pageNumber: 0,
          },
        });
        observer.unobserve(entry.target);
      }
    };

    const observer = new IntersectionObserver(callback, options);

    if (spinnerRef.current) {
      observer.observe(spinnerRef.current);
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.SimpleBar ref={simpleBarRef}>
      {notificationsArray.map(({ id, action, ...props }, idx, array) => {
        const isDividerRendered = idx !== array.length - 1;
        if (action === notNetworkResponseNotificationsActions.ERROR)
          return (
            <Fragment key={idx}>
              <NotificationsListErrorItem {...props} />
              {isDividerRendered && <S.Divider />}
            </Fragment>
          );

        if (action === notNetworkResponseNotificationsActions.NO_NEW_NEW_NOTIFICATIONS)
          return <NotificationsListErrorItem {...props} key={idx} />;

        return (
          <Fragment key={id}>
            <NotificationsListItem
              onClickAction={() => goToNotificationDetails(id)}
              isPending={closedNotificationId === id}
              onClickDelete={() => markNotificationAsClosed(id)}
              action={action}
              isMobileDevice={isMobileDevice}
              {...props}
            />
            {isDividerRendered && <S.Divider />}
          </Fragment>
        );
      })}
      {canFetchMore && (
        <S.SpinnerPlaceholder ref={spinnerRef}>{isNotificationsPending && <S.Spinner />}</S.SpinnerPlaceholder>
      )}
    </S.SimpleBar>
  );
};
