import styled from 'styled-components';
import { rem } from 'polished';
import { up } from 'styled-breakpoints';
import { breakpointsKeys } from 'src/enums/breakpoints';

export const HeaderLogoWrapper = styled.div`
  text-align: left;
`;

export const HeaderLogoOnninen = styled.div`
  display: inline-flex;

  > img {
    max-width: ${rem(130)};
  }
  ${up(breakpointsKeys.TABLET)} {
    > img {
      max-width: unset;
    }
  }
`;
