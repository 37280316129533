import styled from 'styled-components';

import { rem } from 'polished';
import Link from '../Link';

const getFontColor = (theme, status) =>
  ({
    green: theme.defaultPalette.teal[1],
    yellow: theme.defaultPalette.blazeOrange[4],
    red: theme.defaultPalette.alizarinCrimson[0],
  }[status]);

export const BalancePanelDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const BalancePanelDetail = styled(Link)`
  text-transform: lowercase;
  display: block;

  color: ${({ theme }) => theme.defaultPalette.monochrome[0]};

  ${({ theme }) => theme.helpers.dynamicFontSize(theme.typography.sizesSet.r7)};
  & > .content {
    display: grid;
    gap: ${rem(5)};

    margin-left: auto;
    display: flex;
    margin-top: ${rem(2)};
  }
  &.payment-type {
    & > span {
      > div {
        width: 100%;
      }
    }
  }
`;

export const BalancePanelValue = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-left: 0.5em;
`;
export const BalancePanelFreeBalanceValue = styled.div`
  color: ${(p) => (!p.status ? 'inherit' : getFontColor(p.theme, p.status))};
`;
