import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { em } from 'polished';
import dynamic from 'next/dynamic';
import Link from '../Link';
const Image = dynamic(() => import('../Image'), { ssr: true });

export const ProductLink = styled(Link)`
  width: 100%;

  .content {
    width: 100%;
  }
`;

export const MiniHorizontalProductListItem = styled.div`
  font-size: ${em(16)}!important;
  line-height: 110% !important;
  margin-bottom: 0.75em;

  ${up('desktop')} {
    font-size: ${em(14)}!important;
  }
`;

export const ProductImg = styled(Image)`
  object-fit: contain;
  margin-right: 1em !important;
  width: ${em(45)}!important;

  img {
    max-height: ${em(40)};
    height: ${em(40)};
    max-width: ${em(40)};
  }
`;

export const ProductNameContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ProductName = styled.div`
  width: calc(100% - 2em);
  margin-right: 0.5em;
`;

export const ProductAvailabilityCondition = styled.div`
  width: auto;
  ${up('tablet')} {
    font-size: 0.75em;
    margin-top: 0.15em;
    align-items: center;
    align-self: flex-start;
  }

  .product-availability-status {
    ${({ condition }) => {
      if (condition) {
        return css`
          &:before {
            margin-right: ${({ conditionLabel }) => (conditionLabel !== null ? '0em' : '0em')};
            content: ' ';
            display: inline-block;
            width: 0.7em;
            height: 0.7em;
            border-style: solid;
            border-radius: 50%;
            margin-top: 0.25em;
            border-width: 2px;

            ${up('tablet')} {
              margin-right: ${({ conditionLabel }) => (conditionLabel !== null ? '0.5em' : '0em')};
              width: 1em;
              height: 1em;
              margin-top: 0;
            }

            ${({ theme }) => {
              const variantColor = theme.productAvailability.conditionStyle[condition];
              if (!variantColor) {
                return css`
                  display: none;
                `;
              }
              return css`
                border-color: ${variantColor.borderColor || 'unset'};
                background-color: ${variantColor.bgColor || 'unset'};
              `;
            }}
          }
          }
        `;
      }

      return '';
    }};
  }
`;
