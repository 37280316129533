import { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { sendMessageTargetsKeys } from 'src/enums/sendMessageTargets';
import Modal from 'src/components/Modal';
import FormContact from 'src/components/FormContact';
import useTranslation from 'src/hooks/useTranslation';
import ContactFormContext from 'src/utils/ContactFormContext';
import { checkDocumentStateEqualComplete } from 'src/utils/checkDocumentStateEqualComplete';

const initialModalState = {
  isOpen: false,
  subject: '',
  emailTarget: '',
  submitButtonLabel: '',
  messagePlaceholder: '',
  message: '',
  title: '',
};

const reCaptchaKey = process.env.reCaptchaKey || '';

const getMailPropertiesFromHref = (href) => {
  const prefixHrefWithMail = 'mailto:';
  const prefixBeforeSubject = 'subject=';
  const prefixBeforeBody = 'body=';
  const hrefWithoutMailPrefix = href.replace(prefixHrefWithMail, '');
  const [mail, subjectAndBody] = hrefWithoutMailPrefix.split('?');
  let subject = '';
  let body = '';

  if (subjectAndBody) {
    const mailProperties = subjectAndBody.split('&');

    for (const item of mailProperties) {
      if (item.indexOf(prefixBeforeSubject) > -1) {
        subject = item.replace(prefixBeforeSubject, '');
      } else if (item.indexOf(prefixBeforeBody) > -1) {
        body = item.replace(prefixBeforeBody, '');
      }
    }
  }

  return [mail, subject, body];
};

export const ContactFormViaLink = ({ isLoggedInUser, children }) => {
  const { t } = useTranslation();
  const { events: routeEvents } = useRouter();
  const [modalState, setModalState] = useState(initialModalState);

  const closeModal = () => {
    setModalState(initialModalState);
  };

  const showContactForm = useCallback(
    ({ subject, emailTarget, submitButtonLabel = '', messagePlaceholder = '', message = '', title = '' }) =>
      setModalState({
        isOpen: true,
        subject,
        emailTarget,
        submitButtonLabel,
        messagePlaceholder,
        message,
        title,
      }),
    [setModalState],
  );

  const onClickHandler = useCallback(
    (event) => {
      if (event.preventDefault) {
        event.preventDefault();

        const $mailToLink = event.target;

        const [mailAddress, subject, message] = getMailPropertiesFromHref(decodeURIComponent($mailToLink.href));
        const submitButtonLabel = $mailToLink.dataset.submitLabel || '';
        const messagePlaceholder = $mailToLink.dataset.messagePlaceholder || '';
        const modalTitle = $mailToLink.dataset.modalTitle || '';

        showContactForm({
          subject: subject || t('globalContactFormDefaultSubject'),
          emailTarget: mailAddress,
          submitButtonLabel,
          messagePlaceholder,
          title: modalTitle,
          message,
        });
      }
    },
    [showContactForm, t],
  );

  useEffect(() => {
    if (!document && typeof window === 'undefined') {
      return;
    }
    const addEventOnClickMailTo = () => {
      const $allMailTo = document.querySelectorAll('a[href^=mailto]');

      $allMailTo.forEach(($mailTo) => {
        $mailTo.addEventListener('click', onClickHandler);
      });
    };

    const removeEventOnClickMailTo = () => {
      const $allMailTo = document.querySelectorAll('.wysiwyg a[href^=mailto]');
      $allMailTo.forEach(($mailTo) => {
        $mailTo.removeEventListener('click', onClickHandler);
      });
    };

    // Note: this run on first mounted, after SSR
    checkDocumentStateEqualComplete(addEventOnClickMailTo);

    routeEvents.on('routeChangeComplete', addEventOnClickMailTo);
    routeEvents.on('routeChangeStart', removeEventOnClickMailTo);

    return () => {
      routeEvents.off('routeChangeComplete', addEventOnClickMailTo);
      routeEvents.off('routeChangeStart', removeEventOnClickMailTo);
      removeEventOnClickMailTo();
    };
  }, [onClickHandler, routeEvents]);

  const renderForm = (
    <FormContact
      headingTxt={!modalState.title ? t('globalContactFormHeading') : modalState.title}
      source={sendMessageTargetsKeys.CONTACT_CMS}
      subject={modalState.subject}
      emailTarget={modalState.emailTarget}
      displayTitle={modalState.subject}
      isUser={isLoggedInUser}
      submitButtonLabel={modalState.submitButtonLabel}
      messagePlaceholder={modalState.messagePlaceholder}
      message={modalState.message}
    />
  );

  return (
    <ContactFormContext.Provider value={onClickHandler}>
      {children}
      <Modal isOpen={modalState.isOpen} onCloseClick={() => closeModal()}>
        {!isLoggedInUser ? (
          <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>{renderForm}</GoogleReCaptchaProvider>
        ) : (
          renderForm
        )}
      </Modal>
    </ContactFormContext.Provider>
  );
};
