import styled from 'styled-components';
import { rem, em, transparentize } from 'polished';
import { up, only } from 'styled-breakpoints';
import ImportedSelect from '../Select';
import LinkComponent from '../Link';
import HorizontalMenu from '../HorizontalMenu';

import _Button from 'src/components/Button';
import { breakpointsKeys } from 'src/enums/breakpoints';
import _Icon from 'src/components/Icon';
import _Person from 'src/components/Icons/Person';
import _Heading from 'src/components/Heading';
import _LogIn from 'src/components/Icons/LogIn';
import _LocaleOptions from 'src/components/LocaleOptions';

import _ClipboardIconLarge from 'src/components/Icons/ClipboardIconLarge';
import _Text from 'src/components/Text';

const itemsSpacing = '1.75em';

export const Header = styled.header`
  ${up(breakpointsKeys.TABLET)} {
    background: ${(p) => p.theme.global.bgAlt};
  }
`;

export const DropdownMenuContainer = styled.div`
  position: relative;
  max-width: ${(p) => p.theme.global.maxWidth};
`;

export const Select = styled(ImportedSelect)`
  margin: 0 0 0 0.5em;
  width: 100%;
  color: ${({ theme }) => theme.header.select.listColor};
  width: max-content;
  position: relative;
  font-size: 1em;

  && > div:nth-child(2) {
    background: ${({ theme }) => theme.header.select.listBackground};
    color: ${({ theme }) => theme.header.select.listColor};
    font-family: ${({ theme }) => theme.header.select.fontFamily.default};
    padding: 0.55em;
    left: auto;
    right: 0;
    min-width: ${em(200)};
    border: 0;
    box-shadow: ${(p) => `2px 2px 6px 1px ${transparentize(0.74, p.theme.header.select.boxShadow)}`};
    padding-bottom: 2.5em;
    margin-top: 0.5em;

    li {
      font-size: 1em;
      text-align: left;
      position: relative;
      margin-left: 2.2em;

      &[aria-selected='true'] {
        font-family: ${({ theme }) => theme.header.select.fontFamily.selected};
      }

      &:not(:last-child) {
        margin-bottom: 0.5em;
      }

      &:before {
        color: ${(p) => p.theme.breadcrumbs.icon.color};
        content: '•';
        position: absolute;
        left: -0.75em;
        top: -0.425em;
        font-size: 2em;
      }
    }
  }

  && button {
    box-shadow: none;
    padding: 0 0.5em;
    color: ${({ theme }) => theme.header.select.buttonColor};
    width: 100%;
    background: ${({ theme }) => theme.header.select.buttonBackground};

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      box-shadow: none;
      color: ${({ theme }) => theme.header.select.buttonColor};
    }

    > .inner {
      justify-content: space-between;
    }

    .icon {
      color: ${({ theme }) => theme.header.select.buttonColor};
    }
  }
`;

export const HeaderPrintOnlyLogoWrapper = styled.div`
  padding-bottom: 1em;
`;

export const HeaderPrintOnlyLogo = styled.img``;

export const HeaderContent = styled.div`
  padding-top: ${rem(15)};

  padding-bottom: ${rem(6)};
  ${up(breakpointsKeys.TABLET)} {
    padding-top: ${rem(15)};
    padding-bottom: 0;
  }
`;

export const HeaderContentInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderContentLeft = styled.div`
  display: flex;

  align-items: center;
  ${up(breakpointsKeys.TABLET)}{
    margin-right: ${rem(30)};
  }
  /* flex-grow: 2;
  justify-content: space-between;

  ${up('desktopLarge')} {
    flex-direction: column-reverse;
    text-align: left;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
  } */
`;

export const HeaderContentRight = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  ${up(breakpointsKeys.TABLET)} {
    width: auto;

    align-items: flex-end;
    text-align: right;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

export const HeaderGuestMenu = styled.nav`
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.header.fontSizeSet.small)};

  ${only(breakpointsKeys.MOBILE)} {
    display: none;
  }
`;

export const HeaderIconControls = styled.ul.attrs(() => ({
  role: 'menubar',
}))`
  display: flex;
  gap: ${rem(7)};
  > li {
    display: flex;
  }
  ${up(breakpointsKeys.DESKTOP)} {
    gap: ${rem(18)};
  }
`;

const Link = styled(LinkComponent)`
  span {
    display: none;
  }

  ${up(breakpointsKeys.DESKTOP)} {
    span {
      display: flex;
    }
  }
`;

export const BusinessLoginLink = styled(Link)`
  max-width: ${(p) => (p.isClientB2BType ? rem(180) : 'auto')};
  text-align: left;
  margin-right: 7px;

  ${up(breakpointsKeys.TABLET)} {
    margin-left: 10px;
  }
  ${up(breakpointsKeys.DESKTOP)} {
    .content {
      display: flex;
      gap: ${rem(5)};
      align-items: center;
      flex-direction: row;
    }
  }
`;

export const MyAccountLink = styled(Link)`
  svg {
    transform: scale(1.1);
    transform-origin: center bottom;
    color: ${(p) => p.theme.header.accountIconColor};
  }
  text-align: center;

  ${up(breakpointsKeys.TABLET)} {
    && {
      display: none;
    }
  }
`;

export const UserAccountPageLink = styled(Link)`
  &:hover:before,
  &:focus:before {
    background: none;
    color: transparent;
  }
`;

export const BasketControl = styled.li``;

export const HeaderUser = styled.div`
  ${up(breakpointsKeys.TABLET)} {
    ${HeaderIconControls} {
      display: none;
    }
  }
`;

export const HeaderUserMenuWrapper = styled.div`
  width: 100%;
  display: none;

  ${up(breakpointsKeys.TABLET)} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: auto;
  }
`;

export const HeaderUserMenu = styled.nav`
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.header.fontSizeSet.small)};

  ul {
    & > li {
      & + li {
        margin-top: 1em;
      }
    }
  }

  ${up(breakpointsKeys.TABLET)} {
    ul {
      display: flex;

      & > li {
        & + li {
          margin-top: 0;
          margin-left: ${itemsSpacing};
        }
      }
    }
  }
`;

export const HeaderUserName = styled.span`
  color: ${(p) => p.theme.header.userAccountLink.color};
  display: flex;
  align-items: center;
  margin-left: ${em(19)};
  margin-right: ${em(3)};

  svg {
    margin-right: 0.5em;
  }
`;

export const HeaderUserPages = styled.nav`
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.header.fontSizeSet.large)};
  padding: 1em 0 0.75em;
`;

export const HeaderUserControls = styled.nav`
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.header.fontSizeSet.small)};
`;

export const HeaderBottomBar = styled.div`
  ${up(breakpointsKeys.TABLET)} {
    display: block;
    /* background: ${(p) => p.theme.header.bottomBar.bg}; */
    /* height: ${em(66)}; */
  }
`;

export const DarkHorizontalMenu = styled(HorizontalMenu)`
  background: ${(p) => p.theme.header.bottomBar.menuBg};
  height: 100%;
  align-items: center;
  padding-right: ${em(60)};

  a {
    font-family: ${(p) => p.theme.header.bottomBar.fontFamily};
  }
`;

export const HeaderBottomBarWrapper = styled.div`
  display: flex;

  justify-content: center;
  ${only(breakpointsKeys.MOBILE)} {
    min-height: ${rem(41)};
    padding: 0 ${rem(16)};
  }
  ${up(breakpointsKeys.TABLET)} {
    height: 100%;
    position: relative;
  }
`;

export const HeaderBottomBarWrapperRightSide = styled.div`
  display: none;

  ${up(breakpointsKeys.TABLET)} {
    display: flex;
    flex-grow: 1;
    gap: ${em(24)};
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const HeaderBottomBarSearch = styled.div`
  position: relative;
  width: 100%;
  display: none;

  & > * {
    margin-bottom: 0;
    width: 100%;
  }

  ${up(breakpointsKeys.TABLET)} {
    display: flex;
    align-items: center;
    width: auto;
    flex-basis: ${rem(680)};
    flex-shrink: 1;
  }
`;

export const HeaderBottomBarSearchMobile = styled.div`
  position: relative;
  width: 100%;

  & > * {
    margin-bottom: 0;
    width: 100%;
  }
  ${up(breakpointsKeys.TABLET)} {
    display: none;
  }
`;

export const BalancePanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BottomHeaderNavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${rem(16)};
  max-width: ${rem(1260)};
`;

export const LightMenuRightSide = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  ${up('desktopLarge')} {
    width: auto;
  }

  & > ul {
    margin-right: 1.75em;
  }
`;

export const HeaderUserWarning = styled.div`
  display: none;
  max-width: ${rem(200)};

  & p {
    ${(p) => p.theme.helpers.fontFamilyWithFallback(p.theme.global.fontFamily.primary)};
    line-height: 1.4em;
  }

  ${up(breakpointsKeys.DESKTOP)} {
    display: block;
  }
`;

export const HeaderRightInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${up(breakpointsKeys.TABLET)} {
    position: relative;
  }
`;

export const LoginIcon = styled(_Icon)`
  font-size: ${rem(24)};
  color: ${({ theme }) => theme.defaultPalette.monochrome[4]};
  ${up(breakpointsKeys.TABLET)} {
    font-size: ${rem(34)};
    color: ${({ theme }) => theme.defaultPalette.monochrome[1]};
  }
`;

export const Button = styled(_Button)`
  display: flex;
  height: ${rem(29)};
  align-items: center;
  ${up(breakpointsKeys.TABLET)} {
    margin-right: 0;
    height: ${rem(37)};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    border-radius: 30px;
    height: ${rem(32)};
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[0]};
    padding: ${rem(4)} ${rem(12)};
    ${({ theme }) => theme.helpers.transition(['background-color'])};
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.defaultPalette.teal[0]};
    }
  }
`;

// &:focus-visible {
//   * > path {
//     ${({ theme }) => theme.helpers.transition(['stroke'])};

//     stroke: ${({ theme }) => theme.helpers.hexToRGBA(theme.defaultPalette.chateauGreen[0])};
//   }

//   * > p {
//     ${({ theme }) => theme.helpers.transition(['color'])};
//     color: ${({ theme }) => theme.defaultPalette.chateauGreen[0]};
//   }
// }

export const UserControlsLabel = styled.p`
  display: none;
  ${up(breakpointsKeys.DESKTOP)} {
    color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    display: block;
    margin-top: ${rem(4)};
    font-size: ${({ theme }) => theme.typography.fontSize.s1};
  }
`;

export const UserControlsButton = styled(_Button)`
  height: 100%;
  &:focus-visible,
  &:hover {
    .content {
      ${up(breakpointsKeys.DESKTOP)} {
        * > path {
          ${({ theme }) => theme.helpers.transition(['stroke'])};
          stroke: ${({ theme }) => theme.helpers.hexToRGBA(theme.defaultPalette.teal[0])};
        }

        ${UserControlsLabel} {
          ${({ theme }) => theme.helpers.transition(['color'])};
          color: ${({ theme }) => theme.defaultPalette.teal[0]};
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  /* ${up(breakpointsKeys.TABLET)} {
    height: ${rem(39)};
  } */
`;

export const HeaderGuestControlWrapper = styled.li`
  list-style: none;
  ${up(breakpointsKeys.TABLET)} {
    position: relative;
  }
  ${up(breakpointsKeys.DESKTOP)} {
    align-items: center;
  }
`;

export const HeaderGuestControlMainContentWrapper = styled.div`
  height: fit-content;
`;

export const HeaderGuestControlDialogContentWrapper = styled.div`
  padding: ${rem(15)} ${rem(25)};
  display: flex;
  flex-direction: column;
  width: ${rem(300)};
`;

export const NavigateToLogInLink = styled(LinkComponent)`
  border-radius: ${rem(45)};
  width: 100%;
  background-color: ${({ theme }) => theme.defaultPalette.teal[0]};
  color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  border: 1px solid ${({ theme }) => theme.defaultPalette.teal[0]};
  display: flex;
  padding: ${rem(8)} ${rem(15)};
  justify-content: center;
  align-items: center;
`;
export const NavigateToRegisterLink = styled(LinkComponent)`
  width: 100%;
  border-radius: ${rem(45)};
  color: ${({ theme }) => theme.defaultPalette.teal[0]};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  display: flex;
  padding: ${rem(8)} ${rem(15)};
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.defaultPalette.teal[0]};
`;

export const Person = styled(_Person)`
  margin: auto;
`;
export const GuestHeaderPersonIcon = styled(_Person)`
  height: ${rem(20)};
  width: ${rem(20)};

  margin: ${rem(4)} ${rem(8)} ${rem(4)} ${rem(4)};
  > path {
    stroke: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  }
`;
export const UserHeaderPersonIcon = styled(_Person)`
  height: ${rem(28)};

  > path {
    stroke: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  }
`;
export const GuestHeaderPersonIconLabel = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.s2};
  font-family: ${({ theme }) => theme.global.fontFamily.secondary};
  color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
`;

export const HeaderGuestControlsLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(10)};
  margin: ${rem(15)} 0;
`;

export const HeaderGuestControlsSubHeading = styled(_Heading)`
  width: 100%;
  padding: ${rem(10)};
  margin: 0;
  color: ${({ theme }) => theme.defaultPalette.teal[0]};
  font-family: ${({ theme }) => theme.global.fontFamily.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.s2};
  &::before {
    background: ${({ theme }) => theme.defaultPalette.monochrome[1]};
  }
`;

export const LogIn = styled(_LogIn)`
  * > path {
    fill: ${({ theme }) => theme.defaultPalette.monochrome[4]};
  }
  ${up(breakpointsKeys.TABLET)} {
    height: ${rem(33)};
    width: ${rem(33)};
    margin-left: ${rem(5)};
    * > path {
      fill: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    }
  }
`;

export const HeaderBasketControlLink = styled(LinkComponent)`
  &:focus-visible {
    * > path {
      ${({ theme }) => theme.helpers.transition(['fill'])};
      /* NOTE: it has to be a rgb, cause when we use # transition doesnt animate */
      fill: ${({ theme }) => theme.helpers.hexToRGBA(theme.defaultPalette.teal[0])};
    }

    * > p {
      ${({ theme }) => theme.helpers.transition(['color'])};
      color: ${({ theme }) => theme.defaultPalette.teal[0]};
    }
  }
`;

export const HeaderUserControlWrapper = styled.li`
  display: block !important;
  ${up(breakpointsKeys.TABLET)} {
    position: relative;
  }
`;
export const BottomBarIconsMenuList = styled.ul`
  display: flex;
  align-items: center;
  gap: ${rem(18)};
`;

export const IconLabel = styled.p`
  display: none;
  ${up(breakpointsKeys.TABLET)} {
    margin-top: ${rem(4)};
    color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    display: block;
    font-size: ${({ theme }) => theme.typography.fontSize.s1};
  }
`;

export const ClipboardIconContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ClipboardIconLarge = styled(_ClipboardIconLarge)`
  height: ${rem(25)};
  ${up(breakpointsKeys.TABLET)} {
    height: ${rem(28)};
    * > path {
      stroke: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    }
  }
`;

export const ClipboardControlLink = styled(LinkComponent)`
  &:focus-visible {
    * > path {
      ${({ theme }) => theme.helpers.transition(['stroke'])};

      stroke: ${({ theme }) => theme.helpers.hexToRGBA(theme.defaultPalette.teal[0])};
    }

    * > p {
      ${({ theme }) => theme.helpers.transition(['color'])};
      color: ${({ theme }) => theme.defaultPalette.teal[0]};
    }
  }
  ${up(breakpointsKeys.DESKTOP)} {
    &:hover {
      * > path {
        ${({ theme }) => theme.helpers.transition(['stroke'])};

        stroke: ${({ theme }) => theme.helpers.hexToRGBA(theme.defaultPalette.teal[0])};
      }

      .clipboardLabel {
        ${({ theme }) => theme.helpers.transition(['color'])};
        color: ${({ theme }) => theme.defaultPalette.teal[0]};
      }
    }
  }
`;

export const ExtendedMenuAndBalancePanelWrapper = styled.div`
  display: flex;
  gap: ${rem(10)};
`;

export const HeaderUserControlsDialogContentWrapper = styled.div`
  display: flex;
  width: ${rem(300)};
  padding: ${rem(15)} ${rem(25)};
  flex-direction: column;
  align-items: center;
  gap: ${rem(15)};
`;

export const HeaderUserControlsMainUserName = styled.p`
  color: ${({ theme }) => theme.defaultPalette.teal[0]};
  margin-top: ${rem(10)};
`;

export const HeaderUserControlsNameAndIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoutButton = styled(_Button)`
  width: 100%;
  height: ${rem(35)};
  margin-bottom: ${rem(15)};
`;

export const LocaleOptionsForLoggedInUser = styled(_LocaleOptions)`
  ${up(breakpointsKeys.DESKTOP)} {
    margin-right: ${rem(18)};
    margin-left: ${rem(18)};
  }
`;

export const HeaderUserWarningText = styled(_Text)`
  text-align: center;
`;
