import * as S from './styles';

const defaultProps = {
  tip: null,
};

export const FormRow = ({ children, tip, ...restProps } = defaultProps) => (
  <S.FormRow {...restProps}>{children}</S.FormRow>
);

FormRow.defaultProps = defaultProps;
