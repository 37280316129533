import styled from 'styled-components';
import { em, rem } from 'polished';
import { down, up } from 'styled-breakpoints';
import DefaultInput from '../Input';
import DefaultButton from '../Button';
import Icon from '../Icon';
import { breakpointsKeys } from 'src/enums/breakpoints';
import _SearchIcon from 'src/components/Icons/Search';

export const Input = styled(DefaultInput)`
  width: 100%;
  margin-bottom: 0 !important;
  margin-top: ${rem(2)};

  .input {
    ${({ removeLeftPadding }) => removeLeftPadding && 'padding-left: 0 !important;'}
    padding-right: 11em !important;

    ${down('tablet')} {
      padding-right: 2.5em !important;
    }
  }
`;

export const Search = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  z-index: ${({ isOpen }) => (isOpen ? 300 : 29)};
  position: relative;
`;

export const SearchIcon = styled(_SearchIcon)`
  ${up(breakpointsKeys.TABLET)} {
    > path {
      fill: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    }
  }
`;

export const Button = styled(DefaultButton)`
  border-radius: 2em;
  ${({ theme }) => theme.helpers.transition(['background-color', 'border'])};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  border: 1px solid ${({ theme }) => theme.defaultPalette.teal[0]};
  margin-left: -3em;
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
  z-index: 4;

  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.defaultPalette.teal[0]};
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[0]};
    ${SearchIcon} {
      > path {
        fill: ${({ theme }) => theme.defaultPalette.monochrome[0]};
      }
    }
  }
  ${up(breakpointsKeys.TABLET)} {
    background-color: ${({ theme }) => theme.defaultPalette.teal[0]};

    &:active,
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.defaultPalette.teal[1]};
      border: 1px solid ${({ theme }) => theme.defaultPalette.teal[1]};
    }
  }
`;

export const SearchManyContainer = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  right: 5em;
  z-index: 11;

  > svg {
    cursor: pointer;
    height: 0.75em;
    width: 0.75em;
  }
`;

export const SearchManyBtnVerticalLine = styled.span`
  width: 1px;
  height: ${rem(30)};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[2]};
  margin-left: ${rem(16)};
`;

export const SearchManyBtn = styled.div`
  color: ${({ theme }) => theme.defaultPalette.monochrome[4]};
  font-size: ${({ theme }) => theme.typography.fontSize.s2};
  margin-left: ${rem(16)};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const CodeScannerContainer = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  right: 70px;
  z-index: 11;
`;

export const SearchIconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;

  ${up(breakpointsKeys.TABLET)} {
    left: 25px;
  }
`;

export const LeftIcon = styled(Icon)`
  cursor: pointer;
  height: ${({ shouldCloseIconBeDisplayed }) => (shouldCloseIconBeDisplayed ? em(16) : em(20))};
  width: ${({ shouldCloseIconBeDisplayed }) => (shouldCloseIconBeDisplayed ? em(16) : em(20))};
  margin-bottom: ${({ shouldCloseIconBeDisplayed }) => (shouldCloseIconBeDisplayed ? em(2) : em(1))};
`;
