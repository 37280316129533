import { forwardRef } from 'react';
import * as S from './styles';

export const BaseInput = forwardRef(({ onInput, defaultValue, ...props }, innerRef) => {
  return (
    <S.Input
      ref={innerRef}
      defaultValue={defaultValue}
      onInput={onInput}
      className="input"
      autoComplete="nope"
      type="text"
      {...props}
    />
  );
});

BaseInput.displayName = 'BaseInput';
