import { clientTypesKeys, clientTypesMap } from 'src/enums/clientsTypes';
import { mapDatoResponseToProducersQueryString } from 'src/utils/mapDatoResponseToProducersQueryString';
import useDatoCMSQuery from 'src/hooks/useDatoCMSQuery';
import useLanguage from '../useLanguage';
import GET_FOOTER_DATA_BY_PERMISSION from 'src/graphql/queries/getFooterDataByPermission.gql';
import removeLinksToPagesWithoutAccess from './removeLinksToPagesWithoutAccess';
import { addLangSignToFooterHtmlLinks } from 'src/utils/addLangSignToHtmlLinksFromDato';
import { defaultLanguage } from 'config/i18n';

export const useFooterEntries = (user) => {
  const clientType = user?.clientType;
  const accessNameList = user?.accessList?.map(({ name }) => name) || [];
  const [language] = useLanguage();
  const locale = language;

  const permissions = {
    shouldDisplayForNotLoggedIn: clientType === undefined,
    shouldBeDisplayedForB2b: clientTypesMap.get(clientType) === clientTypesKeys.CLIENT_B2B,
    shouldBeDisplayedForB2c: clientTypesMap.get(clientType) === clientTypesKeys.CLIENT_B2C,
  };

  const { data } = useDatoCMSQuery(GET_FOOTER_DATA_BY_PERMISSION, {
    ssr: true,
    variables: {
      locale,
      ...permissions,
    },
  });

  if (!data) {
    return null;
  }

  const footerBrandsList = mapDatoResponseToProducersQueryString(data?.footerWithCustomisation?.brands);
  let footerUpdatedLinks =
    data?.footerWithCustomisation?.b2bUser ||
    data?.footerWithCustomisation?.b2cUser ||
    data?.footerWithCustomisation?.guest ||
    [];

  if (locale !== defaultLanguage) {
    footerUpdatedLinks = addLangSignToFooterHtmlLinks(footerUpdatedLinks, locale);
  }
  return {
    columns: removeLinksToPagesWithoutAccess(footerUpdatedLinks, accessNameList),
    brands: footerBrandsList,
    copyrightsText: data?.footerWithCustomisation?.copyrightsText,
    socialMedia: data?.footerWithCustomisation?.socialMedia,
    allContactsPage: data?.footerWithCustomisation?.allContactsPage,
    officeLocatorPage: data?.footerWithCustomisation?.officeLocatorPage,
    htmlBottomFooter: data?.footerWithCustomisation?.htmlBottomFooter,
    displayBottomBar: data?.footerWithCustomisation?.displayBottomBar,
    htmlBasketBottomFooter: data?.footerWithCustomisation?.htmlBasketBottomFooter,
  };
};
