import styled, { css } from 'styled-components';
import { rem } from 'polished';

const createStylesForCircularVariant = () => css`
  border-radius: 50%;
`;
const createStylesForRoundedVariant = () => css`
  border-radius: 4px;
`;

const getDimensionsUnit = (unit) => {
  switch (typeof unit) {
    case 'string':
      return unit;
    case 'number':
      return rem(unit);
    default:
      return '100%';
  }
};

export const SkeletonBody = styled.div`
  z-index: 1;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #eee;
  background-size: 200% 100%;
  background-position-x: 180%;
  height: ${({ height }) => getDimensionsUnit(height)};
  width: ${({ width }) => getDimensionsUnit(width)};

  animation: 1.5s shine linear infinite;
  ${({ variant }) => variant === 'circular' && createStylesForCircularVariant()}
  ${({ variant }) => variant === 'rounded' && createStylesForRoundedVariant()}

  @keyframes shine {
    to {
      background-position-x: -20%;
    }
  }
`;
