import IMask from 'imask';
import { useCallback } from 'react';

const defaultOptions = { sign: 'zł', signOnLeft: false, delimiter: ',' };

export const transformValue = (fractionalUnitValue, delimiter = defaultOptions.delimiter) =>
  IMask.pipe(`${fractionalUnitValue}`, {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: ' ',
    normalizeZeros: true,
    padFractionalZeros: true,
    radix: delimiter,
    mapToRadix: ['.'],
  });

export const useCurrentCurrency = (options = {}) =>
  useCallback(
    (fractionalUnitValue, customSign = null) => {
      const { sign, signOnLeft, delimiter } = {
        ...defaultOptions,
        ...options,
      };
      const transformedValue = transformValue(`${fractionalUnitValue}`, delimiter);

      return signOnLeft ? `${customSign || sign} ${transformedValue}` : `${transformedValue} ${customSign || sign}`;
    },
    [options],
  );
