import useTranslation from 'src/hooks/useTranslation';

import NumberForIcon from 'src/components/NumberForIcon';

import * as S from './styles';

export const BasketIndicator = ({ indicatorNumber, ...restProps } = defaultProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper {...restProps}>
      <NumberForIcon number={indicatorNumber}>
        <S.Basket />
      </NumberForIcon>

      <S.BasketLabel className="basketLabel">{t('basket')}</S.BasketLabel>
    </S.Wrapper>
  );
};
