import { rem, em } from 'polished';
import { up, only } from 'styled-breakpoints';
import styled from 'styled-components';

import { breakpointsKeys } from 'src/enums/breakpoints';

import ImportedButton from 'src/components/Button';
import ImportedText from 'src/components/Text';
import ImportedIcon from 'src/components/Icon';

export const Flag = styled.div`
  padding: ${rem(2)};
  border-radius: 50%;
  width: ${rem(24)};
  height: ${rem(24)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${only(breakpointsKeys.MOBILE)} {
    outline: 1px solid ${({ theme }) => theme.defaultPalette.prussianBlue[0]};
  }
  ${up(breakpointsKeys.TABLET)} {
    width: ${rem(33)};
    height: ${rem(33)};
    margin: 0 0 0 ${rem(5)};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    margin: 0;
  }

  &:before {
    content: '';
    background-image: url(${(p) => `/static/svg/flag-${p.code}.svg`});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    width: ${rem(20)};
    height: ${rem(20)};
    display: block;
    /* position: absolute; */
    ${up(breakpointsKeys.TABLET)} {
      width: ${rem(28)};
      height: ${rem(28)};
    }
  }
`;

export const DeliveryCountryText = styled(ImportedText)`
  color: ${({ theme }) => theme.defaultPalette.monochrome[0]};

  text-align: start;
  font-size: ${({ theme }) => theme.typography.size.s2};
  margin-bottom: 0;
  line-height: ${rem(16)};
  ${({ theme }) => theme.helpers.transition(['transform', 'color'])};
`;

export const TypographyWrapper = styled.div`
  ${only(breakpointsKeys.MOBILE)} {
    display: none;
  }
`;

export const Icon = styled(ImportedIcon)`
  color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  font-size: ${({ theme }) => theme.typography.size.s3};
  transform: rotate(${({ isUp }) => (isUp ? '180deg' : '0')});
  ${({ theme }) => theme.helpers.transition(['transform', 'color'])};
`;

export const LocaleOptionsWrapper = styled.button`
  cursor: pointer;
  gap: ${em(8)};
  display: flex;
  height: 100%;
  align-items: center;
  ${up(breakpointsKeys.DESKTOP)} {
    gap: ${em(6)};

    &:hover,
    &:focus-visible {
      ${DeliveryCountryText}, ${Icon} {
        color: ${({ theme }) => theme.defaultPalette.monochrome[8]};
      }
    }
  }
`;

export const CurrencyAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(5)};
`;

export const LocaleOptionsMainWrapper = styled.div`
  align-self: center;
  margin-right: ${rem(7)};
  height: ${rem(29)};

  ${up(breakpointsKeys.TABLET)} {
    position: relative;
    margin: 0 auto;
    /* margin-right: 0; */
    height: ${rem(37)};
    margin-right: ${rem(15)};
    margin-left: ${rem(10)};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    margin-right: ${rem(18)};
  }
`;

export const LocaleOptionsContentWrapper = styled.div`
  padding: ${rem(30)} ${rem(25)};
  display: flex;
  width: ${rem(320)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${rem(30)};
`;

const BaseFlag = styled.div`
  outline: ${({ theme }) => `${rem(0.5)} solid ${theme.defaultPalette.monochrome[5]}`};
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-width: ${rem(20)};
  height: ${rem(20)};
  display: block;
  border: ${({ theme }) => `${rem(0.5)} solid ${theme.defaultPalette.monochrome[0]}`};
`;

export const SelectCountryFlag = styled(BaseFlag).attrs(({ code, theme }) => ({
  style: {
    backgroundImage: `url(/static/svg/flags/country/flag_${code.toLowerCase()}-4x3.svg)`,
    borderColor: theme.defaultPalette.monochrome[0],
  },
}))``;

export const SelectLocaleFlag = styled(BaseFlag).attrs(({ code, theme }) => ({
  style: {
    backgroundImage: `url(/static/svg/flags/locale/flag_${code.toLowerCase()}-4x3.svg)`,
    borderColor: theme.defaultPalette.monochrome[0],
  },
}))``;

export const SubmitSettingsButton = styled(ImportedButton)`
  border-radius: ${rem(55)};

  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.size.s2};
  margin: 0 auto;
  padding: ${rem(10)} ${rem(20)};
`;
