import Collapse from '@kunukn/react-collapse';
import * as S from './styles';
import { keyboardEventCode } from 'src/enums/keyboardEventProperties';

const defaultProps = {
  forceBeingOpen: false,
  id: '',
  isOpen: false,
  defaultIsOpen: false,
  toggle: null,
  showIcon: false,
  headingDescription: null,
  heading: null,
  iconOpen: 'arrowDown',
  iconClose: 'arrowRight',
};

export const AccordionSection = ({
  heading,
  children,
  id,
  forceBeingOpen,
  isOpen,
  // NOTE: Look at src\components\Accordion\Accordion.jsx, there this props was use
  defaultIsOpen,
  toggle,
  showIcon,
  headingDescription,
  iconOpen,
  iconClose,
  ...restProps
} = defaultProps) => {
  const contentId = `${id}_content`;
  const shouldSectionBeOpened = forceBeingOpen || isOpen;

  const headingClassName = shouldSectionBeOpened ? 'is-open' : 'is-closed';

  const handleClick = () => {
    if (forceBeingOpen) return;
    toggle();
  };

  const handleKeyDown = (e) => {
    if (forceBeingOpen) return;

    if (e.code === keyboardEventCode.SPACE) {
      e.preventDefault();
      toggle();
    }
  };

  return (
    <S.AccordionSection {...restProps}>
      <S.AccordionSectionHeader
        isActive={!forceBeingOpen}
        id={id}
        className={headingClassName}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        aria-expanded={shouldSectionBeOpened}
        aria-controls={contentId}
        showIcon={showIcon}
      >
        {heading} {showIcon ? <S.Icon icon={isOpen ? iconOpen : iconClose} /> : null}
      </S.AccordionSectionHeader>
      {headingDescription && <div>{headingDescription}</div>}

      <Collapse isOpen={shouldSectionBeOpened} noAnim={forceBeingOpen}>
        {(state) => (
          <S.AccordionSectionInner
            id={contentId}
            className={state}
            role="region"
            aria-labelledby={id}
            aria-hidden={!forceBeingOpen || !isOpen}
          >
            {children}
          </S.AccordionSectionInner>
        )}
      </Collapse>
    </S.AccordionSection>
  );
};

AccordionSection.defaultProps = defaultProps;
