import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import useLazyShopQuery from 'src/hooks/useLazyShopQuery';
import useTranslation from 'src/hooks/useTranslation';
import useLanguage from 'src/hooks/useLanguage';
import GET_CATALOG_CATEGORIES from 'src/graphql/queries/getCatalogCategories.gql';
import GET_USER_INFO from 'src/graphql/queries/getUserInfo.gql';
import LOGOUT from 'src/graphql/queries/logout.gql';
import { createCustomLinkProps } from 'src/utils/createCustomLinkProps';

import * as S from './styles';

import { CategoryList } from './CategoryList';
import { LogOutButton } from './LogOutButton';

import useDebounce from 'src/hooks/useDebounce';
import { NotLoggedInUserMenu } from './NotLoggedInUserMenu';

const separateTopMenuPagesLinkProps = (pagePropsArray, layoutToSeparate) => {
  const layoutToFind = `/${layoutToSeparate}`;

  const separatedObject = pagePropsArray.find(({ href }) => href === layoutToFind) || {};
  const filteredArray = pagePropsArray.filter(({ href }) => href !== layoutToFind) || [];

  return { separatedObject, filteredArray };
};

export const menuTypes = {
  main: 'main',
  category: 'category',
  extendedMenu: 'extendedMenu',

  selectedPriceTypeApiId: null,
};

const defaultProps = {
  user: null,
  coreRoutesInfo: {},
  leftBottomMenuEntries: [],
  rightBottomMenuEntries: [],
};

const DELAY = 200;
const INITIAL_MENU_DEPTH = 0;
const FIRST_MENU_DEPTH = 1;
const SECOND_MENU_DEPTH = 2;

export const MobileMenu = ({
  isMenuOpen,
  toggleMenu,
  user,
  topMenuEntries,
  searchMenuPages,
  locale,
  coreRoutesInfo,
  leftBottomMenuEntries,
  rightBottomMenuEntries,
  handleChangeCustomer,
  selectedPriceTypeApiId,
  initialMenuType,
  ...restProps
} = defaultProps) => {
  const homePageSlug = coreRoutesInfo.homePage.name[locale];
  const homePageLayout = coreRoutesInfo.homePage.layout;
  const loginPageSlug = coreRoutesInfo.businessUserLoginPage.name[locale];
  const loginPageLayout = coreRoutesInfo.businessUserLoginPage.layout;
  const productsPageSlug = coreRoutesInfo.productsPage.name[locale];
  const productsPageLayout = coreRoutesInfo.productsPage.layout;
  const registrationB2BPageLayout = coreRoutesInfo.registerPageForB2b.layout;
  const { t } = useTranslation();
  const router = useRouter();

  const timeoutRef = useRef(null);
  const listWrapperRef = useRef();
  const [getUserInfoLazily] = useLazyShopQuery(GET_USER_INFO, { variables: { query: 'user' } });
  const [activeMenu, setActiveMenu] = useState(initialMenuType);

  const [isRoutingChange, setIsRoutingChange] = useState(false);
  const [currentActiveCategory, setCurrentActiveCategory] = useState(null);
  const [currentExtendedMenu, setCurrentExtendedMenu] = useState(null);

  const [language] = useLanguage();

  const handleAddOpacityToList = () => {
    setIsRoutingChange(true);
  };
  const handleHideOpacityFromList = () => {
    setIsRoutingChange(false);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleAddOpacityToList);
    router.events.on('routeChangeComplete', handleHideOpacityFromList);

    return () => {
      router.events.off('routeChangeComplete', handleHideOpacityFromList);
      router.events.off('routeChangeStart', handleHideOpacityFromList);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeCategoryMenu = activeMenu === menuTypes.category;
  const activeExtendedMenu = activeMenu === menuTypes.extendedMenu;

  const [currentActiveLevel, setCurrentActiveLevel] = useState(0);
  const shouldHideLogo = currentActiveLevel !== INITIAL_MENU_DEPTH;

  useEffect(() => {
    if (initialMenuType !== activeMenu) {
      setActiveMenu(initialMenuType);

      switch (initialMenuType) {
        case menuTypes.main:
          setCurrentActiveLevel(INITIAL_MENU_DEPTH);
          break;

        case menuTypes.category:
          setCurrentActiveLevel(FIRST_MENU_DEPTH);
          break;

        case menuTypes.extendedMenu:
          setCurrentActiveLevel(SECOND_MENU_DEPTH);
          break;

        default:
          setCurrentActiveLevel(INITIAL_MENU_DEPTH);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMenuType]);

  const leftBottomMenuPages = createCustomLinkProps({ navEntries: leftBottomMenuEntries, language, user });
  const topMenuPages = createCustomLinkProps({ navEntries: topMenuEntries, language, user });

  const { separatedObject, filteredArray } = separateTopMenuPagesLinkProps(topMenuPages, registrationB2BPageLayout);

  useEffect(() => {
    if (!isMenuOpen) {
      const timer = setTimeout(() => {
        setCurrentActiveLevel(INITIAL_MENU_DEPTH);
      }, 200);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isMenuOpen]);

  const [logout] = useLazyShopQuery(LOGOUT, {
    onCompleted: async () => {
      await getUserInfoLazily();
      router.push(homePageLayout, `/${homePageSlug}`);
      toggleMenu();
      setCurrentActiveLevel(0);
    },
  });

  const [getCategories, { data: categoriesData, loading: categoriesLoading }] = useLazyShopQuery(
    GET_CATALOG_CATEGORIES,
    {
      variables: {
        locale,
      },
    },
  );

  const categories = categoriesData?.catalog?.categories;

  const assignTimeoutRef = (callback) => {
    timeoutRef.current = setTimeout(() => {
      callback();
    }, DELAY);
  };

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleMoveNextTab = (menuType, e) => {
    if (e) {
      const preventLinkToChangeRoute = () => e.preventDefault();
      preventLinkToChangeRoute();
    }
    setActiveMenu(menuType);
    setCurrentActiveLevel((prevState) => prevState + 1);
  };

  const handleMovePrevTab = (menuType) => {
    setCurrentActiveLevel((prevState) => prevState - 1);
    assignTimeoutRef(() => setActiveMenu(menuType));

    if (currentActiveCategory) {
      assignTimeoutRef(() => setCurrentActiveCategory(false));
    }

    if (currentExtendedMenu) {
      assignTimeoutRef(() => setCurrentExtendedMenu(false));
    }
  };

  const [handleChangeDebouncedScrollToTop] = useDebounce(() => {
    listWrapperRef.current.scrollIntoView({
      block: 'start',
      inline: 'nearest',
      behavior: 'smooth',
    });
  }, 150);

  const handleMoveToCategory = (category, e) => {
    if (e) {
      const preventLinkToChangeRoute = () => e.preventDefault();

      preventLinkToChangeRoute();
    }
    setCurrentActiveCategory(category);
    clearTimeoutRef();
    handleMoveNextTab(menuTypes.category);
    handleChangeDebouncedScrollToTop();
  };

  const handleShowWholeCategory = (categoryLayout, categorySlug) => {
    router.push(categoryLayout, categorySlug);
    toggleMenu();
  };

  const handleMoveToExtendedCategory = (pages, e) => {
    if (e) {
      const preventLinkToChangeRoute = () => e.preventDefault();
      preventLinkToChangeRoute();
    }
    setCurrentExtendedMenu(pages);
    clearTimeoutRef();
    handleMoveNextTab(menuTypes.extendedMenu);
  };

  useEffect(() => {
    if (isMenuOpen && !categoriesData) {
      getCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen, categoriesData]);

  return (
    <div>
      <S.MobileMenu role="menubar" isMenuOpen={isMenuOpen} {...restProps}>
        <div ref={listWrapperRef} />
        <S.MenuContent currentActiveLevel={currentActiveLevel} isRoutingChange={isRoutingChange}>
          <S.MenuItems isActive={currentActiveLevel === INITIAL_MENU_DEPTH}>
            <S.MenuHeader>
              <S.Logo inverse user={user} hideLogo={shouldHideLogo} />
              <S.CloseButton icon="close" onClick={toggleMenu} blank inverseBusyIcon />
            </S.MenuHeader>

            {searchMenuPages.map((menuPage) => {
              const currentMenuItemLayout = menuPage.href;
              const isProductPage = currentMenuItemLayout === `/${productsPageLayout}`;

              return (
                <S.TopMenuItem key={menuPage.label}>
                  <S.MenuItemLink
                    href={menuPage.href}
                    blank
                    block
                    isNextLink
                    nextLinkProps={{
                      as: menuPage.nextLinkProps.as,
                    }}
                    onClick={(e) => (isProductPage ? handleMoveNextTab(menuTypes.category, e) : toggleMenu())}
                  >
                    {menuPage.label}
                    {isProductPage && <S.MoreItemsIcon icon="arrowRight" />}
                  </S.MenuItemLink>
                </S.TopMenuItem>
              );
            })}

            {rightBottomMenuEntries.map((menuEntry) => {
              const { linkToStandardNavigation, titleLink, title } = menuEntry;
              const hasExtendedMenuEntries = linkToStandardNavigation?.set?.length;
              const titleLinkProps = createCustomLinkProps({ navEntries: titleLink, language, user });

              const menuEntryProps = {
                title,
                set: createCustomLinkProps({ navEntries: linkToStandardNavigation?.set, language, user }),
              };

              return titleLinkProps.length ? (
                titleLinkProps.map(({ label, ...restTitleLinkProps }) => (
                  <S.MenuItem key={`${label}_${title}`}>
                    <S.MenuItemLink
                      blank
                      block
                      onClick={(e) =>
                        hasExtendedMenuEntries ? handleMoveToExtendedCategory(menuEntryProps, e) : toggleMenu()
                      }
                      {...restTitleLinkProps}
                    >
                      {title}
                      {hasExtendedMenuEntries && <S.MoreItemsIcon icon="arrowRight" />}
                    </S.MenuItemLink>
                  </S.MenuItem>
                ))
              ) : (
                <S.MenuItem key={title}>
                  <S.MenuItemLink
                    blank
                    block
                    href="/"
                    onClick={(e) => {
                      toggleMenu();
                      e.preventDefault();
                    }}
                  >
                    {title}
                  </S.MenuItemLink>
                  {hasExtendedMenuEntries ? (
                    <S.MoreItemsButton
                      icon="arrowRight"
                      blank
                      inverseBusyIcon
                      onClick={() => handleMoveToExtendedCategory(menuEntryProps)}
                      disabled={categoriesLoading}
                    />
                  ) : null}
                </S.MenuItem>
              );
            })}

            {leftBottomMenuPages.map(({ label, ...restLinkProps }) => (
              <S.MenuItem key={label}>
                <S.MenuItemLink blank block onClick={toggleMenu} {...restLinkProps}>
                  {label}
                </S.MenuItemLink>
              </S.MenuItem>
            ))}

            {filteredArray.map(({ label, ...restLinkProps }) => (
              <S.MenuItem key={label}>
                <S.MenuItemLink blank block onClick={toggleMenu} {...restLinkProps}>
                  {label}
                </S.MenuItemLink>
              </S.MenuItem>
            ))}

            {user ? (
              <LogOutButton onClick={logout} />
            ) : (
              <NotLoggedInUserMenu
                loginPageLayout={loginPageLayout}
                loginPageSlug={loginPageSlug}
                onClickCloseMobileMenu={toggleMenu}
                redirectRegisterB2BObject={separatedObject}
              />
            )}
          </S.MenuItems>

          {activeCategoryMenu && (
            <S.MenuItems isActive={currentActiveLevel === FIRST_MENU_DEPTH}>
              <S.MenuHeader>
                <S.GoBackMenuItem
                  onClick={() => {
                    handleMovePrevTab(menuTypes.category);
                  }}
                >
                  <S.GoBackMenuButton blank block>
                    <S.MoreItemsIcon icon="arrowLeft" />
                    {t('mobileMenuGoBackButtonLabel')}
                  </S.GoBackMenuButton>
                </S.GoBackMenuItem>
                <S.CloseButton icon="close" onClick={toggleMenu} blank inverseBusyIcon />
              </S.MenuHeader>

              <CategoryList
                isLoading={categoriesLoading}
                categoryArray={categories}
                onCloseMenu={toggleMenu}
                onMoveToCategory={handleMoveToCategory}
                productsPageLayout={productsPageLayout}
                productsPageSlug={productsPageSlug}
              />
            </S.MenuItems>
          )}

          {currentActiveCategory && activeCategoryMenu && (
            <S.MenuItems isActive={currentActiveLevel === SECOND_MENU_DEPTH}>
              <S.SecondStepMenuHeader>
                <S.GoBackMenuItem
                  onClick={() => {
                    handleMovePrevTab(menuTypes.category);
                  }}
                >
                  <S.GoBackMenuButton blank block>
                    <S.MoreItemsIcon icon="arrowLeft" />
                    {t('mobileMenuGoBackButtonLabel')}
                  </S.GoBackMenuButton>
                </S.GoBackMenuItem>
              </S.SecondStepMenuHeader>
              <S.ShowCategoryButton
                onClick={() =>
                  handleShowWholeCategory(productsPageLayout, `${productsPageSlug}${currentActiveCategory.slug}`)
                }
              >
                {t('showWholeCategory')}
              </S.ShowCategoryButton>

              <CategoryList
                categoryArray={currentActiveCategory.children}
                onCloseMenu={toggleMenu}
                onMoveToCategory={handleMoveToCategory}
                productsPageLayout={productsPageLayout}
                productsPageSlug={productsPageSlug}
              />
            </S.MenuItems>
          )}

          {currentExtendedMenu && activeExtendedMenu && (
            <S.MenuItems isActive={currentActiveLevel === FIRST_MENU_DEPTH}>
              <S.MenuHeader>
                <S.GoBackMenuItem
                  onClick={() => {
                    handleMovePrevTab(menuTypes.category);
                  }}
                >
                  <S.GoBackMenuButton blank block>
                    <S.MoreItemsIcon icon="arrowLeft" />
                    {t('mobileMenuGoBackButtonLabel')}
                  </S.GoBackMenuButton>
                  <S.CloseButton icon="close" onClick={toggleMenu} blank inverseBusyIcon />
                </S.GoBackMenuItem>
              </S.MenuHeader>

              {currentExtendedMenu.set.map(({ label, ...restLinkProps }) => (
                <S.MenuItem key={label}>
                  <S.MenuItemLink blank block onClick={toggleMenu} {...restLinkProps}>
                    {label}
                  </S.MenuItemLink>
                </S.MenuItem>
              ))}
            </S.MenuItems>
          )}
        </S.MenuContent>
      </S.MobileMenu>

      <S.Overlay isMenuOpen={isMenuOpen} onClick={toggleMenu} />
    </div>
  );
};

MobileMenu.defaultProps = defaultProps;
