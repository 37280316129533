import SimpleBarComponent from 'simplebar-react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { rem, em } from 'polished';
import Text from '../Text';

export const Select = styled.span`
  position: relative;

  .select__counter {
    padding-left: 0.25em;

    color: ${({ theme }) => theme.defaultPalette.monochrome[3]};
  }

  ${({ variant }) => {
    switch (variant) {
      case 'secondary': {
        return css`
          display: flex;
          flex-direction: column;

          ${up('tablet')} {
            display: initial;
          }

          #available-clipboards-label {
            color: ${({ theme }) => theme.defaultPalette.monochrome[3]};
            margin-bottom: 0.5em;

            ${up('desktop')} {
              margin-bottom: 1em;
            }
          }

          .selectBox {
            max-width: ${em(450)};
            min-width: ${em(250)};

            > #available-clipboards-toggle-button {
              width: 100%;

              > .inner {
                justify-content: space-between;

                .content {
                  width: 100%;
                  justify-content: space-between;
                }

                svg {
                  color: ${({ theme }) => theme.defaultPalette.blazeOrange[0]};
                }
              }
            }
          }
        `;
      }
      case 'tertiary': {
        return css`
          width: 100%;

          .selectBox {
            width: 100%;
          }

          & button {
            box-shadow: none;

            border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[2]};
            width: 100%;
            position: relative;
            padding-top: ${em(12)};
            padding-bottom: ${em(12)};

            & > .inner {
              justify-content: space-between;
            }

            & .content {
              color: #000;
            }

            &::after {
              content: ${({ placeholder, hasSelectedItemId }) =>
                !!placeholder && hasSelectedItemId ? `"${placeholder}"` : ''};
              position: absolute;
              top: ${em(-10)};
              left: ${rem(8)};
              background-color: white;
              color: gray;
              padding: 0 ${em(8)};
              font-size: 14px;
              border-radius: 7px;
            }

            &:not(:disabled):hover {
              box-shadow: none;
            }
          }
        `;
      }
    }
  }}
`;

export const SelectLabel = styled(Text)`
  &:after {
    content: ' ';
  }
`;

export const CustomSelect = styled.div`
  position: relative;
  display: inline-block;
`;

export const NativeSelect = styled.select`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
`;

export const SelectListWrapper = styled.div`
  max-width: ${({ size }) => (Number.isNaN(Number(size)) ? size : `${size + 5}px`)};

  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  width: 100%;
  position: absolute;
  left: ${({ offset }) => `${offset}px`};

  z-index: 200;
  margin-top: ${rem(-1)};

  border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[2]};
  border-radius: 15px;
  overflow: hidden;
  padding: 7px 0;
`;

export const SelectScrollBar = styled(SimpleBarComponent)`
  width: 100%;
  margin-top: ${rem(-1)};
  padding: 4px 0.25em;

  max-height: ${({ displayItemLimit }) => displayItemLimit * 1.5}em;
  margin-right: 4px;
  .simplebar-vertical {
    width: 4px !important;
    border-radius: 4px;
  }
`;

export const SelectList = styled.ul`
  padding: 0;
  list-style: none;
`;

export const SelectListItem = styled.li`
  background-color: ${({ isHighlighted, noBackgroundHighlight, theme }) =>
    isHighlighted && !noBackgroundHighlight ? theme.header.select.backgroundHighlight : 'unset'};
  cursor: pointer;
  min-height: 1.5em;

  &.active {
    font-weight: 700;
  }
`;

export const TooltipBox = styled.div`
  margin-top: 2px;
`;

export const Tooltip = styled.span`
  background: inherit;
  color: ${({ theme }) => theme.input.tooltipColor};
  font-family: inherit;
  font-size: ${({ theme }) => theme.input.tooltipFontSize};

  margin-left: ${rem(16)};
`;
