import dynamic from 'next/dynamic';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'src/styles/themes';
import { createCustomLinkProps } from 'src/utils/createCustomLinkProps';
import useLanguage from 'src/hooks/useLanguage';
import Link from '../Link';
import * as S from './styles';

const TooltipPopper = dynamic(() => import('../TooltipPopper'));

const defaultProps = {
  linkVariant: 'quaternary',
  listLinkVariant: 'quaternary',
  items: [],
  user: null,
};

export const ExtendedMenu = ({ items, linkVariant, listLinkVariant, user, ...rest } = defaultProps) => {
  const [language] = useLanguage();
  if (!items.length) {
    return null;
  }

  return (
    <S.ExtendedMenu role="menubar" {...rest}>
      {items.map(({ titleLink, title, linkToStandardNavigation }, pathIdx) => {
        const hasSubNav = !!linkToStandardNavigation?.set?.length;
        const menuLinksProps = createCustomLinkProps({ navEntries: linkToStandardNavigation?.set, language, user });
        const titleLinkProps = createCustomLinkProps({ navEntries: titleLink, language, user });

        return (
          <li role="none" key={pathIdx}>
            <TooltipPopper
              disabled={!hasSubNav}
              ariaLabel={title}
              hideOnTooltipHover={false}
              openDelay={0}
              tooltipContent={
                hasSubNav ? (
                  <ThemeProvider theme={defaultTheme}>
                    <S.SubNavList>
                      {menuLinksProps.map(({ label, ...restProps }, subPathIdx) => {
                        return (
                          <li key={subPathIdx}>
                            <Link variant={listLinkVariant} {...restProps}>
                              {label}
                            </Link>
                          </li>
                        );
                      })}
                    </S.SubNavList>
                  </ThemeProvider>
                ) : null
              }
              theme="breadcrumbs"
              placement="bottom-start"
              trigger="mouseenter"
            >
              {titleLinkProps.length ? (
                titleLinkProps.map(({ label, ...restProps }) => (
                  <S.ExtendedMenuItem key={label}>
                    <S.Link
                      variant={linkVariant}
                      role="menuitem"
                      onClick={(e) => {
                        if (!restProps.href) {
                          e.preventDefault();
                        }
                      }}
                      {...restProps}
                    >
                      {title}
                    </S.Link>
                  </S.ExtendedMenuItem>
                ))
              ) : (
                <S.Link
                  variant={linkVariant}
                  role="menuitem"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {title}
                </S.Link>
              )}
            </TooltipPopper>
          </li>
        );
      })}
    </S.ExtendedMenu>
  );
};

ExtendedMenu.defaultProps = defaultProps;
