import { forwardRef } from 'react';
import Input from '../Input';
import * as S from './styles';

const defaultProps = {
  defaultValue: '',
  placeholder: '',
  variant: 'primary',
  required: false,
  disabled: false,
  readOnly: false,
  hideLabel: false,
  error: null,
  confirmed: false,
  mask: null,
  iMaskConfig: null,
  block: false,
  style: {},
  rows: 2,
  shouldLabelBeAlwaysVisible: false,
};

export const Textarea = forwardRef(
  (
    {
      rows,
      id,
      label,
      defaultValue,
      placeholder,
      variant,
      required,
      disabled,
      readOnly,
      hideLabel,
      error,
      confirmed,
      block,
      style,
      shouldLabelBeAlwaysVisible,
      tooltip,
      isRounded,
      ...restProps
    } = defaultProps,
    ref,
  ) => (
    <S.TextareaContainer variant={variant} block={block} isRounded={isRounded}>
      <Input
        as="textarea"
        disableVariants
        id={id}
        label={label}
        defaultValue={defaultValue}
        placeholder={placeholder}
        variant={variant}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        hideLabel={hideLabel}
        error={error}
        confirmed={confirmed}
        style={style}
        block={block}
        showClearButton={false}
        suggestions={[]}
        rows={rows}
        ref={ref}
        shouldLabelBeAlwaysVisible={shouldLabelBeAlwaysVisible}
        tooltip={tooltip}
        {...restProps}
      />
    </S.TextareaContainer>
  ),
);

Textarea.displayName = 'Textarea';

Textarea.defaultProps = defaultProps;
