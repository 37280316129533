import { productStatusKeys, productStatusMap } from 'src/enums/productStatus';
import { breakpointsKeys } from 'src/enums/breakpoints';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import PreviewOfLargeImage from '../PreviewOfLargeImage';
import { ProductAvailabilityIcon } from '../ProductAvailability/styles';
import * as S from './styles';

const defaultProps = {
  isNextLink: true,
  variant: 'tridenary',
  onClick: () => {},
  nextLinkProps: {},
};

export const MiniHorizontalProductListItem = ({
  image,
  largeImage,
  name,
  avail,
  isNextLink,
  variant,
  href,
  nextLinkProps,
  onClick,
  ...restProps
} = defaultProps) => {
  const currentBreakpoint = useBreakpointDetector();
  const isMobileView = currentBreakpoint === breakpointsKeys.MOBILE || currentBreakpoint === breakpointsKeys.TABLET;
  const productStatus = productStatusMap.get(avail);

  return (
    <S.MiniHorizontalProductListItem {...restProps}>
      <S.ProductLink isNextLink variant={variant} href={href} nextLinkProps={nextLinkProps} onClick={onClick}>
        {isMobileView ? (
          <figure>
            <S.ProductImg url={image} alt={name} width={40} />
          </figure>
        ) : (
          <PreviewOfLargeImage ariaLabel={name} title={name} altTxt={name} imgSrc={largeImage}>
            <figure>
              <S.ProductImg url={image} alt={name} width={40} />
            </figure>
          </PreviewOfLargeImage>
        )}
        <S.ProductNameContainer>
          <S.ProductName>{name}</S.ProductName>
          <S.ProductAvailabilityCondition className="product-availability-condition" condition={productStatus}>
            {productStatusKeys.OVS === productStatus && <ProductAvailabilityIcon icon="truck" />}
            <span className="product-availability-status" />
          </S.ProductAvailabilityCondition>
        </S.ProductNameContainer>
      </S.ProductLink>
    </S.MiniHorizontalProductListItem>
  );
};

MiniHorizontalProductListItem.defaultProps = defaultProps;
