import styled, { css } from 'styled-components';
import { em, hideVisually } from 'polished';

const createBaseCheckboxVariantStyle = (inputVariant, p) => css`
  ${p.theme.helpers.createDefaultCheckboxVariant(inputVariant, p)};

  & .descriptionBox::before {
    background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    ${({ radio }) => radio && 'border-radius: 50%'};
  }

  .error {
    padding-left: ${em(37)};
  }
`;

const primaryVariantStyles = (p) => css`
  ${createBaseCheckboxVariantStyle('primary', p)}

  ${() =>
    p.radio &&
    css`
      input ~ .descriptionBox {
        &:before {
          border: 1px solid ${p.theme.checkbox['primary'].color.border};
        }
      }
    `}
`;

const secondaryVariantStyles = (p) => css`
  ${createBaseCheckboxVariantStyle('secondary', p)}
`;

export const CheckboxContainer = styled.div`
  display: ${(p) => (p.block ? 'flex' : 'inline-flex')};
  flex-direction: column;

  ${(p) => p.variant === 'primary' && primaryVariantStyles(p)};
  ${(p) => p.variant === 'secondary' && secondaryVariantStyles(p)};
`;

export const LabelBox = styled.div`
  margin-bottom: 1em;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Required = styled.span`
  color: ${({ theme, variant }) => theme.checkbox[variant].color.requiredSign};
`;

export const Description = styled.span`
  ${(p) =>
    p.hideLabel &&
    css`
      ${hideVisually()};
      /* NOTE: Added in order to avoid a11y contrast issues */
      color: black !important;
      background: white !important;
      margin-left: 0 !important;
    `};

  a {
    color: ${(p) => p.theme.link.tertiary.color.default};

    &:hover {
      color: ${(p) => p.theme.link.tertiary.color.hover};
    }
  }
`;

export const Input = styled.input`
  ${hideVisually()};
`;

export const ErrorBox = styled.div``;

export const Error = styled.span`
  background: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: 0;
`;
