import GET_DELIVERY_COUNTRY from 'src/graphql/queries/getEnvironment.gql';
import SET_DELIVERY_COUNTRY from 'src/graphql/mutations/setDeliveryCountry.gql';
import useRESTMutation from '../useRESTMutation';
import useShopQuery from '../useShopQuery';
import { useMemo } from 'react';

const defaultOnCompletedCallback = () => null;

const defaultArgs = {
  onCompletedCallback: defaultOnCompletedCallback,
  fetchPolicy: 'network-only',
};

export const useDeliveryCountry = ({ onCompletedCallback, fetchPolicy } = defaultArgs) => {
  const { data: deliveryCountryData, refetch: refetchDeliveryCountryData } = useShopQuery(GET_DELIVERY_COUNTRY, {
    fetchPolicy,
  });

  const [setDeliveryCountryRequest] = useRESTMutation(SET_DELIVERY_COUNTRY, {
    onCompleted: (...args) => {
      refetchDeliveryCountryData();
      onCompletedCallback(...args);
    },
  });

  const resAvailableDeliveryCountries = useMemo(() => (deliveryCountryData ? 
    deliveryCountryData?.environment?.availableDelivery : []), [
    deliveryCountryData,
  ]);

  const resCurrentDeliveryCountry = useMemo(() => (deliveryCountryData ? 
    { ...deliveryCountryData?.environment?.currentDelivery } : {}), [
    deliveryCountryData,
  ]);

  const setDeliveryCountry = async (id) => {
    await setDeliveryCountryRequest({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  return [
    { 
      availableDeliveryCountries: resAvailableDeliveryCountries,
      currentDeliveryCountry: resCurrentDeliveryCountry 
    },
    setDeliveryCountry];
}