import styled from 'styled-components';
import { em } from 'polished';

export const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  height: 100%;
  width: 100%;
`;

export const ModalContainerInner = styled.div`
  position: relative;
  width: 100%;
  .modal-content {
    padding: 0;
  }
`;

export const Content = styled.div`
  position: relative;
  overflow: auto;
  scroll-behavior: smooth;
`;

export const Divider = styled.hr`
  color: #e2e2e2;
  opacity: 0.3;
  margin-bottom: ${em(21)};
  margin-top: unset;
`;
