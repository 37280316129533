import BasketIndicator from '../BasketIndicator';

import * as S from './styles';
export const HeaderBasketControl = ({ showPrice, basketIndicatorData, linkProps, ...rest }) => {
  return (
    basketIndicatorData && (
      <S.HeaderBasketControlLink {...linkProps} blank>
        <BasketIndicator indicatorNumber={basketIndicatorData.indicatorNumber} {...rest} />
      </S.HeaderBasketControlLink>
    )
  );
};

HeaderBasketControl.defaultProps = { showPrice: false };
