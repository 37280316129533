import 'keen-slider/keen-slider.min.css';
import {
  Children,
  Fragment,
  cloneElement,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useKeenSlider } from 'keen-slider/react';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import { durations } from 'src/styles/transitions';
import { mediaQueries as mq } from 'src/styles/breakpoints';
import LoadingCover from '../LoadingCover';
import { breakpointsKeys } from 'src/enums/breakpoints';
import * as S from './styles';

const defaultSlidesAspectRatio = {
  mobile: {
    x: 0,
    y: 0,
    offset: 0,
  },
  tablet: {
    x: 0,
    y: 0,
    offset: 0,
  },
  desktop: {
    x: 0,
    y: 0,
    offset: 0,
  },
  hd: {
    x: 0,
    y: 0,
    offset: 0,
  },
};

const defaultProps = {
  initialSlideIdx: 0,
  infinite: false,
  speed: parseInt(durations[2], 0),
  showArrows: false,
  absoluteArrows: false,
  arrowsShownOnHover: false,
  moveSingleSlideOnArrowClick: true,
  showDots: false,
  slidesPerView: null,
  slideOffset: 0,
  autoPlay: false,
  skipLoader: false,
  loadingCoverAspectRatio: defaultSlidesAspectRatio,
  inverseLoadingCover: false,
  customBreakpoints: {},
  onBeforeSlideChange: () => null,
  onAfterSlideChange: () => null,
  onSlideChanged: () => null,
  onMounted: () => null,
  onCreated: () => null,
  onDestroyed: () => null,
  afterChange: () => null,
  offControls: false,
  bannerMaxWidth: null,
  alignBanner: 'left',
};

export const Slider = forwardRef(
  (
    {
      slides,
      initialSlideIdx,
      infinite,
      speed,
      showArrows,
      absoluteArrows,
      arrowsShownOnHover,
      moveSingleSlideOnArrowClick,
      showDots,
      slidesPerView,
      slideOffset,
      skipLoader,
      autoPlay,
      loadingCoverAspectRatio,
      inverseLoadingCover,
      customBreakpoints,
      onMounted,
      onCreated,
      onDestroyed,
      onBeforeSlideChange,
      onAfterSlideChange,
      onSlideChanged,
      afterChange,
      offControls,
      bannerMaxWidth,
      alignBanner,
      arrowSize,
      disableBannerRounding,
      ...restProps
    } = defaultProps,
    ref,
  ) => {
    const keenSliderMode = 'snap';
    const slidesLength = slides.length;
    const hasSlides = slidesLength > 0;
    const timerRef = useRef();
    const [pause, setPause] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(initialSlideIdx);
    const [sliderItemsLength, setSliderItemsLength] = useState(0);
    const currentBreakpoint = useBreakpointDetector();

    const breakpointsSettings = useMemo(() => {
      const slidesPerViewConfig = {
        mobile: slidesPerView?.mobile || 1,
        tablet: slidesPerView?.tablet || 1,
        desktop: slidesPerView?.desktop || 1,
        hd: slidesPerView?.hd || 1,
      };

      const hasCustomBreakpoints = !!Object.keys(customBreakpoints).length;

      const standardBreakpointsSettings = {
        [mq.mobile]: {
          slidesPerView: slidesPerViewConfig.mobile,
          controls: slidesPerViewConfig.mobile < slidesLength,
          loop: infinite && slidesPerViewConfig.mobile < slidesLength,
        },
        [mq.tablet]: {
          slidesPerView: slidesPerViewConfig.tablet,
          controls: slidesPerViewConfig.tablet < slidesLength,
          loop: infinite && slidesPerViewConfig.tablet < slidesLength,
        },
        [mq.desktop]: {
          slidesPerView: slidesPerViewConfig.desktop,
          controls: slidesPerViewConfig.desktop < slidesLength,
          loop: infinite && slidesPerViewConfig.desktop < slidesLength,
        },
        [mq.hd]: {
          slidesPerView: slidesPerViewConfig.hd,
          controls: slidesPerViewConfig.hd < slidesLength,
          loop: infinite && slidesPerViewConfig.hd < slidesLength,
        },
      };

      const customBreakpointsSettings = !hasCustomBreakpoints
        ? {}
        : Object.entries(customBreakpoints).reduce((customBreakpointConfigsObject, [bp, bpConfig]) => {
            let enhancedBpConfig = {};

            if (bpConfig.slidesPerView != null) {
              enhancedBpConfig = {
                ...enhancedBpConfig,
                controls: bpConfig.slidesPerView < slidesLength,
                loop: infinite && bpConfig.slidesPerView < slidesLength,
              };
            }

            return {
              ...customBreakpointConfigsObject,
              [bp]: {
                ...bpConfig,
                ...enhancedBpConfig,
              },
            };
          }, {});

      return hasCustomBreakpoints ? customBreakpointsSettings : standardBreakpointsSettings;
    }, [customBreakpoints, infinite, slidesLength, slidesPerView]);

    const [sliderRef, slider] = useKeenSlider({
      dragSpeed: 0.5,
      duration: speed,
      spacing: slideOffset,
      initial: initialSlideIdx,
      rubberband: false,
      mode: keenSliderMode,
      breakpoints: breakpointsSettings,
      slideChanged: (sliderCallbackData) => {
        const { details } = sliderCallbackData;
        const { relativeSlide } = details();

        setCurrentSlide(Number.isNaN(relativeSlide) ? initialSlideIdx : relativeSlide);

        if (onSlideChanged) {
          onSlideChanged(sliderCallbackData);
        }
      },
      dragStart: () => {
        if (autoPlay) {
          setPause(true);
        }
      },
      dragEnd: (sliderCallbackData) => {
        // FIXME: Temporary fix for OfferCarousel's lazy load
        if (onBeforeSlideChange) {
          const { direction, relativeSlide } = sliderCallbackData.details();

          if (direction === 1) {
            onBeforeSlideChange(sliderCallbackData, 1);
          }

          if (direction === -1) {
            onBeforeSlideChange(sliderCallbackData, -1);

            // NOTE: Necessary as slidesnumber is not always adequate to pagination
            if (relativeSlide === 0) {
              onBeforeSlideChange(sliderCallbackData, -2);
            }
          }
        }

        if (onAfterSlideChange) {
          onAfterSlideChange(sliderCallbackData);
        }

        if (autoPlay) {
          setPause(false);
        }
      },
      mounted: () => {
        onMounted();
      },
      created: () => {
        setIsCreated(true);
        onCreated();
      },
      destroyed: () => {
        onDestroyed();
      },
      afterChange,
    });

    if (slider?.controls && offControls) {
      if (
        currentBreakpoint === breakpointsKeys.HD ||
        currentBreakpoint === breakpointsKeys.DESKTOP ||
        currentBreakpoint === breakpointsKeys.DESKTOP_LARGE
      ) {
        slider.controls(false);
      } else {
        slider.controls(true);
      }
    }

    const handleTimerStart = () => setPause(true);
    const handleTimerStop = () => setPause(false);

    const goToSlide = useCallback(
      (dotIdx) => {
        if (slider) slider.moveToSlideRelative(dotIdx);
      },
      [slider],
    );

    const handlePrevArrowClick = useCallback(
      async (e) => {
        e.stopPropagation();

        if (slider) {
          if (onBeforeSlideChange) {
            await onBeforeSlideChange(slider, -1);
          }

          if (moveSingleSlideOnArrowClick) {
            slider.prev();
          } else {
            const details = slider.details();
            const nextSlideIdx = currentSlide - details.slidesPerView;
            const overlapsFirstSlide = nextSlideIdx < 0;
            const detailsSizeModulo = details.size % details.slidesPerView;
            let relativeSlideToShow = overlapsFirstSlide
              ? details.size - (details.size % Math.abs(nextSlideIdx))
              : nextSlideIdx;

            if (overlapsFirstSlide && detailsSizeModulo === 0) {
              relativeSlideToShow -= 1;
            }

            slider.moveToSlideRelative(relativeSlideToShow, overlapsFirstSlide);
          }
        }
      },
      [currentSlide, moveSingleSlideOnArrowClick, slider, onBeforeSlideChange],
    );

    const handleNextArrowClick = useCallback(
      async (e) => {
        e.stopPropagation();

        if (slider) {
          if (onBeforeSlideChange) {
            await onBeforeSlideChange(slider, 1);
          }

          if (moveSingleSlideOnArrowClick) {
            slider.next();
          } else {
            const details = slider.details();
            const nextSlideIdx = currentSlide + details.slidesPerView;
            const overlapsLastSlide = nextSlideIdx > details.size;

            slider.moveToSlideRelative(overlapsLastSlide ? 0 : nextSlideIdx, overlapsLastSlide);
          }
        }
      },
      [currentSlide, moveSingleSlideOnArrowClick, slider, onBeforeSlideChange],
    );

    useImperativeHandle(ref, () => ({
      destroySlider: () => (slider ? slider.destroy() : undefined),
      refreshSlider: () => (slider ? slider.refresh() : undefined),
      resizeSlider: () => (slider ? slider.resize() : undefined),
    }));

    useEffect(() => {
      if (!slider?.details) {
        return;
      }

      setSliderItemsLength(slider.details().size);
    }, [slider, currentBreakpoint]);

    useEffect(() => {
      if (autoPlay) {
        const currentSliderRef = sliderRef.current;
        currentSliderRef.addEventListener('mouseover', handleTimerStart);
        currentSliderRef.addEventListener('mouseleave', handleTimerStop);

        return () => {
          currentSliderRef.removeEventListener('mouseover', handleTimerStart);
          currentSliderRef.removeEventListener('mouseleave', handleTimerStop);
        };
      }

      return undefined;
    }, [autoPlay, sliderRef]);

    useEffect(() => {
      if (autoPlay) {
        const onTimerTick = () => {
          if (!pause && slider) {
            slider.next();
          }
        };
        const autoplayDuration = parseInt(durations[3], 10);
        timerRef.current = setInterval(onTimerTick, autoplayDuration);
        return () => clearInterval(timerRef.current);
      }

      return undefined;
    }, [autoPlay, pause, slider]);

    useEffect(() => {
      if (slider && hasSlides) slider.refresh();
    }, [hasSlides, slider]);

    useEffect(() => {
      const { current } = sliderRef;
      const sliderVisibilityObserver = new IntersectionObserver(
        ([entry]) => {
          setPause(!entry.isIntersecting);
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        },
      );
      if (current) {
        sliderVisibilityObserver.observe(current);
      }
      return () => sliderVisibilityObserver.unobserve(current);
    }, [sliderRef]);

    const slidesWithKeenSliderSlideClassName = Children.map(slides, (child, childIdx) =>
      cloneElement(child, {
        key: `slider_child_${childIdx}`,
        className: `${child.props.className || ''} keen-slider__slide`,
      }),
    );

    const loadingCoverAspectRatioMobile = {
      x: loadingCoverAspectRatio.mobile.x || 0,
      y: loadingCoverAspectRatio.mobile.y || 0,
      verticalPixelOffset: loadingCoverAspectRatio.mobile.offset || 0,
    };

    const loadingCoverAspectRatioTablet = {
      x: loadingCoverAspectRatio.tablet.x || 0,
      y: loadingCoverAspectRatio.tablet.y || 0,
      verticalPixelOffset: loadingCoverAspectRatio.tablet.offset || 0,
    };

    const loadingCoverAspectRatioDesktop = {
      x: loadingCoverAspectRatio.desktop.x || 0,
      y: loadingCoverAspectRatio.desktop.y || 0,
      verticalPixelOffset: loadingCoverAspectRatio.desktop.offset || 0,
    };

    const loadingCoverAspectRatioHd = {
      x: loadingCoverAspectRatio.hd.x || 0,
      y: loadingCoverAspectRatio.hd.y || 0,
      verticalPixelOffset: loadingCoverAspectRatio.hd.offset || 0,
    };

    const Wrapper = skipLoader ? Fragment : LoadingCover;

    const wrapperProps = skipLoader
      ? {}
      : {
          isComponentReady: isCreated && hasSlides,
          mobile: loadingCoverAspectRatioMobile,
          tablet: loadingCoverAspectRatioTablet,
          desktop: loadingCoverAspectRatioDesktop,
          hd: loadingCoverAspectRatioHd,
          inverse: inverseLoadingCover,
        };

    return (
      <S.Slider
        {...restProps}
        bannerMaxWidth={bannerMaxWidth}
        alignBanner={alignBanner}
        disableBannerRounding={disableBannerRounding}
      >
        <Wrapper {...wrapperProps}>
          <div className="slider-wrapper" role="presentation">
            {showArrows && (
              <S.Arrow
                className={`arrow ${arrowsShownOnHover ? 'initially-hidden' : ''} ${absoluteArrows ? 'abs left' : ''}`}
                currentSlide={currentSlide}
                sliderItemsLength={sliderItemsLength}
                breakpointsSettings={breakpointsSettings}
                dir="left"
                onClick={handlePrevArrowClick}
                onMouseOver={autoPlay ? handleTimerStart : null}
                onFocus={autoPlay ? handleTimerStart : null}
                onMouseLeave={autoPlay ? handleTimerStop : null}
                onBlur={autoPlay ? handleTimerStop : null}
                arrowSize={arrowSize}
              />
            )}

            <div ref={sliderRef} className="keen-slider" role="region">
              {slidesWithKeenSliderSlideClassName}
            </div>

            {showArrows && (
              <S.Arrow
                className={`arrow ${arrowsShownOnHover ? 'initially-hidden' : ''} ${absoluteArrows ? 'abs right' : ''}`}
                currentSlide={currentSlide}
                sliderItemsLength={sliderItemsLength}
                breakpointsSettings={breakpointsSettings}
                dir="right"
                onClick={handleNextArrowClick}
                onMouseOver={autoPlay ? handleTimerStart : null}
                onFocus={autoPlay ? handleTimerStart : null}
                onMouseLeave={autoPlay ? handleTimerStop : null}
                onBlur={autoPlay ? handleTimerStop : null}
                arrowSize={arrowSize}
              />
            )}
          </div>

          {showDots && slider && (
            <ol className="dots" role="tablist">
              {[...Array(sliderItemsLength)].map((_, dotIdx) => {
                const dotKey = `dot_${dotIdx}`;
                const isDotActive = currentSlide === dotIdx;
                const dotClassName = `dot ${isDotActive ? ' active' : ''}`;

                return (
                  <button
                    type="button"
                    role="tab"
                    aria-selected={isDotActive}
                    key={dotKey}
                    className={dotClassName}
                    onClick={() => goToSlide(dotIdx)}
                    onMouseOver={handleTimerStart}
                    onFocus={handleTimerStart}
                    onMouseLeave={handleTimerStop}
                    onBlur={handleTimerStop}
                  >
                    {/* TODO: Slide name should be entered here */}
                    {/* Also look at: https://jongund.github.io/aria-examples/bootstrap-carousel/carousel-3.html */}
                    <span role="none">&nbsp;</span>
                  </button>
                );
              })}
            </ol>
          )}
        </Wrapper>
      </S.Slider>
    );
  },
);

Slider.displayName = 'Slider';

Slider.defaultProps = defaultProps;
