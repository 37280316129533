export const addPrefixToCategoriesSlug = (categories = [], prefix) => {
  return categories.map((category) => {
    let childrenWithNewSlug = [];
    if (category.children) {
      childrenWithNewSlug = category.children.map((categoryChildren) => ({
        ...categoryChildren,
        slug: `${prefix}${categoryChildren.slug}`,
      }));
    }
    return {
      ...category,
      slug: `${prefix}${category.slug}`,
      children: childrenWithNewSlug,
    };
  });
};
