import { useRef, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';

import { defaultLanguage, localeCookieName } from 'config/locales';
import { SSR_BASE_URL } from 'config/ssr';

import { removeQueryParamsAndHashFromUrl } from 'src/utils/removeQueryParamsAndHashFromUrl';
import { decodeSlugToLanguageCodeAndSlug } from 'src/utils/decodeSlugToLanguageCodeAndSlug';
import { addPrefixToCategoriesSlug } from 'src/utils/addPrefixToCategoriesSlug';
import { createHrefLangItems } from 'src/utils/createHrefLangItems';

import { breakpointsKeys } from 'src/enums/breakpoints';

import GET_CLIPBOARD_INFO from 'src/graphql/queries/getClipboardInfo.gql';

// eslint-disable-next-line max-len
import { useEffectWysiwygLinkTransformToSpa } from 'src/hooks/useEffectWysiwygLinkTransformToSpa/useEffectWysiwygLinkTransformToSpa';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import useFooterEntries from 'src/hooks/useFooterEntries';
import useClipboardInfo from 'src/hooks/useClipboardInfo';
import useLazyShopQuery from 'src/hooks/useLazyShopQuery';

import useSearchString from 'src/hooks/useSearchString';
import useTranslation from 'src/hooks/useTranslation';
import useLocalState from 'src/hooks/useLocalState';
import useLanguage from 'src/hooks/useLanguage';

import StyledModalBackground from 'src/components/StyledModalBackground';
import BrowserHistoryButton from 'src/components/BrowserHistoryButton';
import ModalUserInformation from 'src/components/ModalUserInformation';
import ContactFormViaLink from 'src/components/ContactFormViaLink';
import HtmlBottomFooter from 'src/components/HtmlBottomFooter';
import FooterNavigation from 'src/components/FooterNavigation';
import ProducersList from 'src/components/ProducersList';
import ScrollToTop from 'src/components/ScrollToTop';
import BottomBar from 'src/components/BottomBar';

import CurrentPageRoutingInfo from 'src/providers/currentPageRoutingInfo';
import { AccessContentProvider } from 'src/providers/accessContent';
import { AuthenticationProvider } from 'src/providers/authentication';
import { defaultTheme } from 'src/styles/themes';

import { getFooterSliderEntries } from './mainLayoutHelpers';
import Navbar from 'src/components/Navbar';

const defaultProps = {
  title: null,
  metaTags: [],
  user: null,
  basket: null,
  locale: defaultLanguage,
  asPath: null,
  coreRoutesInfo: {},
  balancePanelDetails: {},
  topMenuNavigation: [],
  bottomLeftNavigation: [],
  rightBottomNavigation: [],
  hrefLangData: undefined,
  currentPageRoutingInfo: null,
  search: null,
};

export const MainLayout = ({
  children,
  user,
  basket,
  locale,
  title,
  metaTags,
  asPath,
  coreRoutesInfo: originalCoreRoutesInfo,
  balancePanelDetails,
  categories,
  topMenuNavigation,
  bottomLeftNavigation,
  rightBottomNavigation,
  searchMenuPagesData,
  hrefLangData,
  currentPageRoutingInfo,
  search,
} = defaultProps) => {
  const markerRef = useRef(null);
  const router = useRouter();
  const [language] = useLanguage();
  const [localState] = useLocalState();
  const isBasketPage =
    router.asPath === localState?.routingMap?.coreRoutesInfo?.basketPage?.pattern[language] ||
    router.asPath === `${localState?.routingMap?.coreRoutesInfo?.basketPage?.pattern[language]}?showonetimeform=true`;

  const isProductPage =
    router.asPath.split('/')[1] === localState?.routingMap?.coreRoutesInfo?.productPage?.name[language];

  const getCoreRoutesInfoToCache = () => {
    if (Object.keys(originalCoreRoutesInfo).length > 0) {
      return originalCoreRoutesInfo;
    }
    if (Object.keys(localState.routingMap.coreRoutesInfo).length > 0) {
      return localState.routingMap.coreRoutesInfo;
    }
  };
  const { current: coreRoutesInfo } = useRef(getCoreRoutesInfoToCache());

  const currentBreakpoint = useBreakpointDetector();

  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;

  const displayShortNavOnLoginOrRegisterPage =
    router.asPath === localState?.routingMap?.coreRoutesInfo?.loginInBasketPage?.pattern[language];

  const accessList = (user?.accessList || []).map((accessElement) => accessElement?.name);

  const userPopupsData = user?.popups;
  const isPopupsToShow = userPopupsData?.length > 0;

  const { t } = useTranslation();
  const searchValue = useSearchString(asPath);

  const footerData = useFooterEntries(user);
  const footerDataReady = footerData !== null;

  const categoryPageSlug = coreRoutesInfo.productsPage.name[locale];
  const categoriesWithTransformSlug = addPrefixToCategoriesSlug(categories, categoryPageSlug);

  useEffectWysiwygLinkTransformToSpa();

  const [clipboardInfo, setClipboardInfo] = useClipboardInfo();

  const [getClipboardInfo] = useLazyShopQuery(GET_CLIPBOARD_INFO, {
    onCompleted: ({ clipboardInfo }) => {
      const { linescount, message, name, id, selected, number } = clipboardInfo;
      setClipboardInfo({
        linesCount: linescount,
        message,
        name,
        id,
        selected,
        number,
      });
    },
  });

  const { formattedStringToQueryProducersLogo, shouldBeDisplayedInMonochrome } = getFooterSliderEntries(
    footerData?.columns,
  );

  const partUrl = router.asPath;
  let hrefLang = [];

  const doesHrefDataFromApiExists = hrefLangData && hrefLangData.length > 0;
  const isBrowser = typeof window !== 'undefined';
  const origin = isBrowser ? window.location?.origin : SSR_BASE_URL;

  if (doesHrefDataFromApiExists) {
    hrefLang = hrefLangData;
  } else if (partUrl && localState) {
    const slugsMap = new Map(localState.routingMap.slugsMap);
    const routesMap = new Map(localState.routingMap.routesMap);
    const { decodeSlug, languageCode } = decodeSlugToLanguageCodeAndSlug(partUrl);

    const cleanDecodedSlug = removeQueryParamsAndHashFromUrl(decodeSlug);
    const cleanDecodeSlugOtherLanguagesWihoutSlash =
      cleanDecodedSlug === '/' ? languageCode : `${languageCode}${cleanDecodedSlug}`;

    const slugForPageId =
      languageCode === defaultLanguage
        ? cleanDecodedSlug.replace(/^\//, '')
        : `${cleanDecodeSlugOtherLanguagesWihoutSlash}`;
    const currentPageId = slugsMap.get(slugForPageId);

    if (currentPageId) {
      const currentPageRoutes = routesMap.get(currentPageId);

      if (currentPageRoutes) {
        const canonicalHref = `${origin}/${slugForPageId}`;
        const hrefLangItems = createHrefLangItems(currentPageRoutes.name);

        hrefLang = [{ rel: 'canonical', href: canonicalHref }, ...hrefLangItems];
      }
    } else {
      function getRouteNamesByRecursion(routes) {
        if (routes?.parentId) {
          const currentPageIdForFragmentedSlug = routesMap.get(routes.parentId);
          return [routes?.name, getRouteNamesByRecursion(currentPageIdForFragmentedSlug)];
        }
        return routes?.name;
      }

      const fragmentedSlugForPageId = slugForPageId.split('/');
      const languageFragmentedSlugId =
        locale === defaultLanguage ? fragmentedSlugForPageId[0] : fragmentedSlugForPageId[1];

      if (languageFragmentedSlugId) {
        const isDefaultLanguage = locale === defaultLanguage;
        const currentPageIdForFragmentedSlug = slugsMap.get(
          isDefaultLanguage ? languageFragmentedSlugId : locale + '/' + languageFragmentedSlugId,
        );
        const currentPageRoutesForFragmentedSlug = routesMap.get(currentPageIdForFragmentedSlug);

        const routeNames = getRouteNamesByRecursion(currentPageRoutesForFragmentedSlug);

        if (routeNames) {
          const preparedNames =
            Object.keys(routeNames).map((key) => {
              let value = '';
              Object.entries(routeNames).forEach((entry) => {
                if (entry[0] === key) {
                  const slugValueMainLanguage =
                    locale === defaultLanguage && !!fragmentedSlugForPageId[1] ? '/' + fragmentedSlugForPageId[1] : '';
                  const slugValueSideLanguage =
                    locale !== defaultLanguage && !!fragmentedSlugForPageId[2] ? '/' + fragmentedSlugForPageId[2] : '';
                  value = entry[1] + `${locale === defaultLanguage ? slugValueMainLanguage : slugValueSideLanguage}`;
                }
              });

              return { [key]: value };
            }) || [];
          const test = preparedNames && Object.assign(...preparedNames);

          const hrefLangItems = createHrefLangItems(test);

          hrefLang = [{ rel: 'canonical', href: `${origin}/${preparedNames[0][locale]}` }, ...hrefLangItems];
        }
      }
    }
  }

  useEffect(() => {
    if (!clipboardInfo) {
      getClipboardInfo();
    }
  }, [clipboardInfo, getClipboardInfo]);

  useEffect(() => {
    function handleLanguageCookie() {
      const languageCookieNameWithEqualSign = `${localeCookieName}=`;
      const languageCookieString = document.cookie
        .split('; ')
        .find((cookie) => cookie.startsWith(languageCookieNameWithEqualSign));

      const languageFromCookie =
        !!languageCookieString && languageCookieString.replace(languageCookieNameWithEqualSign, '');
      const isLanguageCorrect = languageFromCookie === language;

      if (!isLanguageCorrect) {
        document.cookie = `${languageCookieNameWithEqualSign}${language}; path=/`;
      }
    }

    if (isBrowser) {
      window.addEventListener('focus', handleLanguageCookie);
    }

    return () => {
      if (isBrowser) {
        window.removeEventListener('focus', handleLanguageCookie);
      }
    };
  }, [isBrowser, language]);

  const { icon, icontooltip } = search ? search : { icon: null, tooltip: null };

  return (
    <ThemeProvider theme={defaultTheme}>
      {isMobile && !isBasketPage && !isProductPage ? <BrowserHistoryButton key="BrowserHistoryButton" /> : null}

      <AccessContentProvider value={{ accessContentInfo: accessList }}>
        {isBasketPage || isProductPage ? null : <ScrollToTop key="ScrollToTopButton" />}
        <ModalProvider backgroundComponent={StyledModalBackground}>
          <AuthenticationProvider>
            <Head>
              {!!title && <title>{title}</title>}
              {metaTags.map(({ tag: Tag = 'meta', attributes = {}, content }, metaIdx) => {
                const key = `head_${metaIdx}`;

                if (content) {
                  return (
                    <Tag {...attributes} key={key}>
                      {content}
                    </Tag>
                  );
                }

                return <Tag {...attributes} key={key} />;
              })}
              {hrefLang.map(({ rel, lang, href }, index) => {
                if (rel === 'canonical') {
                  return <link key={`${index}_${href}`} rel={rel} href={href} />;
                }
                return <link key={`${index}_${href}`} rel={rel} hrefLang={lang} href={href} />;
              })}
            </Head>

            <CurrentPageRoutingInfo value={currentPageRoutingInfo || null}>
              <Navbar
                siteName={t('appName')}
                innerClassName="wrapper"
                user={user}
                basket={basket}
                locale={locale}
                balancePanelDetails={balancePanelDetails}
                searchValue={searchValue}
                coreRoutesInfo={coreRoutesInfo}
                categories={categoriesWithTransformSlug}
                topMenuNavigation={topMenuNavigation}
                bottomLeftNavigation={bottomLeftNavigation}
                rightBottomNavigation={rightBottomNavigation}
                searchMenuPagesData={searchMenuPagesData}
                markerRef={markerRef}
                icon={icon}
                icontooltip={icontooltip}
                isBasketPage={isBasketPage || displayShortNavOnLoginOrRegisterPage}
              />
            </CurrentPageRoutingInfo>
            <div ref={markerRef} style={{ visibility: 'hidden' }} />
            <ContactFormViaLink isLoggedInUser={!!user}>
              {isPopupsToShow && <ModalUserInformation popupsData={userPopupsData} user={user} />}
              <main className={`main wrapper ${isBasketPage ? 'basket-main' : ''}`} role="main">
                {children}
              </main>

              <footer className="no-print" role="contentinfo">
                {footerDataReady ? (
                  <>
                    {isBasketPage || (isProductPage && isMobile) ? null : (
                      <>
                        <FooterNavigation columns={footerData.columns} user={user} innerClassName="wrapper" />
                        <ProducersList
                          className="wrapper"
                          logoSize={90}
                          greyscaleEffect={shouldBeDisplayedInMonochrome}
                          autoPlay
                          inverse={false}
                          inverseLoadingCover={false}
                          infinite
                          producers={formattedStringToQueryProducersLogo}
                          brandPageSlug={coreRoutesInfo.brandPage.name[locale]}
                          brandPageLayout={coreRoutesInfo.brandPage.layout}
                        />

                        {footerData.displayBottomBar ? (
                          <BottomBar
                            innerClassName="wrapper"
                            copyrightsText={footerData.copyrightsText}
                            socialMedia={footerData.socialMedia}
                            homePageSlug={coreRoutesInfo.homePage.name[locale]}
                            homePageLayout={coreRoutesInfo.homePage.layout}
                          />
                        ) : null}
                        <HtmlBottomFooter text={footerData.htmlBottomFooter} />
                      </>
                    )}
                    {isBasketPage && !isMobile ? <HtmlBottomFooter text={footerData.htmlBasketBottomFooter} /> : null}

                    {/* <CookiesConsentModal locale={locale} /> */}
                  </>
                ) : null}
              </footer>
            </ContactFormViaLink>
          </AuthenticationProvider>
        </ModalProvider>
      </AccessContentProvider>
    </ThemeProvider>
  );
};

MainLayout.defaultProps = defaultProps;
