import styled, { css } from 'styled-components';
import { em } from 'polished';

const calcEmFromNumber = (value) => {
  if (typeof value === 'string') {
    return value;
  }

  return em(value);
};

const setMaxHeight = ({ dimensions }) =>
  !dimensions
    ? null
    : css`
        max-height: ${calcEmFromNumber(dimensions.maxHeight)};
      `;

export const Image = styled.img`
  object-fit: contain;
`;

export const ImageWrapper = styled.figure`
  position: relative;

  ${(p) =>
    p.customDimensions &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0em auto;
      width: 100%;
    `};
  
  ${(p) => setMaxHeight(p)}

  ${Image} {
    ${(p) => setMaxHeight(p)}

    ${({ dimensions }) =>
      dimensions?.maxWidth &&
      css`
        max-width: ${calcEmFromNumber(dimensions?.maxWidth)};
      `}
    
    ${({ dimensions }) =>
      dimensions?.width &&
      css`
        width: ${calcEmFromNumber(dimensions?.width)};
      `}

    ${({ dimensions }) =>
      dimensions?.height &&
      css`
        height: ${calcEmFromNumber(dimensions?.height)};
      `}
  }
`;
