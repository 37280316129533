import { useEffect } from 'react';
import useTranslation from 'src/hooks/useTranslation';
import useRESTMutation from 'src/hooks/useRESTMutation';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm } from 'react-hook-form';
import SEND_MESSAGE from 'src/graphql/mutations/sendMessage.gql';
import SEND_MESSAGE_PUBLIC from 'src/graphql/mutations/sendMessagePublic.gql';
import { vestResolver } from '@hookform/resolvers/vest/dist/vest.umd';
import {
  sendProductAvailabilityQuestionGuestSchema,
  sendProductAvailabilityQuestionUserSchema,
  sendProductAvailabilityQuestionSchemaVariables,
} from 'src/schemas/sendProductAvailabilityQuestion';
import Heading from '../Heading';
import Input from '../Input';
import Checkbox from '../Checkbox';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import * as S from './styles';

const validationResolverGuest = vestResolver(sendProductAvailabilityQuestionGuestSchema);
const validationResolverUser = vestResolver(sendProductAvailabilityQuestionUserSchema);

const defaultProps = {
  displayTitle: null,
  sourceId: null,
  limitWidth: false,
  isUser: false,
  emailTarget: null,
  submitButtonLabel: '',
  messagePlaceholder: '',
  message: '',
};

export const FormContact = ({
  limitWidth,
  isUser,
  source,
  subject,
  displayTitle,
  headingTxt,
  sourceId,
  emailTarget,
  submitButtonLabel,
  messagePlaceholder,
  message,
} = defaultProps) => {
  const { t } = useTranslation();
  const { t: tErr } = useTranslation('errors');
  const { t: tTip } = useTranslation('tooltips');
  const [sendMessage, { data, loading, error: sendError }] = useRESTMutation(
    isUser ? SEND_MESSAGE : SEND_MESSAGE_PUBLIC,
  );

  const reactHookFormConfig = {
    mode: 'onSubmit',
    resolver: isUser ? validationResolverUser : validationResolverGuest,
    defaultValues: {
      isCompany: '0',
      availabilityQuestion: message,
    },
  };
  const { register, handleSubmit, errors: inputErrors, clearErrors, reset, watch, control } = useForm(
    reactHookFormConfig,
  );
  const { executeRecaptcha } = useGoogleReCaptcha();
  const shouldShowRespons = !!data;
  const isFormHasErrorsOrSucceeded = !!Object.keys(inputErrors).length || shouldShowRespons;
  const responseData = isUser ? data?.sendMessage : data?.sendMessagePublic;
  const isCompany = watch('isCompany');

  const shouldShowNip = isCompany === '1';

  useEffect(() => {
    if (!isCompany) {
      clearErrors('taxcode');
    }
  }, [clearErrors, isCompany]);

  const onFormSubmit = async (input) => {
    let payload = {};

    if (!isUser) {
      const reCaptchaResults = await executeRecaptcha('login_page');

      payload = {
        fromname: input.fullName,
        fromemail: input.email,
        fromphone: input.phone,
        recaptchatoken: reCaptchaResults,
        iscompany: input.isCompany,
        taxcode: input.taxcode,
      };
    }

    if (sourceId) {
      payload.sourceid = sourceId;
    }

    if (emailTarget) {
      payload.target = emailTarget;
    }

    if (loading || isFormHasErrorsOrSucceeded) return;

    sendMessage({
      variables: {
        input: {
          ...payload,
          message: input.availabilityQuestion,
          // TODO: Fix source after API makes this possible
          // source: document.location.pathname,
          subject,
          referrer: document.location.href,
          source,
        },
      },
    });
  };

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <S.FormContact limitWidth={limitWidth}>
      <S.CenteredTextSection>
        {!shouldShowRespons ? (
          <Heading variant="octonary" fontSizeIndex={2}>
            {headingTxt}:
          </Heading>
        ) : null}

        {shouldShowRespons ? (
          <Heading variant="tertiary" fontSizeIndex={1}>
            {t('inquirySent')}
          </Heading>
        ) : null}
      </S.CenteredTextSection>

      {!shouldShowRespons && !!displayTitle ? <S.FormTitle>{displayTitle}</S.FormTitle> : null}

      <S.Form>
        {!shouldShowRespons && (
          <>
            <S.FormContainer>
              <S.Form.Row>
                <S.Textarea
                  addBottomMargin={!isUser}
                  autoComplete="nope"
                  block
                  disabled={loading}
                  error={tErr(
                    inputErrors.availabilityQuestion?.message,
                    sendProductAvailabilityQuestionSchemaVariables,
                  )}
                  id="product_availability_question"
                  label={!messagePlaceholder ? t('questionContent') : messagePlaceholder}
                  name="availabilityQuestion"
                  placeholder={!messagePlaceholder ? t('questionContent') : messagePlaceholder}
                  ref={register}
                  required
                  rows={4}
                  shouldLabelBeAlwaysVisible={!!watch('availabilityQuestion').length}
                  tooltip={tTip('productAvailabilityQuestion')}
                />
              </S.Form.Row>
              {!isUser ? (
                <S.Form.Row>
                  <Input
                    autoComplete="nope"
                    block
                    disabled={loading}
                    error={tErr(inputErrors.fullName?.message)}
                    id="product_availability_full_name"
                    label={t('fullName')}
                    name="fullName"
                    placeholder={t('fullName')}
                    ref={register}
                    required
                    type="text"
                    tooltip={tTip('productAvailabilityName')}
                  />
                </S.Form.Row>
              ) : null}
              {!isUser ? (
                <S.Form.Row>
                  <Controller
                    control={control}
                    name="email"
                    render={({ onChange, name, value: inputValue }) => (
                      <Input
                        ref={register}
                        type="email"
                        id="email"
                        name={name}
                        label={t('emailAddress')}
                        placeholder={t('emailAddress')}
                        block
                        mask={/^[^ ]+$/}
                        autoComplete="nope"
                        required
                        disabled={loading}
                        error={tErr(inputErrors.email?.message)}
                        value={inputValue}
                        onAccept={(value) => {
                          onChange(value);
                        }}
                        tooltip={tTip('productAvailabilityEmail')}
                      />
                    )}
                  />
                </S.Form.Row>
              ) : null}
              {!isUser ? (
                <S.Form.Row>
                  <Controller
                    control={control}
                    name="phone"
                    render={({ onChange, name, value: inputValue }) => (
                      <Input
                        ref={register}
                        type="number"
                        id="phone"
                        name={name}
                        label={t('phone')}
                        placeholder={t('phone')}
                        block
                        mask={/^[0-9 \\+]+$/}
                        autoComplete="nope"
                        disabled={loading}
                        error={tErr(inputErrors.phone?.message)}
                        value={inputValue}
                        onAccept={(value) => {
                          onChange(value);
                        }}
                        tooltip={tTip('productAvailabilityPhone')}
                      />
                    )}
                  />
                </S.Form.Row>
              ) : null}
              {!isUser ? (
                <>
                  <S.Form.Row>
                    <S.CompanyPurchaseQuestionNote>{t('isCompanyQuestion')}</S.CompanyPurchaseQuestionNote>

                    <S.CompanyPurchaseRadioWrapper>
                      <Checkbox
                        ref={register}
                        variant="secondary"
                        id="is_company_yes"
                        label={t('yes')}
                        value="1"
                        name="isCompany"
                        radio
                      />
                      <Checkbox
                        ref={register}
                        variant="secondary"
                        id="is_company_no"
                        label={t('no')}
                        name="isCompany"
                        value="0"
                        radio
                      />
                    </S.CompanyPurchaseRadioWrapper>
                  </S.Form.Row>

                  {shouldShowNip && (
                    <S.Form.Row>
                      <Controller
                        control={control}
                        name="taxcode"
                        render={({ onChange, name, value: inputValue }) => (
                          <Input
                            ref={register}
                            type="text"
                            id="product_availability_tax_code"
                            name={name}
                            label={t('nip')}
                            placeholder={t('nip')}
                            block
                            mask={/^[0-9 \\+]+$/}
                            autoComplete="nope"
                            required
                            disabled={loading}
                            error={tErr(inputErrors.taxcode?.message)}
                            value={inputValue}
                            onAccept={(value) => {
                              onChange(value.replace(/\s/g, ''));
                            }}
                            tooltip={tTip('nipOnQuestionForm')}
                          />
                        )}
                      />
                    </S.Form.Row>
                  )}
                </>
              ) : null}
            </S.FormContainer>
            <S.CenteredTextSection>
              <Button
                onClick={handleSubmit(onFormSubmit)}
                variant="primary"
                fontSizeIndex={2}
                inverseBusyIcon
                busy={loading}
                busyChildren={t('loading')}
                disabled={isFormHasErrorsOrSucceeded}
              >
                {!submitButtonLabel ? t('sendMessage') : submitButtonLabel}
              </Button>
            </S.CenteredTextSection>
          </>
        )}

        {responseData?.message ? (
          <S.CenteredTextSection>
            <S.MarginTopSection>
              <Text variant="secondary" fontSizeIndex={1}>
                {responseData.message}
              </Text>
            </S.MarginTopSection>
          </S.CenteredTextSection>
        ) : null}

        {sendError?.networkError?.result?.message ? (
          <S.CenteredTextSection>
            <S.MarginTopSection>
              <Text variant="septenary" fontSizeIndex={1}>
                {sendError.networkError.result.message}
              </Text>
            </S.MarginTopSection>
          </S.CenteredTextSection>
        ) : null}

        <br />

        <S.IconFooter>
          <Icon icon="lock" className="icon" /> {t('yourDataIsSafe')}
        </S.IconFooter>
      </S.Form>
    </S.FormContact>
  );
};

FormContact.defaultProps = defaultProps;
