import { authenticationSteps } from 'src/enums/authenticationSteps';

export const actions = {
  GET_TOKEN: 'get-token',
  SEND_TOKEN: 'send-token',
  SUCCESSFUL_AUTHORIZATION: 'successful',
};

export const initialState = {
  actualStep: authenticationSteps.INITIAL_STEP,
  authUid: '',
  btnTxt: '',
  modalTitle: '',
  inputPlaceholder: '',
  message: '',
  authMessage: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.GET_TOKEN:
      const { mfaResponse } = action.payload;
      return {
        ...state,
        actualStep: mfaResponse.step,
        authUid: mfaResponse.uid,
        btnTxt: mfaResponse.btntxt,
        modalTitle: mfaResponse.title,
        message: mfaResponse.desc,
        inputPlaceholder: '',
        authMessage: '',
      };
    case actions.SEND_TOKEN:
      const { sendMfaUid } = action.payload;
      return {
        ...state,
        actualStep: sendMfaUid.step,
        btnTxt: sendMfaUid.btntxt,
        modalTitle: sendMfaUid.title,
        message: sendMfaUid.message,
        authMessage: sendMfaUid.desc,
        inputPlaceholder: sendMfaUid.inputtxt,
      };
    case actions.SUCCESSFUL_AUTHORIZATION:
      const { sendMfaToken } = action.payload;

      return {
        ...state,
        actualStep: sendMfaToken.step,
        btnTxt: sendMfaToken.btntxt,
        modalTitle: sendMfaToken.title,
        message: sendMfaToken.message,
        authMessage: '',
      };
  }
};

export const setInitialValues = () => {
  return {
    state: initialState,
  };
};
