import GET_LOCAL_STATE from 'src/graphql/queries/getLocalState.gql';

export const getCachedRoutingMap = async ({ apolloClient }) => {
  const { localState } = await apolloClient.readQuery({ query: GET_LOCAL_STATE });

  const routingMap = {
    coreRoutesInfo: localState?.routingMap.coreRoutesInfo,
    routesMap: new Map(localState?.routingMap.routesMap),
    slugsMap: new Map(localState?.routingMap.slugsMap),
    slugsMapByLocale: { pl: new Map(localState?.routingMap.slugsMapByLocale.pl) },
  };

  return routingMap;
};
