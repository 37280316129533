import { useEffect, useState } from 'react';
export const useCheckIsMobileDevice = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isMobile = window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/);
      const isIPad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;

      setIsMobileDevice(!!isMobile || !!isIPad);
    }
  }, []);
  return isMobileDevice;
};
