import styled from 'styled-components';
import { em } from 'polished';
import { up } from 'styled-breakpoints';
import FormComponent from '../Form';
import TextareaComponent from '../Textarea';

export const FormContact = styled.div`
  max-width: ${(p) => p.limitWidth && em(p.theme.form.maxWidth.small)};
  width: ${(p) => p.limitWidth && '75vw'};
  background: none;
  padding: 1em 0;

  ${up('tablet')} {
    padding: 1em;
    min-width: 600px;
  }
`;

export const CenteredTextSection = styled.div`
  text-align: center;
`;

export const FormTitle = styled.div`
  text-align: center;
  margin-bottom: 1.5em;
`;

export const Form = styled(FormComponent)``;

export const FormContainer = styled.div`
  margin: 0 0.5em 0 0.5em;
`;

export const Textarea = styled(TextareaComponent)`
  margin-bottom: ${(p) => (p.addBottomMargin ? '3em' : '1.5em')};
`;

export const CompanyPurchaseQuestionNote = styled.p`
  margin-bottom: 1em;
`;

export const CompanyPurchaseRadioWrapper = styled.div`
  & > * + * {
    margin-left: 2em;
  }
`;

export const IconFooter = styled.footer`
  margin: 1em 0 0;
  text-align: center;
  white-space: nowrap;
  display: flex;
  justify-content: column;
  align-items: center;
  justify-content: center;

  .icon {
    color: ${(p) => p.theme.form.icon.color};
    font-size: 1.25em;
    margin-right: 0.5em;
  }
`;

export const MarginTopSection = styled.div`
  margin-top: 1em;
`;
