import { forwardRef } from 'react';
import { FormRow } from './FormRow';
import * as S from './styles';

const defaultProps = {
  onSubmit: null,
};

export const Form = forwardRef(({ children, onSubmit, ...restProps } = defaultProps, ref) => (
  <S.Form noValidate onSubmit={onSubmit} ref={ref} {...restProps}>
    {children}
    {!!onSubmit && <S.FormHideSubmit />}
  </S.Form>
));

Form.displayName = 'Form';

Form.Row = FormRow;

Form.defaultProps = defaultProps;
