import { em } from 'polished';
import styled from 'styled-components';
import Heading from '../Heading';
import Modal from '../Modal';
import ImportedInput from '../Input';
import ImportedButton from '../Button';

export const AuthenticationModal = styled(Modal)`
  text-align: center;
  z-index: 999999;
`;

export const Text = styled.p`
  margin: ${em(15)} 0;
`;

export const Title = styled(Heading)`
  margin-bottom: ${em(20)};

  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.typography.sizesSet.r10)};
`;

export const ErrorMessage = styled.div`
  margin-top: ${em(5)};

  color: ${(p) => p.theme.defaultPalette.alizarinCrimson[0]};
`;

export const SendTokenForm = styled.form`
  display: flex;
  justify-content: center;
  gap: ${em(12)};
  margin-bottom: ${em(5)};
`;

export const Input = styled(ImportedInput)`
  margin-bottom: 0;
`;

export const Button = styled(ImportedButton)`
  width: ${em(200)};
`;

export const ButtonGetToken = styled(ImportedButton)`
  width: ${em(170)};
`;
