import Head from 'next/head';
import styled, { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import BottomBar from 'src/components/BottomBar';
import useDatoCMSQuery from 'src/hooks/useDatoCMSQuery';
import GET_FOOTER_DATA_BY_PERMISSION from 'src/graphql/queries/getFooterDataByPermission.gql';
import { defaultLanguage } from 'config/locales';
import { defaultTheme } from 'src/styles/themes';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
// eslint-disable-next-line max-len
import { useEffectWysiwygLinkTransformToSpa } from 'src/hooks/useEffectWysiwygLinkTransformToSpa/useEffectWysiwygLinkTransformToSpa';
import { breakpointsKeys } from 'src/enums/breakpoints';

const defaultProps = {
  title: null,
  metaTags: [],
  user: null,
  basket: null,
  locale: defaultLanguage,
  asPath: null,
  coreRoutesInfo: {},
};

const StyledModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
`;

export const BlankLayout = ({ children, locale, title, metaTags, coreRoutesInfo } = defaultProps) => {
  const datoCMSQueryParams = {
    variables: {
      locale,
      shouldDisplayForNotLoggedIn: false,
      shouldBeDisplayedForB2b: false,
      shouldBeDisplayedForB2c: false,
    },
  };
  const footerData = useDatoCMSQuery(GET_FOOTER_DATA_BY_PERMISSION, datoCMSQueryParams);
  const footerDataReady = !footerData.loading && !footerData.error && footerData.data?.footerWithCustomisation;

  const currentBreakpoint = useBreakpointDetector();

  const isMobileView = currentBreakpoint === breakpointsKeys.MOBILE;

  useEffectWysiwygLinkTransformToSpa();

  return (
    <ThemeProvider theme={defaultTheme}>
      <ModalProvider backgroundComponent={StyledModalBackground}>
        <Head>
          {title && <title>{title}</title>}
          {metaTags.map(({ tag: Tag = 'meta', attributes = {}, content }, metaIdx) => {
            const key = `head_${metaIdx}`;

            if (content) {
              return (
                <Tag {...attributes} key={key}>
                  {content}
                </Tag>
              );
            }

            return <Tag {...attributes} key={key} />;
          })}
        </Head>

        <main className="main basket wrapper" role="main">
          {children}
        </main>

        {!isMobileView && (
          <footer role="contentinfo">
            {footerDataReady ? (
              <BottomBar
                innerClassName="wrapper"
                copyrightsText={footerData.data.footerWithCustomisation.copyrightsText}
                socialMedia={footerData.data.footerWithCustomisation.socialMedia}
                homePageSlug={coreRoutesInfo.homePage.name[locale]}
                homePageLayout={coreRoutesInfo.homePage.layout}
              />
            ) : null}
          </footer>
        )}
      </ModalProvider>
    </ThemeProvider>
  );
};

BlankLayout.defaultProps = defaultProps;
