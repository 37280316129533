import styled from 'styled-components';
import { em } from 'polished';

const getColorVariant = (theme, status) =>
  ({
    success: theme.defaultPalette.teal[0],
    error: theme.defaultPalette.alizarinCrimson[0],
  }[status]);

export const FormStatusMessage = styled.div`
  background: unset;

  color: ${({ theme, status }) => getColorVariant(theme, status)};
  border: solid ${em(1)} transparent;
  margin-bottom: ${(p) => (p.hideMargin ? '0' : '1.5em')};
  padding: ${(p) => (p.hidePadding ? 0 : '1em')};
  text-align: center;

  .icon {
    color: ${({ theme, status }) => getColorVariant(theme, status)};
    font-size: 1.25em;
    margin-right: 0.5em;
  }
`;
