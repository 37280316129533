import useTranslation from 'src/hooks/useTranslation';

import * as S from './styles';

export const LogOutButton = ({ onClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <S.LoggedInMenuItem>
      <S.AccountItemsButton blank onClick={onClick}>
        <S.ChangeCustomerInnerWrapper>
          <S.ChangeCustomerButtonLabel>{t('logout')}</S.ChangeCustomerButtonLabel>
        </S.ChangeCustomerInnerWrapper>
      </S.AccountItemsButton>
    </S.LoggedInMenuItem>
  );
};
