import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Icon from '../Icon';

import * as S from './styles';

const DISTANCE_FROM_TOP = 50;

const METHOD_GO_BACK = 'goBack';
const METHOD_GO_FORWARD = 'goForward';

const defaultProps = {
  method: METHOD_GO_BACK,
};

export const BrowserHistoryButton = ({ method, preventFromMovingLeft = false, className } = defaultProps) => {
  const { events: routeEvents } = useRouter();
  const [shouldMoveToLeft, setShouldMoveToLeft] = useState(false);

  const handleBrowserHistory = () => {
    if (method === METHOD_GO_BACK) {
      return history.back();
    }

    if (method === METHOD_GO_FORWARD) {
      return history.forward();
    }
    return console.debug('Wrong type of method in history button was passed');
  };

  useEffect(() => {
    if ((!document && typeof window === 'undefined') || preventFromMovingLeft) {
      return;
    }

    const scrollWatchToMoveLeft = () => {
      setShouldMoveToLeft(window.scrollY > DISTANCE_FROM_TOP);
    };

    document.addEventListener('scroll', scrollWatchToMoveLeft);
    routeEvents.on('routeChangeComplete', scrollWatchToMoveLeft);

    // eslint-disable-next-line
    return () => {
      routeEvents.on('routeChangeComplete', scrollWatchToMoveLeft);
      document.removeEventListener('scroll', scrollWatchToMoveLeft);
    };
    // eslint-disable-next-line
  }, []);

  if (typeof window === 'undefined' || window.history?.state?.idx === 0) {
    return null;
  }

  return (
    <S.BrowserHistoryButton
      id="browserHistoryButton"
      shouldMoveToLeft={shouldMoveToLeft}
      onClick={handleBrowserHistory}
      className={className}
    >
      <Icon icon={method === METHOD_GO_BACK ? 'arrowLeft' : 'arrowRight'} />
    </S.BrowserHistoryButton>
  );
};

BrowserHistoryButton.defaultProps = defaultProps;
