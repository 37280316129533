import { rem } from 'polished';
import styled from 'styled-components';

const dotWidthInPx = 17;

export const NumberForIcon = styled.div`
  position: relative;
  display: flex;
`;

export const DotWithNumber = styled.span`
  position: relative;
  width: ${rem(dotWidthInPx)};

  height: ${rem(dotWidthInPx)};
  ${({ hideNumber }) => hideNumber && 'visibility: hidden'};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.defaultPalette.chateauGreen[0]};
  display: flex;
  right: ${rem(dotWidthInPx / 2)};

  margin-right: -${rem(dotWidthInPx / 2)};

  justify-content: center;
  align-items: center;
`;

export const Number = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.xs3};
  color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
`;
