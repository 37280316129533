import { forwardRef, useState } from 'react';
import useLanguage from 'src/hooks/useLanguage';
import { addLangToLinksFromHtmlSetDangerously } from 'src/utils/addLangSignToHtmlLinksFromDato';
import * as S from './styles';

const defaultProps = {
  checked: null,
  defaultChecked: null,
  radio: false,
  variant: 'primary',
  required: false,
  disabled: false,
  fullLabel: null,
  hideLabel: false,
  error: null,
  block: false,
  className: '',
  style: {},
  includesHTMLTags: false,
  disabledInvisible: false,
  onContainerClick: () => {},
};

export const Checkbox = forwardRef(
  (
    {
      id,
      label,
      fullLabel,
      radio,
      defaultChecked,
      checked,
      variant,
      required,
      disabled,
      hideLabel,
      error,
      block,
      className,
      style,
      includesHTMLTags,
      disabledInvisible,
      onContainerClick,
      ...restProps
    } = defaultProps,
    ref,
  ) => {
    const [moreLabelShown, setMoreLabelShown] = useState(false);
    const errorId = `${id}_error`;
    const inputType = radio ? 'radio' : 'checkbox';
    const [language] = useLanguage();

    const checkboxContainerProps = {
      className,
      variant,
      block,
      style,
      onClick: onContainerClick,
      radio,
    };

    const checkboxBaseProps = {
      required,
      disabled,
      ref,
      id,
    };

    if (checked != null) {
      checkboxBaseProps.checked = checked;
    }

    if (defaultChecked != null) {
      checkboxBaseProps.defaultChecked = defaultChecked;
    }

    if (error) {
      checkboxContainerProps.className += ' is-invalid';
    }

    if (required) {
      checkboxContainerProps.className += ' is-required';
    }

    const renderLabel = includesHTMLTags ? (
      <span dangerouslySetInnerHTML={{ __html: addLangToLinksFromHtmlSetDangerously(label, language) }} />
    ) : (
      label
    );

    return (
      <S.CheckboxContainer disabledInvisible={disabledInvisible} {...checkboxContainerProps}>
        <S.LabelBox className="labelBox">
          <S.Label className="label">
            <S.Input type={inputType} {...checkboxBaseProps} {...restProps} />

            <span className="descriptionBox">
              <S.Description className="description" hideLabel={hideLabel}>
                {fullLabel && moreLabelShown ? fullLabel : renderLabel}
                {required ? <S.Required variant={variant}>*</S.Required> : null}
                {fullLabel && (
                  <button type="button" onClick={() => setMoreLabelShown((s) => !s)}>
                    {moreLabelShown ? 'Less' : 'More'}
                  </button>
                )}
              </S.Description>
            </span>

            {error ? (
              <S.ErrorBox className="errorBox" id={errorId} aria-live="assertive" aria-relevant="additions removals">
                <S.Error className="error">{error}</S.Error>
              </S.ErrorBox>
            ) : null}
          </S.Label>
        </S.LabelBox>
      </S.CheckboxContainer>
    );
  },
);

Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = defaultProps;
