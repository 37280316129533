import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { em, rem } from 'polished';
import Icon from '../Icon';
import Link from '../Link';
import TooltipPopper from '../TooltipPopper';
import { productStatusKeys } from 'src/enums/productStatus';

const createConditionLabelStyles = (condition) => {
  if (condition === productStatusKeys.BESPOKE) {
    return css`
      color: ${({ theme }) => theme.defaultPalette.monochrome[4]};
      font-family: ${({ theme }) => theme.global.fontFamily.secondary};
    `;
  }
  if (condition === productStatusKeys.OVS) {
    return css`
      color: ${({ theme }) => theme.defaultPalette.chateauGreen[0]};
      font-family: ${({ theme }) => theme.global.fontFamily.secondary};
    `;
  }
};

const productAvailabilityStyles = css`
  display: flex;
  text-align: center;
  align-items: baseline;

  grid-area: 3 / 1 / 4 / 5;

  ${up('tablet')} {
    width: 100%;
    align-items: unset;
    flex-direction: column;
  }
`;

export const Tooltip = styled(TooltipPopper)`
  display: inline-block;
`;

export const ProductAvailability = styled(Link)`
  ${(p) =>
    p.isNextLink
      ? css`
          ${up('tablet')} {
            width: 100%;
          }

          > .content {
            ${productAvailabilityStyles}
          }
        `
      : productAvailabilityStyles};
`;

export const ProductAvailabilityCondition = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  & .product-availability-label {
    ${({ displayMarginBottom }) => displayMarginBottom && 'margin-bottom: 15px;'};

    ${({ condition }) => createConditionLabelStyles(condition)}
  }

  ${up('tablet')} {
    font-size: 0.75em;
    margin-top: 0.25em;
    align-items: ${({ alignToEnd }) => (alignToEnd ? 'end' : 'start')};
    ${({ isNotificationBtnDisplayed }) => isNotificationBtnDisplayed && 'align-items: baseline'};
    justify-content: center;
  }
  ${({ isNotificationBtnDisplayed }) => isNotificationBtnDisplayed && 'align-items: baseline'};
  .product-availability-label {
    font-size: ${rem(14)};
    ${({ isNotificationBtnDisplayed }) => isNotificationBtnDisplayed && `margin-right: ${em(5)}`}
  }
  .product-availability-status {
    ${({ condition }) => {
      if (condition) {
        return css`
          &:before {
            margin-right: ${({ conditionLabel }) => (conditionLabel !== null ? '0.4em' : '0em')};
            content: ' ';
            display: inline-block;
            width: 0.7em;
            height: 0.7em;
            min-width: 0.7em;
            min-height: 0.7em;
            border-style: solid;
            border-radius: 50%;
            margin-top: 0.25em;
            border-width: 1px;

            ${up('tablet')} {
              margin-right: ${({ conditionLabel }) => (conditionLabel !== null ? '0.5em' : '0em')};
              width: 1em;
              height: 1em;
              margin-top: 0;
            }

            ${({ theme }) => {
              const variantColor = theme.productAvailability.conditionStyle[condition];
              if (!variantColor) {
                return css`
                  display: none;
                `;
              }
              return css`
                border-color: ${variantColor.borderColor || 'unset'};
                background-color: ${variantColor.bgColor || 'unset'};
              `;
            }}
          }
        `;
      }

      return '';
    }};
  }
`;

export const ProductAvailabilityVolume = styled.div`
  font-size: 1em;
  padding-right: ${({ localVolume }) => (localVolume ? '1em' : '0.7em')};
  line-height: 1.5em;

  ${up('tablet')} {
    padding-right: unset;
    line-height: inherit;
  }

  & mark {
    background-color: unset;
    color: ${({ theme }) => theme.productAvailability.localVolumeColor};
    margin: 0 0.3em;
  }
`;

export const ProductAvailabilityIcon = styled(Icon)`
  vertical-align: bottom;
  color: ${({ theme }) => theme.defaultPalette.chateauGreen[0]};
  font-size: 1.125rem;
  margin-right: 0.3em;
  ${up('tablet')} {
    margin-right: 0.5em;
  }
`;

export const ProductAvailabilityLabelOVS = styled.span`
  color: ${({ theme }) => theme.defaultPalette.monochrome[4]} !important;
  font-family: ${({ theme }) => theme.global.fontFamily.primary} !important;
`;
