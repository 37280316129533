import useTranslation from 'src/hooks/useTranslation';
import Link from '../Link';
import Icon from '../Icon';
import * as S from './styles';

const defaultProps = {
  socialMedia: [],
  innerClassName: '',
};

export const BottomBar = ({
  copyrightsText,
  socialMedia,
  innerClassName,
  homePageSlug,
  homePageLayout,
  ...restProps
} = defaultProps) => {
  const { t } = useTranslation();
  const footerLogoLink = t('logoFooter');

  return (
    <S.BottomBar {...restProps}>
      <S.BottomBarInner className={innerClassName}>
        <S.BottomBarLeft>
          <S.BottomBarLogoLink
            href={homePageLayout}
            blank
            isNextLink
            nextLinkProps={{
              as: `/${homePageSlug}`,
            }}
          >
            <img src={footerLogoLink} alt="Onninen" />
          </S.BottomBarLogoLink>

          {!copyrightsText ? null : <p role="contentinfo">{copyrightsText}</p>}
        </S.BottomBarLeft>

        <S.BottomBarRight>
          {socialMedia.map(({ iconName, url }) => (
            <Link key={iconName} href={url} target="_blank" rel="noopener noreferrer" variant="secondary">
              <span className="social-name" role="presentation">
                {iconName}
              </span>
              <Icon icon={iconName} aria-label={iconName} />
            </Link>
          ))}
        </S.BottomBarRight>
      </S.BottomBarInner>
    </S.BottomBar>
  );
};

BottomBar.defaultProps = defaultProps;
