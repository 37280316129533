import { SHOP_CLIENT_NAME } from 'src/graphql/constants';
import { useMutation } from '@apollo/client/react';
import useAuthenticationModal from '../useAuthenticationModal';

export const useRESTMutation = (query = '', queryOptions = {}) => {
  const { setIsAuthModalOpen } = useAuthenticationModal()

  if(typeof queryOptions.onError === 'function') {
    const oldErrorFunction = queryOptions.onError
    const updatedError = (error) => {
      oldErrorFunction(error);
      if(error?.networkError?.response?.status === 406) {
        setIsAuthModalOpen(true)
      }
    }

    queryOptions.onError = updatedError
  } else {
    const updatedError = (error) => {
      if(error?.networkError?.response?.status === 406) {
        setIsAuthModalOpen(true)
      }
    }

    queryOptions.onError = updatedError
  }

  const [method, payload] = useMutation(query, {
    context: { clientName: SHOP_CLIENT_NAME },
    onError: ({ networkError = null }) => {
      if (networkError) {
        if(networkError?.response?.status === 406) {
          setIsAuthModalOpen(true)
        }
        console.debug(
          // eslint-disable-next-line max-len
          `useRESTMutation got expected error from network! [${networkError?.response?.status} ${networkError?.response?.statusText}] ${networkError?.message}.`,
        );
      }
    },
    ...queryOptions,
  });

  const enhancedPayload = {
    ...payload,
  };

  return [method, enhancedPayload];
};
