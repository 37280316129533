import { availableLocales } from 'config/locales';

import useEnvironmentContext from 'src/hooks/useEnvironmentContext';

import SelectWithSearch from 'src/components/SelectWithSearch';
import useTranslation from 'src/hooks/useTranslation';
import Skeleton from 'src/components/Skeleton';

import { ACTIONS } from './localeOptionsReducer';

import * as S from './styles';

const skeletonsQuantity = 3;

export const LocaleOptionsDialogContent = ({ dispatch = () => null, state, onClickSubmit = () => null }) => {
  const { environmentData, isEnvironmentLoading } = useEnvironmentContext();
  const { t } = useTranslation();

  const availableCurrencies = environmentData?.environment?.availableCurrences || [];
  const availableDelivery = environmentData?.environment?.availableDelivery || [];
  const selectedDeliveryItemIdx = availableDelivery.map(({ id }) => id).indexOf(state.deliveryCountry);
  const currentCurrencyIdx = availableCurrencies.map(({ name }) => name).indexOf(state.currency.name);
  const availableLocalesToSelect = environmentData?.environment?.availableLocales || availableLocales;
  const currentLocaleIdx = availableLocalesToSelect.map(({ locale }) => locale).indexOf(state.locale);
  const availablePriceTypes = environmentData?.environment?.availablePriceTypes || [];
  const selectedPriceTypeIdx = availablePriceTypes.map(({ type }) => type).indexOf(state.priceType);

  if (isEnvironmentLoading)
    return (
      <>
        {Array.from({ length: skeletonsQuantity }, (_, index) => {
          return <Skeleton height={45} key={index} />;
        })}
        <Skeleton height={35} width={100} />
      </>
    );

  return (
    <S.LocaleOptionsContentWrapper>
      <SelectWithSearch
        label={t('userSettingsDropdownDeliverySelectLabel')}
        searchInputPlaceholder={t('userSettingsDropdownSearchInputPlaceholder')}
        id="available-delivery"
        selectedItemId={selectedDeliveryItemIdx}
        handleSelectedItemChange={({ selectedItem }) =>
          dispatch({ type: ACTIONS.SET_DELIVERY_COUNTRY, payload: { deliveryCountryObj: selectedItem.value } })
        }
        items={
          availableDelivery?.map((country) => {
            return {
              label: country?.name || '',
              value: country,
              icon: <S.SelectCountryFlag code={country?.id} />,
            };
          }) || []
        }
      />
      <SelectWithSearch
        searchInputPlaceholder={t('userSettingsDropdownSearchInputPlaceholder')}
        label={t('userSettingsDropdownLocaleSelectLabel')}
        handleSelectedItemChange={({ selectedItem }) =>
          dispatch({ type: ACTIONS.SET_LOCALE, payload: { locale: selectedItem.value.locale } })
        }
        selectedItemId={currentLocaleIdx}
        id="available-locales"
        items={
          availableLocalesToSelect?.map((locale) => {
            return {
              label: locale?.name || '',
              value: locale,
              icon: <S.SelectLocaleFlag code={locale?.locale} />,
            };
          }) || []
        }
      />
      <SelectWithSearch
        searchInputPlaceholder={t('userSettingsDropdownSearchInputPlaceholder')}
        label={t('userSettingsDropdownCurrencySelectLabel')}
        handleSelectedItemChange={({ selectedItem }) =>
          dispatch({
            type: ACTIONS.SET_CURRENCY,
            payload: { currency: selectedItem.value },
          })
        }
        selectedItemId={currentCurrencyIdx}
        id="available-currencies"
        hideSearchInput
        items={
          availableCurrencies?.map((currency) => {
            return {
              label: `${currency?.name || ''} ${currency?.description || ''}`,
              value: currency,
            };
          }) || []
        }
      />
      <SelectWithSearch
        searchInputPlaceholder={t('userSettingsDropdownSearchInputPlaceholder')}
        label={t('userSettingsDropdownPriceTypeLabel')}
        handleSelectedItemChange={({ selectedItem }) =>
          dispatch({
            type: ACTIONS.SET_PRICE_TYPE,
            payload: { priceType: selectedItem.value },
          })
        }
        selectedItemId={selectedPriceTypeIdx}
        id="price-types"
        hideSearchInput
        items={
          availablePriceTypes?.map((price) => {
            return {
              label: price.name,
              value: price.type,
            };
          }) || []
        }
      />
      <S.SubmitSettingsButton onClick={onClickSubmit}>{t('userSettingsDropdownCTAButton')}</S.SubmitSettingsButton>
    </S.LocaleOptionsContentWrapper>
  );
};
