import { useCallback } from 'react';
import { useMutation } from '@apollo/client/react';
import GET_CACHED_LOCALE from 'src/graphql/queries/getCachedLocale.gql';
import SET_SERVER_LOCALE from 'src/graphql/mutations/setServerLocale.gql';
import useTranslation from '../useTranslation';
import { useRouter } from 'next/router';

const syncServerLocaleCookieMutationOptions = {
  update: (cache, { data }) =>
    cache.writeQuery({
      query: GET_CACHED_LOCALE,
      data: {
        language: data.setServerLocale.locale,
      },
    }),
};

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const router = useRouter();

  const [syncServerLocaleCookie] = useMutation(SET_SERVER_LOCALE, syncServerLocaleCookieMutationOptions);

  const changeLanguage = useCallback(
    async (locale) => {
      await syncServerLocaleCookie({ variables: { input: { locale } } });
      i18n.changeLanguage(locale);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n, router, syncServerLocaleCookie],
  );

  return [i18n.language, changeLanguage];
};
