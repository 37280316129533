import { useState } from 'react';

import { NotificationsContext } from './NotificationsContext';

export const NotificationsProvider = ({ children }) => {
  const [notificationsArray, setNotificationsArray] = useState([]);
  const [canFetchMore, setCanFetchMore] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  return (
    <NotificationsContext.Provider
      value={{
        setNotificationsArray,
        notificationsArray,
        setCanFetchMore,
        canFetchMore,
        showSkeleton,
        setShowSkeleton,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
