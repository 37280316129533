import { useRouter } from 'next/router';
import useRESTMutation from 'src/hooks/useRESTMutation';
import CHANGE_CUSTOMER from 'src/graphql/mutations/changeCustomer.gql';
import useProgressBar from 'src/hooks/useProgressBar';
import useTranslation from 'src/hooks/useTranslation';
import * as S from './styles';

const defaultProps = {
  afterItemClick: () => null,
};

export const UserCustomersList = ({ user, afterItemClick, ...restProps } = defaultProps) => {
  const router = useRouter();
  const progressBar = useProgressBar();
  const { t } = useTranslation();

  const { availableCustomers, currentCustomer } = user;

  let selectList = [];

  if (!availableCustomers && currentCustomer) {
    selectList = [
      {
        selected: true,
        id: currentCustomer.id,
        nameShort: currentCustomer.nameShort,
        idEx: currentCustomer.idEx,
      },
    ];
  } else {
    selectList = availableCustomers;
  }

  const [changeCustomer] = useRESTMutation(CHANGE_CUSTOMER);

  const handleChangeCustomer = async (id) => {
    progressBar.start();

    await changeCustomer({
      variables: {
        input: {
          customerId: id,
        },
      },
    });

    return router.reload();
  };

  return (
    <S.UserCustomersList {...restProps}>
      <S.Heading>{t('customerChoice')}</S.Heading>
      <S.SelectScrollBar>
        <ul>
          {selectList.map((customer) => (
            <S.UserCustomersListItem
              selected={customer.selected}
              onClick={() => {
                handleChangeCustomer(customer.id);
                afterItemClick();
              }}
              key={customer.id}
            >
              <S.IconWrapper>
                <S.Person />
              </S.IconWrapper>
              {customer.nameShort} ({customer.idEx})
            </S.UserCustomersListItem>
          ))}
        </ul>
      </S.SelectScrollBar>
    </S.UserCustomersList>
  );
};

UserCustomersList.defaultProps = defaultProps;
