export const prepareQuery = (queryStr) => {
  const unwiseRegExp = /[/{}|^[\]`]/;
  let preparedQuery = queryStr;
  const invalidCharsList = preparedQuery.match(unwiseRegExp) || [];

  invalidCharsList.forEach((incident) => {
    const incidentRegExp = new RegExp(incident, 'gmi');
    preparedQuery = preparedQuery.replace(incidentRegExp, `\\${incident}`);
  });

  return encodeURIComponent(preparedQuery);
};
