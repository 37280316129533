import { defaultClientType } from 'config/clientType';
import { defaultCurrencySign, defaultPriceType, defaultValue, defaultQuantity } from 'config/basket';
import { defaultBalance, defaultPaymentType } from 'config/balancePanel';
import { defaultCount } from 'config/notifications';

export const localState = {
  client: {
    clientType: defaultClientType,
  },
  basketSummary: {
    value: defaultValue,
    currencySign: defaultCurrencySign,
    priceType: defaultPriceType,
    quantity: defaultQuantity,
  },
  balancePanel: {
    paymentType: defaultPaymentType,
    availableBalance: defaultBalance,
    currencySign: defaultCurrencySign,
  },
  routingMap: {},
  newNotificationsCount: defaultCount,
};
