import styled from 'styled-components';

const SVG = styled.svg``;

export const Person = ({ className }) => {
  return (
    <SVG
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.6517 17.3643H14.6523C16.5669 17.3643 18.4513 17.5893 20.3073 18.0393C22.1616 18.489 23.9919 19.1632 25.7989 20.065C26.5063 20.4322 27.0729 20.9605 27.5082 21.6691C27.939 22.3704 28.1533 23.132 28.1523 23.9756V23.9768V28.0518H1.15234V23.9768C1.15234 23.1314 1.36736 22.3687 1.79829 21.6672C2.23375 20.9584 2.79986 20.4308 3.50578 20.0649C5.31283 19.162 7.14313 18.4872 8.99739 18.0375C10.8532 17.5875 12.7374 17.3631 14.6517 17.3643ZM14.6523 13.5518C12.9259 13.5518 11.4729 12.9487 10.2391 11.715C9.00539 10.4812 8.40234 9.02818 8.40234 7.30176C8.40234 5.57533 9.00539 4.1223 10.2391 2.88855C11.4729 1.6548 12.9259 1.05176 14.6523 1.05176C16.3788 1.05176 17.8318 1.6548 19.0655 2.88855C20.2993 4.1223 20.9023 5.57533 20.9023 7.30176C20.9023 9.02818 20.2993 10.4812 19.0655 11.715C17.8318 12.9487 16.3788 13.5518 14.6523 13.5518Z" //eslint-disable-line
        stroke="#333333"
        stroke-width="2" //eslint-disable-line
      />
    </SVG>
  );
};
