// NOTE: This install with simplebar-react
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/src/simplebar.css';
import useMergedRef from '@react-hook/merged-ref';
import { useEffect, useRef, useState } from 'react';
import { useSelect } from 'downshift';

import useLanguage from 'src/hooks/useLanguage';

import { addLangToLinksFromHtmlSetDangerously } from 'src/utils/addLangSignToHtmlLinksFromDato';
import * as S from './styles';

const defaultProps = {
  displayItemLimit: 5,
  buttonProps: {
    variant: 'tertiary',
  },
  textProps: {},
  defaultSelectedItemId: null,
  selectedItemId: null,
  placeholder: null,
  label: null,
  listMaxWidth: null,
  withoutIcon: false,
  noBackgroundHighlight: false,
  displayCustomSelectOnMobile: false,
  footer: null,
  variant: 'primary',
  disabled: false,
  tooltip: '',
};

const itemToString = (item) => (item ? item.label : '');

export const SelectWithSearch = ({
  id,
  displayItemLimit,
  label,
  buttonProps: buttonPropsFromProps,
  textProps,
  items: itemsFromProps,
  handleSelectedItemChange,
  defaultSelectedItemId,
  selectedItemId,
  placeholder,
  listMaxWidth: listMaxWidthFromProps,
  noBackgroundHighlight,
  withoutIcon,
  displayCustomSelectOnMobile,
  footer,
  variant,
  disabled,
  tooltip,
  searchInputPlaceholder = '',
  hideSearchInput = false,
  ...restProps
} = defaultProps) => {
  const [language] = useLanguage();
  const [items, setItems] = useState(itemsFromProps);
  const [searchValue, setSearchValue] = useState('');
  const scrollBarRef = useRef();
  const addRightMarginToItem = items.length > 8;

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const scrollToScrollbarTopOnSearch = () => {
      if (scrollBarRef.current) {
        const scrollElement = scrollBarRef.current.getScrollElement();
        scrollElement.scrollTop = 0;
      }
    };

    setItems(itemsFromProps.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase())));
    scrollToScrollbarTopOnSearch();
  }, [searchValue, itemsFromProps]);

  const hasDefaultSelectedItemId = defaultSelectedItemId !== null;
  const hasSelectedItemId = selectedItemId !== null && selectedItemId > -1;
  const defaultSelectedItem = hasDefaultSelectedItemId ? itemsFromProps[defaultSelectedItemId] : null;

  const currentSelectedItem = hasSelectedItemId ? itemsFromProps[selectedItemId] : null;
  const currentItem = hasSelectedItemId ? currentSelectedItem : defaultSelectedItem;

  const buttonWithIcon = !!currentItem?.icon;
  const preventToClosingDropdownWhileSpaceIsPressed = (e) => {
    if (e.key === ' ') {
      e.stopPropagation();
    }
  };

  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items,
    itemToString,

    selectedItem: currentItem,
    onSelectedItemChange: ({ selectedItem }) => {
      handleSelectedItemChange({ selectedItem });
    },
  });

  const hasLabel = label != null;
  const selectId = `${id}-label`;
  const labelId = `${id}-label`;
  const buttonId = `${id}-toggle-button`;
  const searchId = `${id}-search-input`;

  const buttonRef = useRef(null);
  const customSelectRef = useRef(null);

  const labelProps = getLabelProps();
  const menuProps = getMenuProps({}, { suppressRefError: true });
  const toggleButtonProps = getToggleButtonProps();

  const buttonProps = { ...buttonPropsFromProps };
  const mergedButtonRef = useMergedRef(buttonRef, toggleButtonProps.ref);

  if (!withoutIcon) {
    buttonProps.icon = 'arrowDown';
    buttonProps.iconBefore = false;

    if (isOpen) {
      buttonProps.icon = 'arrowUp';
    }
  }

  useEffect(() => {
    if (isOpen) {
      const activeElement = document.querySelector('.select-list-item-active');

      if (activeElement) {
        activeElement.scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [isOpen]);

  return (
    <S.Select id={id} {...restProps} placeholder={placeholder} hasSelectedItemId={hasSelectedItemId}>
      {hasLabel && (
        <S.SelectLabel
          {...labelProps}
          component="label"
          {...textProps}
          id={selectId}
          htmlFor={buttonId}
          dangerouslySetInnerHTML={{ __html: addLangToLinksFromHtmlSetDangerously(label, language) }}
        />
      )}

      <S.CustomSelect ref={customSelectRef} className="selectBox">
        <S.SelectButton
          disabled={disabled}
          {...buttonProps}
          {...toggleButtonProps}
          id={buttonId}
          ref={mergedButtonRef}
          aria-labelledby={`${hasLabel ? labelId : ''} ${buttonId}`}
        >
          {buttonWithIcon && (
            <S.OptionWithIconWrapper>
              {currentItem.icon}
              {hasSelectedItemId ? itemToString(currentItem) : placeholder}
            </S.OptionWithIconWrapper>
          )}
          {!buttonWithIcon && (hasSelectedItemId ? itemToString(currentItem) : placeholder)}
        </S.SelectButton>
      </S.CustomSelect>

      {isOpen && (
        <S.SelectListWrapper {...menuProps}>
          {!hideSearchInput && (
            <S.Search>
              <S.SearchInput
                isRounded
                placeholder={searchInputPlaceholder}
                id={searchId}
                hideLabel
                value={searchValue}
                onChange={handleSearchValue}
                onKeyDown={preventToClosingDropdownWhileSpaceIsPressed}
                icon="search"
              />
            </S.Search>
          )}
          <S.ScrollBarWrapper addRightMarginToItem={addRightMarginToItem}>
            <S.SelectScrollBar ref={scrollBarRef}>
              <S.SelectList>
                {items.map((item, index) => {
                  const key = `${itemToString(item)}${index}`;
                  const itemProps = getItemProps({ item, index });
                  const optionWithIcon = !!item?.icon;

                  return (
                    <S.SelectListItem
                      addRightMarginToItem={addRightMarginToItem}
                      noBackgroundHighlight={noBackgroundHighlight}
                      isHighlighted={highlightedIndex === index}
                      key={key}
                      {...itemProps}
                      className={`select-list-item 
                  ${highlightedIndex === index && 'select-list-item-active'}
                  ${selectedItemId === index && 'active'}
                  `}
                    >
                      {optionWithIcon && (
                        <S.OptionWithIconWrapper>
                          {item.icon}
                          {itemToString(item)}
                        </S.OptionWithIconWrapper>
                      )}

                      {!optionWithIcon && itemToString(item)}
                    </S.SelectListItem>
                  );
                })}
              </S.SelectList>
            </S.SelectScrollBar>
          </S.ScrollBarWrapper>
        </S.SelectListWrapper>
      )}
    </S.Select>
  );
};

SelectWithSearch.defaultProps = defaultProps;
