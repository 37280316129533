import { useState, useRef, useEffect } from 'react';
import { useCombobox } from 'downshift';

const defaultProps = {};

const menuStyles = {
  maxHeight: '200px',
  overflowY: 'auto',
  width: '100%',
  margin: 0,
  borderTop: 0,
  background: 'white',
  position: 'absolute',
  top: '100%',
  zIndex: 999,
};

export const SuggestionsDropdown = ({ id, items, labelledBy, children } = defaultProps) => {
  const [inputItems, setInputItems] = useState(items);
  const didRendered = useRef(false);

  const { isOpen, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(items.filter((item) => item.toLowerCase().startsWith(inputValue.toLowerCase())));
    },
  });

  useEffect(() => {
    didRendered.current = true;
    return () => {
      didRendered.current = false;
    };
  }, []);

  const allInputProps = getInputProps();

  // TODO: Fix this to avoid deleting properties!
  const allInputPropsCopy = { ...allInputProps };

  delete allInputPropsCopy.id;
  delete allInputPropsCopy['aria-labelledby'];

  return (
    <>
      {children(allInputPropsCopy, didRendered.current)}

      <ul id={id} role="listbox" aria-labelledby={labelledBy} style={menuStyles}>
        {isOpen
          ? inputItems.map((item, index) => {
              const key = `${item}${index}`;
              // TODO: Should be taken from styles config
              const style = highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {};

              return (
                // eslint-disable-next-line
                <li key={key} style={style} {...getItemProps({ item, index })}>
                  {item}
                </li>
              );
            })
          : null}
      </ul>
    </>
  );
};
