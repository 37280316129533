import styled from 'styled-components';

const SVG = styled.svg``;

export const ClipboardIconLarge = ({ className }) => {
  return (
    <SVG
      width="25"
      height="30"
      viewBox="0 0 25 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_4875_103048)">
        <path d="M5.54688 5.4209L19.5195 5.4209" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <path d="M8.19141 1.0166H16.875" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <path d="M3.3125 9.8252H21.7539" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <path d="M7.73047 19.3262H17.2656" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <path
          d="M1.22829 13.4053H23.7622L21.4637 28.0513L3.53331 28.0513L1.22829 13.4053Z"
          stroke="#333333"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_4875_103048">
          <rect width="25" height="29" fill="white" transform="translate(0 0.0517578)" />
        </clipPath>
      </defs>
    </SVG>
  );
};
