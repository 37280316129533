import { useMemo } from 'react';
import progressBar from 'src/utils/progressBar';

export const useProgressBar = () =>
  useMemo(() => {
    if (typeof window !== 'undefined') {
      return progressBar;
    }

    return {
      start: () => {},
      end: () => {},
    };
  }, []);
