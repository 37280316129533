import styled from 'styled-components';
import { rem } from 'polished';
import { up } from 'styled-breakpoints';

import { breakpointsKeys } from 'src/enums/breakpoints';

export const SmallDialog = styled.div`
  position: absolute;
  border-radius: ${rem(30)};
  border: ${({ theme }) => `${rem(0.7)} solid ${theme.defaultPalette.monochrome[0]}`};
  box-shadow: 2px 2px 30px 0px rgba(0, 0, 0, 0.08);
  ${({ theme }) => theme.helpers.transition(['transform'])};
  z-index: 900;
  background: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  left: 50%;
  transform: ${({ isOpen }) => (isOpen ? `translate3d(-50%, ${rem(50)}, 0)` : `translate3d(-50%, ${rem(-500)}, 0)`)};

  ${up(breakpointsKeys.TABLET)} {
    left: unset;
    right: 50%;
    transform: ${({ isOpen, offsetRight = 0 }) =>
      isOpen
        ? `translate3d(calc(50% - ${offsetRight}px), ${rem(50)}, 0)`
        : `translate3d(calc(50% - ${offsetRight}px),  ${rem(-500)}, 0)`};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    right: unset;
    left: 50%;
    transform: ${({ isOpen }) => (isOpen ? `translate3d(-50%, ${rem(25)}, 0)` : `translate3d(-50%, ${rem(-500)}, 0)`)};
  }
`;
