import Image from 'next/image';
import styled, { css } from 'styled-components';
import { up, only } from 'styled-breakpoints';
import { rem } from 'polished';
import Link from '../Link';
import Accordion from '../Accordion';
import { breakpointsKeys } from 'src/enums/breakpoints';
import Heading from '../Heading';

export const FooterNavigation = styled.div`
  background: ${({ theme }) => theme.defaultPalette.monochrome[9]};
  padding: 1em 0;
  line-height: 1.5;

  ul {
    li {
      margin-top: 0.25em;
    }
  }

  ${up('tablet')} {
    padding: 2em 0;
  }
`;

export const FooterNavigationInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  ${up('tablet')} {
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
  }
`;

export const FooterNavigationLinksList = styled.ul`
  margin-bottom: 0;

  & li.footer-nav-link {
    font-size: ${({ theme }) => theme.typography.fontSize.s3};
    margin-bottom: ${rem(2)};
    margin-top: ${rem(2)};
  }

  ${up('tablet')} {
    columns: 2;

    margin-top: 0;
    & li.footer-nav-link {
      margin-bottom: ${rem(4)};
      margin-top: 0;
    }
  }
`;

export const FooterAccordion = styled(Accordion)`
  ${({ removeSidePaddings }) =>
    removeSidePaddings &&
    `
      & > section {
      padding: 0;
    }
    `}
  &.footerHtml {
    width: auto;
  }

  ${up('tablet')} {
    &.footerForm {
      width: 100%;
      max-width: 100%;
    }

    & header > p {
      white-space: break-spaces;
    }

    & > * {
      padding: 0 1em;
    }
  }
`;

export const FooterNavigationContactZone = styled.div`
  ${up('tablet')} {
    display: flex;
    justify-content: column;
  }
`;

export const FooterNavigationContactZoneHeader = styled.div`
  margin-top: 0.25em;
`;

export const FooterNavigationContactZoneInfo = styled.div`
  ${up('tablet')} {
    order: 2;
  }
`;

export const FooterNavigationContactZoneLinksList = styled.ul`
  display: flex;

  & > li {
    white-space: nowrap;

    & + li {
      margin-left: 1em;
    }
  }

  ${only('tablet')} {
    flex-direction: column;

    & > li {
      & + li {
        margin-left: 0;
      }
    }
  }
`;

export const FooterNavigationContactZoneSeeMoreList = styled.ul`
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const FooterNavigationContactZoneImgLink = styled(Link)`
  display: ${(p) => (p.mobileOnly ? 'block' : 'none')};
  width: 70%;
  max-width: ${rem(262)};
  margin: 2em auto 0;

  ${up('tablet')} {
    display: ${(p) => (p.mobileOnly ? 'none' : 'flex')};
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    margin-right: 1em;
    min-width: ${rem(200)};
  }
`;

export const FooterNavigationContactZoneImg = styled(Image)`
  width: 100%;
  height: auto;
`;

export const FooterCard = styled.div`
  ${({ displayAsCard, theme }) =>
    displayAsCard
      ? css`
          background-color: ${theme.defaultPalette.monochrome[0]};
          margin-right: -15px;
          margin-left: -15px;
          padding: 15px;
          border: 1px solid ${theme.defaultPalette.monochrome[1]};
        `
      : null}

  ${up(breakpointsKeys.TABLET)} {
    width: 100%;
    ${({ displayAsCard }) =>
      displayAsCard
        ? css`
            border-radius: 20px;
            margin-right: 0;
            margin-left: 0;
          `
        : null}
  }
`;

export const FooterNavigationInnerLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  ${up(breakpointsKeys.TABLET)} {
    width: 50%;
  }
`;

export const FooterNavigationInnerRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${up(breakpointsKeys.TABLET)} {
    width: 50%;
  }
`;

export const FooterNavigationHeading = styled(Heading)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.type.tertiary};
  color: ${({ theme }) => theme.defaultPalette.monochrome[4]};
  text-align: center;
  margin-bottom: 5px;

  & span {
    background-color: transparent;
    padding: 0;
  }
`;
