import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { em, rem, transparentize } from 'polished';

import Button from '../Button';
import Link from '../Link';
import ImportedLogo from '../Logo';
import _Icon from 'src/components/Icon';
import { breakpointsKeys } from 'src/enums/breakpoints';

export const Overlay = styled.div`
  background: ${({ theme }) => transparentize(0.3, theme.defaultPalette.monochrome[5])};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${({ isMenuOpen }) => isMenuOpen && '0'};
  z-index: 890;
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : 0)};
  height: 100vh;

  ${({ theme }) => theme.helpers.transition(['opacity'])}
`;

export const MobileMenu = styled.div`
  ${({ theme }) => theme.helpers.transition(['transform'])}
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 900;

  width: 100%;
  background: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  overflow-x: hidden;
  transform: ${({ isMenuOpen }) => (isMenuOpen ? 'translate3d(0, 0, 0)' : `translate3d(-100%, 0, 0)`)};
  transition-delay: 0.15s;
  ${up(breakpointsKeys.TABLET)} {
    width: 500px;
  }
`;

export const MenuHeader = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: ${em(20)} ${rem(3)} 0;
  margin-bottom: ${rem(7)};
  transform: ${({ currentActiveLevel }) => `translate(-${currentActiveLevel}00%, 0)`};
  ${({ theme }) => theme.helpers.transition(['transform'])};
`;

export const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  transform: translateX(-25%);
  border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  height: ${rem(30)};
  align-self: center;
  width: ${rem(30)};
  border-radius: 50%;
  color: ${(p) => p.theme.defaultPalette.teal[0]};
`;

export const MoreItemsButton = styled(Button)`
  padding: 0 ${em(32)};
  font-size: ${em(12)};
`;

export const MoreItemsIcon = styled(_Icon)`
  font-size: ${rem(10)};
  height: 100%;
`;

export const LogInIcon = styled(_Icon)`
  font-size: ${rem(24)};
`;

export const MenuContent = styled.div`
  display: flex;

  transform: ${({ currentActiveLevel }) => `translate(-${currentActiveLevel}00%, 0)`};
  ${({ theme }) => theme.helpers.transition(['transform'])};
  ${({ isRoutingChange }) => isRoutingChange && 'opacity: 0.5; pointer-events: none;'}
`;

export const MenuItems = styled.ul`
  flex: 0 0 100%;
  display: flex;
  gap: ${rem(3)};
  padding: 0 ${rem(16)};
  flex-direction: column;
  align-items: center;
  padding-bottom: ${rem(15)};
  ${({ isActive }) => !isActive && 'overflow: hidden; height: 100svh;'}
`;

export const MenuItem = styled.li`
  color: ${({ theme }) => theme.defaultPalette.monochrome[4]};
  font-family: ${({ theme }) => theme.typography.type.primary};

  font-size: ${({ theme }) => theme.typography.fontSize.s3};
  border: ${rem(1)} solid ${({ theme }) => theme.defaultPalette.monochrome[8]};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[9]};
  border-radius: ${rem(12)};
  width: 100%;
  display: flex;
`;

export const TopMenuItem = styled(MenuItem)`
  font-family: ${({ theme }) => theme.typography.type.tertiary};
`;
export const TopMenuItemSecondStep = styled(TopMenuItem)`
  margin-right: auto;
  width: fit-content;
`;

export const GoBackMenuItem = styled(TopMenuItem)`
  margin-right: auto;
  width: fit-content;
`;

export const ShowCategoryButton = styled(TopMenuItem)`
  padding: ${rem(11)} 0;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  margin-bottom: ${rem(5)};
`;

export const NotLoggedInUserMenuItem = styled(MenuItem)`
  margin-top: ${rem(7)};
`;
export const NotLoggedInUserTopMenuItem = styled(MenuItem)`
  margin-top: ${rem(18)};
`;

export const UserLoginIcon = styled.div`
  background-image: url('/static/svg/people-icon-circle.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-width: ${rem(24)};
  height: ${rem(24)};
`;

export const MenuItemLink = styled(Link)`
  padding: ${rem(2)} ${rem(12)} ${rem(2)} ${rem(15)};
  min-height: ${rem(43)};
  width: 100%;
  display: flex;
  > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const MenuLinkWithQuantity = styled(MenuItemLink)`
  > span {
    gap: ${rem(3)};
  }
`;

export const CategoryQuantity = styled.p`
  margin-right: ${rem(3)};
  font-size: ${({ theme }) => theme.typography.fontSize.s2};
  line-height: ${({ theme }) => theme.typography.size.s2};
`;

export const MenuLinkItemWithQuantityNameAndQuantityValue = styled.span`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: ${({ theme }) => theme.defaultPalette.monochrome[3]};
`;

export const SecondStepMenuHeader = styled(MenuHeader)`
  padding: ${em(20)} 0 0;
`;

export const GoBackMenuButton = styled(MenuItemLink)`
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  border-radius: ${rem(12)};
  text-transform: capitalize;
  > span {
    align-items: center;
    justify-content: flex-start;
    gap: ${rem(6)};
  }
`;

export const NotLoggedInUserMenuLink = styled(MenuItemLink)`
  min-height: ${rem(39)};
  font-size: ${({ theme }) => theme.typography.fontSize.s3};
  font-family: ${({ theme }) => theme.typography.type.secondary};
  > span {
    gap: ${rem(6)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LowerMenuItem = styled(MenuItem)`
  border-top: 0;
  margin-bottom: ${em(10)};
  margin-top: ${em(6)};
`;

export const LowerMenuItemLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;

  > .content {
    font-family: ${({ theme }) => theme.typography.type.tertiary};
  }
`;

export const AccountItemsButton = styled(Button)`
  width: 100%;
  font-size: ${({ theme }) => theme.typography.fontSize.s3};
  font-family: ${({ theme }) => theme.typography.type.secondary};
  > .inner > .content {
    width: 100%;
  }
`;

export const Logo = styled(ImportedLogo)`
  display: flex;
  justify-content: center;
  ${({ hideLogo }) => hideLogo && 'visibility: hidden'};

  ${up('tablet')} {
    width: 100%;
    margin: ${rem(10)};
  }
`;

export const UserMenu = styled.div`
  div[data-simplebar='init'] {
    width: 97%;
  }
`;

export const SelectFooter = styled.div`
  padding: ${em(12)};
  cursor: pointer;
  background: ${({ theme }) => theme.userDropDownMenu.footerBg};
  width: 100%;
  text-align: center;
  text-transform: lowercase;

  &:hover {
    span {
      font-family: ${({ theme }) => theme.userDropDownMenu.footerLabelHover};
    }
  }

  path {
    fill: ${({ theme }) => theme.userDropDownMenu.footerIconFill};
  }
`;

export const SelectFooterLabel = styled.span`
  padding-right: 0.3em;
`;
export const LoggedInMenuItem = styled(NotLoggedInUserMenuItem)`
  min-height: ${rem(43)};
  padding: 0 ${rem(15)};
`;

export const ChangeCustomerInnerWrapper = styled.div`
  display: flex;
  gap: ${rem(7)};
  flex: 2;
  justify-content: center;
`;

export const CustomerSwitcherMenuHeader = styled(SecondStepMenuHeader)`
  display: flex;

  flex-direction: column;

  gap: ${rem(10)};
`;

export const ChangeCustomerButtonLabel = styled.p`
  display: flex;
  align-items: center;
`;

export const UserCustomersListMenuWrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${rem(3)};
`;

export const UserSelectButton = styled(GoBackMenuButton)`
  font-family: ${({ theme, selected }) => selected && theme.userDropDownMenu.fontFamilyHover};
`;
