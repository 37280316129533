import { forwardRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import dynamic from 'next/dynamic';

import { createNextLinkProps } from 'src/utils/createNextLinkProps';
import { createCustomLinkProps } from 'src/utils/createCustomLinkProps';

import { breakpointsKeys } from 'src/enums/breakpoints';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import useTranslation from 'src/hooks/useTranslation';

import { HeaderClipboardControl } from './HeaderClipboardControl';
import { HeaderUserControls } from './HeaderUserControls';
import { HeaderBasketControl } from './HeaderBasketControl';
import { HeaderGuestControl } from './HeaderGuestControl';
import BalancePanelDetails from '../BalancePanelDetails';
import LocaleOptions from 'src/components/LocaleOptions';
import NotificationsIndicator from '../NotificationsIndicator';
import ExtendedMenu from '../ExtendedMenu';
import { menuTypes } from '../MobileMenu/MobileMenu';
import HorizontalMenu from '../HorizontalMenu';
import TooltipPopper from '../TooltipPopper';

import BurgerButton from '../BurgerButton';

import Search from '../Search';
import Link from '../Link';
import Logo from '../Logo';

import * as S from './styles';

import { breakpoints } from 'src/styles/breakpoints';

const CategoriesColumnWithContent = dynamic(() => import('../CategoriesColumnWithContent'));

const BALANCE_WARNING_STATUS = 2;

const defaultProps = {
  innerClassName: '',
  user: null,
  basket: null,
  searchValue: '',
  coreRoutesInfo: {},
  balancePanelDetails: null,
};

const removeProductsPageLinkOnCertainPages = ['/homePage', '/homePageForUser'];

const compileHorizontalMenuEntries = ({ isTablet, searchMenuPages, leftBottomMenuPages }) => {
  if (isTablet) {
    return [...searchMenuPages];
  }
  return [...searchMenuPages, ...leftBottomMenuPages];
};

export const Header = forwardRef(
  (
    {
      user,
      innerClassName,
      balancePanelDetails,
      searchValue,
      coreRoutesInfo,
      categories,
      topMenuNavigation: topMenuEntries,
      bottomLeftNavigation: leftBottomMenuEntries,
      rightBottomNavigation: rightBottomMenuEntries,
      searchMenuPagesData: leftBottomDarkNavigation,
      handleSearch,
      language,
      redirectToNewSearchPath,
      isCurrentRouteProductsList,
      isPathContainingProductFilter,
      shouldDisplayBalancePanelDetails,
      balancePanelDetailsRedirectCfg,
      localState,
      paymentStatus,
      headerBasketLinkProps,
      headerClipboardLinkProps,
      headerGuestLinkProps,
      handleCloseMobileMenu,
      handleOpenMobileMenu,
      isMobileMenuOpen,
      initialMenuType,
      basketIndicatorData,
      isClientB2BType,
      shouldRenderDropDownMenu,
      isDropDownMenuOpen,
      searchMenuPages,
      handleCloseDropdownMenu,
      headerUserLinkProps,
      filtersTooltipValue,
      setFiltersTooltipValue,
      setIsFiltersIcon,
      isFiltersIcon,
      shouldDisplayLogoAsH1,
      ...restProps
    } = defaultProps,
    forwardedRefs,
  ) => {
    const { t } = useTranslation();

    const currentBreakpoint = useBreakpointDetector();

    const [headerRef, dropdownMenuRef] = forwardedRefs || [];
    const isNotificationsIndicatorDisplayed = language === 'pl';
    const homePageLayout = coreRoutesInfo.homePage.layout;
    const userAccountPageSlug = coreRoutesInfo.userAccountPage.name[language];
    const userAccountPageLayout = coreRoutesInfo.userAccountPage.layout;
    const productsPageLayout = coreRoutesInfo.productsPage.layout;
    const isTablet = currentBreakpoint === breakpointsKeys.TABLET;

    const leftBottomMenuPages = createCustomLinkProps({ navEntries: leftBottomMenuEntries, language, user });
    const horizontalMenuEntries = compileHorizontalMenuEntries({
      isTablet,
      searchMenuPages,
      leftBottomMenuPages,
    });

    const userAccountLinkProps = createNextLinkProps(userAccountPageSlug, userAccountPageLayout);

    const isMobileView = currentBreakpoint === breakpointsKeys.MOBILE;
    const isDesktopLarge = currentBreakpoint === breakpointsKeys.DESKTOP_LARGE;
    const isHd = currentBreakpoint === breakpointsKeys.HD;
    const shouldDisplayShopAndOptionsMenu = isHd || isDesktopLarge;

    const shouldImportUserDropDownMenu = useMediaQuery({ minWidth: breakpoints.tablet });

    return (
      <S.Header {...restProps} ref={headerRef}>
        <S.HeaderPrintOnlyLogoWrapper style={{ display: 'none' }} className="print-only">
          <S.HeaderPrintOnlyLogo src="/static/svg/logo.svg" width="145" height="35" alt="Onninen" />
        </S.HeaderPrintOnlyLogoWrapper>

        <S.HeaderContent className="no-print">
          <S.HeaderContentInner className={innerClassName} keepStandardOrder={!user}>
            <S.HeaderContentLeft>
              <BurgerButton onClick={() => handleOpenMobileMenu(menuTypes.main)} />
              <Logo user={user} shouldDisplayLogoAsH1={shouldDisplayLogoAsH1} />
            </S.HeaderContentLeft>

            {!isMobileView && (
              <S.HeaderBottomBarSearch>
                <Search
                  onSearch={handleSearch}
                  defaultValue={searchValue}
                  coreRoutesInfo={coreRoutesInfo}
                  locale={language}
                  redirectToNewSearchPath={redirectToNewSearchPath}
                  isCurrentRouteProductsList={isCurrentRouteProductsList}
                  isPathContainingProductFilter={isPathContainingProductFilter}
                  tooltipValue={filtersTooltipValue}
                  setTooltipValue={setFiltersTooltipValue}
                  setIsFiltersIcon={setIsFiltersIcon}
                  isFiltersIcon={isFiltersIcon}
                  user={user}
                />
              </S.HeaderBottomBarSearch>
            )}

            <S.HeaderContentRight>
              {!!user ? <S.LocaleOptionsForLoggedInUser /> : <LocaleOptions />}

              {isTablet && (
                <HeaderUserControls
                  user={user}
                  homePageSlug={coreRoutesInfo?.homePage?.name?.[language]}
                  homePageLayout={homePageLayout}
                />
              )}

              <S.HeaderRightInfoWrapper>
                <S.HeaderIconControls>
                  {user ? (
                    <>
                      {!isTablet && (
                        <>
                          <li role="none" className="notifications-control">
                            {isNotificationsIndicatorDisplayed && <NotificationsIndicator />}
                          </li>
                          <HeaderUserControls
                            user={user}
                            homePageSlug={coreRoutesInfo?.homePage?.name?.[language]}
                            homePageLayout={homePageLayout}
                          />
                          <li role="none" className="basket-control">
                            <HeaderClipboardControl
                              linkProps={headerClipboardLinkProps}
                              defaultClipboardTitle={t('clipboard')}
                            />
                          </li>

                          <li role="none" className="basket-control">
                            <HeaderBasketControl
                              basketIndicatorData={basketIndicatorData}
                              linkProps={headerBasketLinkProps}
                            />
                          </li>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <HeaderGuestControl coreRoutesInfo={coreRoutesInfo} language={language} />
                      <li role="none">
                        {!isTablet && (
                          <HeaderBasketControl
                            linkProps={headerBasketLinkProps}
                            basketIndicatorData={basketIndicatorData}
                          />
                        )}
                      </li>
                    </>
                  )}
                </S.HeaderIconControls>
              </S.HeaderRightInfoWrapper>
            </S.HeaderContentRight>
          </S.HeaderContentInner>
        </S.HeaderContent>

        <S.HeaderBottomBar className="no-print">
          <S.HeaderBottomBarWrapper>
            {isMobileView ? (
              <S.HeaderBottomBarSearchMobile user={user}>
                <Search
                  onSearch={handleSearch}
                  defaultValue={searchValue}
                  coreRoutesInfo={coreRoutesInfo}
                  locale={language}
                  id="searchMobile"
                  redirectToNewSearchPath={redirectToNewSearchPath}
                  isCurrentRouteProductsList={isCurrentRouteProductsList}
                  isPathContainingProductFilter={isPathContainingProductFilter}
                  tooltipValue={filtersTooltipValue}
                  setTooltipValue={setFiltersTooltipValue}
                  setIsFiltersIcon={setIsFiltersIcon}
                  isFiltersIcon={isFiltersIcon}
                />
              </S.HeaderBottomBarSearchMobile>
            ) : null}

            {!isMobileView ? (
              <S.BottomHeaderNavigationWrapper>
                <HorizontalMenu variant="quattuordenary" items={horizontalMenuEntries || []} />

                {isTablet && (
                  <S.BottomBarIconsMenuList>
                    {!!user && (
                      <>
                        {isNotificationsIndicatorDisplayed && <NotificationsIndicator />}
                        <li role="none" className="basket-control">
                          <HeaderClipboardControl
                            linkProps={headerClipboardLinkProps}
                            defaultClipboardTitle={t('clipboard')}
                          />
                        </li>
                      </>
                    )}
                    <HeaderBasketControl linkProps={headerBasketLinkProps} basketIndicatorData={basketIndicatorData} />
                  </S.BottomBarIconsMenuList>
                )}
                {!isTablet && (
                  <S.ExtendedMenuAndBalancePanelWrapper>
                    {shouldDisplayShopAndOptionsMenu && (
                      <ExtendedMenu
                        listLinkVariant="quaternary"
                        linkVariant="quattuordenary"
                        items={rightBottomMenuEntries}
                        user={user}
                      />
                    )}
                    <S.BalancePanelWrapper>
                      {user?.currentCustomer?.warningshow ? (
                        <S.HeaderUserWarning>
                          <Link
                            {...createNextLinkProps(
                              coreRoutesInfo.clientStatusesPage.name[language],
                              coreRoutesInfo.clientStatusesPage.layout,
                            )}
                            blank
                          >
                            <TooltipPopper title={t('findOutMore')} placement="bottom-start" trigger="mouseenter">
                              <S.HeaderUserWarningText
                                variant="tertiary"
                                fontSizeIndex={2}
                                dangerouslySetInnerHTML={{ __html: user.currentCustomer?.warning }}
                              />
                            </TooltipPopper>
                          </Link>
                        </S.HeaderUserWarning>
                      ) : null}

                      {balancePanelDetails?.balancestatus === BALANCE_WARNING_STATUS ? (
                        <S.HeaderUserWarning>
                          <Link
                            {...createNextLinkProps(
                              coreRoutesInfo?.receivablesListPage?.name[language],
                              coreRoutesInfo.receivablesListPage.layout,
                            )}
                            blank
                          >
                            <TooltipPopper title={t('findOutMore')} placement="bottom-start" trigger="mouseenter">
                              <S.HeaderUserWarningText
                                variant="tertiary"
                                fontSizeIndex={2}
                                dangerouslySetInnerHTML={{ __html: balancePanelDetails?.balancemessage }}
                              />
                            </TooltipPopper>
                          </Link>
                        </S.HeaderUserWarning>
                      ) : null}

                      {shouldDisplayBalancePanelDetails && (
                        <BalancePanelDetails
                          freeBalance={localState.balancePanel.availableBalance}
                          paymentType={localState.balancePanel.paymentType}
                          currencySign={localState.balancePanel.currencySign}
                          paymentStatus={paymentStatus}
                          paymentStatusDescription={
                            localState.balancePanel?.paymentStatusDescription || balancePanelDetails?.paymentstatusdesc
                          }
                          redirectPageSlug={balancePanelDetailsRedirectCfg.slug}
                          redirectPageLayout={balancePanelDetailsRedirectCfg.layout}
                        />
                      )}
                    </S.BalancePanelWrapper>
                  </S.ExtendedMenuAndBalancePanelWrapper>
                )}
              </S.BottomHeaderNavigationWrapper>
            ) : null}
          </S.HeaderBottomBarWrapper>
        </S.HeaderBottomBar>
        <S.DropdownMenuContainer ref={dropdownMenuRef}>
          {!isMobileView && shouldRenderDropDownMenu ? (
            <CategoriesColumnWithContent
              categories={categories}
              productsPageLayout={productsPageLayout}
              productPageSlug={coreRoutesInfo.productPage.name[language]}
              productPageLayout={coreRoutesInfo.productPage.layout}
              brandPageSlug={coreRoutesInfo.brandPage.name[language]}
              brandPageLayout={coreRoutesInfo.brandPage.layout}
              onnTopHomePageLayout={coreRoutesInfo.onntopHomepage.layout}
              onnTopHomePageSlug={coreRoutesInfo.onntopHomepage.name[language]}
              hoverVersion
              visibleOnHover={isDropDownMenuOpen}
            />
          ) : null}
        </S.DropdownMenuContainer>
      </S.Header>
    );
  },
);

Header.displayName = 'Header';

Header.defaultProps = defaultProps;
