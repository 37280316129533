import styled, { css } from 'styled-components';
import { rem } from 'polished';

// TODO: Avoid duplication of styles!!!
const primaryVariantStyles = (p) => css`
  ${p.theme.helpers.createDefaultTextareaVariant('primary', p)};
  margin-bottom: 1.5em;
  position: relative;

  &.is-required {
    .inputBox {
      position: relative;
    }
  }

  &.is-invalid {
    .inputBox {
      box-shadow: 0 0 0 ${rem(1)} ${p.theme.textarea.primary.borderColor.textarea.invalid};
    }
  }

  &.is-confirmed {
    .inputBox {
      box-shadow: 0 0 0 ${rem(1)} ${p.theme.textarea.primary.borderColor.textarea.confirmed};
    }
  }

  &:focus-within {
    .inputBox {
      box-shadow: 0 0 0 ${rem(1)} ${p.theme.textarea.primary.borderColor.textarea.focused};
      background-color: ${p.theme.textarea.primary.bg.textareaFocused};
    }

    .label {
      opacity: 1 !important;
    }

    .input {
      &::placeholder {
        opacity: 0 !important;
      }
    }
  }

  &.is-filled {
    .labelBox {
      .label {
        opacity: 1;
      }
    }
  }

  .labelBox {
    z-index: 5;
    position: relative;
    height: 0;

    .label {
      ${p.theme.helpers.transition(['opacity'], 4)};
      background: ${p.theme.global.bg};
      position: absolute;
      left: 1rem;
      top: -0.6em;
      opacity: 0;

      &:before {
        content: '';
        background: ${p.theme.global.bg};
        width: calc(100% + ${rem(8)});
        position: absolute;
        height: ${rem(3)};
        top: ${rem(-1)};
        margin-top: 0.6em;
        left: ${rem(-4)};
        z-index: -1;
      }
    }
  }

  .inputBox {
    ${p.theme.helpers.transition(['box-shadow'])};
    box-shadow: 0 0 0 ${rem(1)} ${p.theme.textarea.primary.borderColor.textarea.default};
    position: relative;
    z-index: 4;
    ${p.isRounded && 'border-radius: 25px;'};
  }

  .input {
    padding: 0.75em 1rem;

    &::placeholder {
      ${p.theme.helpers.transition(['opacity'], 4)};
      opacity: 1;
    }
  }

  .clear {
    border-radius: 50%;
  }

  .errorBox {
    position: relative;
  }

  .error {
    padding: 0.5em 1rem 0;
    display: block;
  }
`;

export const TextareaContainer = styled.div`
  display: ${(p) => (p.block ? 'flex' : 'inline-flex')};
  flex-direction: column;

  & > * {
    ${(p) => p.variant === 'primary' && primaryVariantStyles(p)};
  }
`;
