import { useRef, useEffect, useState } from 'react';

import Overlay from 'src/components/Overlay';

import * as S from './styles';

export const SmallDialog = ({ children, isOpen = false, className, onOverlayClick = () => null, ...rest }) => {
  const [modalOffset, setModalOffset] = useState({ right: 0 });
  const dialogRef = useRef();
  useEffect(
    function calculateModalOffset() {
      if (dialogRef.current) {
        const modalRect = dialogRef.current.getBoundingClientRect();

        const viewportWidth = window.innerWidth;

        if (modalRect.right > viewportWidth) {
          const offset = modalRect.right - viewportWidth;
          setModalOffset({ right: offset });
        }
      }
    },
    /* eslint-disable-next-line */
    [dialogRef.current],
  );

  return (
    <>
      <S.SmallDialog isOpen={isOpen} className={className} offsetRight={modalOffset.right} ref={dialogRef} {...rest}>
        {children}
      </S.SmallDialog>
      <Overlay displayOverlay={isOpen} onClick={onOverlayClick} className="smallDialogOverlay" />
    </>
  );
};
