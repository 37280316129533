// NOTE: This install with simplebar-react
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/src/simplebar.css';
import useMergedRef from '@react-hook/merged-ref';
import { useEffect, useRef } from 'react';
import { useSelect } from 'downshift';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import useLanguage from 'src/hooks/useLanguage';
import Button from '../Button';
import { breakpointsKeys } from 'src/enums/breakpoints';
import { addLangToLinksFromHtmlSetDangerously } from 'src/utils/addLangSignToHtmlLinksFromDato';
import * as S from './styles';

const defaultProps = {
  displayItemLimit: 5,
  buttonProps: {
    variant: 'tertiary',
  },
  textProps: {},
  defaultSelectedItemId: null,
  selectedItemId: null,
  placeholder: null,
  label: null,
  listMaxWidth: null,
  withoutIcon: false,
  noBackgroundHighlight: false,
  displayCustomSelectOnMobile: false,
  footer: null,
  variant: 'primary',
  disabled: false,
  tooltip: '',
};

const itemToString = (item) => (item ? item.label : '');

export const Select = ({
  id,
  displayItemLimit,
  label,
  buttonProps: buttonPropsFromProps,
  textProps,
  items,
  handleSelectedItemChange,
  defaultSelectedItemId,
  selectedItemId,
  placeholder,
  listMaxWidth: listMaxWidthFromProps,
  noBackgroundHighlight,
  withoutIcon,
  displayCustomSelectOnMobile,
  footer,
  variant,
  disabled,
  tooltip,
  ...restProps
} = defaultProps) => {
  const currentBreakpoint = useBreakpointDetector();
  const isMobileView = currentBreakpoint === breakpointsKeys.MOBILE;
  const isTabletView = currentBreakpoint === breakpointsKeys.TABLET;
  const displayNativeSelect = !displayCustomSelectOnMobile && (isMobileView || isTabletView);
  const [language] = useLanguage();

  const hasDefaultSelectedItemId = defaultSelectedItemId !== null;
  const hasSelectedItemId = selectedItemId !== null && selectedItemId > -1;
  const defaultSelectedItem = hasDefaultSelectedItemId ? items[defaultSelectedItemId] : null;

  const currentSelectedItem = hasSelectedItemId ? items[selectedItemId] : null;
  const currentItem = hasSelectedItemId ? currentSelectedItem : defaultSelectedItem;
  const currentItemIdx = hasSelectedItemId ? selectedItemId : defaultSelectedItemId;

  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items,
    itemToString,

    selectedItem: currentItem,
    onSelectedItemChange: ({ selectedItem }) => {
      handleSelectedItemChange({ selectedItem });
    },
  });
  const hasLabel = label != null;
  const selectId = `${id}-label`;
  const labelId = `${id}-label`;
  const buttonId = `${id}-toggle-button`;
  const nativeSelectId = `${id}-native-select`;
  const buttonRef = useRef(null);
  const customSelectRef = useRef(null);
  const labelProps = getLabelProps();
  const menuProps = getMenuProps({}, { suppressRefError: true });
  const toggleButtonProps = getToggleButtonProps();
  const listMaxWidth = listMaxWidthFromProps || buttonRef.current?.offsetWidth;
  const buttonLeftOffsetPosition = customSelectRef.current?.offsetLeft;
  const buttonProps = { ...buttonPropsFromProps };
  const mergedButtonRef = useMergedRef(buttonRef, toggleButtonProps.ref);

  if (!withoutIcon) {
    buttonProps.icon = 'arrowDown';
    buttonProps.iconBefore = false;

    if (isOpen) {
      buttonProps.icon = 'arrowUp';
    }
  }

  const onSelectChange = (evChange) => {
    const { value: selectedOptionKey } = evChange.target;
    const selectedOption = items[selectedOptionKey];

    handleSelectedItemChange({ selectedItem: selectedOption });
  };

  useEffect(() => {
    if (isOpen) {
      const activeElement = document.querySelector('.select-list-item-active');

      if (!!activeElement) {
        activeElement.scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [isOpen]);

  return (
    <S.Select id={id} variant={variant} {...restProps} placeholder={placeholder} hasSelectedItemId={hasSelectedItemId}>
      {hasLabel && (
        <S.SelectLabel
          {...labelProps}
          component="label"
          {...textProps}
          id={selectId}
          htmlFor={buttonId}
          dangerouslySetInnerHTML={{ __html: addLangToLinksFromHtmlSetDangerously(label, language) }}
        />
      )}

      <S.CustomSelect ref={customSelectRef} className="selectBox">
        <Button
          disabled={disabled}
          {...buttonProps}
          {...toggleButtonProps}
          id={buttonId}
          ref={mergedButtonRef}
          aria-labelledby={`${hasLabel ? labelId : ''} ${buttonId}`}
        >
          {itemToString(currentItem) || placeholder}
          {!currentItem?.counter && currentItem?.counter !== 0 ? null : (
            <span className="select__counter">({currentItem?.counter})</span>
          )}
        </Button>

        {displayNativeSelect && (
          <S.NativeSelect id={nativeSelectId} onChange={onSelectChange} defaultValue={currentItemIdx}>
            {placeholder ? <option value="">{placeholder}</option> : null}
            {items.map((item, index) => {
              const key = `${itemToString(item)}${index}`;

              return (
                <option key={key} value={index}>
                  {itemToString(item)} {!item?.counter && item?.counter !== 0 ? null : `(${item?.counter})`}
                </option>
              );
            })}
          </S.NativeSelect>
        )}
      </S.CustomSelect>

      {!displayNativeSelect && isOpen ? (
        <S.SelectListWrapper
          {...menuProps}
          size={listMaxWidth}
          offset={buttonLeftOffsetPosition}
          className="selectListWrapper"
        >
          <S.SelectScrollBar displayItemLimit={displayItemLimit}>
            <S.SelectList>
              {items.map((item, index) => {
                const itemProps = getItemProps({ item, index });

                return (
                  <S.SelectListItem
                    noBackgroundHighlight={noBackgroundHighlight}
                    isHighlighted={highlightedIndex === index}
                    key={index}
                    {...itemProps}
                    className={`select-list-item 
                    ${highlightedIndex === index && 'select-list-item-active'}
                    ${selectedItemId === index && 'active'}
                    `}
                  >
                    {itemToString(item)}
                    {!item?.counter && item?.counter !== 0 ? null : (
                      <span className="select__counter">({item?.counter})</span>
                    )}
                  </S.SelectListItem>
                );
              })}
            </S.SelectList>
          </S.SelectScrollBar>
          {footer}
        </S.SelectListWrapper>
      ) : null}
      {!!tooltip ? (
        <S.TooltipBox className="tooltipBox">
          <S.Tooltip>{tooltip}</S.Tooltip>
        </S.TooltipBox>
      ) : null}
    </S.Select>
  );
};

Select.defaultProps = defaultProps;
