import { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { uid } from 'uid';
import { useTooltipOpenByClick } from './useTooltipOpenByClick';
import { breakpointsKeys } from 'src/enums/breakpoints';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import { useTooltipOpenByHover } from './useTooltipOpenByHover';
import { useTooltipOpenByClickCloseWhenSelect } from './useTooltipOpenByClickCloseWhenSelect';
import * as S from './styles';

const getHookByTriggerName = (triggerName) => {
  if (triggerName === 'mouseenter') {
    return useTooltipOpenByHover;
  }

  if (triggerName === 'click') {
    return useTooltipOpenByClick;
  }

  if (triggerName === 'clickAndSelect') {
    return useTooltipOpenByClickCloseWhenSelect;
  }

  throw new Error('The passed trigger props is not supported');
};

const defaultProps = {
  delay: 20,
  openDelay: 1000,
  placement: 'bottom-start',
  arrowSize: 0,
  forceOpen: false,
  trigger: 'mouseenter',
  disabled: false,
  title: undefined,
  theme: undefined,
  distance: 0,
  isVirtualElement: false,
  hasMaxWidth: true,
  setWidthOfAnchor: false,
  popperConfig: {
    anchorElement: true,
    popperElement: true,
  },
  specificZIndex: 99999999999,
  ariaLabel: '',
  preventShowTooltip: false,
  hideOnTooltipHover: true,
  useBrowserTooltip: false,
};

const generateGetBoundingClientRect = (x = 0, y = 0) => () => ({
  width: 0,
  height: 0,
  top: y,
  right: x,
  bottom: y,
  left: x,
});

export const TooltipPopper = ({
  children,
  delay,
  openDelay,
  forceOpen,
  ariaLabel,
  placement,
  tooltipContent,
  arrowSize,
  theme,
  disabled,
  trigger,
  title,
  distance,
  isVirtualElement,
  hasMaxWidth,
  popperConfig: popperConfigDefault,
  setWidthOfAnchor,
  specificZIndex,
  preventShowTooltip,
  hideOnTooltipHover,
  useBrowserTooltip,
  showTooltipOnTouchDevices = false,
  ...restProps
} = defaultProps) => {
  const popperConfig = useMemo(
    () => ({
      ...popperConfigDefault,
      delayClose: delay,
      delayOpen: openDelay,
    }),
    [popperConfigDefault, delay, openDelay],
  );

  const id = useMemo(() => `tooltip-${uid()}`, []);
  const [popperElement, setPopperElement] = useState(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const [rightMouseClicked, toggleRightMouseClicked] = useState(false);
  const currentBreakpoint = useBreakpointDetector();
  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;
  const isTablet = currentBreakpoint === breakpointsKeys.TABLET;
  const preventTooltipPopCertainBreakpoints = isMobile || isTablet;

  const virtualElement = useMemo(() => {
    if (!isVirtualElement) {
      return anchorElement;
    }

    return {
      getBoundingClientRect: generateGetBoundingClientRect(),
      contextElement: anchorElement,
    };
  }, [anchorElement, isVirtualElement]);

  const offsetCalc = useCallback(
    ({ placement }) => {
      switch (placement) {
        case 'left':
        case 'right':
        case 'right-start':
        case 'right-end':
        case 'left-start':
        case 'left-end':
          return [0, arrowSize + distance];
        case 'top-start':
        case 'bottom-start':
          return [distance, arrowSize];
        case 'top-end':
        case 'bottom-end':
          return [-1 * distance, arrowSize];
        default:
          return [distance, arrowSize];
      }
    },
    [arrowSize, distance],
  );

  const { styles, attributes, update } = usePopper(virtualElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offsetCalc,
        },
      },
      {
        name: 'eventListeners',
        options: { scroll: false, resize: false },
      },
    ],
  });

  const useOpenMethod = getHookByTriggerName(trigger);
  const isTooltipOpen =
    useOpenMethod(
      isVirtualElement ? virtualElement?.contextElement : anchorElement,
      popperElement,
      delay,
      popperConfig,
    ) || forceOpen;

  const handleMouseClick = () => {
    toggleRightMouseClicked(false);
    window.removeEventListener('click', handleMouseClick);
  };

  const handleMouseLeave = () => {
    window.removeEventListener('click', handleMouseClick);
  };

  const handleMouseEnter = ({ clientX: x, clientY: y }) => {
    if (!rightMouseClicked && isVirtualElement) {
      virtualElement.getBoundingClientRect = generateGetBoundingClientRect(x, y);
      window.addEventListener('click', handleMouseClick);
    }
  };

  const handleMouseMove = ({ clientX: x, clientY: y }) => {
    if (!rightMouseClicked && isVirtualElement && isTooltipOpen) {
      virtualElement.getBoundingClientRect = generateGetBoundingClientRect(x, y);
      update();
    }
  };

  const handleRightMouseClick = (ev) => {
    if (isVirtualElement) {
      ev.preventDefault();
      toggleRightMouseClicked((clicked) => !clicked);
    }
  };

  const handleSetAnchorElement = useCallback((ref) => {
    if (ref !== anchorElement) {
      setAnchorElement(ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPopperStyles = () => {
    const popperStyles = { ...styles.popper };
    if (setWidthOfAnchor) {
      popperStyles.minWidth = anchorElement?.scrollWidth;
    }

    return popperStyles;
  };

  if (!showTooltipOnTouchDevices && (preventShowTooltip || preventTooltipPopCertainBreakpoints)) {
    return <>{children}</>;
  }

  if (useBrowserTooltip) {
    return (
      <S.TooltipPopperChildren {...restProps} title={tooltipContent || title}>
        {children}
      </S.TooltipPopperChildren>
    );
  }

  if (typeof document === 'undefined') {
    return (
      <S.TooltipPopperChildren ref={handleSetAnchorElement} {...restProps}>
        {children}
      </S.TooltipPopperChildren>
    );
  }

  return (
    <>
      {/* TODO: Fix div in div element */}
      <S.TooltipPopperChildren
        ref={handleSetAnchorElement}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onContextMenu={handleRightMouseClick}
        onMouseLeave={handleMouseLeave}
        {...restProps}
      >
        {children}
      </S.TooltipPopperChildren>

      {(rightMouseClicked || isTooltipOpen) &&
        !disabled &&
        createPortal(
          <S.TooltipPopper
            ref={setPopperElement}
            id={id}
            role="tooltip"
            aria-label={ariaLabel || title}
            style={setPopperStyles()}
            className={theme ? `${theme}-theme` : undefined}
            hasMaxWidth={hasMaxWidth}
            specificZIndex={specificZIndex}
            hideOnTooltipHover={hideOnTooltipHover}
            {...attributes.popper}
          >
            <div className="tooltip__arrow" />
            <div className="tooltip__inner">{tooltipContent || title}</div>
          </S.TooltipPopper>,
          document.body,
        )}
    </>
  );
};

TooltipPopper.defaultProps = defaultProps;
