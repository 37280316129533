import useTranslation from 'src/hooks/useTranslation';

import * as S from './styles';

export const NotLoggedInUserMenu = ({
  loginPageLayout = '',
  loginPageSlug = '',
  onClickCloseMobileMenu = () => {},
  redirectRegisterB2BObject = {},
}) => {
  const { t } = useTranslation();

  return (
    <>
      <S.NotLoggedInUserTopMenuItem>
        <S.NotLoggedInUserMenuLink
          variant="orange"
          blank
          block
          href={loginPageLayout}
          isNextLink
          nextLinkProps={{
            as: `/${loginPageSlug}`,
          }}
          onClick={onClickCloseMobileMenu}
        >
          <>
            <S.LogInIcon icon="peopleCircle" svgSupportedViewBox="0 0 24 25" />
            {t('logIn')}
          </>
        </S.NotLoggedInUserMenuLink>
      </S.NotLoggedInUserTopMenuItem>

      {Object.keys(redirectRegisterB2BObject).length > 0 && (
        <S.NotLoggedInUserMenuItem>
          <S.NotLoggedInUserMenuLink
            href={redirectRegisterB2BObject.href}
            blank
            block
            isNextLink
            nextLinkProps={{
              as: redirectRegisterB2BObject.nextLinkProps.as,
            }}
          >
            {redirectRegisterB2BObject.label}
          </S.NotLoggedInUserMenuLink>
        </S.NotLoggedInUserMenuItem>
      )}
    </>
  );
};
