export const extractClipboardInfo = (unknownData) => {
  if (Object.keys(unknownData).length <= 0) {
    return {};
  }

  const linesCount = unknownData?.linescount;
  const message = unknownData?.message;
  const name = unknownData?.name;
  const id = unknownData?.id;
  const selected = unknownData?.selected;
  const number = unknownData?.number;
  const active = unknownData?.active;

  return {
    linesCount,
    message,
    name,
    id,
    selected,
    number,
    active,
  };
};
