import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { rem } from 'polished';
import { breakpointsKeys } from 'src/enums/breakpoints';

import _ArrowBig from 'src/components/Icons/ArrowBig';

const limitBannerSize = () => css`
  ${({ bannerMaxWidth }) => `max-width: ${bannerMaxWidth}px !important`};
  min-width: unset !important;
  justify-self: flex-end;
`;

export const Slider = styled.div`
  &:hover {
    ${up('desktop')} {
      .initially-hidden {
        opacity: 1;
      }
    }
  }

  .slider-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .keen-slider {
    flex-grow: 1;
    ${({ bannerMaxWidth, alignBanner }) => bannerMaxWidth && `justify-content: ${alignBanner}`}
  }

  .keen-slider__slide {
    display: flex;
    justify-content: center;

    ${({ disableBannerRounding }) => !disableBannerRounding && `border-radius: ${rem(30)}`};

    align-items: center;
    ${({ bannerMaxWidth }) => bannerMaxWidth && limitBannerSize()}
  }
  .banner-img {
    ${({ disableBannerRounding }) => !disableBannerRounding && `border-radius: ${rem(30)}`};
  }
  .initially-hidden {
    ${up('desktop')} {
      opacity: 0;
    }
  }

  .arrow {
    flex-shrink: 0;
    z-index: 2;

    &.abs {
      position: absolute;
      top: 50%;
      margin-top: -0.5em;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  .dots {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* position: absolute; */
    right: 0;
    top: 100%;
  }

  .dot {
    ${(p) => p.theme.helpers.transition(['background'])};

    background: ${(p) => p.theme.defaultPalette.monochrome[0]};
    width: ${rem(12)};
    height: ${rem(12)};

    border: solid ${rem(1)} ${(p) => p.theme.defaultPalette.blazeOrange[0]};
    border-radius: 50%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    &:not(.active):hover {
      background: ${(p) => p.theme.defaultPalette.blazeOrange[1]};
      cursor: pointer;
    }

    &.active {
      background: ${(p) => p.theme.defaultPalette.blazeOrange[0]};
    }

    & + * {
      margin-left: 0.5em;
    }
  }
`;

const getArrowSize = (arrowSize) =>
  ({
    verySmall: '16px !important',
    small: '32px !important',
    large: '44px !important',
  }[arrowSize] ?? arrowSize);

const rotateArrow = (dir) =>
  ({
    left: '180deg',
    down: '90deg',
    up: '270deg',
  }[dir] ?? '0deg');

export const SliderArrow = styled(_ArrowBig)`
  ${({ theme }) => theme.helpers.transition(['color', 'opacity', 'visibility'])};

  color: ${({ theme }) => theme.defaultPalette.teal[0]};
  width: auto;

  font-size: ${({ arrowSize }) => getArrowSize(arrowSize)};

  margin-top: 0;
  z-index: 10;
  user-select: none;

  ${up(breakpointsKeys.TABLET)} {
    display: block;

    &:hover,
    &:focus {
      cursor: pointer;

      color: ${({ theme }) => theme.defaultPalette.blazeOrange[0]};
    }

    &:active {
      color: ${({ theme }) => theme.defaultPalette.alizarinCrimson[0]};
    }
  }
`;

export const Arrow = styled(SliderArrow)`
  ${({ dir }) => `transform: rotate(${rotateArrow(dir)});`}

  ${(p) => {
    if (!p.breakpointsSettings) {
      return '';
    }

    return Object.entries(p.breakpointsSettings).reduce(
      (mediaQueryRules, [query, { loop, slidesPerView, controls }]) => {
        const shouldHideBothArrows = loop ? false : slidesPerView === p.sliderItemsLength;
        const shouldHideArrowForFirstSlide = !loop && !shouldHideBothArrows && p.dir === 'left' && p.currentSlide === 0;
        const shouldHideArrowForLastSlide =
          !loop &&
          !shouldHideBothArrows &&
          p.dir === 'right' &&
          p.currentSlide !== 0 &&
          p.currentSlide === p.sliderItemsLength - slidesPerView;

        let showArrow = controls;

        if (shouldHideArrowForFirstSlide) {
          showArrow = false;
        }

        if (shouldHideArrowForLastSlide) {
          showArrow = false;
        }

        let bpMediaQueryRule = `${mediaQueryRules} @media ${query} { `;
        bpMediaQueryRule += `opacity: ${showArrow ? 1 : 0};`;
        bpMediaQueryRule += `visibility: ${showArrow ? 'visible' : 'hidden'};`;
        bpMediaQueryRule += `display: ${shouldHideBothArrows ? 'none' : 'block'};`;
        bpMediaQueryRule += '}';

        return bpMediaQueryRule;
      },
      '',
    );
  }};
`;
