import styled from 'styled-components';

export const rotateIconDependsOnDirection = (direction) => ({ up: '270', down: '90', left: '180' }[direction] ?? '0');

const SVG = styled.svg`
  cursor: pointer;

  ${({ rotate }) => rotate && `rotate: ${rotate}deg`};

  &:hover {
    & > path:nth-of-type(1) {
      transition: 0.3s;
      fill: ${({ theme }) => theme.defaultPalette.monochrome[2]};
    }
  }
`;

export const ArrowBig = ({ className, direction = 'right', ...rest }) => {
  const rotate = rotateIconDependsOnDirection(direction);

  return (
    <SVG
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      className={className}
      {...rest}
    >
      <path
        /* eslint-disable-next-line */
        d="M18 4.375C10.7539 4.375 4.875 10.2539 4.875 17.5C4.875 24.7461 10.7539 30.625 18 30.625C25.2461 30.625 31.125 24.7461 31.125 17.5C31.125 10.2539 25.2461 4.375 18 4.375Z"
        fill="white"
        stroke="black"
        /* eslint-disable-next-line */
        strokeMiterlimit="10"
      />
      <path
        d="M14.94 24L21.5 17.5L14.94 11"
        stroke="black"
        /* eslint-disable-next-line */
        strokeWidth="2.1875"
        /* eslint-disable-next-line */
        strokeLinecap="round"
        /* eslint-disable-next-line */
        strokeLinejoin="round"
      />
    </SVG>
  );
};
