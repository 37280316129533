import { SHOP_CLIENT_NAME, DATO_CLIENT_NAME } from 'src/graphql/constants';

export { withApollo } from '../pagesHOCs/withApollo';

export const shopQuery = async ({ client, query, ...options }) => {
  const results = await client.query({
    context: { clientName: SHOP_CLIENT_NAME },
    fetchPolicy: 'no-cache',
    ssr: true,
    query,
    ...options,
  });

  return results;
};

export const shopMutation = async ({ client, query, ...options }) => {
  const results = await client.mutate({
    context: { clientName: SHOP_CLIENT_NAME },
    fetchPolicy: 'no-cache',
    ssr: true,
    mutation: query,
    ...options,
  });

  return results;
};

export const datoCMSQuery = async ({ client, query, ...options }) => {
  const results = await client.query({
    context: { clientName: DATO_CLIENT_NAME },
    ssr: true,
    query,
    ...options,
  });

  return results;
};
