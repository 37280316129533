import TooltipPopper from '../TooltipPopper';
import useTranslation from 'src/hooks/useTranslation';

import useClipboard from 'src/hooks/useClipboard';

import NumberForIcon from 'src/components/NumberForIcon';

import * as S from './styles';

export const HeaderClipboardControl = ({ defaultClipboardTitle, linkProps }) => {
  const [clipboardState] = useClipboard();
  const { t } = useTranslation();

  return (
    <S.ClipboardControlLink {...linkProps} blank>
      <TooltipPopper
        title={clipboardState?.name ? `${defaultClipboardTitle}: ${clipboardState?.name}` : defaultClipboardTitle}
        placement="bottom-start"
        trigger="mouseenter"
        useBrowserTooltip
      >
        <S.ClipboardIconContentWrapper>
          <NumberForIcon number={clipboardState?.linesCount || 0}>
            <S.ClipboardIconLarge />
          </NumberForIcon>

          <S.IconLabel className="clipboardLabel">{t('clipboard')}</S.IconLabel>
        </S.ClipboardIconContentWrapper>
      </TooltipPopper>
    </S.ClipboardControlLink>
  );
};
