import { forwardRef, useRef, useState, useEffect } from 'react';
import useMergedRef from '@react-hook/merged-ref';
import { renderInput } from './renderInput';
import * as S from './styles';

const trimValue = (e) => {
  if (e.target.value !== e.target.value.trim()) {
    e.target.value = e.target.value.trim();
  }
};

const defaultProps = {
  defaultValue: '',
  placeholder: '',
  variant: 'primary',
  required: false,
  disabled: false,
  readOnly: false,
  hideLabel: false,
  icon: null,
  iconBefore: true,
  onIconClick: null,
  error: null,
  confirmed: false,
  showClearButton: false,
  onClearButtonClick: null,
  suggestions: [],
  mask: null,
  iMaskConfig: null,
  block: false,
  style: {},
  disableVariants: false,
  className: '',
  textCentered: false,
  isNotEmpty: false,
  hideErrorMessage: false,
  shouldLabelBeAlwaysVisible: false,
  placeholderChar: null,
  tooltip: '',
  isRounded: false,
};

export const Input = forwardRef(
  (
    {
      id,
      label,
      defaultValue,
      placeholder,
      variant,
      required,
      disabled,
      readOnly,
      hideLabel,
      error,
      hideErrorMessage,
      confirmed,
      icon,
      iconText,
      iconBefore,
      onIconClick,
      showClearButton,
      onClearButtonClick,
      suggestions,
      mask,
      iMaskConfig,
      block,
      className,
      style,
      disableVariants,
      textCentered,
      isNotEmpty,
      shouldLabelBeAlwaysVisible,
      placeholderChar,
      tooltip,
      isRounded,
      ...restProps
    } = defaultProps,
    ref,
  ) => {
    const labelId = `${id}_label`;
    const suggestionsMenuId = `${id}_suggestions`;
    const errorId = `${id}_error`;
    const tooltipId = `${id}_tooltip`;
    const clearButtonVisible = showClearButton && !!onClearButtonClick;
    const canHaveActions = !disabled && !readOnly;
    const [isFilled, setIsFilled] = useState(!!defaultValue);

    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isInputHover, setIsInputHover] = useState(false);

    const inputRef = useRef(null);
    const multiForInputRef = useMergedRef(ref, inputRef);

    const inputBaseProps = {
      id,
      placeholder,
      required,
      disabled,
      readOnly,
      defaultValue,
      textCentered,
      ref: multiForInputRef,
      ...restProps,
    };

    let inputContainerProps = {
      className,
    };

    if (!error && !!confirmed) {
      if (inputBaseProps['aria-describedby']) {
        inputBaseProps['aria-describedby'] = null;
      }

      inputContainerProps.className += ' is-confirmed';
    }

    if (error) {
      inputBaseProps['aria-describedby'] = error;
      inputContainerProps.className += ' is-invalid';
    }

    if (required) {
      inputContainerProps.className += ' is-required';
    }

    if (readOnly) {
      inputContainerProps.className += ' is-read-only';
    }

    if (isNotEmpty) {
      inputContainerProps.className += ' is-not-empty';
    }

    if (isFilled) {
      inputContainerProps.className += ' is-filled';
    }

    if (suggestions.length) {
      inputContainerProps = {
        ...inputContainerProps,
        role: 'combobox',
        'aria-haspopup': 'listbox',
        // TODO: Should be toggled when combobox opens
        'aria-expanded': 'false',
        'aria-owns': suggestionsMenuId,
      };
    }

    if (inputBaseProps?.type !== 'password' && !inputBaseProps?.onBlur) {
      inputBaseProps.onBlur = trimValue;
    }

    const handleIconClick = (e) => {
      e.preventDefault();
      if (onIconClick && canHaveActions) {
        onIconClick(e);
      }
    };

    const handleClearButtonClick = (e) => {
      e.preventDefault();
      if (onClearButtonClick && canHaveActions) {
        onClearButtonClick(e);
      }
    };

    useEffect(() => {
      if (!!inputRef?.current?.value !== isFilled) {
        setIsFilled(!!inputRef?.current?.value);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef?.current?.value]);

    return (
      <S.InputContainer
        variant={variant}
        block={block}
        style={style}
        disableVariants={disableVariants}
        hideErrorMessage={hideErrorMessage}
        isFilled={isFilled}
        isRounded={isRounded}
        className="inputContainer"
        {...inputContainerProps}
      >
        {/* NOTE: Why isn't this rendered conditionally depends on the hideLabel flag? */}
        <S.LabelBox className="labelBox" hideLabel={hideLabel}>
          <S.Label className="label" htmlFor={id} shouldLabelBeAlwaysVisible={shouldLabelBeAlwaysVisible}>
            {label}
          </S.Label>
        </S.LabelBox>

        <S.InputBox className="inputBox" isRounded={isRounded}>
          {icon && iconBefore && (
            <S.IconBox className="iconBox left" role="img" aria-hidden="true">
              <S.Icon
                className="icon"
                icon={icon}
                onClick={handleIconClick}
                hasAction={!!onIconClick}
                disabled={disabled}
                aria-hidden="true"
              />
            </S.IconBox>
          )}

          {renderInput({
            inputBaseProps,
            defaultValue,
            suggestionsMenuId,
            labelId,
            suggestions,
            mask,
            iMaskConfig,
            setIsInputFocused,
            isInputFocused,
            isInputHover,
            setIsInputHover,
            placeholderChar,
            setIsFilled,
          })}

          {clearButtonVisible && (
            <S.ClearBox className={`clearBox ${icon && !iconBefore ? 'has-icon-aside' : ''}`}>
              <S.Clear
                type="button"
                className="clear"
                disabled={disabled}
                onClick={handleClearButtonClick}
                tabIndex={-1}
              />
            </S.ClearBox>
          )}

          {icon && !iconBefore && (
            <S.IconBox className="iconBox right" role="img" aria-hidden="true">
              <S.Icon
                className="icon"
                icon={icon}
                onClick={handleIconClick}
                hasAction={!!onIconClick}
                disabled={disabled}
                aria-hidden="true"
              />
            </S.IconBox>
          )}
          {iconText && !iconBefore && (
            <S.IconBox className="iconBox right" aria-hidden="true">
              <S.IconText onClick={handleIconClick} hasAction={!!onIconClick}>
                {iconText}
              </S.IconText>
            </S.IconBox>
          )}
        </S.InputBox>
        {error && !hideErrorMessage ? (
          <S.ErrorBox
            className="errorBox"
            id={errorId}
            aria-live="assertive"
            aria-relevant="additions removals"
            isTooltip={!!tooltip}
            isRounded={isRounded}
          >
            <S.Error className="error" dangerouslySetInnerHTML={{ __html: error }} />
          </S.ErrorBox>
        ) : null}
        {!!tooltip ? (
          <S.TooltipBox className="tooltipBox" id={tooltipId}>
            <S.Tooltip className="tooltip" isRounded={isRounded}>
              {tooltip}
            </S.Tooltip>
          </S.TooltipBox>
        ) : null}
      </S.InputContainer>
    );
  },
);

Input.displayName = 'Input';

Input.defaultProps = defaultProps;
