import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import StyledReactModalComponent from 'styled-react-modal';
import { rem } from 'polished';
import Icon from '../Icon';

export const StyledReactModal = styled(StyledReactModalComponent)`
  z-index: 9999;
  width: 20em;
`;

export const ModalContainer = styled.div`
  background-color: ${(p) => p.theme.defaultPalette.monochrome[0]};

  box-shadow: 0px 8px 23px 0px rgba(0, 0, 0, 0.09);

  ${({ isRounded }) => isRounded && 'border-radius: 30px'};
  ${(p) =>
    p.isPhotoModal &&
    css`
      height: 100%;
      width: 100%;
    `}

  ${up('tablet')} {
    ${(p) =>
      p.isPhotoModal &&
      css`
        width: 90vw;
      `}

    max-width: 1190px;
    height: auto;
  }
`;

export const ModalContainerInner = styled.div`
  position: relative;

  ${(p) =>
    p.isPhotoModal &&
    css`
      height: 100%;
      width: 100%;

      .modal-content {
        max-height: 100vh;
        height: 100%;
        padding: 0;

        ${up('tablet')} {
          max-height: 90vh;
        }
    `}
`;

export const Content = styled.div`
  padding: ${rem(20)};
  position: relative;
  max-height: 90vh;
  overflow: auto;
  scroll-behavior: smooth;
`;

export const CloseIcon = styled(Icon)`
  ${(p) => p.theme.helpers.transition(['transform'])};

  background-color: ${(p) => p.theme.defaultPalette.monochrome[2]};

  color: ${(p) => p.theme.defaultPalette.blazeOrange[0]};
  position: absolute;
  top: 0;
  right: 0;
  z-index: 33;
  transform: scale(1);
  padding: ${rem(8)};
  width: ${({ isPhotoModal }) => (!isPhotoModal ? rem(35) : rem(45))};
  height: ${({ isPhotoModal }) => (!isPhotoModal ? rem(35) : rem(45))};
  cursor: pointer;
  flex-shrink: 0;

  ${(p) =>
    !p.isPhotoModal &&
    css`
      margin-top: -1em;
      margin-right: -1em;
      border-radius: 50%;
    `}

  ${up('tablet')} {
    margin-top: -1em;
    margin-right: -1em;

    border-radius: 50%;
  }

  &:hover {
    transform: scale(1.25);
  }
`;
