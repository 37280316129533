import styled, { css } from 'styled-components';
import { hideVisually, rem } from 'polished';
import { up } from 'styled-breakpoints';
import IconComponent from '../Icon';
import Text from '../Text';

const createBaseInputVariantStyles = (inputVariant, p) => css`
  ${p.theme.helpers.createDefaultInputVariant(inputVariant, p)};
  margin-bottom: 1.5em;
  position: relative;

  &.is-required {
    .inputBox {
      position: relative;
    }
  }

  &.is-invalid {
    .inputBox {
      box-shadow: 0 0 0 1px ${p.theme.input[inputVariant].borderColor.input.invalid};
    }
  }

  &.is-confirmed {
    .inputBox {
      box-shadow: 0 0 0 1px ${p.theme.input[inputVariant].borderColor.input.confirmed};
    }
  }

  &.is-read-only {
    .inputBox {
      background: ${p.theme.input[inputVariant].bg.readOnly};
    }
  }

  &:not(.is-read-only):focus-within {
    .inputBox {
      box-shadow: 0 0 0 ${rem(1)} ${p.theme.input[inputVariant].borderColor.input.focused};
      background-color: ${p.theme.input[inputVariant].bg.inputFocused};
    }

    .label {
      opacity: 1 !important;
    }

    .input {
      &::placeholder {
        opacity: 0 !important;
      }
    }
  }

  &.is-filled {
    .labelBox {
      .label {
        opacity: 1;
      }
    }
  }

  .labelBox {
    z-index: 5;
    position: relative;
    height: 0;

    .label {
      ${p.theme.helpers.transition(['opacity'], 4)};
      background: ${p.theme.global.bg};
      position: absolute;
      left: 1rem;
      top: -0.6em;
      opacity: 0;
      padding: 0 5px;
      border-radius: ${({ isRounded }) => (isRounded ? '7px' : '3px')};

      &:before {
        content: '';
        background: ${p.theme.global.bg};
        width: 100%;
        position: absolute;
        height: ${rem(3)};
        top: ${rem(-1)};
        margin-top: 0.6em;
        left: 0;
        z-index: -1;
      }
    }
  }

  .inputBox {
    ${p.theme.helpers.transition(['box-shadow'])};
    box-shadow: 0 0 0 ${rem(1)} ${p.theme.input[inputVariant].borderColor.input.default};
    position: relative;
    z-index: 4;
    padding: 1px 1rem;
  }

  .input {
    padding-top: 0.75em;
    padding-bottom: 0.75em;

    &::placeholder {
      ${p.theme.helpers.transition(['opacity'], 4)};
      opacity: 1;
    }
  }

  .clear {
    border-radius: 50%;
  }

  .errorBox {
    position: relative;
  }

  .error {
    padding: 0.5em 0;
    display: block;
  }

  .tooltip {
    margin-top: 2px;
    display: block;
  }
`;

const primaryVariantStyles = (props) => css`
  ${createBaseInputVariantStyles('primary', props)}
`;

const secondaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultInputVariant('secondary', props)};
  margin-bottom: 0.5em;
  margin-right: 1em;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &.is-required {
    .inputBox {
      &:after {
        content: '*';
        color: ${props.theme.input.secondary.color.requiredSign};
        position: absolute;
        right: -1em;
        top: auto;
        bottom: 0px;
      }
    }
  }

  .labelBox {
    flex-basis: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.75em;
  }

  .inputBox {
    flex-basis: 60%;
    box-shadow: 0 0 0 ${rem(1)} ${props.theme.input.secondary.borderColor.input.default};
    position: relative;
    z-index: 4;
    padding: 0.25rem;
    ${props.isRounded &&
      `
    border-radius: 25px;
    padding: 0.25rem 0.75rem;
    `}
  }

  .errorBox {
    order: 1;
    margin-left: auto;
    flex-basis: 60%;
    padding: 0.25em 0em;
    text-align: left;
    ${props.isRounded &&
      `
    padding-left: 0.75rem;
    `}
  }
`;

const tertiaryVariantStyles = (props) => css`
  ${createBaseInputVariantStyles('tertiary', props)}
  .inputBox {
    box-shadow: none !important;
    border-radius: 3em;
    padding: 0 1.5rem;
  }

  .input {
    ${up('tablet')} {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }

  .icon {
    width: 1.25em;
    height: 1.25em;
    background: transparent;
    color: ${(p) => p.theme.input.tertiary.color.icon};
  }

  .iconBox + .input {
    text-indent: 0;
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  ${up('tablet')} {
    .inputBox {
      background: ${(p) => p.theme.input.tertiary.bg.inputDesktop};
    }
  }
`;

const quaternaryVariantStyles = (props) => css`
  ${createBaseInputVariantStyles('quaternary', props)}

  .inputBox {
    box-shadow: none !important;
  }
`;

const quinaryVariantStyles = (props) => css`
  ${createBaseInputVariantStyles('quinary', props)};
`;

const senaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultInputVariant('senary', props)};
  margin-bottom: 0.5em;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &.is-required {
    .inputBox {
      &:after {
        content: '*';
        color: ${props.theme.input.senary.color.requiredSign};
        position: absolute;
        right: -1em;
        top: auto;
        bottom: 0px;
      }
    }
  }

  .labelBox {
    display: none;
  }

  .inputBox {
    flex-basis: 100%;
    box-shadow: 0 0 0 ${rem(1)} ${props.theme.input.senary.borderColor.input.default};
    position: relative;
    z-index: 4;
    padding: 0.25rem;

    input {
      color: inherit;
    }
  }

  &.is-invalid {
    .inputBox {
      box-shadow: 0 0 0 ${rem(1)} ${props.theme.input.senary.borderColor.input.invalid};
      margin-bottom: ${({ hideErrorMessage }) => (hideErrorMessage ? '0' : '1.2rem')};
      color: ${props.theme.input.senary.borderColor.input.focused};
    }
  }

  &:focus-within&.is-invalid {
    .inputBox {
      margin-bottom: 0;
    }
  }

  &:focus-within {
    .inputBox {
      box-shadow: 0 0 0 ${rem(1)} ${props.theme.input.senary.borderColor.input.focused};

      &:before {
        content: '%';
        right: 5px;
        position: absolute;
        top: 5px;
      }
    }

    .input {
      position: relative;
      left: -6px;
      &::placeholder {
        opacity: 0 !important;
      }
    }
  }

  &.is-not-empty {
    .inputBox {
      &:before {
        content: '%';
        right: 5px;
        position: absolute;
        top: 5px;
      }
    }

    input {
      position: relative;
      left: -6px;
    }

    .input {
      &::placeholder {
        opacity: 0 !important;
      }
    }
  }

  &:focus-within {
    .errorBox {
      display: none;
    }
  }

  .errorBox {
    position: absolute;
    margin-left: auto;
    padding: 0.25em 0em;
    text-align: left;
    width: max-content;
    bottom: -0.3rem;

    span {
      width: auto;
      display: block;
    }

    .hideErrorMessage {
      display: none;
    }
  }
`;

export const InputContainer = styled.div`
  display: ${(p) => (p.block ? 'flex' : 'inline-flex')};
  flex-direction: column;

  ${(p) =>
    !p.disableVariants &&
    css`
      ${p.variant === 'primary' && primaryVariantStyles(p)};
      ${p.variant === 'secondary' && secondaryVariantStyles(p)};
      ${p.variant === 'tertiary' && tertiaryVariantStyles(p)};
      ${p.variant === 'quaternary' && quaternaryVariantStyles(p)};
      ${p.variant === 'quinary' && quinaryVariantStyles(p)};
      ${p.variant === 'senary' && senaryVariantStyles(p)};
    `};
`;

export const LabelBox = styled.span`
  ${(p) =>
    p.hideLabel &&
    css`
      ${hideVisually()};
      /* NOTE: Added in order to avoid a11y contrast issues */
      color: black !important;
      background: white !important;
    `};
`;

export const Label = styled.label`
  opacity: ${(p) => p.shouldLabelBeAlwaysVisible && 1}!important;
  border-radius: 3px;
  padding: 0 3px;
`;

export const InputBox = styled.div`
  display: flex;
  position: relative;

  ${({ isRounded }) => isRounded && 'border-radius: 50px'};
`;

export const Input = styled.input`
  padding: 0;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: auto;
  width: 100%;
  resize: none;
  position: relative;
  z-index: 1;
  font-family: inherit;
  font-size: inherit;

  &::placeholder {
    opacity: 1;
  }

  &:invalid {
    box-shadow: none;
  }

  &:disabled,
  &[disabled],
  &:read-only {
    /* TODO: All of the view needs to be disabled */
    cursor: not-allowed;
    opacity: 1;
    color: inherit;
  }

  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  ${({ textCentered }) =>
    textCentered &&
    css`
      text-align: center;
    `}
`;

export const IconBox = styled.span`
  width: 0;
  display: flex;
  align-items: center;

  & + .input {
    text-indent: 1.5em;
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
`;

export const Icon = styled(IconComponent)`
  align-self: center;
  flex-shrink: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 1em;
  height: 1em;
  position: relative;
  z-index: 2;

  ${(p) =>
    !p.disabled &&
    p.hasAction &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};
`;

export const ClearBox = styled.div`
  width: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.has-icon-aside {
    .clear {
      margin-right: 1.5em;
    }
  }
`;

export const Clear = styled.button`
  opacity: 1;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: 0;
  min-width: 1em;
  min-height: 1em;
  position: relative;
  z-index: 2;
  display: block;

  &::before {
    content: '×';
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }
`;

export const ErrorBox = styled.div`
  ${({ isRounded }) =>
    isRounded &&
    `
    margin-left: 1rem;
  `}
  & .error {
    ${({ isTooltip }) => isTooltip && `padding-bottom: 0`};
  }
`;

export const Error = styled.span`
  background: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
`;

export const TooltipBox = styled.div``;

export const Tooltip = styled.span`
  background: inherit;
  color: ${({ theme }) => theme.input.tooltipColor};
  font-family: inherit;
  font-size: ${({ theme }) => theme.input.tooltipFontSize};
  ${({ isRounded }) =>
    isRounded &&
    `
    margin-left: 1rem;
  `}
`;

export const IconText = styled(Text)`
  margin-bottom: 0;
  cursor: pointer;
  padding: 0 2px;
  z-index: 4;
  color: ${({ theme }) => theme.input.primary.color.icon};
`;
