import { useEffect, useRef } from 'react';
import useTranslation from 'src/hooks/useTranslation';
import { generateQueryUri, encodeSearchQueryParam } from 'src/utils/searchApi';
import { query as queryString } from 'config/queries';
import { defaultLanguage } from 'config/locales';
import Heading from '../Heading';
import MiniHorizontalProductListItem from '../MiniHorizontalProductListItem';
import * as S from './styles';

const defaultProps = {
  search: null,
  locale: defaultLanguage,
  coreRoutesInfo: {},
  isPathContainingProductFilter: false,
};

const queryPrefix = `${queryString}=`;

export const SearchSuggest = ({
  search,
  coreRoutesInfo,
  locale,
  inputRef,
  setIsInputFocused,
  isOpen,
  isCurrentRouteProductsList,
  isPathContainingProductFilter,
  onSearch,
  ...restProps
} = defaultProps) => {
  const { frazes, cats, brands, products, totalmorefilter, totalmore, total, message } = search;
  const searchPageLayout = coreRoutesInfo.searchPage.layout;
  const searchPageSlug = coreRoutesInfo.searchPage.name[locale];
  const productsPageLayout = coreRoutesInfo.productsPage.layout;
  const productsPageSlug = coreRoutesInfo.productsPage.name[locale];
  const brandPageLayout = coreRoutesInfo.brandPage.layout;
  const brandPageSlug = coreRoutesInfo.brandPage.name[locale];

  const { t } = useTranslation();
  const { t: tSearchFilter } = useTranslation('searchfilter');

  const categoryLinkPath = (slug) =>
    inputRef.current.value
      ? `/${searchPageSlug}?${queryPrefix}${slug}${encodeSearchQueryParam({
          [tSearchFilter('find')]: encodeURIComponent(inputRef.current.value),
        })}`
      : generateQueryUri(productsPageSlug, null, slug);

  const brandLinkPath = (slug, filtername) =>
    inputRef.current.value
      ? generateQueryUri(searchPageSlug, {
          [tSearchFilter('find')]: encodeURIComponent(inputRef.current.value),
          [filtername.toLowerCase()]: slug,
        })
      : generateQueryUri(brandPageSlug, null, `/${encodeURIComponent(slug)}`);

  const focusableElementsRef = useRef([]);

  useEffect(() => {
    if (!document && typeof window === 'undefined') {
      return;
    }
    const handleKeyDown = (e) => {
      const focusableElements = focusableElementsRef.current;
      const currentSelectedElement = document.activeElement;
      const currentIndex = focusableElements.findIndex((elem) => elem === currentSelectedElement);
      if (e.key === 'Down' || e.key === 'ArrowDown') {
        e.stopPropagation();
        e.preventDefault();

        if (currentIndex === -1) {
          focusableElements?.['0'].focus();
        } else {
          const newElement = focusableElements?.[currentIndex + 1] || focusableElements?.['0'];

          newElement.focus();
        }
      }

      if (e.key === 'Up' || e.key === 'ArrowUp') {
        e.stopPropagation();
        e.preventDefault();

        if (currentIndex === -1) {
          focusableElements?.[focusableElements.length - 1].focus();
        } else {
          const newElement = focusableElements?.[currentIndex - 1] || focusableElements?.[focusableElements.length - 1];

          newElement.focus();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [focusableElementsRef]);
  const showNoResultInfo = !total && !totalmore && message;
  return (
    <>
      <S.SearchSuggest
        isOpen={isOpen}
        totalmore={totalmore}
        ref={(ele) => {
          if (!document && typeof window === 'undefined') {
            return;
          }
          if (ele) {
            const links = ele.querySelectorAll('a, [role="button"]');
            focusableElementsRef.current = Array.from(links);
          } else {
            focusableElementsRef.current = [];
          }
        }}
        {...restProps}
      >
        {showNoResultInfo ? (
          <Heading variant="primary" fontSizeIndex={6} component="div">
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </Heading>
        ) : null}

        <S.LinksLeftContainer>
          {frazes.length ? (
            <S.PhrasesContainer>
              <S.LinksContainer>
                {isCurrentRouteProductsList && !isPathContainingProductFilter
                  ? frazes.map(({ name, value }) => (
                      <S.SearchFilter
                        tabIndex="0"
                        key={name + value}
                        onClick={() => {
                          onSearch(value);
                          inputRef.current.value = value;
                          setIsInputFocused(false);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onSearch(value);
                            inputRef.current.value = value;
                            setIsInputFocused(false);
                          }
                        }}
                        role="button"
                      >
                        <span dangerouslySetInnerHTML={{ __html: name }} />
                      </S.SearchFilter>
                    ))
                  : frazes.map(({ name, value }) => (
                      <S.AutoSuggestLink
                        variant="tridenary"
                        isNextLink
                        key={name + value}
                        href={searchPageLayout}
                        onClick={() => {
                          /* eslint-disable-next-line */
                          inputRef.current.value = value;
                          setIsInputFocused(false);
                        }}
                        nextLinkProps={{
                          as: generateQueryUri(searchPageSlug, {
                            [tSearchFilter('find')]: encodeURIComponent(value.replace(/\//g, '\\/')),
                          }),
                        }}
                      >
                        <span dangerouslySetInnerHTML={{ __html: name }} />
                      </S.AutoSuggestLink>
                    ))}
              </S.LinksContainer>
            </S.PhrasesContainer>
          ) : null}

          {cats?.length ? (
            <S.CategoriesContainer>
              <Heading variant="duodenary" fontSizeIndex={0} component="div">
                {t('categories')}
              </Heading>
              <S.LinksContainer>
                {cats.map(({ name, slug }) => (
                  <S.AutoSuggestLink
                    variant="tridenary"
                    key={name + slug}
                    isNextLink
                    onClick={() => setIsInputFocused(false)}
                    href={inputRef.current.value ? searchPageLayout : productsPageLayout}
                    nextLinkProps={{
                      as: categoryLinkPath(slug),
                    }}
                  >
                    <span>{name}</span>
                  </S.AutoSuggestLink>
                ))}
              </S.LinksContainer>
            </S.CategoriesContainer>
          ) : null}

          {brands?.length ? (
            <S.BrandsContainer>
              <Heading variant="duodenary" fontSizeIndex={0} component="div">
                {t('brands')}
              </Heading>
              <S.LinksContainer>
                {brands.map(({ name, slug, filtername }) => (
                  <S.AutoSuggestLink
                    variant="tridenary"
                    key={name + slug}
                    isNextLink
                    onClick={() => setIsInputFocused(false)}
                    href={inputRef.current.value ? searchPageLayout : brandPageLayout}
                    nextLinkProps={{
                      as: brandLinkPath(slug, filtername),
                    }}
                  >
                    {name}
                  </S.AutoSuggestLink>
                ))}
              </S.LinksContainer>
            </S.BrandsContainer>
          ) : null}
        </S.LinksLeftContainer>

        {products?.length ? (
          <S.ProductsContainer>
            <S.ProductHeading variant="duodenary" fontSizeIndex={0} component="div">
              {t('products')}
            </S.ProductHeading>
            {products.map(({ name, slug, avail, image, imagem }) => (
              <MiniHorizontalProductListItem
                variant="tridenary"
                key={name + slug}
                isNextLink
                href={searchPageLayout}
                onClick={() => setIsInputFocused(false)}
                nextLinkProps={{
                  as: generateQueryUri(searchPageSlug, {
                    [tSearchFilter('product')]: slug,
                  }),
                }}
                name={name}
                largeImage={imagem}
                image={image}
                avail={avail}
              />
            ))}
          </S.ProductsContainer>
        ) : null}

        {totalmorefilter && totalmore ? (
          <S.SearchSuggestFooter>
            <S.FooterAutoSuggestLink
              variant="tridenary"
              isNextLink
              onClick={() => setIsInputFocused(false)}
              href={searchPageLayout}
              nextLinkProps={{
                as: generateQueryUri(searchPageSlug, {
                  [tSearchFilter('find')]: encodeURIComponent(inputRef.current.value.replace(/\//g, '\\/')),
                  [totalmorefilter.slug]: [totalmorefilter.value],
                }),
              }}
            >
              <S.Icon icon="arrowRight" /> {t('includeCustomProducts')} ({totalmore})
            </S.FooterAutoSuggestLink>
          </S.SearchSuggestFooter>
        ) : null}
      </S.SearchSuggest>
      {isOpen ? (
        <S.SearchSuggestBackground
          onClick={() => {
            setIsInputFocused(false);
          }}
        />
      ) : null}
    </>
  );
};

SearchSuggest.defaultProps = defaultProps;
