import useRESTMutation from '../useRESTMutation';
import { extractClipboardInfo } from 'src/utils/extractClipboardInfo';
import ADD_PRODUCT_TO_CLIPBOARD from 'src/graphql/mutations/addProductToClipboard.gql';
import useClipboardInfo from 'src/hooks/useClipboardInfo';

export function useClipboard() {
  const [clipboard, setClipboard] = useClipboardInfo();
  const [addProductToClipboard, { loading }] = useRESTMutation(ADD_PRODUCT_TO_CLIPBOARD, {
    onCompleted: (clipboardData) => {
      setClipboard(extractClipboardInfo(clipboardData.addProductToClipboard));
    },
  });

  return [clipboard, addProductToClipboard, loading];
}
