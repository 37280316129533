import useTranslation from '../useTranslation';

export const useSearchString = (query) => {
  const { t } = useTranslation('searchfilter');

  if (query === '/' || query === null) {
    return '';
  }

  const decodedQuery = decodeURIComponent(query);
  const wordplace = decodedQuery.indexOf(`/${t('find')}:`);
  if (wordplace <= 0) {
    return '';
  }
  const searchPart = decodedQuery.substring();
  let searchValue = searchPart.substring(searchPart.indexOf(':') + 1);
  const firstColonIndex = searchValue.indexOf(':');

  if (firstColonIndex > 0) {
    searchValue = searchValue.substring(0, searchValue.lastIndexOf('/', firstColonIndex));
  }

  searchValue = searchValue.replace(/\\\//g, '/');

  return searchValue;
};
