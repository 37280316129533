import * as S from './styles';

const defaultProps = {
  variant: 'primary',
  innerRef: null,
};

export const HorizontalMenu = ({ items, variant, ...rest } = defaultProps) => {
  return (
    <S.HorizontalMenu role="menubar" {...rest}>
      {items.map(({ label, isActive, innerRef, ...restProps }) => {
        return (
          <S.HorizontalMenuItem key={label} role="none" ref={innerRef}>
            <S.Link variant={variant} className={`${isActive ? 'focus' : ''}`} role="menuitem" {...restProps}>
              {label}
            </S.Link>
          </S.HorizontalMenuItem>
        );
      })}
    </S.HorizontalMenu>
  );
};

HorizontalMenu.defaultProps = defaultProps;
