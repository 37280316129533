import styled, { css } from 'styled-components';
import IconComponent from '../Icon';

export const COLLAPSED_STATE_NAME = 'collapsed';
export const COLLAPSING_STATE_NAME = 'collapsing';
export const EXPANDED_STATE_NAME = 'expanded';
export const EXPANDING_STATE_NAME = 'expanding';

export const Accordion = styled.div`
  .collapse-css-transition {
    ${(p) => p.theme.helpers.transition(['height'])};
  }
`;

export const AccordionSection = styled.section``;

export const Icon = styled(IconComponent)`
  margin-left: 1em;
  margin-right: 0.5em;
  path {
    fill: ${(p) => p.theme.defaultPalette.blazeOrange[0]};
  }
`;

export const AccordionSectionHeader = styled.header`
  ${({ showIcon }) => {
    if (showIcon) {
      return css`
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        position: relative;
        max-width: calc(100% - 1em);
      `;
    }

    return css`
      display: initial;
    `;
  }};

  &:hover {
    cursor: ${(p) => (p.isActive ? 'pointer' : 'initial')};
  }
`;

export const AccordionSectionInner = styled.div`
  ${(p) => p.theme.helpers.transition(['opacity'])};

  ${() => css`
    &.${COLLAPSED_STATE_NAME} {
      opacity: 0;
    }

    &.${COLLAPSING_STATE_NAME} {
      opacity: 0;
    }

    &.${EXPANDING_STATE_NAME} {
      opacity: 1;
    }

    &.${EXPANDED_STATE_NAME} {
      opacity: 1;
    }
  `}
`;
