import { defaultLanguage } from 'config/locales';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { NOT_LOGGED_IN_TAG_ID } from 'src/../config/personalizationTags';
import { datoHtmlFieldsTypenames } from 'src/enums/datoHtmlFieldsTypenames';
import { addLangSignToHtmlLinksFromDato } from './addLangSignToHtmlLinksFromDato';

dayjs.extend(isBetween);

const isBetweenDates = (startDate, endDate) => {
  const currentDate = dayjs();
  const startDisplayingDate = startDate ? dayjs(startDate) : dayjs().subtract(1, 'day');
  const endDisplayingDate = endDate ? dayjs(endDate) : dayjs().add(1, 'day');

  return currentDate.isBetween(startDisplayingDate, endDisplayingDate, 'second', '()');
};

const filterSinglePersonlizedImage = (image, tags) => {
  const isVisible = isBetweenDates(image.visibilityStartDate, image.visibilityEndDate);
  let isPersonalizationTagsMatch = true;

  if (image?.personalizationTags.length) {
    isPersonalizationTagsMatch = image.personalizationTags.some((tag) => tags.includes(tag.id))
  }

  if (isVisible && isPersonalizationTagsMatch) return image;
}

export const filterCMSContent = (content, { user = null }, language) => {
  if (!content) {
    return [];
  }
  const isLoggedIn = !!user;
  let updateContent = [...content];

  if(language !== defaultLanguage) {
    updateContent = addLangSignToHtmlLinksFromDato(updateContent, language);
  }

  const userPersonalizationTags = isLoggedIn ? user?.tags || [] : [NOT_LOGGED_IN_TAG_ID];

  for(let i = 0; i < updateContent.length; i ++) {
    if(updateContent[i].__typename === datoHtmlFieldsTypenames.bannersCarouselPersonalizedSet) {
      const updatedPersonalizedImages = updateContent[i].personalizedImages.filter(image => 
        filterSinglePersonlizedImage(image, userPersonalizationTags))

      const updatedPersonalizedImagesObject = {
        ...updateContent[i],
        personalizedImages: updatedPersonalizedImages
      }

      updateContent[i] = updatedPersonalizedImagesObject
    }
  }

  return updateContent.filter((elementSet) => {
    if (elementSet.personalizationTags?.length) {
      const isSomePersonalisationTagsMatch = elementSet.personalizationTags.some((personalizationTag) =>
        userPersonalizationTags.includes(personalizationTag.id),
      );

      if (!isSomePersonalisationTagsMatch) {
        return false;
      }
    }

    return isBetweenDates(elementSet.visibilityStartDate, elementSet.visibilityEndDate);
  });
};
