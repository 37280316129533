import { useContext } from 'react';

import { NotificationsContext } from 'src/providers/notifications';

export const useNotificationsContext = () => {
  const ctx = useContext(NotificationsContext);
  if (!ctx) {
    throw new Error('Component beyond NotificationsContext!');
  }

  return ctx;
};
