import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { defaultLanguage } from 'config/locales';

import useEnvironmentContext from 'src/hooks/useEnvironmentContext';
import useLanguage from 'src/hooks/useLanguage';

import { ACTIONS } from './localeOptionsReducer';

import SmallDialog from 'src/components/SmallDialog';
import { LocaleOptionsDialogContent } from './LocaleOptionsDialogContent';

const HOME_PAGE_URL = '/';

export const LocaleOptionsDropdown = ({ state = {}, dispatch = () => null }) => {
  const {
    environmentData,
    setCurrency,
    setDeliveryCountry,
    refetchEnvironment,
    setPriceType,
  } = useEnvironmentContext();

  const router = useRouter();

  const [language] = useLanguage();

  const setInitialState = () => {
    const { currentLocale, currentDelivery, currentCurrency, availablePriceTypes } = environmentData.environment;
    dispatch({
      type: ACTIONS.SET_INITIAL_STATE_LAZILY,
      payload: {
        locale: currentLocale,
        deliveryCountry: currentDelivery?.id,
        currency: currentCurrency,
        priceType: availablePriceTypes?.find((item) => !!item.selected)?.type,
      },
    });
  };

  const handleCloseModal = () => {
    dispatch({ type: ACTIONS.CLOSE_DROPDOWN });
  };
  const handleOnOverlayClick = () => {
    handleCloseModal();
    setInitialState();
  };

  const currentSelectedDeliveryCountryId = environmentData?.environment.currentDelivery?.id;
  const currentSelectedCurrencyId = environmentData?.environment.currentCurrency?.id;
  const currentPriceType = environmentData?.environment.availablePriceTypes?.find((item) => !!item.selected)?.type;

  const clientPageReload = () => {
    const { asPath, pathname } = router;
    router.replace(pathname, asPath);
  };

  const redirectToHomePageOnLanguageChange = (newLocale) => {
    const shouldRedirectToDefaultLanguageHomePage = newLocale === defaultLanguage;
    if (shouldRedirectToDefaultLanguageHomePage) {
      router.push(HOME_PAGE_URL);
      return;
    }

    router.push(`${newLocale}${HOME_PAGE_URL}`);
  };

  const handleSubmitSettings = async () => {
    handleCloseModal();
    await setCurrency(state.currency.id);
    await setDeliveryCountry(state.deliveryCountry);
    await refetchEnvironment();
    await setPriceType(state.priceType);
    if (language !== state.locale) {
      redirectToHomePageOnLanguageChange(state.locale);
      return;
    }
    if (
      state.currency.id !== currentSelectedCurrencyId ||
      state.deliveryCountry !== currentSelectedDeliveryCountryId ||
      state.priceType !== currentPriceType
    ) {
      clientPageReload();
    }
  };

  useEffect(() => {
    if (environmentData?.environment) {
      setInitialState();
    }
    /* eslint-disable-next-line */
  }, [environmentData]);

  return (
    <SmallDialog isOpen={state.isDropdownOpen} onOverlayClick={handleOnOverlayClick}>
      <LocaleOptionsDialogContent state={state} dispatch={dispatch} onClickSubmit={handleSubmitSettings} />
    </SmallDialog>
  );
};
