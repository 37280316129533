import useGlobalContactFormViaLink from 'src/hooks/useGlobalContactFormViaLink';
import useTranslation from 'src/hooks/useTranslation';
import { createKey } from 'src/utils/createKey';
import { createCustomLinkProps } from 'src/utils/createCustomLinkProps';
import { getFirstAvailableSalesman } from 'src/utils/getFirstAvailableSalesman';
import { createNextLinkProps } from 'src/utils/createNextLinkProps';
import Link from '../Link';
import FormContactSalesman from '../FormContactSalesman';
import { breakpointsKeys } from 'src/enums/breakpoints';
import useLanguage from 'src/hooks/useLanguage';
import * as S from './styles';

const defaultProps = {
  innerClassName: '',
  user: null,
};

export const FooterNavigation = ({ columns, user, innerClassName } = defaultProps) => {
  const lastBpWithAccordions = breakpointsKeys.TABLET;
  const { t } = useTranslation();
  const [language] = useLanguage();
  const addEventListenerByRef = useGlobalContactFormViaLink();

  return (
    <S.FooterNavigation>
      <S.FooterNavigationInner className={innerClassName}>
        <S.FooterNavigationInnerLeft>
          {columns.map((columnSet, elementIdx) => {
            /* eslint-disable-next-line */
            const elementName = columnSet?._modelApiKey;
            const key = `${elementName}_${elementIdx}`;
            const isTitleFilled = !!columnSet?.title?.length;

            switch (elementName) {
              case 'footer_custom_nav': {
                const navEntries = columnSet?.navigation?.set;

                if (!navEntries?.length) {
                  return null;
                }

                const menuItems = createCustomLinkProps({ navEntries, language, user });

                if (!menuItems.length) {
                  return null;
                }

                const navigation = columnSet?.navigation;

                return (
                  <S.FooterCard displayAsCard key={createKey(key)}>
                    <S.FooterAccordion
                      id={`footer_nav_${key}`}
                      toBp={lastBpWithAccordions}
                      className="footerNavigation"
                      forceBeingOpen
                    >
                      <S.FooterAccordion.Section
                        heading={
                          navigation?.displayedTitle ? (
                            <S.FooterNavigationHeading component="p">
                              {navigation.displayedTitle}
                            </S.FooterNavigationHeading>
                          ) : null
                        }
                      >
                        <S.FooterNavigationLinksList>
                          {menuItems.map((navEntry, navEntryIdx) => {
                            const { label, href, ...restProps } = navEntry;

                            const navEntryKey = `${href}_${navEntryIdx}`;
                            if (!href) {
                              console.debug('[ERROR] FooterNavigation', { navEntry });
                              return null;
                            }

                            return (
                              <li key={createKey(navEntryKey)} className="footer-nav-link">
                                <Link href={href} variant="novendenary" {...restProps}>
                                  {label}
                                </Link>
                              </li>
                            );
                          })}
                        </S.FooterNavigationLinksList>
                      </S.FooterAccordion.Section>
                    </S.FooterAccordion>
                  </S.FooterCard>
                );
              }
              case 'footer_contact': {
                const {
                  emailAddress,
                  displayedTitle,
                  description,
                  phoneNumberNumericFormat,
                  phoneNumberPrettyFormat,
                  allContactsPage,
                  officeLocatorPage,
                  openingHours,
                  contactImage,
                } = columnSet;

                const hasContactZoneImg = contactImage?.alt && contactImage?.url;
                return (
                  <S.FooterCard displayAsCard key={createKey(key)}>
                    <S.FooterNavigationContactZone>
                      <S.FooterNavigationContactZoneInfo>
                        <S.FooterAccordion id="footer_nav_contact_info" toBp={lastBpWithAccordions} forceBeingOpen>
                          <S.FooterAccordion.Section
                            heading={
                              displayedTitle ? (
                                <S.FooterNavigationHeading component="p">{displayedTitle}</S.FooterNavigationHeading>
                              ) : null
                            }
                          >
                            <S.FooterNavigationContactZoneHeader>{description}</S.FooterNavigationContactZoneHeader>

                            <S.FooterNavigationContactZoneLinksList>
                              <li>
                                <Link
                                  href={`tel:${phoneNumberNumericFormat}`}
                                  variant="quaternary"
                                  icon="phone"
                                  iconBefore
                                >
                                  {phoneNumberPrettyFormat}
                                </Link>
                              </li>
                            </S.FooterNavigationContactZoneLinksList>

                            <S.FooterNavigationContactZoneLinksList>
                              <li>
                                <Link
                                  ref={addEventListenerByRef}
                                  href={`mailto:${emailAddress}`}
                                  variant="quaternary"
                                  icon="mail"
                                  iconBefore
                                >
                                  {emailAddress}
                                </Link>
                              </li>
                            </S.FooterNavigationContactZoneLinksList>

                            <p>{openingHours}</p>

                            <S.FooterNavigationContactZoneSeeMoreList>
                              {!allContactsPage?.layout && !allContactsPage?.slug ? null : (
                                <li>
                                  <Link
                                    variant="quaternary"
                                    icon="arrowRight"
                                    iconBefore
                                    {...createNextLinkProps(allContactsPage.slug, allContactsPage.layout, language)}
                                  >
                                    {t('moreContacts')}
                                  </Link>
                                </li>
                              )}
                              {!officeLocatorPage?.layout && !officeLocatorPage?.slug ? null : (
                                <li>
                                  <Link
                                    variant="quaternary"
                                    icon="pointer"
                                    iconBefore
                                    {...createNextLinkProps(officeLocatorPage.slug, officeLocatorPage.layout, language)}
                                  >
                                    {t('findOffice')}
                                  </Link>
                                </li>
                              )}
                            </S.FooterNavigationContactZoneSeeMoreList>
                          </S.FooterAccordion.Section>
                        </S.FooterAccordion>
                      </S.FooterNavigationContactZoneInfo>

                      {hasContactZoneImg && (
                        <S.FooterNavigationContactZoneImgLink
                          blank
                          {...createNextLinkProps(officeLocatorPage?.slug, officeLocatorPage?.layout, language)}
                        >
                          {/* eslint-disable-next-line */}
                          <S.FooterNavigationContactZoneImg
                            src={contactImage.url}
                            alt={contactImage.alt}
                            width={300}
                            height={285}
                          />
                        </S.FooterNavigationContactZoneImgLink>
                      )}
                    </S.FooterNavigationContactZone>
                  </S.FooterCard>
                );
              }
              case 'footer_html': {
                if (!!columnSet?.isRightSide) return;
                return (
                  <S.FooterCard displayAsCard={!!columnSet?.displayAsCard} key={createKey(key)}>
                    <S.FooterAccordion
                      id={`footer_nav_html_section_${columnSet?.id}`}
                      forceBeingOpen
                      toBp={lastBpWithAccordions}
                      className="footerHtml"
                      removeSidePaddings={!columnSet?.displayAsCard}
                    >
                      <S.FooterAccordion.Section
                        heading={
                          isTitleFilled ? (
                            <S.FooterNavigationHeading component="p" variant="duodenary">
                              {columnSet.title}
                            </S.FooterNavigationHeading>
                          ) : null
                        }
                      >
                        <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: columnSet?.htmlContent || '' }} />
                      </S.FooterAccordion.Section>
                    </S.FooterAccordion>
                  </S.FooterCard>
                );
              }
              default:
                return null;
            }
          })}
        </S.FooterNavigationInnerLeft>
        <S.FooterNavigationInnerRight>
          {columns.map((columnSet, elementIdx) => {
            /* eslint-disable-next-line */
            const elementName = columnSet?._modelApiKey;
            const key = `${elementName}_${elementIdx}`;
            const isTitleFilled = !!columnSet?.title?.length;

            switch (elementName) {
              case 'footer_form': {
                const registeredUserSalesman = getFirstAvailableSalesman(
                  user?.salesman,
                  user?.salesman?.replacementPerson,
                );
                if (!registeredUserSalesman) {
                  return null;
                }

                return (
                  <S.FooterCard displayAsCard key={createKey(key)}>
                    <S.FooterAccordion
                      id="footer_nav_authenticated_user_contact_form"
                      toBp={lastBpWithAccordions}
                      className="footerForm"
                      forceBeingOpen
                    >
                      <S.FooterAccordion.Section
                        heading={
                          isTitleFilled ? (
                            <S.FooterNavigationHeading component="p">{columnSet.title}</S.FooterNavigationHeading>
                          ) : null
                        }
                      >
                        <FormContactSalesman
                          name={registeredUserSalesman.name}
                          phone={registeredUserSalesman.phone}
                          email={registeredUserSalesman.email}
                          status={registeredUserSalesman.status}
                          statusDescription={registeredUserSalesman.statusDescription}
                        />
                      </S.FooterAccordion.Section>
                    </S.FooterAccordion>
                  </S.FooterCard>
                );
              }
              case 'footer_html': {
                if (!columnSet?.isRightSide) return;
                return (
                  <S.FooterCard displayAsCard={!!columnSet?.displayAsCard} key={createKey(key)}>
                    <S.FooterAccordion
                      id={`footer_nav_html_section_${columnSet?.id}`}
                      toBp={lastBpWithAccordions}
                      className="footerHtml"
                      forceBeingOpen
                      removeSidePaddings={!columnSet?.displayAsCard}
                    >
                      <S.FooterAccordion.Section
                        heading={
                          isTitleFilled ? (
                            <S.FooterNavigationHeading component="p">{columnSet.title}</S.FooterNavigationHeading>
                          ) : null
                        }
                      >
                        <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: columnSet?.htmlContent || '' }} />
                      </S.FooterAccordion.Section>
                    </S.FooterAccordion>
                  </S.FooterCard>
                );
              }
              default:
                return null;
            }
          })}
        </S.FooterNavigationInnerRight>
      </S.FooterNavigationInner>
    </S.FooterNavigation>
  );
};

FooterNavigation.defaultProps = defaultProps;
