import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import useTranslation from 'src/hooks/useTranslation';
import { vestResolver } from '@hookform/resolvers/vest/dist/vest.umd';
import { searchManySchema } from 'src/schemas/searchMany';
import { query as queryString } from 'config/queries';
import { indexes } from 'config/filters';
import { defaultLanguage } from 'config/locales';
import Heading from '../Heading';
import * as S from './styles';

const validationResolver = vestResolver(searchManySchema);

const queryPrefix = `${queryString}=`;

export const formatIndexesQuery = (indexesListStr) =>
  `${indexes}:${indexesListStr
    .split(',')
    .map((i) => i.replace(/\n|\r|\s/g, ','))
    .join(',')}`;

const defaultProps = {
  locale: defaultLanguage,
  coreRoutesInfo: {},
};

export const SearchMany = ({ coreRoutesInfo, locale, setIsInputFocused, isOpen, ...restProps } = defaultProps) => {
  const searchPageLayout = coreRoutesInfo.searchPage.layout;
  const searchPageSlug = coreRoutesInfo.searchPage.name[locale];
  const router = useRouter();

  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onSubmit',
    resolver: validationResolver,
  });

  const inputValue = watch('productsIndexes');

  const { t } = useTranslation();
  const { t: tLabel } = useTranslation('inputlabels');
  const { t: tErr } = useTranslation('errors');

  const onFormSubmit = async (input) => {
    setIsInputFocused(false);
    router.push(searchPageLayout, `${searchPageSlug}?${queryPrefix}${formatIndexesQuery(input.productsIndexes)}`);
  };

  return (
    <>
      <S.SearchMany isOpen={isOpen} {...restProps}>
        <S.Form onSubmit={handleSubmit(onFormSubmit)}>
          <S.SearchManyContainer>
            <Heading variant="primary" fontSizeIndex={8} component="div">
              <span dangerouslySetInnerHTML={{ __html: t('searchManyDesc') }} />
            </Heading>
            <S.TextareaContainer>
              <S.Textarea
                ref={register}
                autoComplete="nope"
                block
                tabIndex={1}
                name="productsIndexes"
                id="productsIndexes"
                rows={errors?.productsIndexes ? 9 : 11}
                autoFocus
                hideLabel
              />
              {!inputValue?.length ? (
                <S.Placeholder dangerouslySetInnerHTML={{ __html: tLabel('productsIndexesPlaceholder') }} />
              ) : null}
            </S.TextareaContainer>
            <div>
              <S.FormStatusMessage id="indexes-error-message" errorMessage={tErr(errors?.productsIndexes?.message)} />
            </div>
          </S.SearchManyContainer>
          <S.SearchManyFooter>
            <S.Button disabled={!inputValue?.length} type="submit">
              {t('lookForMany')}
            </S.Button>
          </S.SearchManyFooter>
        </S.Form>
      </S.SearchMany>
      {isOpen ? (
        <S.SearchManyBackground
          onClick={() => {
            setIsInputFocused(false);
          }}
        />
      ) : null}
    </>
  );
};

SearchMany.defaultProps = defaultProps;
