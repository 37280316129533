import dynamic from 'next/dynamic';
import { Children, cloneElement, useReducer } from 'react';

import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'src/styles/breakpoints';
import { breakpointsKeys } from 'src/enums/breakpoints';
import * as S from './styles';

const AccordionSection = dynamic(() => import('./index').then((component) => component.AccordionSection));

const SECTION_HEADER_CLICKED = 'sectionHeaderClicked';

const isSectionIdxInOpenSectionsIndexes = (state, sectionIdx) => state.openChildrenIndexes.has(sectionIdx);

const createUpdateOpenSectionsIndexes = (currentOpenSectionsIndexes, sectionIdx, isSectionCurrentlyOpen) => {
  const newOpenSectionsIndexes = new Set(currentOpenSectionsIndexes);

  if (!isSectionCurrentlyOpen) {
    newOpenSectionsIndexes.add(sectionIdx);
  } else {
    newOpenSectionsIndexes.delete(sectionIdx);
  }

  return newOpenSectionsIndexes;
};

const initialAccordionState = {
  openChildrenIndexes: new Set(),
};

const accordionReducer = (state, { type, payload }) => {
  switch (type) {
    case SECTION_HEADER_CLICKED: {
      return {
        openChildrenIndexes: createUpdateOpenSectionsIndexes(
          state.openChildrenIndexes,
          payload,
          isSectionIdxInOpenSectionsIndexes(state, payload),
        ),
      };
    }
    default:
      return state;
  }
};

const defaultProps = {
  fromBp: breakpointsKeys.MOBILE,
  toBp: null,
  forceBeingOpen: false,
};

export const Accordion = ({ children, id, fromBp, toBp, forceBeingOpen, ...restProps } = defaultProps) => {
  const shouldBehaveLikeAccordion = useMediaQuery({
    minWidth: breakpoints[fromBp],
    maxWidth: breakpoints[toBp],
  });

  Children.forEach(children, (child, childIdx) => {
    const { defaultIsOpen } = child.props;

    const isSectionInitiallyOpened = !shouldBehaveLikeAccordion || defaultIsOpen;

    initialAccordionState.openChildrenIndexes = createUpdateOpenSectionsIndexes(
      initialAccordionState.openChildrenIndexes,
      childIdx,
      !isSectionInitiallyOpened,
    );
  });

  const [state, dispatch] = useReducer(accordionReducer, initialAccordionState);

  const enhancedSections = Children.map(children, (child, childIdx) => {
    const childForceBeingOpen = !!child.props?.forceBeingOpen;
    return cloneElement(child, {
      id: `${id}_${childIdx}`,
      forceBeingOpen: forceBeingOpen || childForceBeingOpen || !shouldBehaveLikeAccordion,
      isOpen: !shouldBehaveLikeAccordion || isSectionIdxInOpenSectionsIndexes(state, childIdx),
      toggle: () =>
        dispatch({
          type: SECTION_HEADER_CLICKED,
          payload: childIdx,
        }),
    });
  });

  return <S.Accordion {...restProps}>{enhancedSections}</S.Accordion>;
};

Accordion.Section = AccordionSection;

Accordion.defaultProps = defaultProps;
