import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Icon from '../Icon';

import * as S from './styles';

const DISTANCE_FROM_TOP = 50;

export const ScrollToTop = () => {
  const { events: routeEvents } = useRouter();

  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!document && typeof window === 'undefined') {
      return;
    }

    const scrollWatcher = () => {
      setShowScrollToTopButton(window.scrollY > DISTANCE_FROM_TOP);
    };

    document.addEventListener('scroll', scrollWatcher);
    routeEvents.on('routeChangeComplete', scrollWatcher);

    // eslint-disable-next-line
    return () => {
      routeEvents.on('routeChangeComplete', scrollWatcher);
      document.removeEventListener('scroll', scrollWatcher);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <S.ScrollToTop showScrollToTopButton={showScrollToTopButton} id="scrollToTop" onClick={handleScrollToTop}>
      <Icon icon="arrowUp" />
    </S.ScrollToTop>
  );
};
