export const httpStatusCodesKeys = {
  BAD_REQUEST: 'badRequest',
  UNAUTHORIZED: 'unauthorized',
  FORBIDDEN: 'forbidden',
  METHOD_NOT_ALLOWED: 'methodNotAllowed',
  NOT_FOUND: 'notFound',
  TIME_OUT: 'timeOut',
  PERMAMENT_REDIRECT: 'permamentRedirect',
  PRODUCT_DOESNT_EXIST: 'productDoesntExist',
  AUTHENTICATION: 'authentication',
};

export const httpStatusCodesMap = new Map([
  [201, httpStatusCodesKeys.PRODUCT_DOESNT_EXIST],
  [301, httpStatusCodesKeys.PERMAMENT_REDIRECT],
  [400, httpStatusCodesKeys.BAD_REQUEST],
  [401, httpStatusCodesKeys.UNAUTHORIZED],
  [403, httpStatusCodesKeys.FORBIDDEN],
  [404, httpStatusCodesKeys.NOT_FOUND],
  [405, httpStatusCodesKeys.METHOD_NOT_ALLOWED],
  [406, httpStatusCodesKeys.AUTHENTICATION],
  [408, httpStatusCodesKeys.TIME_OUT],
]);

export const httpStatusCodesEnum = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  METHOD_NOT_ALLOWED: 405,
  NOT_FOUND: 404,
  TIME_OUT: 408,
  PERMAMENT_REDIRECT: 301,
  PRODUCT_DOESNT_EXIST: 201,
};
