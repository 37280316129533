import useCurrentCurrency from 'src/hooks/useCurrentCurrency';

import * as S from './styles';
import { BalancePanelDetail } from './BalancePanelDetail';

const defaultProps = {
  freeBalance: 0,
  paymentStatus: null,
  paymentStatusDescription: null,
};

export const BalancePanelDetails = ({
  paymentType,
  freeBalance,
  currencySign,
  paymentStatus,
  paymentStatusDescription,
  redirectPageSlug,
  redirectPageLayout,
  ...restProps
} = defaultProps) => {
  const transformPrice = useCurrentCurrency({
    sign: currencySign,
  });

  const commonProps = {
    redirectPageLayout,
    redirectPageSlug,
  };

  return (
    <S.BalancePanelDetails {...restProps}>
      <BalancePanelDetail title={'availableBalance'} tooltipTitle={paymentStatusDescription} {...commonProps}>
        <S.BalancePanelFreeBalanceValue status={paymentStatus}>
          {transformPrice(freeBalance)}
        </S.BalancePanelFreeBalanceValue>
      </BalancePanelDetail>
      {/* <BalancePanelDetail tooltipTitle={paymentType} {...commonProps} className="payment-type">
        <S.BalancePanelValue>{paymentType}</S.BalancePanelValue>
      </BalancePanelDetail> */}
    </S.BalancePanelDetails>
  );
};

BalancePanelDetails.defaultProps = defaultProps;
