import styled from 'styled-components';

const SVG = styled.svg``;

export const MessageFull = ({ className, ...rest }) => {
  return (
    <SVG width="19" height="13" viewBox="0 0 19 13" fill="none" className={className} {...rest}>
      <path
        //eslint-disable-next-line
        d="M17.4606 0.333977C17.3849 0.326174 17.3085 0.326174 17.2328 0.333977H1.67726C1.57756 0.335513 1.47852 0.350462 1.38281 0.378422L9.41059 8.37287L17.4606 0.333977Z"
        fill="#00857E"
      />
      <path
        //eslint-disable-next-line
        d="M18.2814 0.105469L10.1925 8.16103C9.98435 8.36797 9.70274 8.48413 9.4092 8.48413C9.11566 8.48413 8.83405 8.36797 8.62587 8.16103L0.609201 0.16658C0.584557 0.257158 0.57149 0.350494 0.570312 0.444358V11.5555C0.570312 11.8502 0.687376 12.1328 0.895749 12.3411C1.10412 12.5495 1.38674 12.6666 1.68142 12.6666H17.237C17.5317 12.6666 17.8143 12.5495 18.0227 12.3411C18.231 12.1328 18.3481 11.8502 18.3481 11.5555V0.444358C18.3437 0.32861 18.3212 0.214266 18.2814 0.105469ZM2.44253 11.5555H1.67031V10.761L5.7092 6.75547L6.49253 7.5388L2.44253 11.5555ZM17.2259 11.5555H16.4481L12.3981 7.5388L13.1814 6.75547L17.2203 10.761L17.2259 11.5555Z"
        fill="#00857E"
      />
    </SVG>
  );
};
