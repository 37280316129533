import * as S from './styles';

const defaultRatio = {
  x: 0,
  y: 0,
  verticalPixelOffset: 0,
};

const defaultProps = {
  isComponentReady: false,
  inverse: false,
  mobile: defaultRatio,
  tablet: defaultRatio,
  desktop: defaultRatio,
  hd: defaultRatio,
};

export const LoadingCover = ({ mobile, tablet, desktop, isComponentReady, inverse, children } = defaultProps) => (
  <S.LoadingCover
    mobile={mobile}
    tablet={tablet}
    desktop={desktop}
    isReady={isComponentReady}
    className="loadingCoverWrapper"
  >
    <S.LoadingCoverFront className={`${isComponentReady ? 'ready' : ''} loadingCover`} hasInverseBg={inverse} />

    {children && <S.LoadingCoverContent>{children}</S.LoadingCoverContent>}
  </S.LoadingCover>
);

LoadingCover.defaultProps = defaultProps;
