import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { rem } from 'polished';

import FormStatusMessage from '../FormStatusMessage';
import ImportedBadge from '../Badge';
import Textarea from '../Textarea';
import Form from '../Form';
import Link from '../Link';
import { breakpointsKeys } from 'src/enums/breakpoints';

export const Badge = styled(ImportedBadge)`
  text-transform: uppercase;
`;

export const FormContactSalesman = styled.div`
  background: none;
`;

export const FormContactSalesmanName = styled.p`
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  & svg {
    width: 17px;
    height: 16px;
    margin-bottom: 2px;
  }
`;

export const FormContactSalesmanLinks = styled.ul`
  width: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  gap: ${rem(6)};

  .link-text {
    ${(p) => p.theme.helpers.dynamicFontSize(p.theme.typography.sizesSet.r7)};
  }

  & li {
    margin-top: 0;
  }

  & > li {
    a {
      width: 100%;

      & > .content {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }

  ${up('desktop')} {
    grid-template-columns: auto auto;
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    & > li {
      /* width: 100%; */
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;

      & + li {
        margin-top: 0;
        /* width: calc(100% - 1em); */
      }
    }
  }
`;

export const FormContactSalesmanForm = styled(Form)`
  margin-top: 15px;
  & > * {
    margin-bottom: 1rem;
  }

  .textarea {
    margin-bottom: 0;
    border-radius: 10px;
  }

  .input,
  .statusMessage {
    ${(p) => p.theme.helpers.dynamicFontSize(p.theme.typography.sizesSet.r7)};
  }
`;

export const FormContactSalesmanSubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * {
    width: 100%;
    max-width: 140px;
  }

  ${up('desktop')} {
    text-align: right;

    & > * {
      width: auto;
      max-width: 100%;
      min-width: 120px;
    }
  }
`;

export const ContactElement = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  width: calc(100% - 2em);
`;

export const FormContactSalesmanTextarea = styled(Textarea)`
  margin: 0 ${rem(5)};

  & .inputBox {
    border-radius: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 1px;
  }

  & textarea {
    height: 100px;
  }

  & textarea::-webkit-scrollbar {
    width: 10px;
  }

  & textarea::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 10px;
  }

  & textarea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid #eee;
    background-color: ${({ theme }) => theme.defaultPalette.monochrome[8]};
  }

  ${up(breakpointsKeys.DESKTOP)} {
    & textarea {
      height: 150px;
    }
  }
`;

export const FormSalesmanStatusMessage = styled(FormStatusMessage)`
  margin-top: -1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  text-align: left;
  margin-bottom: 0;
`;

export const FormSalesmanTopLink = styled(Link)`
  gap: 5px;

  & svg.icon {
    font-size: 18px;
    margin-right: 0;
    color: ${({ theme }) => theme.defaultPalette.teal[0]};
    margin-bottom: 2px;
  }

  & span.link-text {
    font-size: 18px;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 7px;
  }
`;
