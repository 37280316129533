import dynamic from 'next/dynamic';
import { useReducer } from 'react';
import Heading from '../Heading';

const ChooseSegmentModal = dynamic(() => import('./ChooseSegmentModal'), {
  ssr: false,
});
const PageBodyModal = dynamic(() => import('./PageBodyModal'), {
  ssr: false,
});

const popupsKeys = {
  FormChooseSegment: 0,
  RegulationComponent: 1,
};
const supportedPopupKeys = Object.keys(popupsKeys);

const init = (popupsData) => {
  const firstIdx = 0;
  const onlySupportedPopupsData = popupsData.filter(({ component }) => supportedPopupKeys.includes(component));

  return {
    popupsData: onlySupportedPopupsData,
    currentPopup: onlySupportedPopupsData[firstIdx],
    currentIdx: firstIdx,
  };
};

const actionTypes = {
  RESET: 0,
  NEXT: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.NEXT:
      const { currentIdx, popupsData } = state;
      const nextIdx = currentIdx + 1;

      return {
        ...state,
        currentIdx: nextIdx,
        currentPopup: popupsData?.[nextIdx],
      };
    case actionTypes.RESET:
      return init(action.payload);
    default:
      console.debug('ModalUserInfo: Unsupported reducer action: ', action.type);
  }
};

export const ModalUserInformation = ({ popupsData, user }) => {
  const [{ currentPopup }, dispatch] = useReducer(reducer, popupsData, init);

  const nextModalHandler = () => {
    dispatch({ type: actionTypes.NEXT });
  };

  switch (popupsKeys[currentPopup?.component]) {
    case popupsKeys.FormChooseSegment:
      return (
        <ChooseSegmentModal
          topChildren={
            !!currentPopup?.title ? (
              <Heading variant="octonary" fontSizeIndex={2}>
                {currentPopup?.title}
              </Heading>
            ) : null
          }
          onCloseModal={nextModalHandler}
        />
      );
    case popupsKeys.RegulationComponent:
      return (
        <PageBodyModal
          topChildren={
            !!currentPopup?.title ? (
              <Heading variant="octonary" fontSizeIndex={2}>
                {currentPopup?.title}
              </Heading>
            ) : null
          }
          onCloseModal={nextModalHandler}
          user={user}
        />
      );
    default:
      return null;
  }
};
