import styled from 'styled-components';
import { rem } from 'polished';

export const Overlay = styled.div`
  ${({ theme }) => theme.helpers.transition(['opacity'])};
  position: fixed;
  width: 100vw;
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[4]};
  top: 0;
  left: 0;
  z-index: 50;
  height: ${({ bgHeight }) => rem(bgHeight)};
  opacity: ${({ displayOverlay }) => (displayOverlay ? 0.25 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
