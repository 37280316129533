import { rem } from 'polished';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

export const BrowserHistoryButton = styled.div`
  .shouldStickToBottom & {
    bottom: ${rem(90)};
  }

  ${(p) => p.theme.helpers.transition(['opacity', 'bottom'])};

  position: fixed;
  bottom: ${rem(20)};
  width: ${rem(50)}!important;
  height: ${rem(50)};
  right: ${(p) => (p.shouldMoveToLeft ? rem(80) : rem(20))};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 101;
  background: ${({ theme }) => theme.defaultPalette.monochrome[1]};
  color: ${({ theme }) => theme.defaultPalette.teal[0]};
  border: 2px solid ${({ theme }) => theme.defaultPalette.monochrome[0]};

  ${up('desktop')} {
    right: ${(p) => (p.shouldMoveToLeft ? rem(110) : rem(50))};
    bottom: ${rem(50)};
  }

  svg {
    transform: translateX(0);
    transition: transform 100ms ease-in 0ms;
    width: 1.3em;
    height: 1.3em;

    ${up('desktop')} {
      width: 1em;
      height: 1em;
    }
  }

  &:hover {
    svg {
      transform: translateX(-2px);
    }
  }
`;
