import GET_LOCAL_STATE from 'src/graphql/queries/getLocalState.gql';

export const Mutation = {
  setCachedLocale: (_root, { language }, { cache }) => {
    const { localState } = cache.readQuery({ query: GET_LOCAL_STATE });

    cache.writeQuery({
      query: GET_LOCAL_STATE,
      data: {
        localState: {
          ...localState,
          language,
        },
      },
    });

    return language;
  },
  setCachedClient: (_root, { clientType }, { cache }) => {
    const { localState } = cache.readQuery({ query: GET_LOCAL_STATE });

    const client = {
      clientType,
    };

    cache.writeQuery({
      query: GET_LOCAL_STATE,
      data: {
        localState: {
          ...localState,
          client,
        },
      },
    });

    return client;
  },
  setCachedBasketSummary: (_root, { value, currencySign, priceType, quantity }, { cache }) => {
    const { localState } = cache.readQuery({ query: GET_LOCAL_STATE });

    const basketSummary = {
      value,
      currencySign,
      priceType,
      quantity,
    };

    const newLocalState = {
      localState: {
        ...localState,
        basketSummary: {
          ...basketSummary,
        },
      },
    };

    cache.writeQuery({
      query: GET_LOCAL_STATE,
      data: newLocalState,
    });

    return basketSummary;
  },
  setCachedBalancePanel: (_root, { availableBalance, currencySign, paymentType }, { cache }) => {
    const { localState } = cache.readQuery({ query: GET_LOCAL_STATE });

    const balancePanel = {
      availableBalance,
      currencySign,
      paymentType,
    };

    cache.writeQuery({
      query: GET_LOCAL_STATE,
      data: {
        localState: {
          ...localState,
          balancePanel,
        },
      },
    });

    return balancePanel;
  },
  setCachedRoutingMap: (_root, { routingMap }, { cache }) => {
    const { localState } = cache.readQuery({ query: GET_LOCAL_STATE });

    cache.writeQuery({
      query: GET_LOCAL_STATE,
      data: {
        localState: {
          ...localState,
          routingMap,
        },
      },
    });

    return routingMap;
  },
  setCachedNewNotificationsCount: (_root, { newNotificationsCount }, { cache }) => {
    const { localState } = cache.readQuery({ query: GET_LOCAL_STATE });
    cache.writeQuery({
      query: GET_LOCAL_STATE,
      data: {
        localState: {
          ...localState,
          newNotificationsCount,
        },
      },
    });

    return newNotificationsCount;
  },
};
