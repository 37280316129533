import nextWithApollo from 'next-with-apollo';
import fetch from 'isomorphic-unfetch';
import nodeFetch from 'node-fetch';
import { ApolloClient, Observable } from '@apollo/client/core';
import { HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloProvider } from '@apollo/client/react';
import { RestLink } from 'apollo-link-rest';
import { from } from '@apollo/client/link/core';
import GET_LOCAL_STATE from 'src/graphql/queries/getLocalState.gql';
import * as configs from 'src/graphql/configs';
import * as resolvers from 'src/graphql/resolvers';
import * as typeDefs from 'src/graphql/typeDefs';
import { httpStatusCodesMap, httpStatusCodesKeys } from 'src/enums/httpStatusCodes';
import { localState } from 'src/graphql/initialLocalState';
import { SHOP_CLIENT_NAME } from 'src/graphql/constants';

export const withApollo = nextWithApollo(
  ({ initialState, ctx }) => {
    if (global.Headers == null) {
      global.Headers = nodeFetch.Headers;
    }

    const datoLinkConfig = {
      ...configs.contentDatasourceApolloClientConfig,
      fetch,
    };

    const restLinkConfig = {
      ...configs.shopDatasourceApolloClientConfig,
      credentials: 'include',
      customFetch: fetch,
    };

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.debug(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
        });
      }
      if (networkError) {
        console.debug(`[Network error]: ${networkError}`);
      }
    });

    if (ctx) {
      restLinkConfig.headers = {
        cookie: ctx.req.headers.cookie || '',
        host: ctx.req.headers.host || '',
        Referer: ctx.req.headers.referer || '',
        'user-agent': ctx.req.headers['user-agent'] || '',
        'accept-language': ctx.req.headers['accept-language'] || '',
        'accept-encoding': ctx.req.headers['accept-encoding'] || '',
        'X-Url': ctx.req.url || '',
        'X-Forwarded-Host': ctx.req.headers['x-forwarded-host'] || '',
        'X-Forwarded-For': ctx.req.headers['x-forwarded-for'] || '',
        'X-SSR': '1',
      };
    }

    const datoLink = new HttpLink(datoLinkConfig);
    const restLink = new RestLink(restLinkConfig);
    // const fragmentMatcher = new ProgressiveFragmentMatcher();

    const cache = new InMemoryCache({
      freezeResults: true,
      // fragmentMatcher,
    }).restore(initialState);

    cache.writeQuery({
      query: GET_LOCAL_STATE,
      data: { localState },
    });

    const catch404ErrorFromShopApi = new ApolloLink((operation, forward) => {
      const contextBefore = operation.getContext();
      if (contextBefore.clientName !== SHOP_CLIENT_NAME) {
        return forward(operation);
      }

      const observable = new Observable((observer) => {
        const sub = forward(operation).subscribe(
          (data) => {
            const contextAfter = operation.getContext();
            const restResponseObject = contextAfter?.restResponses?.[0] || null;
            if (httpStatusCodesMap.get(restResponseObject?.status) === httpStatusCodesKeys.NOT_FOUND) {
              const error = new Error('Response not successful: Received status code 404');
              error.statusCode = 404;
              error.response = restResponseObject;
              observer.error(error);
            } else {
              observer.next(data);
            }
          },
          (e) => observer.error(e),
          () => observer.complete(),
        );

        return () => {
          sub.unsubscribe();
        };
      });

      return observable;
    });

    return new ApolloClient({
      ssrMode: Boolean(ctx),
      link: from([catch404ErrorFromShopApi, restLink, errorLink, datoLink]),
      assumeImmutableResults: true,
      cache,
      resolvers,
      typeDefs,
    });
  },
  {
    // eslint-disable-next-line react/display-name
    render: ({ Page, props }) => (
      // eslint-disable-next-line
      <ApolloProvider client={props.apollo}>
        <Page {...props} />
      </ApolloProvider>
    ),
  },
);
