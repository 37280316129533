import { SuggestionsDropdown } from './SuggestionsDropdown';
import { BaseInput } from './BaseInput';
import InputMask from './InputMask';

const removeDefaultValueInheritedFromDownshiftProps = (
  inputProps,
  defaultValueToAttach,
  didDownshiftRendered = false,
  refToAttach = null,
) => {
  const inputPropsCopy = { ...inputProps };
  const { value } = inputPropsCopy;

  delete inputPropsCopy.defaultValue;

  if (!didDownshiftRendered) {
    inputPropsCopy.value = value || defaultValueToAttach;
  }

  if (refToAttach) {
    inputPropsCopy.ref = refToAttach;
  }

  return inputPropsCopy;
};

const enhancePropsWithMask = (mask, maskOptions, inputBaseProps) => {
  /* eslint-disable-next-line */
  const iMask = require('imask/esm').default;
  const iMaskConfig = {
    mask,
    ...maskOptions,
  };
  const { ref } = inputBaseProps;

  return {
    ...inputBaseProps,
    ref: (element) => {
      if (element) {
        iMask(element, iMaskConfig);
        if (ref) ref(element);
      }
    },
  };
};

const renderSuggestionsDropdown = (inputDefaultValue, inputProps, labelId, suggestionsMenuId, suggestions, ref) => (
  <SuggestionsDropdown id={suggestionsMenuId} labelledBy={labelId} items={suggestions}>
    {(downshiftInputProps, didDownshiftRendered) => (
      <BaseInput
        ref={ref}
        {...removeDefaultValueInheritedFromDownshiftProps(
          {
            ...inputProps,
            ...downshiftInputProps,
            ['aria-controls']: suggestionsMenuId,
            ['aria-autocomplete']: 'list',
          },
          inputDefaultValue,
          didDownshiftRendered,
        )}
      />
    )}
  </SuggestionsDropdown>
);

export const renderInput = ({
  inputBaseProps,
  suggestionsMenuId,
  labelId,
  suggestions,
  mask,
  defaultValue,
  iMaskConfig,
  setIsInputFocused,
  isInputFocused,
  isInputHover,
  setIsInputHover,
  placeholderChar,
  setIsFilled,
}) => {
  const hasMask = !!mask;
  const hasSuggestions = !!suggestions.length;

  if (hasMask) {
    const inputPropsWithMask = enhancePropsWithMask(mask, iMaskConfig, inputBaseProps);

    if (hasSuggestions) {
      const { ref } = inputPropsWithMask;
      /* INFO: lazy prop have to be true in order for masking to work with suggestions */
      inputPropsWithMask.lazy = true;

      return renderSuggestionsDropdown(defaultValue, inputPropsWithMask, labelId, suggestionsMenuId, suggestions, ref);
    }

    const { ref: inputRef, defaultValue: inputDefaultValue, value, onInput, ...rest } = inputBaseProps;

    const currentValue = `${inputDefaultValue}` || value || '';

    if (placeholderChar) {
      return (
        <InputMask
          mask={mask}
          onInput={(element) => {
            if (onInput) {
              onInput(element);
            }
            setIsFilled(!!currentValue?.length);
          }}
          {...iMaskConfig}
          {...rest}
          inputRef={(ele) => {
            setIsFilled(!!currentValue?.length);
            if (typeof inputRef === 'function') {
              inputRef(ele);
            } else {
              return inputRef;
            }
          }}
          value={currentValue}
          placeholderChar={placeholderChar || '_'}
          lazy={!(isInputHover || isInputFocused)}
          onFocus={() => setIsInputFocused(true)}
          onMouseOver={() => setIsInputHover(true)}
          onMouseOut={() => setIsInputHover(false)}
          onBlur={() => setIsInputFocused(false)}
        />
      );
    }

    return (
      <InputMask
        mask={mask}
        {...iMaskConfig}
        {...rest}
        onInput={(e) => {
          if (onInput) {
            onInput(e);
          }
          setIsFilled(!!e.target.value);
        }}
        inputRef={(ele) => {
          setIsFilled(!!currentValue?.length);
          if (typeof inputRef === 'function') {
            inputRef(ele);
          } else {
            return inputRef;
          }
        }}
        value={currentValue}
      />
    );
  }

  const { ref: inputRef, defaultValue: inputDefaultValue, value, ...rest } = inputBaseProps;

  if (!hasMask && hasSuggestions) {
    return renderSuggestionsDropdown(defaultValue, inputBaseProps, labelId, suggestionsMenuId, suggestions);
  }

  return <BaseInput {...inputBaseProps} ref={inputRef} {...rest} />;
};
