import styled from 'styled-components';

const SVG = styled.svg``;

export const PersonBold = ({ className, ...rest }) => {
  return (
    <SVG
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        //eslint-disable-next-line
        d="M10.0403 9.48667C10.9373 9.68807 11.8234 9.99009 12.6992 10.3946C12.9956 10.5375 13.2243 10.7374 13.4 11.0022C13.5709 11.2599 13.6519 11.5302 13.6516 11.8329V11.834V13.3173H0.951563V11.834C0.951563 11.5301 1.03302 11.2592 1.20405 11.0014L0.495763 10.5315L1.20406 11.0014C1.37989 10.7364 1.6083 10.5369 1.90385 10.3946C2.77969 9.98954 3.6658 9.68724 4.56278 9.48583C5.46112 9.28412 6.3735 9.18346 7.30109 9.18398H7.30156C8.22931 9.18398 9.14185 9.28492 10.0403 9.48667ZM7.30156 6.65065C6.5185 6.65065 5.87924 6.40027 5.33656 5.89779C4.79587 5.39715 4.55156 4.83465 4.55156 4.16732C4.55156 3.49998 4.79587 2.93749 5.33656 2.43685C5.87924 1.93437 6.5185 1.68398 7.30156 1.68398C8.08462 1.68398 8.72389 1.93437 9.26657 2.43685C9.80726 2.93749 10.0516 3.49998 10.0516 4.16732C10.0516 4.83465 9.80726 5.39715 9.26657 5.89779C8.72389 6.40027 8.08462 6.65065 7.30156 6.65065Z"
        stroke="#00857E"
        strokeWidth="1.7"
      />
    </SVG>
  );
};
