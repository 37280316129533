import App from 'next/app';
import { useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/client/react';
import GET_CACHED_LOCALE from 'src/graphql/queries/getCachedLocale.gql';
import SET_CACHED_ROUTING_MAP from 'src/graphql/mutations/setCachedRoutingMap.gql';
import { additionalMenuMobileHamburger, headerLeftSideMenu } from 'config/navigations';
import { defaultLanguage, otherLanguages } from 'config/locales';
import { getCachedRoutingMap } from 'src/utils/getCachedRoutingMap';
import {
  getUserInfo,
  getBasketInfo,
  getEnvironment,
  getStylesFromDato,
  getBalancePanel,
  getCategories,
  getNavigation,
  getExtendedNavigation,
  getSearchMenuPagesData,
} from './onnshopSessionDataUtils';
import { getCoreRoutesInfoByLocale } from 'src/utils/getCoreRoutesInfoByLocale';
import { fromEntriesPolyfill } from 'src/utils/objectFromEntriesPolyfill';
import BlankLayout from 'src/layouts/BlankLayout';
import MainLayout from 'src/layouts/MainLayout';
import ErrorPage404 from 'src/components/ErrorPage404';

const getLanguage = async (ctx) => {
  const { apolloClient, req } = ctx;
  const isSSR = !!req;
  let locale = null;

  if (isSSR) {
    locale = req.locale;
  } else {
    const { language } = await apolloClient.readQuery({
      query: GET_CACHED_LOCALE,
    });

    locale = language;
  }

  return locale;
};

const createLocaleSlugsObject = (routingMap, language) => {
  const localeSlugs = {};

  if (routingMap.slugsMapByLocale) {
    const slugsForLanguage = routingMap.slugsMapByLocale[language];
    localeSlugs[language] = [...slugsForLanguage];

    if (routingMap.slugsMapByLocale[defaultLanguage] && language === defaultLanguage) {
      localeSlugs[defaultLanguage] = [...routingMap.slugsMapByLocale[defaultLanguage]];
    }

    return localeSlugs;
  }
};

const filterByKey = (obj, targetKey) => {
  return { [targetKey]: obj[targetKey] };
};

const removeNonTargetLanguageKeys = (map, targetLanguage) =>
  Array.from(map).reduce((newMap, [key, value]) => {
    const shouldAdd =
      filterByKey(value.name, targetLanguage)[targetLanguage] &&
      filterByKey(value.pattern, targetLanguage)[targetLanguage] &&
      filterByKey(value.pageTitle, targetLanguage)[targetLanguage];

    if (shouldAdd) {
      newMap.set(key, {
        id: value.id,
        parentId: value.parentId,
        layout: value.layout,
        name: filterByKey(value.name, targetLanguage),
        pattern: filterByKey(value.pattern, targetLanguage),
        pageTitle: filterByKey(value.pageTitle, targetLanguage),
      });
    }
    return newMap;
  }, new Map());

const filterKeysByLanguage = (obj, language) => {
  const filteredKeys = {};

  for (const key in obj) {
    if (key.startsWith(language + '/') || key === language) {
      filteredKeys[key] = obj[key];
    }
  }

  return filteredKeys;
};
const filterKeyByDefaultLanguage = (obj) => {
  const missingKeysWithValues = {};

  for (const key in obj) {
    const value = obj[key];
    const hasLanguagePrefix = otherLanguages.some((lang) => key.startsWith(lang + '/'));
    const hasLanguage = otherLanguages.some((lang) => key.includes(lang));

    if (((!hasLanguagePrefix || !hasLanguage) && !otherLanguages.includes(key)) || key === '') {
      missingKeysWithValues[key] = value;
    }
  }

  return missingKeysWithValues;
};

export const createBasicPageWithMainLayoutAndProps = (PageComponent, pagePrefix, initialPropsCreator) => {
  // NOTE: HoC for original Page
  const PageWithMainLayoutAndProps = (props) => {
    const {
      user,
      basket,
      title,
      locale,
      metaTags,
      asPath,
      errorPage404Props,
      datoStyleCSS,
      balancePanelDetails,
      routingMap,
      categories,
      topMenuNavigation,
      bottomLeftNavigation,
      rightBottomNavigation,
      searchMenuPagesData,
      search,
    } = props;

    const hrefLangData = props?.hrefLangData;
    // TODO: opportunity for future improvement
    const currentPageRoutingInfo = props?.currentPageRoutingInfo;

    const [setCachedRoutingMap] = useMutation(SET_CACHED_ROUTING_MAP, {
      variables: { routingMap },
    });

    const apolloClient = useApolloClient();

    // Set initial language
    useEffect(() => {
      apolloClient.writeQuery({
        query: GET_CACHED_LOCALE,
        data: {
          language: locale,
        },
      });
    }, [apolloClient, locale]);

    useEffect(() => {
      if (routingMap && Object.keys(routingMap).length > 0) {
        setCachedRoutingMap();
      }
    }, [setCachedRoutingMap, routingMap]);

    return (
      <MainLayout
        user={user}
        basket={basket}
        title={title}
        locale={locale}
        metaTags={metaTags}
        asPath={asPath}
        coreRoutesInfo={routingMap?.coreRoutesInfo}
        balancePanelDetails={balancePanelDetails}
        categories={categories}
        topMenuNavigation={topMenuNavigation}
        bottomLeftNavigation={bottomLeftNavigation}
        rightBottomNavigation={rightBottomNavigation}
        searchMenuPagesData={searchMenuPagesData}
        hrefLangData={hrefLangData}
        currentPageRoutingInfo={currentPageRoutingInfo}
        search={search}
      >
        {datoStyleCSS ? <style dangerouslySetInnerHTML={{ __html: datoStyleCSS }} /> : null}

        {errorPage404Props ? (
          <ErrorPage404 title={errorPage404Props.title} subtitle={errorPage404Props.subtitle} />
        ) : (
          <PageComponent {...props} />
        )}
      </MainLayout>
    );
  };

  // NOTE: GetInitialProps for wrapper component
  PageWithMainLayoutAndProps.getInitialProps = async (ctx) => {
    const inAppContext = Boolean(ctx.ctx);
    const { apolloClient, req, asPath, query, res } = ctx;
    const isSSR = !!req;
    const user = await getUserInfo(apolloClient, isSSR);

    // TODO: `defaultLanguage` should change to await getLanguage(ctx) when multi languages will be on whole site
    const locale = await getLanguage(ctx);

    const [
      categories,
      topMenuNavigation,
      bottomLeftNavigation,
      rightBottomNavigation,
      basket,
      datoStyleCSS,
      environmentState,
      balancePanelDetails,
      searchMenuPagesData,
    ] = await Promise.all([
      getCategories(apolloClient, locale),
      getNavigation(additionalMenuMobileHamburger, apolloClient, locale, isSSR, user?.clientType),
      getNavigation(headerLeftSideMenu, apolloClient, locale, isSSR, user?.clientType),
      getExtendedNavigation(user, locale, apolloClient, isSSR),
      getBasketInfo(apolloClient, isSSR),
      getStylesFromDato(apolloClient),
      getEnvironment(apolloClient, isSSR),
      getBalancePanel(apolloClient, isSSR, user?.clientType),
      getSearchMenuPagesData(apolloClient, locale, isSSR),
      apolloClient.writeQuery({
        query: GET_CACHED_LOCALE,
        data: {
          language: locale,
        },
      }),
    ]);

    const baseInitialProps = await initialPropsCreator({
      locale,
      apolloClient,
      asPath,
      isSSR,
      ctx,
      req,
      query,
      user,
      res,
    });

    const initialProps = {
      ...baseInitialProps,
      user,
      basket,
      locale,
      datoStyleCSS,
      balancePanelDetails,
      categories,
      environmentState,
      topMenuNavigation,
      bottomLeftNavigation,
      rightBottomNavigation,
      searchMenuPagesData,
    };

    const ctxWithInitialProps = { ...ctx, ...initialProps };
    let childPageInitialProps = {};

    // NOTE: If original component has getInitialProps, get its result
    if (PageComponent.getInitialProps) {
      childPageInitialProps = await PageComponent.getInitialProps(ctxWithInitialProps);
    } else if (inAppContext) {
      childPageInitialProps = await App.getInitialProps(ctxWithInitialProps);
    }

    let routingMap = null;

    if (req) {
      const coreRoutesInfo = getCoreRoutesInfoByLocale(req.routingMap.coreRoutesInfo, locale);

      const localeSlugs = createLocaleSlugsObject(req.routingMap, locale);

      const routesMap = removeNonTargetLanguageKeys(req.routingMap.routesMap, locale);

      const isDefaultLanguage = locale === defaultLanguage;
      const slugsMapObject = fromEntriesPolyfill(req.routingMap.slugsMap);
      const filteredKeys = !isDefaultLanguage
        ? filterKeysByLanguage(slugsMapObject, locale)
        : filterKeyByDefaultLanguage(slugsMapObject);

      const slugsMap = new Map(Object.entries(filteredKeys));

      routingMap = {
        coreRoutesInfo,
        routesMap: [...routesMap],
        slugsMap: [...slugsMap],
        slugsMapByLocale: localeSlugs,
      };
    }
    if (!req && !routingMap) {
      routingMap = await getCachedRoutingMap({ apolloClient });
    }

    return {
      ...childPageInitialProps,
      ...initialProps,
      routingMap,
    };
  };

  // NOTE: Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';
    PageWithMainLayoutAndProps.displayName = `${pagePrefix}(${displayName})`;
  }

  return PageWithMainLayoutAndProps;
};

export const createBasicPageWithBlankLayoutAndProps = (PageComponent, pagePrefix, initialPropsCreator) => {
  // NOTE: HoC for original Page
  const PageWithBlankLayoutAndProps = (props) => {
    const { user, basket, title, locale, coreRoutesInfo, metaTags, asPath, showErrorPage404, datoStyleCSS } = props;

    const apolloClient = useApolloClient();

    // Set initial language
    useEffect(() => {
      apolloClient.writeQuery({
        query: GET_CACHED_LOCALE,
        data: {
          language: locale,
        },
      });
    }, [apolloClient, locale]);

    if (showErrorPage404) {
      return <ErrorPage404 />;
    }

    return (
      <BlankLayout
        user={user}
        basket={basket}
        title={title}
        locale={locale}
        metaTags={metaTags}
        asPath={asPath}
        coreRoutesInfo={coreRoutesInfo}
      >
        {datoStyleCSS ? <style dangerouslySetInnerHTML={{ __html: datoStyleCSS }} /> : null}

        <PageComponent {...props} />
      </BlankLayout>
    );
  };

  // NOTE: GetInitialProps for wrapper component
  PageWithBlankLayoutAndProps.getInitialProps = async (ctx) => {
    const inAppContext = Boolean(ctx.ctx);
    const { apolloClient, req, res, asPath, query } = ctx;
    const isSSR = !!req;
    const user = await getUserInfo(apolloClient, isSSR);

    // TODO: `defaultLanguage` should change to await getLanguage(ctx)
    const locale = defaultLanguage;

    const [basket, datoStyleCSS] = await Promise.all([
      getBasketInfo(apolloClient, isSSR),
      getStylesFromDato(apolloClient),
      getEnvironment(apolloClient, isSSR),
      apolloClient.writeQuery({
        query: GET_CACHED_LOCALE,
        data: {
          language: locale,
        },
      }),
    ]);

    const baseInitialProps = await initialPropsCreator({
      locale,
      apolloClient,
      asPath,
      isSSR,
      ctx,
      req,
      query,
      user,
      res,
    });

    if (!baseInitialProps) {
      return {
        showErrorPage404: true,
      };
    }

    const initialProps = { ...baseInitialProps, user, basket, locale, datoStyleCSS };

    const ctxWithInitialProps = { ...ctx, ...initialProps };
    let childPageInitialProps = {};

    // NOTE: If original component has getInitialProps, get its result
    if (PageComponent.getInitialProps) {
      childPageInitialProps = await PageComponent.getInitialProps(ctxWithInitialProps);
    } else if (inAppContext) {
      childPageInitialProps = await App.getInitialProps(ctxWithInitialProps);
    }

    return {
      ...childPageInitialProps,
      ...initialProps,
    };
  };

  // NOTE: Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';
    PageWithBlankLayoutAndProps.displayName = `${pagePrefix}(${displayName})`;
  }

  return PageWithBlankLayoutAndProps;
};
