import { useState } from 'react';
import StyledReactModal from 'styled-react-modal';
import * as S from './styles';

const defaultProps = {
  onEscapeKeydown: () => null,
  onBackgroundClick: () => null,
  afterOpen: () => null,
  afterClose: () => null,
  onContainerClick: () => null,
  isPhotoModal: false,
};

export const Modal = ({
  isOpen,
  onCloseClick = () => {},
  onEscapeKeydown,
  onBackgroundClick,
  afterOpen,
  afterClose,
  children,
  onContainerClick,
  isPhotoModal,
  isRounded = false,
  ...restProps
} = defaultProps) => {
  const [isOpenWhenMousDownOnContainer, setIsOpenWhenMousDownOnContainer] = useState(false);
  const handleBlockBodyAfterOpen = () => {
    if (document) {
      document.body.parentNode.style.setProperty('overflow', 'hidden');
    }
    afterOpen();
  };

  const handleBlockBodyAfterClose = () => {
    if (document) {
      document.body.parentNode.style.removeProperty('overflow');
    }
    afterClose();
  };

  const handleBackgroundClick = () => {
    if (isOpenWhenMousDownOnContainer) {
      setIsOpenWhenMousDownOnContainer(false);
      return;
    }
    onBackgroundClick();
  };

  return (
    <StyledReactModal
      isOpen={isOpen}
      onEscapeKeydown={onEscapeKeydown}
      onBackgroundClick={handleBackgroundClick}
      afterOpen={handleBlockBodyAfterOpen}
      afterClose={handleBlockBodyAfterClose}
    >
      <S.ModalContainer
        isRounded={isRounded}
        isPhotoModal={isPhotoModal}
        className="modal-container"
        onClick={onContainerClick && typeof onContainerClick === 'function' ? onContainerClick : null}
        onMouseDown={() => setIsOpenWhenMousDownOnContainer(true)}
        onMouseUp={() => setIsOpenWhenMousDownOnContainer(false)}
        {...restProps}
      >
        <S.ModalContainerInner isPhotoModal={isPhotoModal}>
          <S.CloseIcon icon="close" onClick={onCloseClick} isPhotoModal={isPhotoModal} />
          <S.Content className="modal-content">{children}</S.Content>
        </S.ModalContainerInner>
      </S.ModalContainer>
    </StyledReactModal>
  );
};

Modal.defaultProps = defaultProps;
