import styled, { css } from 'styled-components';
import { rem } from 'polished';

const ClearTheme = css`
  border: none;
  background: none;
  padding: 0;
`;

export const TooltipPopper = styled.div`
  ${({ theme }) => theme.helpers.dynamicFontSize(theme.typography.sizesSet.r7)};
  background: ${(p) => p.theme.defaultPalette.monochrome[0]};

  border: solid ${rem(1)} ${(p) => p.theme.defaultPalette.monochrome[2]};
  border-radius: 0;

  color: ${(p) => p.theme.defaultPalette.monochrome[3]};
  display: block;
  padding: 0.33em 1.25em;
  pointer-events: ${({ hideOnTooltipHover }) => (hideOnTooltipHover ? 'none' : 'all')};
  text-align: center;
  z-index: ${({ specificZIndex }) => specificZIndex};

  ${(p) =>
    p.hasMaxWidth &&
    css`
      max-width: ${rem(200)};
    `}

  &.photoTooltip-theme {
    ${ClearTheme};
  }

  &.breadcrumbs-theme {
    max-width: ${rem(300)};
    max-height: 0;
    ${ClearTheme};
  }

  &.invoice-theme {
    max-height: 0;
    ${ClearTheme};
  }
`;

export const TooltipPopperChildren = styled.div``;
