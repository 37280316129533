import vest, { test, enforce } from 'vest';

export const sendMessageSchemaVariables = {
  message: {
    max: 1024,
  },
};

export const sendMessageSchema = vest.create(({ message }) => {
  test('message', 'messageRequired', () => {
    enforce(message.trim()).isNotEmpty();
  });
  test('message', 'messageTooLong', () => {
    enforce(message.trim())
      .isString()
      .shorterThanOrEquals(sendMessageSchemaVariables.message.max);
  });
});
