import { breakpointsKeys } from 'src/enums/breakpoints';

import useCurrentCurrency from 'src/hooks/useCurrentCurrency';
import useTranslation from 'src/hooks/useTranslation';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import NotificationsIndicator from 'src/components/NotificationsIndicator';
import BurgerButton from '../BurgerButton';
import Search from '../Search';
import Logo from '../Logo';

import * as S from './styles';

export const ScrolledHeader = ({
  headerHeight,
  user,
  coreRoutesInfo,
  handleSearch,
  language,
  redirectToNewSearchPath,
  searchValue,
  isCurrentRouteProductsList,
  isPathContainingProductFilter,
  shouldDisplayBalancePanelDetails,
  balancePanelDetailsRedirectCfg,
  balancePanelDetails,
  paymentStatus,
  localState,
  headerBasketLinkProps,
  headerClipboardLinkProps,
  handleOpenMobileMenu,
  basketIndicatorData,
  filtersTooltipValue,
  setFiltersTooltipValue,
  setIsFiltersIcon,
  isFiltersIcon,
  shouldDisplayLogoAsH1,
  isStatic,
  headerRef,
}) => {
  const currentBreakpoint = useBreakpointDetector();
  const transformPrice = useCurrentCurrency({
    sign: localState.balancePanel.currencySign,
  });
  const { t } = useTranslation();
  const shouldRenderBurgerButton =
    currentBreakpoint !== breakpointsKeys.DESKTOP_LARGE && currentBreakpoint !== breakpointsKeys.HD;
  const shouldRenderNotificationsIndicator = currentBreakpoint !== breakpointsKeys.MOBILE && language === 'pl';
  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;
  return (
    <S.HeaderRefWrapper headerHeight={headerHeight}>
      <S.HeaderWrapper isStatic={isStatic} ref={headerRef}>
        <S.HeaderContent>
          <S.LogoWrapper>
            {shouldRenderBurgerButton && <BurgerButton onClick={handleOpenMobileMenu} />}
            {!isMobile && <Logo user={user} shouldDisplayLogoAsH1={shouldDisplayLogoAsH1} />}
          </S.LogoWrapper>
          <S.SearchWrapper user={user}>
            <Search
              onSearch={handleSearch}
              defaultValue={searchValue}
              coreRoutesInfo={coreRoutesInfo}
              locale={language}
              redirectToNewSearchPath={redirectToNewSearchPath}
              isCurrentRouteProductsList={isCurrentRouteProductsList}
              isPathContainingProductFilter={isPathContainingProductFilter}
              tooltipValue={filtersTooltipValue}
              setTooltipValue={setFiltersTooltipValue}
              setIsFiltersIcon={setIsFiltersIcon}
              isFiltersIcon={isFiltersIcon}
              user={user}
            />
          </S.SearchWrapper>
          {user ? (
            <S.UserControlsWrapper>
              {shouldDisplayBalancePanelDetails ? (
                <S.BalancePanelDetail
                  title={'availableBalance'}
                  redirectPageSlug={balancePanelDetailsRedirectCfg.slug}
                  redirectPageLayout={balancePanelDetailsRedirectCfg.layout}
                  tooltipTitle={
                    localState.balancePanel?.paymentStatusDescription || balancePanelDetails?.paymentstatusdesc
                  }
                >
                  <S.BalancePanelFreeBalanceValue status={paymentStatus}>
                    {transformPrice(localState.balancePanel.availableBalance)}
                  </S.BalancePanelFreeBalanceValue>
                </S.BalancePanelDetail>
              ) : null}
              <S.ControlsWrapper>
                {shouldRenderNotificationsIndicator && <NotificationsIndicator />}
                <S.HeaderClipboardControl defaultClipboardTitle={t('clipboard')} linkProps={headerClipboardLinkProps} />
                <S.HeaderBasketControl basketIndicatorData={basketIndicatorData} linkProps={headerBasketLinkProps} />
              </S.ControlsWrapper>
            </S.UserControlsWrapper>
          ) : (
            <S.GuestControlsWrapper>
              <S.HeaderGuestControl coreRoutesInfo={coreRoutesInfo} language={language} />
              <S.HeaderBasketControl linkProps={headerBasketLinkProps} basketIndicatorData={basketIndicatorData} />
            </S.GuestControlsWrapper>
          )}
        </S.HeaderContent>
      </S.HeaderWrapper>
    </S.HeaderRefWrapper>
  );
};
