import { useRef, useState, useEffect } from 'react';

export function useTooltipOpenByClickCloseWhenSelect(anchorElement, popperElement) {
  const timer = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!anchorElement) {
      return undefined;
    }
    const handleAnchorClick = () => {
      setIsOpen((open) => !open);
    };

    const handleOutsideAndChildrenClick = (e) => {
      if (!popperElement) {
        return;
      }

      if (!anchorElement.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideAndChildrenClick);
    anchorElement.addEventListener('click', handleAnchorClick);
    return () => {
      window.removeEventListener('click', handleOutsideAndChildrenClick);
      anchorElement.removeEventListener('click', handleAnchorClick);
    };
  }, [popperElement, anchorElement, timer]);

  return isOpen;
}
