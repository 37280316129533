import { useState, useLayoutEffect, useEffect, useCallback, useMemo, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client/react';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { query } from 'config/queries';

import { createNextLinkProps } from 'src/utils/createNextLinkProps';
import { prepareQuery } from 'src/utils/prepareQuery.js';
import { addScrollToHTML, removeScrollFromHTML } from 'src/utils/toggleScrollVisibility';

import { clientTypesKeys, clientTypesMap } from 'src/enums/clientsTypes';
import { priceTypesKeys, priceTypesMap } from 'src/enums/priceTypes';
import { freeBalanceStatus } from 'src/enums/freeBalanceStatus';
import { breakpointsKeys } from 'src/enums/breakpoints';

import SET_CACHED_NEW_NOTIFICATIONS_COUNT from 'src/graphql/mutations/setCachedNewNotificationsCount.gql';
import SET_CACHED_BASKET_SUMMARY from 'src/graphql/mutations/setCachedBasketSummary.gql';
import SET_CACHED_BALANCE_PANEL from 'src/graphql/mutations/setCachedBalancePanel.gql';
import CHANGE_CUSTOMER from 'src/graphql/mutations/changeCustomer.gql';
import GET_LOCAL_STATE from 'src/graphql/queries/getLocalState.gql';
import SET_CACHED_CLIENT from 'src/graphql/mutations/setCachedClient.gql';

import useProgressBar from 'src/hooks/useProgressBar';
import useRESTMutation from 'src/hooks/useRESTMutation';
import useTranslation from 'src/hooks/useTranslation';
import useLanguage from 'src/hooks/useLanguage';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';

import { menuTypes } from '../MobileMenu/MobileMenu';
import { getAnchorPositionAndScroll, selectElementById, scrollToAnchorOnLoad } from './navbarHelpers';

const BALANCE_WARNING_STATUS = 2;

const useNavbarLogic = ({
  markerRef,
  headerRef,
  coreRoutesInfo,
  user,
  balancePanelDetails,
  basket,
  dropDownMenuRef,
  searchMenuPagesData,
  icon,
  icontooltip,
  smallHeaderRef,
  isBasketPage,
}) => {
  const [isScrolledHeaderVisible, setIsScrolledHeaderVisible] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [initialMenuType, setInitialMenuType] = useState(menuTypes.main);
  const [shouldDropDownMenuOpen, setShouldDropDownMenuOpen] = useState(false);

  const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState(false);
  const [isFiltersIcon, setIsFiltersIcon] = useState(icon);
  const [filtersTooltipValue, setFiltersTooltipValue] = useState(icontooltip);
  const theme = useTheme();
  const currentBreakpoint = useBreakpointDetector();

  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;
  const mediaQueriesForRenderingBalancePanelDetails =
    currentBreakpoint === breakpointsKeys.DESKTOP ||
    currentBreakpoint === breakpointsKeys.DESKTOP_LARGE ||
    currentBreakpoint === breakpointsKeys.HD;

  const shouldRenderMobileMenu =
    isMobile || currentBreakpoint === breakpointsKeys.DESKTOP || currentBreakpoint === breakpointsKeys.TABLET;

  const scrolledHeaderOffset = isMobile
    ? theme.scrolledHeader.mobileStickyItemsOffset
    : theme.scrolledHeader.desktopStickyItemsOffset;

  const dropDownMenuAnchorRef = useRef(null);

  const [changeCustomer] = useRESTMutation(CHANGE_CUSTOMER);

  const progressBar = useProgressBar();

  const handleCloseDropdownMenu = () => {
    setIsDropDownMenuOpen(false);
    setShouldDropDownMenuOpen(false);
  };

  const router = useRouter();
  const { route, asPath, pathname: currentLayout } = router;
  const [_, hashToScroll] = asPath.split('#');
  const [language] = useLanguage();
  const { t: tSearch } = useTranslation('searchfilter');
  const isClientB2BType = clientTypesMap.get(user?.clientType) === clientTypesKeys.CLIENT_B2B;
  const {
    data: { localState },
  } = useQuery(GET_LOCAL_STATE);
  const homePageLayout = coreRoutesInfo.homePage.layout;
  const userB2bHomePageLayout = coreRoutesInfo.userB2bHomePage.layout;
  const userB2cHomePageLayout = coreRoutesInfo.userB2bHomePage.layout;

  const shouldDisplayLogoAsH1 =
    currentLayout === `/${homePageLayout}` ||
    currentLayout === `/${userB2bHomePageLayout}` ||
    currentLayout === `/${userB2cHomePageLayout}`;

  const [setCachedClient] = useMutation(SET_CACHED_CLIENT);
  const [setCachedBalancePanel] = useMutation(SET_CACHED_BALANCE_PANEL);
  const [setCachedNewNotificationsCount] = useMutation(SET_CACHED_NEW_NOTIFICATIONS_COUNT);
  const [setCachedBasketSummary] = useMutation(SET_CACHED_BASKET_SUMMARY);
  const brandPageLayout = coreRoutesInfo.brandPage.layout;
  const productsPageLayout = coreRoutesInfo.productsPage.layout;
  const searchPageLayout = coreRoutesInfo.searchPage.layout;
  const seriesPageLayout = coreRoutesInfo.seriesPage.layout;

  const paymentStatus = localState.balancePanel?.paymentStatus || balancePanelDetails?.paymentstatus;
  const isBalanceStatusOk = paymentStatus === freeBalanceStatus.GREEN;
  const pathWhenProductsPageIsMain = `/${coreRoutesInfo.productsPage.name[language]}`;

  useEffect(() => {
    router.events.on('routeChangeStart', handleCloseDropdownMenu);

    return () => {
      router.events.off('routeChangeStart', handleCloseDropdownMenu);
    };
  }, []);

  const handleOpenMobileMenu = useCallback((menuType) => {
    setInitialMenuType(menuType);
    removeScrollFromHTML();
    setIsMobileMenuOpen(true);
  }, []);

  useEffect(() => {
    if (!user) return;

    setCachedClient({
      variables: {
        clientType: user.clientType,
      },
    });
    setCachedNewNotificationsCount({
      variables: {
        newNotificationsCount: user.notificationscount,
      },
    });
    // eslint-disable-next-line
  }, [user, setCachedClient]);

  useEffect(() => {
    if (!balancePanelDetails) return;
    const { paymenttype, freebalance, currency, paymentstatus, paymentstatusdesc } = balancePanelDetails;

    setCachedBalancePanel({
      variables: {
        paymentType: paymenttype,
        availableBalance: freebalance,
        currencySign: currency,
        paymentStatus: paymentstatus,
        paymentStatusDescription: paymentstatusdesc,
      },
    });
  }, [balancePanelDetails, setCachedBalancePanel]);

  const handleChangeCustomer = async ({ selectedItem }) => {
    progressBar.start();

    await changeCustomer({
      variables: {
        input: {
          customerId: selectedItem.value.value,
        },
      },
    });

    return router.reload();
  };

  const handleCloseMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
    addScrollToHTML();
  }, []);

  useEffect(() => {
    const routeWithFiltersIcon = Object.keys(productsListPagesLayout).some(
      (productsListPagesLayoutItem) => productsListPagesLayout[productsListPagesLayoutItem] === route,
    );

    if (!routeWithFiltersIcon && isFiltersIcon) {
      setIsFiltersIcon(false);
      setFiltersIconTooltip('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productsListPagesLayout = useMemo(
    () => ({
      searchPage: `/${searchPageLayout}`,
      brandPage: `/${brandPageLayout}`,
      productsPage: `/${productsPageLayout}`,
      seriesPage: `/${seriesPageLayout}`,
    }),
    [searchPageLayout, brandPageLayout, productsPageLayout, seriesPageLayout],
  );

  const headerGuestLinkProps = createNextLinkProps(
    coreRoutesInfo.businessUserLoginPage.name[language],
    coreRoutesInfo.businessUserLoginPage.layout,
  );

  const headerBasketLinkProps = createNextLinkProps(
    coreRoutesInfo.basketPage.name[language],
    coreRoutesInfo.basketPage.layout,
  );

  const headerClipboardLinkProps = createNextLinkProps(
    coreRoutesInfo.clipboardPage.name[language],
    coreRoutesInfo.clipboardPage.layout,
  );

  const headerUserLinkProps = createNextLinkProps(
    coreRoutesInfo.userAccountPage.name[language],
    coreRoutesInfo.userAccountPage.layout,
  );

  const shouldDisplayBalancePanelDetails =
    user &&
    isClientB2BType &&
    localState?.balancePanel &&
    !user?.currentCustomer?.warningshow &&
    balancePanelDetails?.balancestatus !== BALANCE_WARNING_STATUS &&
    mediaQueriesForRenderingBalancePanelDetails;

  const balancePanelDetailsRedirectCfg = {
    slug: isBalanceStatusOk
      ? coreRoutesInfo.balancesAndStatisticsPage.name[language]
      : coreRoutesInfo.receivablesListPage.name[language],
    layout: isBalanceStatusOk
      ? coreRoutesInfo.balancesAndStatisticsPage.layout
      : coreRoutesInfo.receivablesListPage.layout,
  };

  const basketIndicatorData = {
    indicatorNumber: localState?.basketSummary?.quantity || basket?.lines,
    price: localState?.basketSummary?.value || basket.value,
    currencySign: localState?.basketSummary?.currencySign || basket.currencySign,
    net: priceTypesMap.get(localState?.basketSummary?.priceType || basket.priceType.type) === priceTypesKeys.NET,
  };

  const isCurrentRouteProductsList = useMemo(() => {
    /**
     * NOTE:
     * productsPageLayout have two variant. On when our path is `/produkty`
     * and secund when `/produkty/:productCategoryPath`. In first search
     * should work in standard mode
     */
    if (pathWhenProductsPageIsMain === asPath) {
      return false;
    }

    return Object.keys(productsListPagesLayout).some((layout) => productsListPagesLayout[layout] === route);
  }, [route, pathWhenProductsPageIsMain, asPath, productsListPagesLayout]);

  const shouldRenderDropDownMenu =
    route !== `/${homePageLayout}` && route !== `/${userB2bHomePageLayout}` && route !== `/${userB2cHomePageLayout}`;

  useEffect(() => {
    if (isDropDownMenuOpen === shouldDropDownMenuOpen) {
      return;
    }
    if (shouldDropDownMenuOpen) {
      const timer = setTimeout(() => {
        setIsDropDownMenuOpen(true);
      }, 150);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setIsDropDownMenuOpen(false);
    }
  }, [shouldDropDownMenuOpen, isDropDownMenuOpen, setIsDropDownMenuOpen]);

  useEffect(() => {
    const handleScroll = (e) => {
      const hashToAnchor = e?.target?.hash;
      const hashToId = e?.srcElement.parentElement?.hash;

      if ((!!hashToId || !!hashToAnchor) === false) return;

      if (!!hashToId) {
        e.preventDefault();
        const element = selectElementById(hashToId);
        getAnchorPositionAndScroll(element, scrolledHeaderOffset);
        return;
      }
      if (!!hashToAnchor) {
        const element = document.querySelector(`[name="${hashToAnchor.substring(1)}"]`);
        getAnchorPositionAndScroll(element, scrolledHeaderOffset);
      }
    };
    document.addEventListener('click', handleScroll);

    if (!hashToScroll) return;

    window.addEventListener('load', () => scrollToAnchorOnLoad(scrolledHeaderOffset));

    return () => {
      window.removeEventListener('load', () => scrollToAnchorOnLoad(scrolledHeaderOffset));
      document.removeEventListener('click', handleScroll);
    };
    /* eslint-disable-next-line */
  }, [hashToScroll]);

  useEffect(() => {
    if ((!document && typeof window === 'undefined') || !shouldDropDownMenuOpen || !shouldRenderDropDownMenu) {
      return;
    }

    const handleMouseLeave = (e) => {
      if (e.target.id === 'menuOverlay') {
        setShouldDropDownMenuOpen(false);
        return;
      }
      if (dropDownMenuRef.current?.contains(e.target) || dropDownMenuAnchorRef.current?.contains(e.target)) {
        return;
      }

      setShouldDropDownMenuOpen(false);
    };

    let timer = null;

    const handleDebounceMouseLeave = (e) => {
      if (timer !== null) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        handleMouseLeave(e);
      }, 10);
    };

    document.addEventListener('mousemove', handleDebounceMouseLeave);

    return () => {
      document.removeEventListener('mousemove', handleDebounceMouseLeave);
    };
  }, [shouldDropDownMenuOpen, shouldRenderDropDownMenu, dropDownMenuRef]);

  const searchMenuPages = searchMenuPagesData?.quickMenu?.items.map((item, i) => ({
    label: item.label,
    ...createNextLinkProps(item.goToPage.slug, item.goToPage.layout, language),
    innerRef: i === 0 ? dropDownMenuAnchorRef : null,
    onMouseOver: () => (!i && !shouldDropDownMenuOpen ? setShouldDropDownMenuOpen(true) : null),
  }));

  const createSearchUrl = (val) =>
    `${coreRoutesInfo.searchPage.name[language]}?${query}=/${tSearch('find')}:${prepareQuery(val)}`;

  const createNewSearchPath = useCallback(
    (val) => {
      const queryPrefix = `?${query}=`;

      if (asPath.includes(queryPrefix)) {
        if (asPath.includes(`${tSearch('find')}:`)) {
          const regExp = new RegExp(`\/${tSearch('find')}:([^\/]*)`);
          return asPath.replace(regExp, `/${tSearch('find')}:${prepareQuery(val)}`);
        }
        return `${asPath.replace('/', '')}/${tSearch('find')}:${prepareQuery(val)}`;
      }

      return `${asPath.replace('/', '')}?${query}=/${tSearch('find')}:${prepareQuery(val)}`;
    },
    [asPath, tSearch],
  );

  useEffect(() => {
    if (!basket) return;

    const { lines: quantity, value, currencySign, priceType } = basket;

    setCachedBasketSummary({
      variables: { quantity, value, currencySign, priceType: priceType.type },
    });
  }, [basket, setCachedBasketSummary]);

  const redirectToNewSearchPath = useCallback(
    (searchVal) => {
      router.push(
        productsListPagesLayout[
          Object.keys(productsListPagesLayout).find((layout) => productsListPagesLayout[layout] === route)
        ],
        createNewSearchPath(searchVal),
      );
    },
    [createNewSearchPath, router, route, productsListPagesLayout],
  );

  const isPathContainingProductFilter = asPath.includes(`${tSearch('product')}:`);

  const handleSearch = (searchVal) => {
    if (!isFiltersIcon) {
      router.push(coreRoutesInfo.searchPage.layout, createSearchUrl(searchVal));
    } else {
      redirectToNewSearchPath(searchVal);
    }
  };

  useEffect(() => {
    const { current } = markerRef;
    const observerCallback = ([entry]) => {
      if (shouldDropDownMenuOpen) return;
      return !entry.isIntersecting ? setIsScrolledHeaderVisible(true) : setIsScrolledHeaderVisible(false);
    };
    const headerVisibilityObserver = new IntersectionObserver(observerCallback);

    if (current) headerVisibilityObserver.observe(current);
    return () => headerVisibilityObserver.unobserve(current);
  }, [markerRef, shouldDropDownMenuOpen]);

  useLayoutEffect(() => {
    if (!!headerRef && typeof window !== 'undefined') {
      const { current } = headerRef;
      const headerHeight = current?.clientHeight;
      setHeaderHeight(headerHeight);
    }
  }, [headerRef]);

  useLayoutEffect(() => {
    if (!!smallHeaderRef && isBasketPage && typeof window !== 'undefined') {
      const { current } = smallHeaderRef;
      const headerHeight = current?.clientHeight;
      setHeaderHeight(headerHeight);
    }
  }, [smallHeaderRef, isBasketPage]);

  useEffect(() => {
    setIsFiltersIcon(icon);
    setFiltersTooltipValue(icontooltip);
  }, [icon, icontooltip]);

  return {
    isScrolledHeaderVisible,
    headerHeight,
    handleSearch,
    language,
    redirectToNewSearchPath,
    isCurrentRouteProductsList,
    isPathContainingProductFilter,
    shouldDisplayBalancePanelDetails,
    balancePanelDetailsRedirectCfg,
    paymentStatus,
    localState,
    headerBasketLinkProps,
    headerClipboardLinkProps,
    headerGuestLinkProps,
    handleCloseMobileMenu,
    handleOpenMobileMenu,
    isMobileMenuOpen,
    initialMenuType,
    basketIndicatorData,
    isClientB2BType,
    shouldRenderDropDownMenu,
    searchMenuPages,
    handleChangeCustomer,
    isDropDownMenuOpen,
    handleCloseDropdownMenu,
    headerUserLinkProps,
    filtersTooltipValue,
    setFiltersTooltipValue,
    setIsFiltersIcon,
    isFiltersIcon,
    shouldDisplayLogoAsH1,
    shouldRenderMobileMenu,
  };
};

export default useNavbarLogic;
