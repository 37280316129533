const whiteSpacePattern = /\s/g;

export const getFooterSliderEntries = (footerColumnsData = []) => {
  const { name, displayInMonochrome: shouldBeDisplayedInMonochrome } = footerColumnsData.find(
    ({ _modelApiKey }) => _modelApiKey === 'brand',
  ) || {
    name: '',
  };

  const formattedStringToQueryProducersLogo = name.replace(whiteSpacePattern, '');

  return { formattedStringToQueryProducersLogo, shouldBeDisplayedInMonochrome };
};
