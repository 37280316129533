import styled from 'styled-components';
import { em } from 'polished';

import ImportedIcon from 'src/components/Icon';
import ImportedText from 'src/components/Text';

export const Text = styled(ImportedText)`
  font-size: ${em(24)};
  grid-area: message;
`;

export const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  font-size: ${em(36)};
  grid-area: icon;
`;

export const HeadingWrapper = styled.div`
  display: grid;
  grid-template-areas: 'icon message close';
  grid-template-columns: ${em(45)} auto ${em(30)};
  grid-gap: ${em(5)};
`;

export const CloseIcon = styled(ImportedIcon)`
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[2]};
  color: ${({ theme }) => theme.defaultPalette.blazeOrange[0]};
  border-radius: 50%;
  padding: ${em(4)};
  width: ${em(30)};
  height: ${em(30)};
  grid-area: close;
`;
