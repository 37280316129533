import { gross } from './priceTypes';

// TODO: Should be fetched initially
export const defaultValue = 0;
export const defaultQuantity = 0;
export const defaultCurrencySign = 'zł';
export const defaultPriceType = gross;
export const defaultWeightUnit = 'kg';
export const productMinQuantity = 0;
export const productMaxQuantity = 999999;
