import { getBadgeTheme } from './badgeHelpers';
import * as S from './styles';

const defaultProps = {
  text: '',
  variant: '',
};

export const Badge = ({ text, variant, ...rest }) => (
  <S.Badge variant={getBadgeTheme(variant)} {...rest}>
    {text}
  </S.Badge>
);
Badge.defaultProps = defaultProps;
