import { Fragment } from 'react';

import NotificationsListSkeleton from './NotificationsListSkeleton';
import { NotificationsList } from './NotificationsList';

import * as S from './styles';

const defaultProps = {
  showSkeleton: false,
  fetchMore: () => null,
  changeNotificationStatus: () => null,
  isNotificationsPending: false,
  closedNotificationId: '',
  goToNotificationDetails: () => null,
  skeletonsQuantity: 0,
};

export const Notifications = ({
  markNotificationAsClosed,
  fetchMore,
  changeNotificationStatus,
  isNotificationsPending,
  closedNotificationId,
  goToNotificationDetails,
  skeletonsQuantity,
  showSkeleton,
  isMobileDevice,
} = defaultProps) => {
  return showSkeleton ? (
    <S.SkeletonWrapper>
      {Array.from({ length: skeletonsQuantity }, (_, index) => {
        const isDividerRendered = index !== skeletonsQuantity - 1;
        return (
          <Fragment key={index}>
            <NotificationsListSkeleton />
            {isDividerRendered && <S.Divider />}
          </Fragment>
        );
      })}
    </S.SkeletonWrapper>
  ) : (
    <NotificationsList
      markNotificationAsClosed={markNotificationAsClosed}
      fetchMore={fetchMore}
      changeNotificationStatus={changeNotificationStatus}
      isNotificationsPending={isNotificationsPending}
      closedNotificationId={closedNotificationId}
      goToNotificationDetails={goToNotificationDetails}
      isMobileDevice={isMobileDevice}
    />
  );
};
