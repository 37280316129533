import * as S from './styles';

const defaultProps = {
  successMessage: null,
  errorMessage: null,
  hideMargin: false,
  hidePadding: false,
};

export const FormStatusMessage = ({
  successMessage,
  errorMessage,
  hideMargin,
  hidePadding,
  ...restProps
} = defaultProps) => {
  const status = successMessage ? 'success' : 'error';
  const messageToShow = successMessage || errorMessage;

  return messageToShow ? (
    <S.FormStatusMessage status={status} hideMargin={hideMargin} hidePadding={hidePadding} {...restProps}>
      {messageToShow}
    </S.FormStatusMessage>
  ) : null;
};

FormStatusMessage.defaultProps = defaultProps;
