import GET_USER_INFO from 'src/graphql/queries/getUserInfo.gql';
import GET_BASKET_SUMMARY from 'src/graphql/queries/getBasketSummary.gql';
import GET_ENVIRONMENT from 'src/graphql/queries/getEnvironment.gql';
import GET_CUSTOM_STYLE from 'src/graphql/queries/getCustomStyle.gql';
import GET_MAIN_NAVIGATION from 'src/graphql/queries/getMainCustomNavigationByPermission.gql';
import GET_EXTENDED_NAVIGATION from 'src/graphql/queries/getExtendedNavigationWithCustomLinksByPermission.gql';
import GET_BALANCE_PANEL from 'src/graphql/queries/getBalancePanel.gql';
import GET_CATALOG_CATEGORIES from 'src/graphql/queries/getCatalogCategories.gql';
import GET_SEARCH_MENU from 'src/graphql/queries/getSearchMenu.gql';
import { clientTypesKeys, clientTypesMap } from 'src/enums/clientsTypes';
import { datoCMSQuery, shopQuery } from './apolloUtils';

export const getUserInfo = async (apolloClient, isSSR) => {
  let user = null;

  try {
    const { data: userData } = await shopQuery({
      query: GET_USER_INFO,
      variables: { query: 'user,availableCustomers,salesman,tags,storelc,pricetype,accessList,popups,notifications' },
      client: apolloClient,
      ssr: isSSR,
    });

    user = userData.userInfo;
  } catch {
    user = null;
  }

  return user;
};

export const getEnvironment = async (apolloClient, isSSR) => {
  let environment = null;
  try {
    const { data } = await shopQuery({
      query: GET_ENVIRONMENT,
      client: apolloClient,
      ssr: isSSR,
      fetchPolicy: 'cache-first',
    });

    environment = data.environment;
  } catch {
    environment = null;
  }

  return environment;
};

export const getBasketInfo = async (apolloClient, isSSR) => {
  let basket = null;

  try {
    const { data: basketData } = await shopQuery({
      query: GET_BASKET_SUMMARY,
      client: apolloClient,
      ssr: isSSR,
    });

    const { value, lines, pricetype, currencysig } = basketData.basketSummary;

    basket = {
      priceType: pricetype,
      currencySign: currencysig,
      value,
      lines,
    };
  } catch {
    basket = null;
  }

  return basket;
};

export const getStylesFromDato = async (apolloClient) => {
  let styleCSS = '';
  try {
    const { data } = await datoCMSQuery({
      query: GET_CUSTOM_STYLE,
      client: apolloClient,
    });

    styleCSS = data.style.css;
  } catch {
    styleCSS = '';
  }

  return styleCSS;
};

export const getBalancePanel = async (apolloClient, isSSR, clientType) => {
  const isB2BClient = clientTypesMap.get(clientType) === clientTypesKeys.CLIENT_B2B;
  let balanceHeaderDetails = null;

  if (isB2BClient) {
    try {
      const { data: balanceData } = await shopQuery({
        query: GET_BALANCE_PANEL,
        client: apolloClient,
        ssr: isSSR,
      });

      balanceHeaderDetails = balanceData?.balancePanelResponse;
    } catch {
      balanceHeaderDetails = null;
    }
  }

  return balanceHeaderDetails;
};

export const getCategories = async (apolloClient, locale) => {
  const { data: categoriesData } = await shopQuery({
    query: GET_CATALOG_CATEGORIES,
    client: apolloClient,
    fetchPolicy: 'cache-first',
    variables: {
      locale,
    },
  });

  const { categories } = categoriesData.catalog;

  return categories;
};

export const getNavigation = async (navName, apolloClient, locale, isSSR, clientType) => {
  if (!navName) {
    throw new Error('getNavigation: Navigation name must be passed!');
  }

  const permissions = {
    shouldDisplayForNotLoggedIn: clientType === undefined,
    shouldBeDisplayedForB2b: clientTypesMap.get(clientType) === clientTypesKeys.CLIENT_B2B,
    shouldBeDisplayedForB2c: clientTypesMap.get(clientType) === clientTypesKeys.CLIENT_B2C,
  };

  const { data } = await datoCMSQuery({
    query: GET_MAIN_NAVIGATION,
    client: apolloClient,
    ssr: isSSR,
    variables: {
      title: navName,
      locale,
      ...permissions,
    },
    fetchPolicy: 'cache-first',
  });

  return (
    data?.mainNavigationWithCustomLink?.b2bUsersNavigation?.set ||
    data?.mainNavigationWithCustomLink?.b2cUsersNavigation?.set ||
    data?.mainNavigationWithCustomLink?.guestUsersNavigation?.set ||
    []
  );
};

export const getExtendedNavigation = async (user, locale, apolloClient, isSSR) => {
  const GUEST_USER = undefined;
  const B2B_USER = 1;
  const B2C_USER = 0;

  const permissions = {};

  if (user?.clientType === GUEST_USER) {
    permissions.shouldDisplayForNotLoggedIn = true;
  } else if (user?.clientType === B2B_USER) {
    permissions.shouldBeDisplayedForB2b = true;
  } else if (user?.clientType === B2C_USER) {
    permissions.shouldBeDisplayedForB2c = true;
  }

  const { data } = await datoCMSQuery({
    query: GET_EXTENDED_NAVIGATION,
    client: apolloClient,
    ssr: isSSR,
    variables: {
      ...permissions,
      locale,
    },
    fetchPolicy: 'cache-first',
  });

  const accessNameList = (user?.accessList || []).map(({ name }) => name);

  const extendedNavigations = data?.allExtendedNavigationWithCustomizations || [];
  const filteredExtendedNavigations = extendedNavigations.map((extendedNavigation) => {
    const { linkToStandardNavigation } = extendedNavigation;
    const withoutNoAccessModule = {
      ...linkToStandardNavigation,
      set: linkToStandardNavigation.set.filter(
        (menuItem) => !accessNameList.includes(menuItem?.page?.requiredAccessToApiModules),
      ),
    };

    return {
      ...extendedNavigation,
      linkToStandardNavigation: withoutNoAccessModule,
    };
  });

  return filteredExtendedNavigations;
};

export const getSearchMenuPagesData = async (apolloClient, locale, isSSR) => {
  const { data: searchMenuPagesData } = await datoCMSQuery({
    query: GET_SEARCH_MENU,
    client: apolloClient,
    ssr: isSSR,
    variables: {
      locale,
    },
  });

  return searchMenuPagesData;
};
