import * as priceTypes from 'config/priceTypes';

export const priceTypesKeys = {
  GROSS: 'Gross',
  NET: 'Net',
};

export const priceTypesMap = new Map([
  [priceTypes.gross, priceTypesKeys.GROSS],
  [priceTypes.net, priceTypesKeys.NET],
]);
