import { contactStatusMap } from '../enums/contactStatus';

const checkIfSalesmanIsAvailable = (salesman) => salesman && contactStatusMap.get(salesman?.status);

export const getFirstAvailableSalesman = (salesman, replacementSalesman) => {
  const salesmanIsAvailable = checkIfSalesmanIsAvailable(salesman);
  const replacementIsAvailable = checkIfSalesmanIsAvailable(replacementSalesman);

  if (salesmanIsAvailable) {
    return salesman;
  }

  if (!salesmanIsAvailable) {
    return replacementIsAvailable ? replacementSalesman : salesman;
  }

  return null;
};
