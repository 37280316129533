import { defaultLanguage } from 'config/locales';

const stripSlash = (str) => str.slice(str.indexOf('/') === 0 ? 1 : 0);

export const createNextLinkProps = (href = '', layout = '', language = undefined) => {
  let hrefWithSlash = `/${stripSlash(href)}`;

  if (language) {
    const isOtherLanguage = language !== defaultLanguage;
    hrefWithSlash = isOtherLanguage ? `/${language}/${stripSlash(href)}` : `/${stripSlash(href)}`;
  }

  const linkProps = {};
  if (layout) {
    linkProps.isNextLink = true;
    linkProps.href = `/${stripSlash(layout)}`;
    linkProps.nextLinkProps = { as: hrefWithSlash };
  } else {
    linkProps.href = hrefWithSlash;
  }

  return linkProps;
};
