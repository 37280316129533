import { useCallback } from 'react';
import { useContext } from 'react';
import ContactFormContext from 'src/utils/ContactFormContext';

export const useGlobalContactFormViaLink = () => {
  const onMailLinkClickHandler = useContext(ContactFormContext);

  const addEventListenerByRef = useCallback(
    ($element) => {
      if ($element !== null && $element.onclick !== onMailLinkClickHandler) {
        /**
         * Note: I don't used addEventListener because
         * component often re-render and create too many events listener
         */
        $element.onclick = onMailLinkClickHandler;
      }
    },
    [onMailLinkClickHandler],
  );

  return addEventListenerByRef;
};
