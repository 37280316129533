import { useContext } from 'react';

import { EnvironmentContext } from 'src/providers/environment';

export const useEnvironmentContext = () => {
  const ctx = useContext(EnvironmentContext);
  if (!ctx) {
    throw new Error('Component beyond EnvironmentContext!');
  }

  return ctx;
};
