import { forwardRef, useMemo, useEffect } from 'react';
import GET_CATALOG_BRANDS from 'src/graphql/queries/getCatalogBrands.gql';
import useLazyShopQuery from 'src/hooks/useLazyShopQuery';
import { imagePlaceholder } from 'config/imagePlaceholder';
import Slider, { SliderPlaceholder } from '../Slider';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import { breakpointsKeys } from 'src/enums/breakpoints';
import * as S from './styles';

const getMaxSlidesPerPage = (maxSlidesPerPage) => (slidesPerPage) => {
  const hasTooManyPages = slidesPerPage > maxSlidesPerPage;
  return hasTooManyPages ? maxSlidesPerPage : slidesPerPage;
};

const defaultProps = {
  prefetchedProducersData: [],
  producers: '',
  maxSlidesPerPage: 7,
  greyscaleEffect: false,
  autoPlay: false,
  skipLoader: true,

  inverse: false,
  inverseLoadingCover: true,
  infinite: false,
  CMSSpacing: {},
};

export const ProducersList = forwardRef(
  (
    {
      prefetchedProducersData,
      producers,
      maxSlidesPerPage,
      greyscaleEffect,
      autoPlay,
      inverse,
      inverseLoadingCover,
      infinite,
      skipLoader,
      brandPageSlug,
      brandPageLayout,
      CMSSpacing,
      logoSize = 109,
      slideOffset,
      ...restProps
    } = defaultProps,
    ref,
  ) => {
    const producersSlugsArray = producers.split(',');
    const initialProducersData = prefetchedProducersData || [];
    const getMaxSlidesPerBp = useMemo(() => getMaxSlidesPerPage(maxSlidesPerPage), [maxSlidesPerPage]);
    const [getBrands, { data, loading, error }] = useLazyShopQuery(GET_CATALOG_BRANDS, {
      variables: {
        brands: producers,
      },
    });
    const isMobile = breakpointsKeys.MOBILE === useBreakpointDetector();

    useEffect(() => {
      if (!prefetchedProducersData?.length && !data) {
        getBrands();
      }
    }, [prefetchedProducersData, getBrands, data]);

    const hasProducersData = !prefetchedProducersData?.length && !loading && !error && !!data;

    const producersData = hasProducersData
      ? producersSlugsArray
          .map((slug) => data.catalog.brands.find((brand) => brand.slug.toLowerCase() === slug.toLowerCase()))
          .filter((brand) => !!brand)
      : initialProducersData;

    const slideAspectRatio = useMemo(
      () => ({
        x: 0,
        y: 0,
        offset: logoSize,
      }),
      [logoSize],
    );

    const loadingCoverAspectRatio = useMemo(
      () => ({
        mobile: slideAspectRatio,
        tablet: slideAspectRatio,
        desktop: slideAspectRatio,
        hd: slideAspectRatio,
      }),
      [slideAspectRatio],
    );

    const producerListSliderBreakpoints = useMemo(
      () => ({
        '(min-width: 1px) and (max-width: 479px)': {
          slidesPerView: getMaxSlidesPerBp(2.5),
        },
        '(min-width: 480px) and (max-width: 599px)': {
          slidesPerView: getMaxSlidesPerBp(2.5),
        },
        '(min-width: 600px) and (max-width: 719px)': {
          slidesPerView: getMaxSlidesPerBp(3),
        },
        '(min-width: 720px) and (max-width: 839px)': {
          slidesPerView: getMaxSlidesPerBp(4),
        },
        '(min-width: 840px) and (max-width: 959px)': {
          slidesPerView: getMaxSlidesPerBp(5),
        },
        '(min-width: 960px) and (max-width: 1079px)': {
          slidesPerView: getMaxSlidesPerBp(6),
        },
        '(min-width: 1080px)': {
          slidesPerView: getMaxSlidesPerBp(7),
        },
      }),
      [getMaxSlidesPerBp],
    );

    if (!producersData?.length) {
      return null;
    }

    return (
      <S.ProducersList logoSize={logoSize} hasInverseBg={inverse} CMSSpacing={CMSSpacing} {...restProps}>
        <SliderPlaceholder>
          <Slider
            ref={ref}
            arrowsShownOnHover
            inverseLoadingCover={inverseLoadingCover}
            infinite={infinite}
            showArrows={infinite && !isMobile}
            autoPlay={autoPlay}
            skipLoader={skipLoader}
            slideOffset={slideOffset}
            customBreakpoints={producerListSliderBreakpoints}
            loadingCoverAspectRatio={loadingCoverAspectRatio}
            arrowSize="small"
            slides={producersData.map(({ slug, name, logourl }) => {
              const lowerCaseSlug = slug.toLowerCase();

              return (
                <S.ProducersListCarouselItem key={lowerCaseSlug} size={logoSize}>
                  <S.ProducerLink
                    greyscaleEffect={greyscaleEffect}
                    key={lowerCaseSlug}
                    size={logoSize}
                    href={brandPageLayout}
                    isNextLink
                    nextLinkProps={{
                      as: `/${brandPageSlug}/${lowerCaseSlug}`,
                    }}
                    blank
                  >
                    {logourl ? (
                      <S.ProducerImg
                        width={logoSize}
                        height={logoSize}
                        alt={name}
                        src={logourl}
                        className="producerImg"
                      />
                    ) : (
                      <S.ProducerImg
                        width={logoSize}
                        height={logoSize}
                        alt={name}
                        src={imagePlaceholder}
                        className="producerImg"
                      />
                    )}
                  </S.ProducerLink>
                </S.ProducersListCarouselItem>
              );
            })}
          />
        </SliderPlaceholder>
      </S.ProducersList>
    );
  },
);

ProducersList.defaultProps = defaultProps;

ProducersList.displayName = 'ProducersList';
