import styled from 'styled-components';

const SVG = styled.svg``;

export const Bell = ({ className }) => {
  return (
    <SVG
      width="27"
      height="29"
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.0775 18.16H4.34507C3.65889 18.16 3.00081 18.4475 2.5156 18.9594C2.0304 19.4712 1.75781 20.1655 1.75781 20.8893C1.75781 21.6132 2.0304 22.3074 2.5156 22.8193C3.00081 23.3312 3.65889 23.6187 4.34507 23.6187H22.6549C23.3411 23.6187 23.9992 23.3312 24.4844 22.8193C24.9696 22.3074 25.2422 21.6132 25.2422 20.8893C25.2422 20.1655 24.9696 19.4712 24.4844 18.9594C23.9992 18.4475 23.3411 18.16 22.6549 18.16H21.8588M4.82497 14.5293C4.76852 12.168 5.64515 9.87854 7.267 8.15174C8.88885 6.42495 11.1269 5.39817 13.5014 5.29147C15.876 5.39817 18.114 6.42495 19.7358 8.15174C21.3577 9.87854 22.2343 12.168 22.1778 14.5293V17.9278M11.4356 25.934C11.4356 26.4784 11.6532 27.0006 12.0407 27.3856C12.4281 27.7705 12.9535 27.9868 13.5014 27.9868C14.0493 27.9868 14.5747 27.7705 14.9622 27.3856C15.3496 27.0006 15.5672 26.4784 15.5672 25.934M15.8257 3.06653C15.8257 2.52208 15.608 1.99992 15.2206 1.61494C14.8332 1.22995 14.3078 1.01367 13.7599 1.01367C13.212 1.01367 12.6865 1.22995 12.2991 1.61494C11.9117 1.99992 11.694 2.52208 11.694 3.06653" //eslint-disable-line
        stroke="#333333"
        stroke-width="2" //eslint-disable-line
        stroke-linecap="round" //eslint-disable-line
        stroke-linejoin="round" //eslint-disable-line
      />
    </SVG>
  );
};
