import { em, rem } from 'polished';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { breakpointsKeys } from 'src/enums/breakpoints';

import { BalancePanelDetail as ImportedBalancePanelDetail } from '../BalancePanelDetails/BalancePanelDetail';
import { HeaderClipboardControl as ImportedHeaderClipboardControl } from '../Header/HeaderClipboardControl';
import { HeaderBasketControl as ImportedHeaderBasketControl } from '../Header/HeaderBasketControl';
import { HeaderGuestControl as ImportedHeaderGuestControl } from '../Header/HeaderGuestControl';

const getFontColor = (theme, status) =>
  ({
    green: theme.defaultPalette.teal[1],
    yellow: theme.defaultPalette.blazeOrange[4],
    red: theme.defaultPalette.alizarinCrimson[0],
  }[status]);

export const HeaderRefWrapper = styled.header`
  height: ${({ headerHeight }) => em(headerHeight)};
`;

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.global.bg};
  position: ${({ isStatic }) => (isStatic ? 'static' : 'fixed')};
  display: flex;
  justify-content: center;
  z-index: 890;
  padding: ${em(7)} 0;
  width: 100%;
  ${up(breakpointsKeys.TABLET)} {
    background-color: ${({ theme }) => theme.global.bgAlt};
  }
`;

export const LogoWrapper = styled.div`
  /* padding-left: ${em(10)}; */
  display: flex;
  
  align-items: center;
  & > div {
    
    margin-bottom: unset;
    margin-top: unset;
  }
 ${up(breakpointsKeys.TABLET)}{
  margin-right: ${rem(30)};
 }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem(16)} 0 ${rem(16)};
  max-width: ${({ theme }) => theme.global.maxWidth};
  width: 100%;
`;

export const BalancePanelFreeBalanceValue = styled.div`
  color: ${({ status, theme }) => (!status ? 'inherit' : getFontColor(theme, status))};
`;

export const BalancePanelDetail = styled(ImportedBalancePanelDetail)`
  display: none;
  ${up(breakpointsKeys.DESKTOP_LARGE)} {
    margin: 0 ${em(15)};

    > .content {
      display: flex;
      gap: ${em(5)};
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
    display: block;
  }
`;

export const HeaderClipboardControl = styled(ImportedHeaderClipboardControl)`
  margin-top: ${em(10)};
  svg {
    ${up(breakpointsKeys.TABLET)} {
      color: ${({ theme }) => theme.scrolledHeader.actionsMainAccent};
    }
  }
`;

export const HeaderBasketControl = styled(ImportedHeaderBasketControl)`
  ${up(breakpointsKeys.TABLET)} {
    svg,
    > p {
      color: ${({ theme }) => theme.scrolledHeader.actionsMainAccent};
      opacity: 1;
    }
  }
`;

export const HeaderGuestControl = styled(ImportedHeaderGuestControl)`
  /* margin: ${em(10)} ${rem(7)} ${em(8)}; */
  .icon {
    font-size: 1.55em !important;
    margin-right: 0;
  }
  ${up(breakpointsKeys.TABLET)} {
    > .content {
      > *:not(:first-child) {
        color: ${({ theme }) => theme.scrolledHeader.actionsMainAccent};

        &:hover {
          color: ${({ theme }) => theme.scrolledHeader.actionsMainAccent};
        }
      }
    }
  }
  ${up(breakpointsKeys.DESKTOP)} {
    .icon {
      margin-right: ${em(8)};
    }
  }
`;

export const SearchWrapper = styled.div`
  ${({ user }) => user && `min-width: ${em(200)}`};

  flex: 1;
  > div > form > div:first-child {
    height: ${em(50)};
    ${down(breakpointsKeys.MOBILE)} {
      > div:nth-child(3) {
        right: 20px;
      }
    }
    > button {
      display: none;
    }
    > div {
      height: 100%;
    }
    .inputBox {
      height: 100%;
    }
    ${up(breakpointsKeys.TABLET)} {
      > button {
        display: block;
      }
    }
  }
  > div > div:nth-child(2) {
    min-width: ${em(300)};
  }
`;

export const ControlsWrapper = styled.div`
  width: auto;
  display: flex;
  gap: ${rem(7)};

  margin: 0 ${rem(2)} 0 ${rem(10)};
  ${up(breakpointsKeys.TABLET)} {
    gap: ${rem(18)};
    margin-left: ${rem(20)};
  }
`;

export const GuestControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(7)};
  margin-left: ${em(20)};
`;

export const UserControlsWrapper = styled.div`
  display: flex;
  margin-left: ${rem(10)};
  ${up(breakpointsKeys.DESKTOP)} {
    margin-left: 0;
  }
`;
