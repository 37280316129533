import styled from 'styled-components';

export const Form = styled.form``;

export const FormRow = styled.div`
  position: relative;
  width: 100%;
`;

export const FormHideSubmit = styled.button.attrs({ type: 'submit' })`
  display: none;
`;
