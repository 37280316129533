import { createContext } from 'react';

const defaultTrigger = () => {
  console.debug('The dispatch function has not been passed to the provider has not been passed to the provider');
};

const TriggeringGlobalContactFormContext = createContext(defaultTrigger);
TriggeringGlobalContactFormContext.displayName = 'TriggeringGlobalContactFormContext';

export default TriggeringGlobalContactFormContext;
