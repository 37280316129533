import StyledReactModal from 'styled-react-modal';

import HeadingWithIcon from 'src/components/HeadingWithIcon';

import * as S from './styles';

const defaultProps = {
  onCloseClick: () => null,
  isOpen: false,
  title: '',
  icon: '',
};

export const FullscreenModal = ({ isOpen, onCloseClick, children, title, icon, ...restProps } = defaultProps) => {
  const handleBlockBodyAfterOpen = () => {
    if (document) {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      document.body.style.overflowY = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100vw';
      document.body.style.top = '-' + scrollPosition + 'px';
    }
  };

  const handleBlockBodyAfterClose = () => {
    if (document) {
      const scrollPosition = document.body.style.top;
      document.body.style.overflowY = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollPosition || '0') * -1);
    }
  };

  return (
    <StyledReactModal isOpen={isOpen} afterOpen={handleBlockBodyAfterOpen} afterClose={handleBlockBodyAfterClose}>
      <S.ModalContainer className="modal-container" {...restProps}>
        <S.ModalContainerInner>
          <HeadingWithIcon onCloseClick={onCloseClick} title={title} icon={icon} />
          <S.Divider />
          <S.Content className="modal-content">{children}</S.Content>
        </S.ModalContainerInner>
      </S.ModalContainer>
    </StyledReactModal>
  );
};

FullscreenModal.defaultProps = defaultProps;
